import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import { useAppSelector } from '../../redux/hooks';
import Input from '../Input/Input';
import Select from '../Select';
import './FilterListEmailTemplates.scss';
import FormSelect from "../FormSelect";
import AlphabetFilter from "../AlphabetFilter";
import {SelectValue} from "../../models/Properties";

interface Props {
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  sortProperty: (field: string) => void;
  sort: string;
  filterByLettersOnMobile: boolean;
  setFilterByLettersOnMobile: (p: boolean) => void;
}

const FilterListEmailTemplates: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  sort,
  sortProperty,
  filtersForm,
  setFiltersForm,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
}) => {
  const { languages } = useAppSelector(state => state.languages);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const onSelectMobileFilter = (e: SelectValue) => {
    if (e.value === (filterByLettersOnMobile ? '1' : '0')) {
      setFilterByLettersOnMobile(e.value === '0');
      setFiltersForm({});
      setCheckedAll && setCheckedAll(false);
    }
  };

  const options = [
    { value: '0', label: 'Filtrare dupa litere' },
    { value: '1', label: 'Filtrare dupa campuri' },
  ];

  const getSelectOptions = (objectsToMap: any) => {
    return objectsToMap.map((obj: any) => {
      return { value: obj.id, label: obj.title };
    });
  };

  const sortByProperty = (property: string) => {
    if (sortProperty)
      if (sort === property) {
        sortProperty(`-${property}`);
      } else if (sort === `-${property}`) {
        sortProperty('');
      } else {
        sortProperty(property);
      }
  }

  return (
    <div className='filter-list-container filter-list-email'>
      <form>
        <div className='form-item hide-on-desktop'>
          <FormSelect
              labelText={false}
              placeholder='Filtrare dupa'
              isSearchable={false}
              options={options}
              className='select-blue'
              onChange={onSelectMobileFilter}
              value={filterByLettersOnMobile ? options[0] : options[1]}
          />
        </div>
        {filterByLettersOnMobile && (
            <div className='alphabet-wrapper hide-on-desktop'>
              <AlphabetFilter
                  filtersForm={filtersForm}
                  setFiltersForm={setFiltersForm}
              />
            </div>
        )}
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
          <div className='form-item-group-label hide-on-desktop'>
            Selecteaza toate
          </div>
        </Form.Group>
        {(!isMobile || !filterByLettersOnMobile) && (
          <>
            <div
              className={`filter-item arrows-label-icon ${sort === 'id' ? 'active-bottom' : ''} ${sort === '-id' ? 'active-top' : ''}`}
              id='id-input'
              onClick={() => sortByProperty('íd')}
            >
              <Input inlineLabel='ID' placeholder='Id' labelText={true} />
            </div>
            <div className='filter-item'>
              <Input inlineLabel='Nume' placeholder='Search' labelText={true} />
            </div>
            <div className='filter-item'>
              <Select
                labelText={true}
                inlineLabel='Limba'
                options={getSelectOptions(languages)}
              />
            </div>
            <div className='filter-item'>
              <Input inlineLabel='Titlu' placeholder='Search' labelText={true} />
            </div>
            <div className='filter-item'>
              <Input
                inlineLabel='Descriere'
                placeholder='Search'
                labelText={true}
              />
            </div>
            <div className='filter-item'>
              <label className='form-item-group-label'>Actiuni</label>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default FilterListEmailTemplates;
