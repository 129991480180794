interface Props {}

const IconPlus: React.FC<Props> = props => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5833 11.9168H14.0833V5.41683C14.0833 4.81775 13.598 4.3335 13 4.3335C12.402 4.3335 11.9167 4.81775 11.9167 5.41683V11.9168H5.41667C4.81867 11.9168 4.33333 12.4011 4.33333 13.0002C4.33333 13.5992 4.81867 14.0835 5.41667 14.0835H11.9167V20.5835C11.9167 21.1826 12.402 21.6668 13 21.6668C13.598 21.6668 14.0833 21.1826 14.0833 20.5835V14.0835H20.5833C21.1813 14.0835 21.6667 13.5992 21.6667 13.0002C21.6667 12.4011 21.1813 11.9168 20.5833 11.9168Z'
        fill='white'
      />
    </svg>
  );
};

export default IconPlus;
