interface Props {}

const IconGalleryLeft: React.FC<Props> = props => {
  return (
    <svg
      width='5'
      height='9'
      viewBox='0 0 5 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.16648 0.667498C4.37981 0.667498 4.59314 0.749162 4.75563 0.911657C5.08146 1.23748 5.08146 1.76413 4.75563 2.08996L4.69896 2.14663C3.20166 3.64393 3.18053 6.06494 4.65147 7.58814C4.97063 7.9198 4.96146 8.44729 4.63063 8.76645C4.29898 9.0856 3.77149 9.07644 3.45233 8.74645L0.234085 5.4132C-0.0817398 5.08571 -0.0775732 4.56656 0.244085 4.2449L3.57733 0.911657C3.73982 0.749162 3.95315 0.667498 4.16648 0.667498Z'
        fill='black'
      />
    </svg>
  );
};

export default IconGalleryLeft;
