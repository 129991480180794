interface Props {}

const IconLabe: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M18.1,8.9l-6.4-6.4c-0.1-0.1-0.2-0.1-0.3-0.2L2,1.5h0c-0.1,0-0.3,0-0.4,0.2C1.5,1.8,1.5,1.9,1.5,2l0.9,9.3
          c0,0.1,0.1,0.2,0.2,0.3l6.4,6.4h0c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4l7.3-7.3c0.2-0.2,0.4-0.6,0.4-0.9
          C18.5,9.5,18.4,9.2,18.1,8.9z M9.9,17.6l-0.4-0.3l-6-6l-0.1-0.1l0-0.2L2.6,3.2L2.5,2.5l0.6,0.1l7.8,0.7l0.2,0l6.2,6.2l0.3,0.4
          L9.9,17.6z"
      />
      <circle cx="7.5" cy="7.5" r="1" />
    </svg>
  );
};

export default IconLabe;
