import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDropzone } from 'react-dropzone';
import SortableList, { SortableItem } from 'react-easy-sort';
import { toast } from 'react-toastify';
import ButtonSquare from '../../components/ButtonSquare/ButtonSquare';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { uploadImage } from '../../redux/media/mediaThunk';
import './ImagesForm.scss';


interface Props {
  images: any[];
  setImages: (image: any) => void;
  getPDF?: boolean;
  onlyDocAndPdf?: boolean;
}

interface LoadingImage extends File {
  progress?: number;
  path?: string;
}

interface LoadedImage extends File {
  progress: number;
  status: number;
  mediaId: number;
  filePath: string;
  path?: string;
  title?: string;
}

const ImagesForm: React.FC<Props> = ({
  images,
  setImages,
  getPDF = false,
  onlyDocAndPdf = false,
}) => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector(state => state.media);
  const [loadingImages, setLoadingImages] = useState<LoadingImage[]>([]);
  const [loadedImages, setLoadedImages] = useState<LoadedImage[]>([]);
  const [renamingImages, setRenamingImages] = useState<any[]>([]);

  useEffect(() => {
    if (loadedImages.length === 0 && images.length > 0) {
      setLoadedImages(
        images.map(img => {
          return {
            ...img,
            filePath: img.media?.path,
            mediaId: img.media?.id,
            name: img.title,
            status: img.status,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    loadingImages.forEach(image => {
      if (loadedImages.find(img => img.path === image.path))
        setLoadingImages(loadingImages.filter(img => image.name !== img.name));
    });
  }, [loadingImages, loadedImages]);

  useEffect(() => {
    setImages(
      loadedImages.map((image, index) => {
        return {
          mediaId: image.mediaId,
          status: image?.status,
          priority: index + 1,
          title: image?.title ?? image?.name ?? '',
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedImages, setLoadedImages]);

  const onDrop = (acceptedFiles: File[]) => {
    setLoadingImages(acceptedFiles);
    acceptedFiles.length > 0 &&
      acceptedFiles?.map((file: File) =>
        dispatch(
          uploadImage({
            file: file,
            type: file?.type === 'application/pdf' ? 'DOCUMENT' : 'IMAGE',
            setProgress: (e: number) =>
              setLoadingImages(
                acceptedFiles.map((item: any) => {
                  let imageFile = item;
                  if (item.name === file.name) item.progress = e;
                  return imageFile;
                }),
              ),
          }),
        ).then(res => {
          if (res.payload?.id) {
            setLoadedImages([
              ...loadedImages,
              ...acceptedFiles.map((item: any) => {
                let imageFile = item;
                if (item.name === file.name) {
                  imageFile.mediaId = res.payload?.id;
                  imageFile.filePath = res.payload?.filePath;
                  imageFile.status = 1;
                }
                return imageFile;
              }),
            ]);
          }
        }),
      );
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    onDrop: onDrop,
  });

  useEffect(() => {
    toast.error(error);
  }, [error]);

  const removeImage = (mediaId: number) => {
    setLoadedImages(loadedImages.filter(image => image.mediaId !== mediaId));
  };

  const removeLoadingImage = (name: string) => {
    setLoadingImages(loadingImages.filter(image => image.name !== name));
  };

  const renameImage = (mediaId: number) => {
    setRenamingImages([
      ...renamingImages,
      {
        id: mediaId,
        value: loadedImages.find(img => img.mediaId === mediaId)?.name,
      },
    ]);
  };

  const saveRenamingImage = (mediaId: number) => {
    setRenamingImages(renamingImages.filter(img => img.id !== mediaId));
    setLoadedImages(
      loadedImages.map(image => {
        if (image.mediaId === mediaId)
          return {
            ...image,
            title: renamingImages.find(img => img.id === mediaId)?.value,
          };
        else return image;
      }),
    );
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setLoadedImages(array => arrayMove(array, oldIndex, newIndex));
  };

  return (
    <div className='ads-file'>
      <div
        {...getRootProps({ onClick: evt => evt.preventDefault() })}
        className='ads-file-drop-zone'
      >
        <div className='file-drop-zone__icon'>
          <svg
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 69 60'
          >
            <path
              d='M57,17.6c0.4-6.1-4.2-11.3-10.2-11.7c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.7,0-1,0.1
                                c-0.2,0-0.5,0-0.7,0.1c0,0,0,0-0.1,0c-0.3,0-0.6,0.1-0.8,0.2c-0.6,0.1-1.1,0.3-1.7,0.6c-0.2,0.1-0.5,0.2-0.7,0.3
                                c-0.3,0.1-0.5,0.3-0.8,0.4c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c-5.5-9-18.3-9.8-25-1.6c-2.8,3.4-3.9,7.7-3.3,11.9
                                C5.3,17.9,0,23.3,0,30c0,6.9,5.6,12.4,12.4,12.4h3.6c0.6,9.6,8.5,17.2,18.2,17.2c9.7,0,17.6-7.6,18.2-17.2h3.8
                                c6.9,0,12.4-5.6,12.4-12.4C68.8,23.4,63.6,18,57,17.6z M34.3,58.1c-9,0-16.4-7.2-16.8-16.1c0-0.1,0.1-0.2,0.1-0.3
                                c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0-9.3,7.5-16.8,16.8-16.8c9.3,0,16.8,7.5,16.8,16.8C51.1,50.6,43.6,58.1,34.3,58.1z M56.3,41
                                h-3.8c-0.2-9.9-8.3-18-18.3-18c-10,0-18.1,8-18.3,18h-3.6c-6,0-11-4.9-11-11s4.9-11,11-11h0.3c0.5,0,0.8-0.4,0.7-0.9
                                c-0.8-4.1,0.2-8.3,2.9-11.6C22.4-0.9,34,0,39,8.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-1.6,1.3-2.9,3.2-3.5,5.3
                                c-0.1,0.4,0.1,0.8,0.5,0.9l0,0h0c0.4,0.1,0.7-0.2,0.8-0.5c0.6-2.1,1.9-3.8,3.6-5c0.3-0.2,0.5-0.4,0.8-0.5c0.3-0.2,0.6-0.3,0.9-0.5
                                c0.2-0.1,0.4-0.2,0.6-0.2c0.5-0.2,1.1-0.3,1.7-0.4c0.5-0.1,1-0.1,1.5-0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0
                                c4.9,0.3,8.8,4.5,8.8,9.5c0,0.4,0,0.9-0.1,1.4c0,0.2,0,0.4,0.2,0.6c0.1,0.2,0.3,0.2,0.5,0.2h0.1c6.1,0,11,4.9,11,11
                                S62.4,41,56.3,41z'
            />
            <path
              d='M35.3,34.3l-0.1-0.1c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2l-4.3,4.3c-0.2,0.2-0.2,0.4-0.2,0.6
                                c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.2l0.2-0.2l0.1-0.1l2.6-2.6v2.7v0.1v9.1c0,0.5,0.4,0.8,0.8,0.8
                                s0.8-0.4,0.8-0.8v-9v-0.1v-2.7v-0.2l3,3c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0c0.2,0,0.5,0,0.7-0.2v0c0.2-0.3,0.2-0.8-0.1-1L35.3,34.3z'
            />
          </svg>
        </div>
        <div className='file-drop-zone__content'>
          <span className='label'>
            Drag & drop files or <span className='link'>Browse</span>
          </span>
          {onlyDocAndPdf ? (
            <p>Supported formates: DOC, PDF</p>
          ) : getPDF ? (
            <p>Supported formates: JPEG, PNG, GIF, PDF</p>
          ) : (
            <p>Supported formates: JPEG, PNG, GIF</p>
          )}
        </div>
        <input {...getInputProps()} />
      </div>

      {loadingImages.length > 0 && (
        <>
          <p className='file-item-length'>
            Uploading - {loadingImages.filter(i => i.progress)?.length}/
            {loadingImages.length} files
          </p>
          <ul className='file-item-load-list'>
            {loadingImages.map(image => (
              <li className='file-item-load-item' key={image?.name}>
                <span className='file-item-load__title'>{image.name}</span>
                <button
                  className='remove'
                  onClick={() => removeLoadingImage(image.name)}
                >
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M8 16C5.87833 16 3.84331 15.1571 2.34315 13.6568C0.842901 12.1565 0 10.1217 0 8C0 5.87833 0.842901 3.84332 2.34315 2.34315C3.84353 0.842902 5.87833 0 8 0C10.1217 0 12.1567 0.842902 13.6568 2.34315C15.1571 3.84353 16 5.87833 16 8C15.9974 10.121 15.1538 12.1545 13.654 13.654C12.1543 15.1538 10.1207 15.9974 8 16ZM11.0851 5.94242V5.9423C11.2254 5.80678 11.3053 5.62063 11.3071 5.42558C11.3088 5.23053 11.2321 5.04292 11.0941 4.90496C10.9562 4.76701 10.7685 4.69031 10.5735 4.69202C10.3784 4.69372 10.1923 4.77371 10.0568 4.91397L8 6.97149L5.94323 4.91397C5.75853 4.7356 5.49346 4.66779 5.2457 4.73572C4.99805 4.80366 4.8046 4.99713 4.73665 5.24477C4.66871 5.49254 4.73653 5.7576 4.9149 5.9423L6.97167 7.99983L4.9149 10.0573C4.77464 10.1929 4.69465 10.379 4.69294 10.5741C4.69124 10.7691 4.76794 10.9567 4.90589 11.0947C5.04383 11.2326 5.23145 11.3093 5.4265 11.3076C5.62155 11.3059 5.8077 11.2259 5.94322 11.0857L7.99999 9.02815L10.0568 11.0857C10.1923 11.2259 10.3784 11.3059 10.5735 11.3076C10.7685 11.3093 10.9561 11.2326 11.0941 11.0947C11.2321 10.9567 11.3088 10.7691 11.307 10.5741C11.3053 10.379 11.2253 10.1929 11.0851 10.0573L9.02832 7.99983L11.0851 5.94242Z'
                      fill='#E6E6E6'
                    />
                  </svg>
                </button>
                {image?.progress && (
                  <span className='progress-item'>
                    <ProgressBar
                      now={Math.floor((image?.progress / image?.size) * 100)}
                    />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
      {loadedImages?.length > 0 && (
        <>
          <p className='heading-images'>Uploaded</p>
          <SortableList
            onSortEnd={onSortEnd}
            className={'sortable-root upload-files__list'}
            draggedItemClassName={'sortable-dragged'}
          >
            {loadedImages?.map(image => (
              <SortableItem key={image?.mediaId}>
                <div className='upload-files__list-item-lg'>
                  <div className='upload-files__list-image_title'>
                    <p>{image?.title ?? image?.name}</p>
                  </div>
                  <div className='button-item'>
                    <ButtonSquare
                      btnSize='sm'
                      remove={true}
                      removeAction={() => removeImage(image?.mediaId)}
                    />
                  </div>
                </div>
              </SortableItem>
            ))}
          </SortableList>
        </>
      )}
    </div>
  );
};

export default ImagesForm;
