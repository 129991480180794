import React from 'react';
import Form from 'react-bootstrap/Form';
import './FilterListResidentialComplexes.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  sortItems?: (field: string) => void;
  sort?: string;
}

const FilterListResidentialComplexes: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  filtersForm,
  setFiltersForm,
  sort,
  sortItems,
}) => {
  // const { users } = useAppSelector(state => state.users);
  // const [formData, setFormData] = useState<any>(filtersForm);

  // useEffect(() => {
  //   setFormData(filtersForm);
  // }, [filtersForm]);

  return (
    <div className='filter-list-container filter-list-resident-complex'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
          <div className='form-item-group-label'>Selecteaza toate</div>
        </Form.Group>
        <div
          className={`filter-item arrows-label-icon ${sort === 'id' ? 'active-bottom' : ''} ${sort === '-id' ? 'active-top' : ''}`}
        >
          <label
            onClick={() => {
              if (sortItems)
                sort === 'id'
                  ? sortItems('-id')
                  : sort === '-id'
                    ? sortItems('')
                    : sortItems('id');
            }}
          >
            ID
          </label>
          {/* <Input
            placeholder='Search'
            labelText={false}
            value={formData?.id}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFiltersForm({ ...filtersForm, id: e.target.value })
            }
          /> */}
        </div>
        <div className='filter-item hide-mobile'>
          <label>Photo</label>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'title' ? 'active-bottom' : ''} ${sort === '-title' ? 'active-top' : ''}`}
        >
          <label
            onClick={() => {
              if (sortItems)
                sort === 'title'
                  ? sortItems('-title')
                  : sort === '-title'
                    ? sortItems('')
                    : sortItems('title');
            }}
          >
            Denumire
          </label>
          {/* <Input
            placeholder='Search'
            labelText={false}
            value={formData?.title}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFiltersForm({ ...filtersForm, name: e.target.value })
            }
          /> */}
        </div>
        <div className='filter-item hide-mobile'>
          <label>Adresa</label>
          {/* <Input
            inlineLabel='Adresa'
            placeholder='Search'
            labelText={true}
            value={formData?.countyTitle}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFiltersForm({ ...filtersForm, countyTitle: e.target.value })
            }
          /> */}
        </div>
        <div className='filter-item hide-mobile'>
          <label>Detalii</label>
          {/* <Input
            inlineLabel='Detalii'
            placeholder='Search'
            labelText={true}
            value={formData?.rangePrice}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFiltersForm({ ...filtersForm, rangePrice: e.target.value })
            }
          /> */}
        </div>
        <div className='filter-item hide-mobile'>
          <label>Anunturi Asociate</label>

          {/* <Input
            inlineLabel='Anunturi Asociate'
            placeholder='Search'
            labelText={true}
            value={formData?.properties}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFiltersForm({ ...filtersForm, properties: e.target.value })
            }
          /> */}
        </div>
        <div className='filter-item hide-mobile'>
          <label>Agent</label>

          {/* <FormSelect
            labelText={true}
            placeholder='Select Options'
            inlineLabel='Agent'
            isSearchable={true}
            isClearable={true}
            value={
              filtersForm?.employeeId
                ? getValue(users, filtersForm?.employeeId)
                : null
            }
            options={parseValues(users) ?? []}
            onChange={(e: SelectValue) =>
              setFiltersForm({ ...filtersForm, employeeId: e?.value })
            }
          /> */}
        </div>
        <div className='filter-item hide-mobile'>
          <label>Status</label>
          {/* <FormSelect
            labelText={true}
            placeholder='Select Options'
            inlineLabel='Agent'
            isSearchable={true}
            isClearable={true}
            value={null}
            options={[]}
            onChange={(e: SelectValue) =>
              setFiltersForm({ ...filtersForm, status: e.value })
            }
          />{' '} */}
        </div>
        <div className='filter-item'>
          <label>Actiuni</label>
        </div>
      </form>
    </div>
  );
};

export default FilterListResidentialComplexes;
