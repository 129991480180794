import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import AddActivityAsociate from '../../components/AddActivityAsociate';
import AddActivityForm from '../../components/AddActivityForm';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import {
  clearActivity,
  setAssociatedContacts,
} from '../../redux/activities/activities-slice';
import {
  createActivity,
  getActivitiesTypes,
  getActivity,
  updateActivity,
} from '../../redux/activities/activitiesThunk';
import { getContacts } from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getProperties } from '../../redux/properties/propertiesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import useHelper, { haveUserPermission } from '../../helpers/useHelper';
import { ActivityForm } from '../../models/Activities';
import { Permissions } from '../../models/Permissions';
import './AddActivity.scss';

interface Props {}

const AddActivity: React.FC<Props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { diplayErrorMessages } = useHelper();
  const dispatch = useAppDispatch();
  const params = useSearchParams();
  const contactId = params[0].get('contact');

  const { activity, activitiesLoading, activitiesTypes } = useAppSelector(
    state => state.activities,
  );
  const { users } = useAppSelector(state => state.users);
  const { properties } = useAppSelector(state => state.properties);
  const { contacts } = useAppSelector(state => state.contacts);
  const { auth } = useAppSelector(state => state.auth);

  const [formData, setFormData] = useState<ActivityForm>({
    employeeId: auth?.id,
    contacts: [Number(contactId)],
    notifications: [
      {
        type: 'POPUP',
        offsetTime: 10,
        durationUnit: 'MINUTES',
      },
    ],
  });
  const [havePermission, setHavePermission] = useState(true);

  useEffect(() => {
    users.length === 0 && dispatch(getUsers({ limit: -1 }));
    activitiesTypes.length === 0 && dispatch(getActivitiesTypes());
    properties.length === 0 && dispatch(getProperties({}));

    if (location.pathname === '/add-activity') {
      setHavePermission(haveUserPermission(Permissions.ActivityAdd));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getActivity(id));
    } else {
      dispatch(clearActivity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (activity) {
      let initialForm = {
        employeeId: activity.employee?.id,
        typeId: activity?.type?.id,
        title: activity?.title,
        dateStart: activity?.dateStart,
        dateEnd: activity?.dateEnd,
        allDay: activity?.allDay,
        details: activity?.details,
        notifications: activity?.notifications,
        contacts: activity?.contacts.map(contact => contact.id),
        properties: activity?.properties.map(property => property.id),
      };

      setFormData(initialForm);
    } else {
      setFormData({
        ...formData,
        notifications: [
          {
            type: 'POPUP',
            offsetTime: 10,
            durationUnit: 'MINUTES',
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  useEffect(() => {
    if (contactId) {
      initAssociatedContacts();
      setFormData({
        ...formData,
        contacts: [...(formData?.contacts || []), Number(contactId)],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  const initAssociatedContacts = async () => {
    let currentContacts = contacts;

    if (!currentContacts || currentContacts.length < 1) {
      await dispatch(getContacts()).then((result: any) => {
        if (!result.error) {
          currentContacts = result.payload.data;
        }
      });
    }

    setFormData({
      ...formData,
      contacts: [...(formData?.contacts || []), Number(contactId)],
    });
    dispatch(
      setAssociatedContacts([
        currentContacts.find(contact => contact.id.toString() === contactId),
      ]),
    );
  };

  const submitForm = () => {
    let submitForm = formData;

    if (formData.dateStart) {
      submitForm.dateStart =
        moment(formData.dateStart).format('YYYY-MM-DD') +
        (formData.allDay === 1
          ? ' 00:00:00'
          : ' ' + moment(formData.dateStart).format('HH:mm') + ':00');
    }

    if (formData.dateEnd) {
      submitForm.dateEnd =
        moment(formData.dateEnd).format('YYYY-MM-DD') +
        (formData.allDay === 1
          ? ' 23:59:59'
          : ' ' + moment(formData.dateEnd).format('HH:mm') + ':00');
    }

    if (submitForm) {
      if (id) {
        dispatch(updateActivity({ form: submitForm, id: id })).then(res => {
          if (res?.payload?.id) {
            toast.success('Activitatea a fost modificata cu succes!');
            navigate('/my-activities');
          } else {
            diplayErrorMessages(res.payload);
          }
        });
      } else {
        dispatch(createActivity(submitForm)).then(res => {
          if (res?.payload?.id) {
            toast.success('Activitatea a fost creata cu succes!');
            navigate('/my-activities');
          } else {
            diplayErrorMessages(res.payload);
          }
        });
      }
    }
  };

  const resetForm = () => {
    setFormData({
      ...formData,
      notifications: activity?.notifications
        ? [...activity?.notifications]
        : [
            {
              type: 'POPUP',
              offsetTime: 10,
              durationUnit: 'MINUTES',
            },
          ],
      dateStart: undefined,
      dateEnd: undefined,
      allDay: 0,
    });
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title={id ? 'Modifica Activitate' : 'Adauga Activitate'}
          subtitle='De aici poti modifica activitatile tale'
          isbutton={false}
          backBtn={true}
          btnText=''
        />
        {havePermission ? (
          <>
            {activitiesLoading ? (
              <Spinner />
            ) : (
              <div className='display-flex flex-add-activity'>
                <AddActivityForm
                  formData={formData}
                  setFormData={setFormData}
                  saveActivity={submitForm}
                  resetForm={resetForm}
                />
                <AddActivityAsociate
                  formData={formData}
                  setFormData={setFormData}
                />
                <div className='btns-container-mobile'>
                  <button
                    className='button-blue button-md'
                    onClick={submitForm}
                  >
                    Salveaza
                  </button>
                  <button className='reset-btn' onClick={resetForm}>
                    Reseteaza
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default AddActivity;
