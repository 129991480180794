import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListPropertiesDB from '../../components/FilterListPropertiesDB';
import FilterListPropertiesImoAP from '../../components/FilterListPropertiesImoAP';
import Pagination from '../../components/Pagination';
import RequestPropertyImoAPListItem from '../../components/RequestPropertyImoAPListItem';
import RequestPropertyListItem from '../../components/RequestPropertyListItem';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getProperties } from '../../redux/properties/propertiesThunk';
import { Filters, Property } from '../../models/Properties';
import './RequestPropertyList.scss';

interface Props {
  removeItems?: (id: number) => void;
  matchItems?: (id: number) => void;
  fromDB: boolean;
  items?: Property[];
  actionsOptions?: any;
  setCheckedProperties?: (e: any[]) => void;
  requestFilters?: any;
}

const RequestPropertyList: React.FC<Props> = ({
  removeItems,
  matchItems,
  fromDB,
  items,
  actionsOptions,
  setCheckedProperties,
  requestFilters,
}) => {
  const dispatch = useAppDispatch();
  const { properties, totalCount, loading } = useAppSelector(
    state => state.properties,
  );
  const { getRequestState } = useAppSelector(state => state.requests);
  const [localProperties, setLocalProperties] = useState(items ?? []);
  const [localTotalCount, setLocalTotalCount] = useState(items?.length ?? 0);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sort, setSort] = useState('');

  const [checkedItems, setCheckedItems] = useState<any[]>([]);

  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);

  const getPropertiesByURL = (params: Filters) => {
    let currentObject: any[] = [];
    let localParams: any = { ...params, fromRequest: 1 };

    requestFilters &&
      Object.keys(requestFilters).length > 0 &&
      getRequestState.request?.category?.filters?.forEach((filter: any) => {
        if (
          requestFilters[filter.id] &&
          requestFilters[filter.id] !== null &&
          requestFilters[filter.id] !== ''
        ) {
          if (
            filter.requestTypeView === 'RANGE' ||
            filter.requestTypeView === 'PRICE'
          ) {
            if (
              requestFilters[filter.id]?.from !== '' &&
              requestFilters[filter.id]?.from !== null
            )
              currentObject[filter.id] = {
                ...currentObject[filter.id],
                from: requestFilters[filter.id]?.from,
              };
            if (
              requestFilters[filter.id]?.to !== '' &&
              requestFilters[filter.id]?.to !== null
            )
              currentObject[filter.id] = {
                ...currentObject[filter.id],
                to: requestFilters[filter.id]?.to,
              };
          }
          if (filter.requestTypeView === 'RANGE_SELECT') {
            if (
              requestFilters[filter.id]?.from?.value &&
              requestFilters[filter.id]?.from?.value !== '' &&
              requestFilters[filter.id]?.from?.value !== null
            )
              currentObject[filter.id] = {
                ...currentObject[filter.id],
                from: requestFilters[filter.id]?.from?.value,
              };
            if (
              requestFilters[filter.id]?.to?.value &&
              requestFilters[filter.id]?.to?.value !== '' &&
              requestFilters[filter.id]?.to?.value !== null
            )
              currentObject[filter.id] = {
                ...currentObject[filter.id],
                to: requestFilters[filter.id]?.to?.value,
              };
          } else if (
            filter.requestTypeView === 'COUNTY' ||
            filter.requestTypeView === 'TOWN'
          ) {
            if (requestFilters[filter.id]?.value)
              currentObject[filter.id] = requestFilters[filter.id]?.value;
          } else if (filter.requestTypeView === 'SELECT') {
            currentObject[filter.id] = requestFilters[filter.id]?.value;
          } else if (filter.requestTypeView === 'EMPLOYEE') {
            if (requestFilters[filter.id]?.value?.value)
              currentObject[filter.id] = {
                id: requestFilters[filter.id]?.value?.value,
                associated: requestFilters[filter.id]?.associated,
              };
          } else if (
            filter.requestTypeView === 'SELECT_OPTIONS' ||
            filter.requestTypeView === 'LOCATION' ||
            filter.requestTypeView === 'STREET' ||
            filter.requestTypeView === 'SPECIFIC_TYPE' ||
            filter.requestTypeView === 'ZONE' ||
            filter.requestTypeView === 'OFFER_TYPE'
          ) {
            if (requestFilters[filter.id]?.length > 0)
              requestFilters[filter.id].forEach((value: number) => {
                currentObject[filter.id] =
                  currentObject[filter.id]?.length > 0
                    ? [...currentObject[filter.id], value]
                    : [value];
              });
          } else {
            currentObject[filter.id] = requestFilters[filter.id];
          }
        }
      });
    if (currentObject?.length > 0)
      localParams = { ...localParams, filters: currentObject };
    if (requestFilters?.zones?.length > 0)
      localParams = { ...localParams, zones: requestFilters.zones };
    if (requestFilters?.priceMin !== null)
      localParams = { ...localParams, priceMin: requestFilters?.priceMin };
    if (requestFilters?.priceMax !== null)
      localParams = { ...localParams, priceMax: requestFilters?.priceMax };

    dispatch(getProperties(localParams));
  };

  useEffect(() => {
    if (fromDB) {
      if (!items) {
        setLocalProperties(properties);
        setLocalTotalCount(totalCount);
      } else {
        setLocalProperties(items);
        setLocalTotalCount(items.length);
      }
    } else {
      setLocalProperties([]);
      setLocalTotalCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, items, fromDB]);

  useEffect(() => {
    if (localTotalCount) {
      const pageCount = Math.ceil(localTotalCount / itemsPerPage);

      setPageCount(pageCount);
    }
  }, [localTotalCount, itemsPerPage]);

  useEffect(() => {
    let filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      sort: sort,
    };
    getPropertiesByURL(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, sort, requestFilters]);

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    let newArray = [];

    if (id) {
      if (checkedItems?.find(item => item.id === id)) {
        newArray = checkedItems.filter(item => item.id !== id);
        if (isCheckedAll) setCheckedAll(false);
      } else {
        newArray = checkedItems;
        newArray.push(localProperties.find(item => item.id === id));
      }
    }
    if (all) newArray = localProperties;

    setCheckedItems([...newArray]);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  useEffect(() => {
    setCheckedProperties && setCheckedProperties(checkedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);

  return (
    <Col xl={12} style={{ minHeight: '10rem' }}>
      {loading ? (
        <Col xl={12} style={{ minHeight: '60rem' }}>
          <Spinner />
        </Col>
      ) : (
        <>
          {' '}
          <FilterListHeader
            pageCount={pageCount}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalCount={localTotalCount}
            count={localProperties?.length}
            showCheckedItems={showCheckedItems}
            checkedItemsLength={checkedItems.length ?? 0}
            setShowCheckedItems={setShowCheckedItems}
            setItemsPerPage={setItemsPerPage}
            setCurrentPage={setCurrentPage}
            handlePageClick={handlePageClick}
            fromDB={fromDB}
            showActions={actionsOptions ?? false}
          />
          {fromDB ? (
            <FilterListPropertiesDB
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) handleCheckboxChange(undefined, true);
                else setCheckedItems([]);
              }}
              isCheckedAll={isCheckedAll}
              sortProperty={setSort}
              sort={sort}
            />
          ) : (
            <FilterListPropertiesImoAP
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) handleCheckboxChange(undefined, true);
                else setCheckedItems([]);
              }}
              isCheckedAll={isCheckedAll}
              sortProperty={setSort}
              sort={sort}
            />
          )}
          <div className={`list-items-container`}>
            {showCheckedItems ? (
              checkedItems?.length > 0 ? (
                checkedItems.map((property: Property) =>
                  fromDB ? (
                    <RequestPropertyListItem
                      key={property.id + 'checked'}
                      item={property}
                      checked={checkedItems?.find(
                        item => item.id === property.id,
                      )}
                      handleCheckboxChange={handleCheckboxChange}
                      removeProperty={removeItems}
                      matchProperty={matchItems}
                    />
                  ) : (
                    <RequestPropertyImoAPListItem
                      key={property.id + 'checked'}
                      item={property}
                      checked={checkedItems?.find(
                        item => item.id === property.id,
                      )}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  ),
                )
              ) : (
                <p className='empty-text'>0 Proprietati selectate</p>
              )
            ) : localProperties?.length > 0 ? (
              localProperties.map((property: Property) =>
                fromDB ? (
                  <RequestPropertyListItem
                    key={`${property.id} ${property?.title ?? ''}`}
                    item={property}
                    checked={
                      checkedItems?.length > 0 &&
                      checkedItems?.find(item => item.id === property.id)
                        ? true
                        : false
                    }
                    removeProperty={removeItems}
                    handleCheckboxChange={handleCheckboxChange}
                    matchProperty={matchItems}
                  />
                ) : (
                  <RequestPropertyImoAPListItem
                    key={`${property.id} ${property?.title ?? ''}`}
                    item={property}
                    checked={
                      checkedItems?.length > 0 &&
                      checkedItems?.find(item => item.id === property.id)
                        ? true
                        : false
                    }
                    handleCheckboxChange={handleCheckboxChange}
                  />
                ),
              )
            ) : (
              <p className='empty-text'>Cautarea ta a returnat 0 Rezultate</p>
            )}
          </div>
          {!showCheckedItems && pageCount > 1 && (
            <Pagination
              isSelectPage={false}
              pageCount={pageCount}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              handlePageClick={handlePageClick}
            />
          )}
        </>
      )}
    </Col>
  );
};

export default RequestPropertyList;
