import parse from 'html-react-parser';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { CheckBox } from '../../models/Models';
import ArrowTopRight from '../../assets/svg/arrowTopRight.svg';
import './CheckboxPromoGroup.scss';
import checkboxData from './checkboxPromo.json';

interface Props {
  idList?: string;
  plus?: boolean;
}

const CheckboxPromoGroup: React.FC<Props> = idList => {
  const checkboxs: CheckBox[] = checkboxData as CheckBox[];
  return (
    <div className='checkbox-group-list checkbox-group-list-md'>
      {checkboxs?.map((checkbox, index) => (
        <div
          className='checkbox-group-list__item checkbox-group-list__item-md bg-gray'
          key={index}
          aria-disabled={checkbox?.ariaDisabled === true ? true : false}
        >
          <fieldset className='checkbox-group-fieldset'>
            {checkbox?.legend && (
              <legend className='heading-block'>
                <span className='heading-block--title'>{checkbox.legend}</span>
                {checkbox?.plus === true && (
                  <button className='checkbox-group-plus' aria-label=''>
                    +
                  </button>
                )}
                {checkbox?.legendImage && (
                  <span className='heading-block--title'>
                    <img
                      src={checkbox.legendImage}
                      alt=''
                      className='heading-image'
                    />
                  </span>
                )}
              </legend>
            )}
            {checkbox.checkList.map((item, index) => (
              <div
                className={`form-item ${
                  item?.separator === true ? 'separator' : ''
                }`}
                key={index}
              >
                <Form.Check
                  type={item?.type}
                  label={parse(`${item?.label}`)}
                  id={`${item?.id}-${index}`}
                  name={item?.name}
                />
              </div>
            ))}

            {checkbox?.additionalInfo?.map((item, index) => (
              <div className='additional-info' key={index}>
                {item?.infoPromoUrl && (
                  <a
                    href={`${item?.infoPromoUrl}`}
                    className='additional-info__url'
                  >
                    <span>{item?.infoPromoText}</span>{' '}
                    <img src={ArrowTopRight} alt='' />
                  </a>
                )}
                {parse(`${item?.infoContent && item?.infoContent}`)}
              </div>
            ))}

            {checkbox?.promoUrl && (
              <div className='checkbox-promo-list-bottom'>
                <a
                  href={checkbox?.promoUrl}
                  className='button-md button-border'
                >
                  {checkbox?.promoActive ? 'Nepromovat' : 'Serviciu inactiv'}
                </a>
              </div>
            )}
          </fieldset>
        </div>
      ))}
    </div>
  );
};

export default CheckboxPromoGroup;
