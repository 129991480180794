import { createAsyncThunk } from '@reduxjs/toolkit';
import { Permission } from '../../models/Permissions';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getPermissions = createAsyncThunk<Permission[]>(
  'crm/getPermissions',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/permissions');

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
