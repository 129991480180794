import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';

import Input from '../../components/Input/Input';

import { PropertyForm } from '../../models/Properties';

interface Props {
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
}

const AuctionBlock: React.FC<Props> = ({ formData, setFormData }) => {
  const [localPrice, setLocalPrice] = useState(
    formData?.prices?.find(price => price.id === 1) ?? null,
  );
  const [auctionDate, setAuctionDate] = useState<Date | null>(null);
  const [auctionRegistrationDeadline, setAuctionRegistrationDeadline] =
    useState<Date | null>(null);

  useEffect(() => {
    setLocalPrice(formData?.prices?.find(price => price.id === 1) ?? null);
    setAuctionDate(
      formData?.prices?.find(price => price.id === 1)?.auctionDate
        ? new Date(
            String(
              formData?.prices?.find(price => price.id === 1)?.auctionDate,
            ),
          )
        : null,
    );
    setAuctionRegistrationDeadline(
      formData?.prices?.find(price => price.id === 1)
        ?.auctionRegistrationDeadline
        ? new Date(
            String(
              formData?.prices?.find(price => price.id === 1)
                ?.auctionRegistrationDeadline,
            ),
          )
        : null,
    );
  }, [formData.prices]);

  const handleChangeAuction = (
    key: string,
    e: string | boolean | number | null,
  ) => {
    const newPrices = formData?.prices?.filter(price => price.id !== 1) ?? [];
    let salePrice = formData?.prices?.find(price => price.id === 1);
    if (salePrice) {
      salePrice = { ...salePrice, [key]: e };
      newPrices.push(salePrice);
    }

    setFormData({
      ...formData,
      prices: newPrices,
    });
  };

  return (
    <fieldset className='row fieldset-block row-93'>
      <Col lg='12'>
        <div className='form-item form-item-flex'>
          <legend className='heading-block'>Licitatie</legend>
        </div>
      </Col>

      <Col lg='4'>
        <div className='form-item mb-lg'>
          <Input
            labelText={true}
            inlineLabel='Numar dosar executional'
            value={localPrice?.auctionExecutiveCaseNumber ?? ''}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeAuction('auctionExecutiveCaseNumber', e.target.value);
            }}
          />
        </div>
        <div className='form-item'>
          <div className='date-picker'>
            <label>Data licitatiei</label>
            <DatePicker
              selected={auctionDate}
              onChange={date =>
                handleChangeAuction(
                  'auctionDate',
                  date ? moment(date).format('YYYY-MM-DD') : '',
                )
              }
            />
          </div>
        </div>
      </Col>
      <Col lg='4'>
        <div className='form-item mb-lg'>
          <Input
            labelText={true}
            inlineLabel='Organizator licitatie'
            value={localPrice?.auctionOrganizer ?? ''}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeAuction('auctionOrganizer', e.target.value);
            }}
          />
        </div>
        <div className='form-item'>
          <div className='date-picker'>
            <label>Data limita inregistrare</label>
            <DatePicker
              selected={auctionRegistrationDeadline}
              onChange={date =>
                handleChangeAuction(
                  'auctionRegistrationDeadline',
                  date ? moment(date).format('YYYY-MM-DD') : '',
                )
              }
            />
          </div>
        </div>
      </Col>
      <Col lg='4'>
        <div className='form-item mb-lg'>
          <Input
            labelText={true}
            inlineLabel='Termen Licitatie'
            value={localPrice?.auctionTerm ?? ''}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeAuction('auctionTerm', e.target.value);
            }}
          />
        </div>
      </Col>
      <Col lg='12'>
        <div className='form-item'>
          <div className='form-item-group'>
            <label htmlFor='content-subject' className='form-item-group-label'>
              Detalii licitatie
            </label>
            <textarea
              id='email-subject'
              className='form-item-control'
              value={localPrice?.auctionDetails}
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                handleChangeAuction('auctionDetails', e.target.value);
              }}
              rows={6}
            ></textarea>
          </div>
        </div>
      </Col>
      <Col lg='12'>
        <hr className='fieldset-block__hr' />
      </Col>
    </fieldset>
  );
};

export default AuctionBlock;
