import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddContactForm from '../../components/AddContactForm';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import SugestedContacts from '../../components/SugestedContacts';
import { getCompanies } from '../../redux/companies/companiesThunk';
import {
  clearContact,
  clearContacts,
} from '../../redux/contacts/contacts-slice';
import {
  getContact,
  getContactsTypes,
  getLeadSources,
} from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './AddContact.scss';

interface Props {}

const AddContact: React.FC<Props> = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { contact, loadingContact } = useAppSelector(state => state.contacts);

  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.ContactAdd),
  );

  useEffect(() => {
    dispatch(getContactsTypes());
    dispatch(clearContacts());
    dispatch(getLeadSources());
    dispatch(getUsers({}));
    dispatch(getCompanies({ limit: -1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getContact(id));
    } else {
      dispatch(clearContact());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title={contact ? 'Editeaza contact' : 'Adauga Contact'}
          subtitle={contact ? contact.name + ' ' + contact.surname : ''}
          isbutton={false}
          backBtn={true}
          btnText=''
        />
        {havePermission ? (
          loadingContact ? (
            <Spinner />
          ) : (
            <div className='display-flex flex-add-contact'>
              <AddContactForm editView={id ? true : false} />
              {!id && <SugestedContacts />}
            </div>
          )
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default AddContact;
