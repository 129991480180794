import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PricesHistoryTable from '../PricesHistoryTable';
import './PricesHistoryModal.scss';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  prices: any;
}

const PricesHistoryModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  prices,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'mobile-modal prices-modal'}
      >
        <Modal.Body>
          <PricesHistoryTable prices={prices} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PricesHistoryModal;
