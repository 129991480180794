import React from 'react';
import './AnnouncesCountPopup.scss';
import Reload from '../../assets/svg/reload.svg';

type PopupProps = {
  count: number;
  onButtonClick: () => void;
};

const AnnouncesCountPopup: React.FC<PopupProps> = ({ count, onButtonClick }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <span>Sunt {count} anunturi noi</span>
        <button className="popup-button" onClick={onButtonClick}>
          Incarca
          <img  className="reload-icon" src={Reload} alt="" />
        </button>
      </div>
    </div>
  );
};

export default AnnouncesCountPopup;
