import moment from 'moment';
import React from 'react';
import FormSelect from '../../components/FormSelect';
import { useAppSelector } from '../../redux/hooks';
import { getImagePath } from '../../helpers/useHelper';
import { Contact } from '../../models/Contacts';
import { SelectValue } from '../../models/Properties';
import Avatar from '../../assets/img/avatar.png';
import './AdsGeneralRequest.scss';

interface Props {
  formData: any;
  setFormData: (e: any) => void;
}

const options = [
  {
    label: 'Activa',
    value: 1,
  },
  {
    label: 'Inactiva',
    value: 0,
  },
  {
    label: 'Tranzactionata',
    value: 8,
  },
  {
    label: 'Arhivata',
    value: 4,
  },
];

const AdsGeneralRequest: React.FC<Props> = ({ formData, setFormData }) => {
  const { getRequestState } = useAppSelector(state => state.requests);
  const { contacts } = useAppSelector(state => state.contacts);

  return (
    <div className='ads-general-request-sm'>
      <div
        className={`ads-general-container ads-general-request-container bg-white `}
      >
        <div className={`ads-general-request-info-bottom `}>
          <div
            className={`ads-general-request-info-container item-details-container `}
          >
            <div className='ads-general-request-info'>
              <div className='ads-general-request__heading'>
                ID SOLICITARE: {getRequestState.request?.id}
              </div>
              <div className='ads-general-request__heading'>
                {getRequestState.request?.transactionType === 'BUY'
                  ? 'Cumparare'
                  : 'Inchiriere'}{' '}
                {getRequestState.request?.category.title}
              </div>
              <table>
                <tbody>
                  {getRequestState.request?.dateCreated && (
                    <tr>
                      <th className='ads-general-request-info__heading'>
                        Adaugat la:
                      </th>
                      <td className='ads-general-request-info__content'>
                        {moment(getRequestState.request?.dateCreated).format(
                          'DD.MM.YYYY',
                        )}
                      </td>
                    </tr>
                  )}
                  {getRequestState.request?.dateUpdated && (
                    <tr>
                      <th className='ads-general-request-info__heading'>
                        Ultima modificare:
                      </th>
                      <td className='ads-general-request-info__content'>
                        {moment(getRequestState.request?.dateUpdated).format(
                          'DD.MM.YYYY',
                        )}
                      </td>
                    </tr>
                  )}

                  {/* <tr>
                    <th className='ads-general-request-info__heading'>Pret vanzare:</th>
                    <td className='ads-general-request-info__content'>160 000 $</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className='user-general-info'>
              {getRequestState.request?.employee?.profile?.avatar?.path ? (
                <div className='image-block'>
                  <img
                    src={getImagePath(
                      getRequestState.request?.employee?.profile?.avatar?.path,
                    )}
                    alt=''
                  />
                </div>
              ) : (
                <div className='image-block'>
                  <img src={Avatar} alt='' />
                </div>
              )}
              <div className='content-info'>
                <p className='name'>
                  {getRequestState.request?.employee?.profile?.fullName}
                </p>
                <span className='info'>
                  {getRequestState.request?.employee?.profile?.phoneNumbers?.map(
                    (number, index) =>
                      number +
                      (index !==
                      (getRequestState.request?.employee?.profile?.phoneNumbers
                        .length ?? 0) -
                        1
                        ? ', '
                        : ''),
                  )}
                </span>
              </div>
            </div>
            <div className='action-select'>
              <div className='form-item-group'>
                <FormSelect
                  options={options}
                  onChange={(e: SelectValue) => {
                    setFormData({ ...formData, status: e.value ?? 0 });
                  }}
                  placeholder={'Status'}
                  menuIsOpen={true}
                  isSearchable={false}
                  value={
                    options.find(option => option.value === formData.status) ??
                    null
                  }
                  classNamePrefix='react-select'
                />
              </div>
            </div>
            <div className='ads-general-request-info'>
              <table>
                <tbody>
                  {getRequestState.request?.transactionType && (
                    <tr>
                      <th className='ads-general-request-info__heading'>
                        Tip Tranzactie:
                      </th>
                      <td className='ads-general-request-info__content'>
                        {getRequestState.request?.transactionType === 'BUY'
                          ? 'Cumparare'
                          : 'Inchiriere'}
                      </td>
                    </tr>
                  )}
                  {getRequestState.request?.category.title && (
                    <tr>
                      <th className='ads-general-request-info__heading'>
                        Tip Imobil:
                      </th>
                      <td className='ads-general-request-info__content'>
                        {getRequestState.request?.category.title}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {contacts &&
              contacts.length > 0 &&
              formData.contacts?.length > 0 && (
                <div className='associated-contacts'>
                  <h3 className='heading'>Contacte asociate</h3>
                  <ul className='associated-contacts-list'>
                    {contacts?.map(item => {
                      if (
                        formData.contacts?.find(
                          (contact: Contact) => contact.id === item.id,
                        )
                      )
                        return (
                          <li
                            className='associated-contacts-list__item'
                            key={item.id}
                          >
                            <a
                              href='/'
                              className='label-item-rounded-info-sm'
                              aria-label='Vezi pagina utilizatorului Lauren Jensen'
                            >
                              {item?.name + ' ' + item.surname}
                            </a>
                          </li>
                        );
                      else return null;
                    })}
                  </ul>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsGeneralRequest;
