import React from 'react';
import {
  setAssociatedContacts,
  setAssociatedProperties,
  setAssociatedRequests,
} from '../../redux/activities/activities-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getImagePath } from '../../helpers/useHelper';
import { ActivityForm } from '../../models/Activities';
import Avatar from '../../assets/img/avatar.png';
import Property from '../../assets/img/house.png';
import CalculatorIcon from '../../assets/svg/asociate.svg';
import IconEnvelope from '../../icons/IconEnvelope';
import IconPhone from '../../icons/IconPhone';
import IconRemove from '../../icons/IconRemove';
import './AddActivityAsociate.scss';

interface Props {
  formData: ActivityForm;
  setFormData: (form: ActivityForm) => void;
}

const AddActivityAsociate: React.FC<Props> = ({ formData, setFormData }) => {
  const dispatch = useAppDispatch();
  const { associatedContacts, associatedProperties, associatedRequests } = useAppSelector(
    state => state.activities,
  );

  const removeContact = (id: number) => {
    let contactArray =
      formData.contacts?.filter((contact: number) => contact !== id) ?? [];

    setFormData({ ...formData, contacts: contactArray });
    dispatch(
      setAssociatedContacts(
        associatedContacts.filter(contact => contact.id !== id),
      ),
    );
  };

  const removeProperty = (id: number) => {
    let propertyArray =
      formData.properties?.filter((property: number) => property !== id) ?? [];

    setFormData({ ...formData, properties: propertyArray });
    dispatch(
      setAssociatedProperties(
        associatedProperties.filter(property => property.id !== id),
      ),
    );
  };

  const removeRequest = (id: number) => {
    let requestArray =
      formData.requests?.filter((request: number) => request !== id) ?? [];

    setFormData({ ...formData, requests: requestArray });

    dispatch(
      setAssociatedRequests(
        associatedRequests.filter(request => request.id !== id),
      ),
    );
  };

  return (
    <div className='sugested-activity-container'>
      <div className='sugested-item'>
        <h4>Proprietati Asociate</h4>
        {associatedProperties?.map(property => {
          let image =
            property?.media?.filter(
              media =>
                media.status === 1 &&
                media.type === 'MAIN' &&
                media.media?.path,
            ) &&
            property?.media?.filter(
              media =>
                media.status === 1 &&
                media.type === 'MAIN' &&
                media.media?.path,
            ).length > 0
              ? property?.media?.filter(
                  media =>
                    media.status === 1 &&
                    media.type === 'MAIN' &&
                    media.media?.path,
                )[0] ?? ''
              : null;

          return (
            <div className='sugested-properties-item'>
              <div className='img-block'>
                {image && image?.media?.path ? (
                  <img src={getImagePath(image.media?.path)} alt='' />
                ) : (
                  <img src={Property} alt='' />
                )}
              </div>
              <div className='info-properties'>
                <div className='details'>
                  {property?.title ?? property?.categoryTitle}
                </div>
                {property?.prices &&
                  property?.prices?.length > 0 &&
                  property?.prices.map(price => {
                    if (price.id === 1 && price.sellingPrice) {
                      return (
                        <div className='price'>
                          <span>Pret vanzare: </span>
                          {price.sellingPrice} {property.currencySymbol ?? '€'}
                        </div>
                      );
                    } else if (price.id === 2 && price.rentalMonthPrice) {
                      return (
                        <div className='price'>
                          <span>Pret inchiriere: </span>
                          {price.rentalMonthPrice} {property.currencySymbol ?? '€'} / luna
                        </div>
                      );
                    } else if (price.id === 3 && price.rentalDayPrice) {
                      return (
                        <div className='price'>
                          <span>Pret regim hotelier: </span>
                          {price.rentalDayPrice} {property.currencySymbol ?? '€'} / zi
                        </div>
                      );
                    } else if (price.id === 4 && price.sellingPriceSqm) {
                      return (
                        <div className='price'>
                          <span>Pret vanzare mp: </span>
                          {price.sellingPriceSqm} {property.currencySymbol ?? '€'} / mp
                        </div>
                      );
                    } else if (price.id === 5 && price.rentalMonthPriceSqm) {
                      return (
                        <div className='price'>
                          <span>Pret inchiriere mp: </span>
                          {price.rentalMonthPriceSqm} {property.currencySymbol ?? '€'} / mp
                        </div>
                      );
                    }
                  })}
                <span className='label-item-rounded-info'>
                  <img src={CalculatorIcon} alt='' />
                  {property.id}
                </span>
              </div>
              <button
                className='remove-btn'
                aria-label=''
                onClick={e => {
                  e.preventDefault();
                  removeProperty(property.id);
                }}
              >
                <IconRemove />
              </button>
            </div>
          );
        })}
      </div>

      <div className='sugested-item'>
        <h4>Solicitări Asociate</h4>
        {associatedRequests?.map(request => {
          return (
            <div
              className="applications-wrapper"
              key={'reqeuest' + request.id}
            >
              <div className="info-content">
                <p>{request.transactionType === 'BUY' ? 'Cumparare' : 'Inchiriere'} {request.categoryTitle}</p>
                {request.prices && request.prices.length > 0 &&
                  <div className="price">Pret: {request.prices[0]}{request.currencySymbol}</div>
                }
                <div className="info-details">
                  <span className="label-item-rounded-info">
                    {request.employeeAvatarPath ? (
                      <img src={getImagePath(request.employeeAvatarPath)} alt="" />
                    ) : (
                      <img src={Avatar} alt="" />
                    )}
                    {request.employeeFullName}
                  </span>
                </div>
              </div>
              <button
                className="remove-btn"
                aria-label=""
                onClick={e => {
                  e.preventDefault();
                  removeRequest(request.id);
                }}
              >
                <IconRemove />
              </button>
            </div>
          )
        })}
      </div>

      <div className="sugested-item">
        <h4>Contacte Asociate</h4>
        {associatedContacts?.map(contact => {
          return (
            <div className="sugested-contact-item">
              <div className="img-block">
                {contact?.avatarPath ? (
                  <img src={getImagePath(contact?.avatarPath)} alt='' />
                ) : (
                  <img src={Avatar} alt='' />
                )}
              </div>
              <div className='info-contact'>
                <span className='name'>
                  {contact?.name + ' ' + contact?.surname}
                </span>
                <div className='info-item'>
                  {contact?.phoneNumbers.map(
                    phone =>
                      phone.phone && (
                        <a
                          href={'tel:' + phone?.phone}
                          aria-label=''
                          className='number'
                        >
                          <IconPhone />
                          {phone.phone}
                        </a>
                      ),
                  )}

                  {contact?.internationalPhoneNumber && (
                    <a
                      href={'tel:' + contact?.internationalPhoneNumber}
                      aria-label=''
                      className='number'
                    >
                      <IconPhone />
                      {contact?.internationalPhoneNumber}{' '}
                    </a>
                  )}
                </div>
                <div className='info-item'>
                  {contact?.emails?.map(
                    email =>
                      email && (
                        <a
                          href={'mailto:' + email}
                          aria-label=''
                          className='mail'
                        >
                          <IconEnvelope />
                          {email}
                        </a>
                      ),
                  )}
                </div>
              </div>
              <button
                className='remove-btn'
                aria-label=''
                onClick={e => {
                  e.preventDefault();
                  removeContact(contact.id);
                }}
              >
                <IconRemove />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddActivityAsociate;
