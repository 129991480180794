import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createActivities,
  createActivity,
  getActivities,
  getActivitiesTypes,
  getPropertyActivities,
} from '../../redux/activities/activitiesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import useHelper, {
  getValue,
  parseValues,
  showError,
} from '../../helpers/useHelper';
import { ActivityForm } from '../../models/Activities';
import { SelectValue } from '../../models/Properties';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconTimes from '../../icons/IconTimes';
import FormSelect from '../FormSelect';
import Input from '../Input';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import './ActivityAddModal.scss';
import { getUserContacts } from '../../redux/contacts/contactsThunk';

interface Props {
  activityType: number | null;
  date?: any;
  isModalOpen: boolean;
  closeModal: () => void;
  fieldName: string;
  fieldId?: string | number;
}

const ActivityAddModal: React.FC<Props> = ({
  activityType,
  date,
  isModalOpen,
  closeModal,
  fieldName,
  fieldId,
}) => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();

  const { auth } = useAppSelector(state => state.auth);
  const { users } = useAppSelector(state => state.users);
  const { contacts } = useAppSelector(state => state.contacts);
  const { property } = useAppSelector(state => state.properties);
  const { activitiesTypes, error } = useAppSelector(state => state.activities);
  const [formData, setFormData] = useState<ActivityForm>(
    activityType
      ? {
          typeId: activityType,
        }
      : date
        ? {
            dateStart: moment(date).format('YYYY-MM-DD') + ' 00:00:00',
            dateEnd: moment(date).format('YYYY-MM-DD') + ' 00:00:00',
          }
        : {},
  );
  const [allDay, setAllDay] = useState<boolean>(!!date);
  const [startDate, setStartDate] = useState<Date | null>(
    date ? new Date(date) : null,
  );
  const [startTime, setStartTime] = useState<Date | null>();
  const [finalDate, setFinalDate] = useState<Date | null>(
    date ? new Date(date) : null,
  );
  const [finalTime, setFinalTime] = useState<Date | null>();

  const typesOptions = [
    { label: 'Popup', value: 'POPUP' },
    { label: 'Email', value: 'EMAIL' },
  ];

  const unitOptions = [
    { label: 'Ore', value: 'HOURS' },
    { label: 'Minute', value: 'MINUTES' },
    { label: 'Zile', value: 'DAYS' },
  ];

  useEffect(() => {
    dispatch(
      getUsers({
        roles: 'AGENCY',
        limit: -1,
      }),
    ).then(() => {
      setFormData({
        ...formData,
        employeeId: Number(auth?.id),
        notifications: formData?.notifications
          ? [
              ...formData?.notifications,
              {
                type: 'POPUP',
                offsetTime: 10,
                durationUnit: 'MINUTES',
              },
            ]
          : [
              {
                type: 'POPUP',
                offsetTime: 10,
                durationUnit: 'MINUTES',
              },
            ],
      });
    });
    activitiesTypes.length === 0 && dispatch(getActivitiesTypes());
    dispatch(getUserContacts({limit: -1}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(fieldName === 'contact'){
      setFormData({
        ...formData,
        contacts: [Number(fieldId)]
      });
    }
  }, [fieldName]);

  useEffect(() => {
    setAllDay(!!date);
    setStartDate(date ? new Date(date) : null);
    setFinalDate(date ? new Date(date) : null);
  }, [date]);

  const addNotification = () => {
    setFormData({
      ...formData,
      notifications: formData?.notifications
        ? [
            ...formData?.notifications,
            {
              type: 'POPUP',
              offsetTime: 10,
              durationUnit: 'MINUTES',
            },
          ]
        : [
            {
              type: 'POPUP',
              offsetTime: 10,
              durationUnit: 'MINUTES',
            },
          ],
    });
  };

  const updateNotification = (index: number, key: string, value: string) => {
    formData?.notifications &&
      setFormData({
        ...formData,
        notifications: [
          ...formData?.notifications.map((notification, id) => {
            if (index === id)
              return {
                ...notification,

                [key]: value,
              };
            else return notification;
          }),
        ],
      });
  };

  const removeNotification = (index: number) => {
    formData?.notifications &&
      setFormData({
        ...formData,
        notifications: [
          ...formData?.notifications?.filter(
            (notification, id) => index !== id,
          ),
        ],
      });
  };

  const submitForm = () => {
    let submitForm = formData;

    if (startDate)
      submitForm.dateStart =
        moment(startDate).format('YYYY-MM-DD') +
        (allDay
          ? ' 00:00:00'
          : ' ' + moment(startTime).format('HH:mm') + ':00');

    if (finalDate)
      submitForm.dateEnd =
        moment(finalDate).format('YYYY-MM-DD') +
        (allDay
          ? ' 23:59:00'
          : ' ' + moment(finalTime).format('HH:mm') + ':00');

    if (startDate || finalDate) {
      submitForm.allDay = allDay ? 1 : 0;
    }

    submitForm.contacts = formData.contacts
      ?.map(contactId => Number(contactId))
      .filter(id => id);
    if (fieldName && fieldId)
      if(fieldName === 'contact'){
        submitForm = { ...submitForm, contacts: [Number(fieldId)] };
      } else {
        submitForm = { ...submitForm, [fieldName]: [fieldId] };
      }

    if (property?.id) {
      dispatch(
        createActivities({ propertyId: property?.id, form: submitForm }),
      ).then(res => {
        if (res?.payload?.id) {
          onCloseModal();
          toast.success('Activitatea a fost creata cu succes!');
          dispatch(getPropertyActivities({ id: property?.id }));
        } else {
          diplayErrorMessages(res.payload);
        }
      });
    } else {
      dispatch(createActivity(submitForm)).then(res => {
        if (res?.payload?.id) {
          onCloseModal();
          dispatch(getActivities(fieldId ? { [fieldName]: fieldId } : {}));
          toast.success('Activitatea a fost creata cu succes!');
        } else {
          diplayErrorMessages(res.payload);
        }
      });
    }
  };

  const onCloseModal = () => {
    closeModal();
    setFormData({});
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={onCloseModal}
        animation={true}
        className={'mobile-modal activities-modal'}
      >
        <Modal.Header>
          {' '}
          <div className='close-btn-modal' onClick={onCloseModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          <span>Adauga activitate</span>
        </Modal.Header>
        <Modal.Body>
          <div className='add-activity-modal'>
            {!auth?.roles?.includes('EMPLOYEE') && (
              <div className='form-item'>
                <FormSelect
                  labelText={true}
                  placeholder='Select Options'
                  inlineLabel='Agentul:'
                  isSearchable={true}
                  value={
                    formData?.employeeId
                      ? getValue(users, formData?.employeeId)
                      : null
                  }
                  options={parseValues(users) ?? []}
                  onChange={(e: SelectValue) =>
                    setFormData({
                      ...formData,
                      employeeId: Number(e.value),
                    })
                  }
                />
              </div>
            )}
            <div className='form-item'>
              <FormSelect
                labelText={true}
                placeholder='Select Options'
                inlineLabel='Tip:'
                value={
                  formData?.typeId
                    ? getValue(activitiesTypes, formData?.typeId)
                    : activityType
                      ? getValue(activitiesTypes, activityType)
                      : null
                }
                options={parseValues(activitiesTypes)}
                onChange={(e: SelectValue) =>
                  setFormData({ ...formData, typeId: Number(e.value) })
                }
              />
              {error?.typeId && (
                <p className='error-message'>{showError(error.typeId)}</p>
              )}
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                value={formData?.title}
                classInput={error?.title ? 'error' : ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                inlineLabel='Titlu:'
              />
              {error?.title && (
                <p className='error-message'>{showError(error.title)}</p>
              )}
            </div>{' '}
            <div className='form-item'>
              <MultiselectCheckboxes
                onChange={(e: string[]) => {
                  setFormData({
                    ...formData,
                    contacts: e.map(contact => Number(contact)),
                  });
                }}
                labelText={true}
                inlineLabel={'Contacte:'}
                value={
                  formData?.contacts?.map(contact => String(contact)) ?? null
                }
                options={parseValues(contacts) ?? []}
              />
            </div>
            <div className='flex-input flex-date flex-input-md'>
              <div className='date-picker date'>
                <label>Data Inceput:</label>
                <div className='flex-range'>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className={error?.dateStart ? 'error' : ''}
                  />
                </div>
                {error?.dateStart && (
                  <p className='error-message'>{showError(error.dateStart)}</p>
                )}
              </div>
              {!allDay && (
                <div className='date-picker date'>
                  <label>Ora Inceput:</label>
                  <div className='flex-range'>
                    <DatePicker
                      wrapperClassName='hours'
                      selected={startTime}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat='HH:mm'
                      timeIntervals={60}
                      dateFormat='HH:mm'
                      timeCaption='time'
                      onChange={date => setStartTime(date)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='flex-input flex-date flex-input-md'>
              <div className='date-picker'>
                <label>Data Sfarsit:</label>
                <div className='flex-range'>
                  <DatePicker
                    selected={finalDate}
                    className={error?.dateEnd ? 'error' : ''}
                    onChange={date => setFinalDate(date)}
                  />
                </div>
                {error?.dateEnd && (
                  <p className='error-message'>{showError(error.dateEnd)}</p>
                )}
              </div>
              {!allDay && (
                <div className='date-picker date'>
                  <label>Ora Sfarsit:</label>
                  <div className='flex-range'>
                    <DatePicker
                      wrapperClassName='hours'
                      selected={finalTime}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat='HH:mm'
                      timeIntervals={60}
                      dateFormat='HH:mm'
                      timeCaption='time'
                      onChange={date => setFinalTime(date)}
                    />
                  </div>
                </div>
              )}
            </div>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                label='Toata Ziua'
                checked={allDay}
                onChange={() => setAllDay(!allDay)}
              />
            </Form.Group>
            <div className='flex-input flex-input-lg'>
              <label className='form-item-group-label'>
                <p>Notificari:</p>
              </label>

              <div className='input-group-flex input-group-flex-4'>
                {formData?.notifications?.map((notification, index) => (
                  <div className='input-flex'>
                    <div className='form-item'>
                      <FormSelect
                        labelText={false}
                        value={
                          notification.type
                            ? {
                                value: notification.type,
                                label: typesOptions.find(
                                  option => option.value === notification.type,
                                )?.label,
                              }
                            : null
                        }
                        options={typesOptions}
                        onChange={(e: SelectValue) =>
                          updateNotification(index, 'type', e.value)
                        }
                      />
                    </div>{' '}
                    <div className='form-item'>
                      <Input
                        value={notification.offsetTime}
                        type='number'
                        max={100}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          updateNotification(
                            index,
                            'offsetTime',
                            e.target.value,
                          )
                        }
                      />
                    </div>{' '}
                    <div className='form-item'>
                      <FormSelect
                        labelText={false}
                        value={
                          notification.durationUnit
                            ? {
                                value: notification.durationUnit,
                                label: unitOptions.find(
                                  option =>
                                    option.value === notification.durationUnit,
                                )?.label,
                              }
                            : null
                        }
                        options={unitOptions}
                        onChange={(e: SelectValue) =>
                          updateNotification(index, 'durationUnit', e.value)
                        }
                      />
                    </div>
                    <button
                      className='close'
                      onClick={() => removeNotification(index)}
                    >
                      <IconTimes />
                    </button>
                  </div>
                ))}
                {(!formData.notifications ||
                  (formData.notifications &&
                    formData.notifications.length < 3)) && (
                  <div
                    className='add-notification'
                    onClick={() => addNotification()}
                  >
                    + Adauga Notificare
                  </div>
                )}
              </div>
            </div>
            <div className='form-item-group'>
              <label className='form-item-group-label' htmlFor=''>
                Descriere:{' '}
              </label>
              <div className={`form-item-group-block`}>
                <textarea
                  name=''
                  id=''
                  value={formData?.details}
                  onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setFormData({ ...formData, details: e.target.value })
                  }
                  rows={4}
                  className={`form-item-control `}
                ></textarea>
              </div>
            </div>
            <div className='btns-container'>
              <button className='reset-btn' onClick={onCloseModal}>
                Anuleaza
              </button>
              <button
                className='button-blue button-md'
                onClick={() => submitForm()}
              >
                Trimite
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActivityAddModal;
