import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListResidentialComplexes from '../../components/FilterListResidentialComplexes/FilterListResidentialComplexes';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import ResidentialComplexListItem from '../../components/ResidentialComplexListItem';
import ResidentialComplexModal from '../../components/ResidentialComplexModal';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  checkAll,
  toggleChecked,
} from '../../redux/residentialComplexes/residentialComplexes-slice';
import {
  getResidentialComplexes,
  removeResidentialComplexes,
} from '../../redux/residentialComplexes/residentialComplexesThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import { ResidentialComplexItem } from '../../models/ResidentialComplexes';
import IconPlus from '../../icons/IconPlus';
import './ResidentialComplexes.scss';


const actionOptions = [
  {
    value: 'edit',
    label: 'Modifica',
  },
  {
    value: 'arhive',
    label: 'Arhiveaza',
  },
  {
    value: 'remove',
    label: 'Sterge',
  },
];

interface Props {}

const ResidentialComplexes: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { residentialComplexes, totalCount, loading } = useAppSelector(
    state => state.residentialComplexes,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [filtersForm, setFiltersForm] = useState({});
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [sort, setSort] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.ResidentialComplexList),
  );

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / itemsPerPage);

      setPageCount(pageCount);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, itemsPerPage]);

  useEffect(() => {
    let filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      sort: sort,
      ...filtersForm,
    };
    setLoadingFilters(true);
    dispatch(getResidentialComplexes(filters)).then(() =>
      setLoadingFilters(false),
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, filtersForm, sort]);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const rc = residentialComplexes.find(rc => rc.id === id);

      if (rc && rc.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const editItems = () => {
    if (residentialComplexes.some(rc => rc.checked)) {
      navigate(
        '/residential-complexes/' +
          residentialComplexes.filter(rc => rc.checked)[0].id,
      );
    } else {
      toast.error('Alegeti un ansamblu rezidential!');
    }
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (residentialComplexes.some(rc => rc.checked)) {
      setTitleConfirmModal(`${titleAction} ansambluri rezidentiale`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} ansamblurile rezidentiale selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin un ansamblu rezidential!');
    }
  };

  const removeConfirmedItems = () => {
    dispatch(
      removeResidentialComplexes(
        residentialComplexes
          .filter(rc => rc.checked)
          .map(rc => rc.id)
          .join(','),
      ),
    ).then((res: any) => {
      let filters = {
        offset: currentPage + 1,
        limit: itemsPerPage,
        sort: sort,
        ...filtersForm,
      };

      if (res.type === 'crm/removeResidentialComplexes/fulfilled') {
        toast.success('Ansamblurile rezidentiale selectate au fost sterse!');
        dispatch(checkAll(false));
        closeModal();
        dispatch(getResidentialComplexes(filters));
      }
    });
  };

  const arhiveConfirmedItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere ansambluri rezidentiale') {
      removeConfirmedItems();
    } else if (titleConfirmModal === 'Arhivare ansambluri rezidentiale') {
      arhiveConfirmedItems();
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Ansambluri Rezidentiale'
          subtitle='Lista Ansambluri Rezidentiale'
          isbutton={havePermission}
          btnEvent={() => navigate('/add-residential-complex')}
          btnText='Adauga Ansamblu'
        />
        {havePermission ? (
          <>
            {loading &&
            residentialComplexes.length === 0 &&
            Object.keys(filtersForm).length === 0 ? (
              <Spinner />
            ) : (
              <>
                <FilterListHeader
                  pageCount={pageCount}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalCount={totalCount}
                  count={residentialComplexes?.length}
                  showCheckedItems={showCheckedItems}
                  checkedItemsLength={
                    residentialComplexes.filter(rc => rc.checked).length
                  }
                  setShowCheckedItems={setShowCheckedItems}
                  setItemsPerPage={setItemsPerPage}
                  handlePageClick={handlePageClick}
                  editItems={editItems}
                  removeItems={() => openConfirmModal('Stergere', 'stergi')}
                  arhiveItems={() => openConfirmModal('Arhivare', 'arhivezi')}
                  options={actionOptions}
                />
                <FilterListResidentialComplexes
                  setCheckedAll={(e: boolean) => {
                    setCheckedAll(e);

                    if (e) {
                      handleCheckboxChange(undefined, true);
                    } else {
                      dispatch(checkAll(false));
                    }
                  }}
                  filtersForm={filtersForm}
                  setFiltersForm={setFiltersForm}
                  isCheckedAll={isCheckedAll}
                  sortItems={setSort}
                  sort={sort}
                />
                {loadingFilters ? (
                  <Spinner />
                ) : (
                  <>
                    <div className='list-items-container'>
                      {residentialComplexes
                        .filter(rc => !showCheckedItems || rc.checked)
                        .map((rc: ResidentialComplexItem) => (
                          <ResidentialComplexListItem
                            key={`${rc.id} ${rc?.title ?? ''}`}
                            item={rc}
                            checked={rc.checked}
                            handleCheckboxChange={handleCheckboxChange}
                          />
                        ))}
                      {!residentialComplexes.some(
                        rc => !showCheckedItems || rc.checked,
                      ) && (
                        <p className='empty-text'>
                          {showCheckedItems
                            ? '0 Ansambluri rezidentiale selectate'
                            : 'Cautarea ta a returnat 0 Rezultate'}
                        </p>
                      )}
                    </div>
                    {!showCheckedItems && pageCount > 1 && (
                      <Pagination
                        isSelectPage={false}
                        pageCount={pageCount}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        handlePageClick={handlePageClick}
                      />
                    )}
                  </>
                )}
              </>
            )}
            <FixedActions
              disabledActions={residentialComplexes.every(rc => !rc.checked)}
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <div
              className='add-btn'
              onClick={() => navigate('/add-residential-complex')}
            >
              <IconPlus />
            </div>
            <ResidentialComplexModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) {
                  handleCheckboxChange(undefined, true);
                } else {
                  dispatch(checkAll(false));
                }
              }}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              isCheckedAll={isCheckedAll}
              sortProperty={setSort}
              sort={sort}
              editItems={editItems}
              removeItems={() => openConfirmModal('Stergere', 'stergi')}
              arhiveItems={() => openConfirmModal('Arhivare', 'arhivezi')}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default ResidentialComplexes;
