import React from 'react';
import Form from 'react-bootstrap/Form';

import './FilterListProperties.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  sortProperty: (field: string) => void;
  sort: string;
}

const FilterListProperties: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  sort,
  sortProperty,
}) => {
  return (
    <div className='filter-list-container property-list'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
        </Form.Group>
        <div
          className={`filter-item arrows-label-icon ${sort === 'id' ? 'active-bottom' : ''} ${sort === '-id' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'id'
              ? sortProperty('-id')
              : sort === '-id'
                ? sortProperty('')
                : sortProperty('id')
          }
        >
          <label>ID</label>
          {/* <Input inlineLabel='ID' placeholder='Search' labelText={true} /> */}
        </div>
        <div className='filter-item'>
          <p>Photo</p>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'categoryTitle' ? 'active-bottom' : ''} ${sort === '-categoryTitle' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'categoryTitle'
              ? sortProperty('-categoryTitle')
              : sort === '-categoryTitle'
                ? sortProperty('')
                : sortProperty('categoryTitle')
          }
        >
          <label>Tip</label>
          {/* <Select labelText={true} inlineLabel={'Tip'} /> */}
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'filterRoomValue' ? 'active-bottom' : ''} ${sort === '-filterRoomValue' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'filterRoomValue'
              ? sortProperty('-filterRoomValue')
              : sort === '-filterRoomValue'
                ? sortProperty('')
                : sortProperty('filterRoomValue')
          }
        >
          <label>Camere</label>
          {/* <Input inlineLabel='Camere' placeholder='Search' labelText={true} /> */}
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'floorNumber' ? 'active-bottom' : ''} ${sort === '-floorNumber' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'floorNumber'
              ? sortProperty('-floorNumber')
              : sort === '-floorNumber'
                ? sortProperty('')
                : sortProperty('floorNumber')
          }
        >
          <label>Nivel</label>
          {/* <Select labelText={true} inlineLabel={'Nivel'} /> */}
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'filterSurfaceCValue' ? 'active-bottom' : ''} ${sort === '-filterSurfaceCValue' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'filterSurfaceCValue'
              ? sortProperty('-filterSurfaceCValue')
              : sort === '-filterSurfaceCValue'
                ? sortProperty('')
                : sortProperty('filterSurfaceCValue')
          }
        >
          <label>Suprafata</label>
          {/* <div className='flex-input'>
            <Input placeholder='De...' labelText={false} />
            <Input placeholder='Pan..' labelText={false} />
          </div> */}
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'filterYearValue' ? 'active-bottom' : ''} ${sort === '-filterYearValue' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'filterYearValue'
              ? sortProperty('-filterYearValue')
              : sort === '-filterYearValue'
                ? sortProperty('')
                : sortProperty('filterYearValue')
          }
        >
          <label>An</label>
          {/* <ReactDatePicker
            selected={new Date()}
            showYearPicker
            dateFormat='yyyy'
            onChange={e => console.log(e)}
          /> */}
        </div>
        <div className='filter-item'>
          <p>Adresa</p>
          {/* <Input inlineLabel='Adresa' placeholder='Search' labelText={true} /> */}
        </div>
        <div className='filter-item'>
          <label>Data</label>
          {/* <div className='flex-input'>
            <ReactDatePicker
              placeholderText={'De la'}
              onChange={e => console.log(e)}
            />
            <ReactDatePicker
              placeholderText={'Pana la'}
              onChange={e => console.log(e)}
            />
          </div> */}
        </div>
        <div className='filter-item'>
          <label>Pret</label>
          {/* <div className='flex-input'>
            <Input placeholder='De...' labelText={false} />
            <Input placeholder='Pan...' labelText={false} />
          </div> */}
        </div>
        <div className='filter-item'>
          <p>Promovare</p>
        </div>
        <div className='filter-item'>
          <label>Agent</label>
          {/* <Select labelText={true} inlineLabel={'Agent'} /> */}
        </div>
        <div className='filter-item'></div>
      </form>
    </div>
  );
};

export default FilterListProperties;
