import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { getImagePath } from '../../helpers/useHelper';
import { Property } from '../../models/Properties';
import House from '../../assets/img/house.png';
import ButtonSquare from '../ButtonSquare';
import ModalConfirm from '../ModalConfirm';
import './RequestPropertyListItem.scss';

interface Props {
  item: Property;
  checked?: boolean;
  handleCheckboxChange?: (id: number) => void;
  removeProperty?: (id: number) => void;
  matchProperty?: (id: number) => void;
}

const RequestPropertyListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
  removeProperty,
  matchProperty,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');

  const openConfirmModal = (title: string, body: string) => {
    setTitleConfirmModal(`${title} proprietate`);
    setBodyConfirmModal(`Esti sigur ca doresti sa ${body} proprietatea?`);
    setShowConfirmModal(true);
  };

  const removeConfirmedItems = () => {
    removeProperty && removeProperty(item.id);
  };

  const matchConfirmedItems = () => {
    matchProperty && matchProperty(item.id);
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere proprietate') {
      removeConfirmedItems();
    } else if (titleConfirmModal === 'Propune proprietate') {
      matchConfirmedItems();
    }
  };
  return (
    item && (
      <>
        <div className={`request-associated-item bg-blue `} key={item.id}>
          <div className='request-associated-item--column'>
            <div className='mobile-lines'>
              <div className='line-border'></div>
              <div className='checkbox-container'>
                <Form.Group id='formGridCheckbox'>
                  <Form.Check
                    type='checkbox'
                    checked={checked}
                    readOnly
                    onClick={() =>
                      handleCheckboxChange && handleCheckboxChange(item.id)
                    }
                  />
                </Form.Group>
              </div>
              <div className='line-border'></div>
            </div>
          </div>
          <div className='request-associated-item--column'>
            <div className='image'>
              {item?.media?.filter((image: any) => image.type === 'MAIN')[0]
                ?.media?.path ? (
                <img
                  src={getImagePath(
                    item?.media?.filter(
                      (image: any) => image.type === 'MAIN',
                    )[0]?.media?.path ?? '',
                  )}
                  alt={`${item?.id}`}
                />
              ) : (
                <img src={House} alt='' />
              )}
            </div>
          </div>
          <div className='request-associated-item--column'>
            <div className='info-adress'>
              {item?.townTitle && (
                <div className='display-flex'>
                  <span>
                    Localitate:{'   '}
                    {item?.townTitle}
                  </span>
                </div>
              )}
              {item?.zoneTitle && (
                <div className='display-flex'>
                  <span>
                    Zona:{'   '}
                    {item?.zoneTitle}
                  </span>
                </div>
              )}
              {item?.streetTitle && (
                <div className='display-flex'>
                  <span>
                    Adresa:{'   '}
                    {'str. ' +
                      item?.streetTitle +
                      (item?.streetNumber
                        ? ', nr. ' + item?.streetNumber
                        : '') +
                      (item?.staircase ? ', bl. ' + item?.staircase : '') +
                      (item?.floorNumber ? ', ap. ' + item?.floorNumber : '')}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='request-associated-item--column'>
            <div className='info-properties'>
              {item?.specificTypeTitle && (
                <div className='display-flex'>
                  <span>{item?.specificTypeTitle}</span>
                </div>
              )}
              {item?.filterSurfaceUValue && (
                <div className='display-flex'>
                  <span>SU: </span>
                  <span>{item?.filterSurfaceUValue}</span>
                </div>
              )}
              {item?.filterSurfaceCValue && (
                <div className='display-flex'>
                  <span>SC: </span>
                  <span>{item?.filterSurfaceCValue}</span>
                </div>
              )}
              {item?.filterYearValue && (
                <div className='display-flex'>
                  <span>An: </span>
                  <span>{item?.filterYearValue}</span>
                </div>
              )}
              {item?.filterStaircaseValue && (
                <div className='display-flex'>
                  <span>Etaj: </span>
                  <span>{item?.filterStaircaseValue}</span>
                </div>
              )}
            </div>
          </div>
          <div className='request-associated-item--column'>
            {item?.prices &&
              item?.prices?.length > 0 &&
              item?.prices.map((price: any) => {
                if (price.id === 1 && price.sellingPrice)
                  return (
                    <div className='price'>
                      <span>Pret vanzare: </span>
                      {price.sellingPrice} {item.currencySymbol ?? '€'}
                    </div>
                  );
                if (price.id === 2 && price.rentalMonthPrice)
                  return (
                    <div className='price'>
                      <span>Pret inchiriere: </span>
                      {price.rentalMonthPrice} {item.currencySymbol ?? '€'} / luna
                    </div>
                  );
                if (price.id === 3 && price.rentalDayPrice)
                  return (
                    <div className='price'>
                      <span>Pret regim hotelier: </span>
                      {price.rentalDayPrice} {item.currencySymbol ?? '€'} / zi
                    </div>
                  );
                if (price.id === 4 && price.sellingPriceSqm)
                  return (
                    <div className='price'>
                      <span>Pret vanzare mp: </span>
                      {price.sellingPriceSqm} {item.currencySymbol ?? '€'} / mp
                    </div>
                  );
                if (price.id === 5 && price.rentalMonthPriceSqm)
                  return (
                    <div className='price'>
                      <span>Pret inchiriere mp: </span>
                      {price.rentalMonthPriceSqm} {item.currencySymbol ?? '€'} / mp
                    </div>
                  );
                else return null;
              })}
          </div>
          <div className='request-associated-item--column'>
            <ButtonSquare
              btnSize='md'
              renames={true}
              editAction={() => {
                window.open(
                  window.location.origin.toString() + '/properties/' + item.id,
                  '_blank',
                );
              }}
              checkedBtn={matchProperty ? true : false}
              checkAction={() => {
                openConfirmModal('Propune', 'propui');
              }}
              remove={removeProperty ? true : false}
              removeAction={() => {
                openConfirmModal('Stergere', 'stergi');
              }}
              save={false}
              saveAction={() => {}}
            />
          </div>
        </div>
        <ModalConfirm
          showModal={showConfirmModal}
          title={titleConfirmModal}
          body={bodyConfirmModal}
          onConfirmed={confirmActionWithSelectedItems}
          hideModal={() => setShowConfirmModal(false)}
        />
      </>
    )
  );
};

export default RequestPropertyListItem;
