import React from "react";
import "./SearchMobile.scss";
import Input from "../Input/Input";
import SearchIcon from "../../assets/svg/searchwhite.svg";

interface Props {}

const SearchMobile: React.FC<Props> = () => {
    return (
        <>
            <div className="search-container-modal">
                <Input placeholder="Search" labelText={false} />
                <button className="search-btn">
                    <img src={SearchIcon} alt="" />
                </button>
            </div>
        </>
    );
};

export default SearchMobile;
