import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoAdai from '../../assets/svg/ADAI.svg';
import Logo from '../../assets/svg/logo.svg';
import IconArrowRight from '../../icons/IconArrowRight';
import './SideNav.scss';
import navData from './accordion.json';

interface SublinkType {
  title: string;
  link: string;
}

interface SublinkProps {
  sublinks?: SublinkType[];
}

const Sublinks: React.FC<SublinkProps> = ({ sublinks }) => {
  const location = useLocation();

  return (
    <div className='accordion-body'>
      <ul>
        {sublinks &&
          sublinks.map((sublink, index) => (
            <li key={index}>
              <Link
                className={`nav-item-sublink ${location.pathname === sublink.link ? 'active' : ''}`}
                to={sublink.link}
              >
                {sublink.title}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

interface AccordionItem {
  id: string;
  icon: string;
  text: string;
  link?: string;
  sublinks?: SublinkType[];
}

interface Props {
  initialExpandNav?: boolean;
}

const SideNav: React.FC<Props> = () => {
  const [expandNav, setExpandNav] = useState(() => {
    const savedState = localStorage.getItem('sideNavState');

    return savedState !== null ? JSON.parse(savedState) : false;
  });
  const [openItems, setOpenItems] = useState<string[]>([]);
  const location = useLocation();


  useEffect(() => {
    localStorage.setItem('sideNavState', JSON.stringify(expandNav));
  }, [expandNav]);

  const toggleExpandNav = () => {
    setExpandNav(!expandNav);
    setOpenItems([]);
  };

  const toggleDropdown = (itemId: string) => {
    setOpenItems(prevItems => {
      if (prevItems.includes(itemId)) {
        return [];
      } else {
        return [itemId];
      }
    });
  };

  return (
    <div
      className={
        expandNav ? 'side-nav-container expanded' : 'side-nav-container'
      }
    >
      <Link to='/' className='logo'>
        <img src={Logo} alt='logo' />
        <img src={LogoAdai} alt='logo' />
      </Link>
      <div
        className='expand-button'
        onClick={() => {
          toggleExpandNav();
        }}
      >
        <IconArrowRight />
      </div>
      <div className='accordion-container-scroll'>
        <div className='accordion-container'>
          {navData.map((item: AccordionItem) => {
            const sublinksHeight =
              item.sublinks && item?.sublinks?.length
                ? item?.sublinks?.length
                : 1;
            const isActive =
              (
                item.sublinks?.filter(
                  sublink => location.pathname === sublink.link,
                ) ?? []
              ).length > 0 || location.pathname === item.link;

            return (
              <div
                key={item.id}
                onMouseEnter={() => setExpandNav(true)}
                className={
                  openItems.includes(item.id) || isActive
                    ? 'accordion-item active'
                    : 'accordion-item'
                }
                style={{
                  height: openItems.includes(item.id)
                    ? `${45 + 46 * sublinksHeight}px`
                    : '45px',
                }}
              >
                {item.link ? (
                  <Link to={item.link} className='accordion-button'>
                    <img src={`/${item.icon}.svg`} alt={item.icon} />
                    <p>{item.text}</p>
                    {item.sublinks && (
                      <div
                        className='accordion-button--after'
                        onClick={() => toggleDropdown(item.id)}
                      />
                    )}
                  </Link>
                ) : (
                  <div
                    className='accordion-button'
                    role='button'
                    onClick={() => toggleDropdown(item.id)}
                  >
                    <img src={`/${item.icon}.svg`} alt={item.icon} />
                    <p>{item.text}</p>
                    {item.sublinks && (
                      <div className='accordion-button--after' />
                    )}
                  </div>
                )}
                {item.sublinks && <Sublinks sublinks={item.sublinks} />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
