interface Props {}

const IconTrash: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        d="M20,5.5h-1h-4h-0.5V5V3.3c0-1-0.9-1.8-2-1.8h-3c-1.1,0-2,0.9-2,1.8V5v0.5H7H3H2C1.7,5.5,1.5,5.7,1.5,6
        S1.7,6.5,2,6.5h1h0.5V7v11c0,1.4,1.1,2.5,2.5,2.5h10c1.4,0,2.5-1.1,2.5-2.5V7V6.5H19h1c0.3,0,0.5-0.2,0.5-0.5S20.3,5.5,20,5.5z
        M8.5,5V3.3c0-0.3,0.2-0.5,0.4-0.6C9,2.6,9.3,2.5,9.5,2.5h3c0.2,0,0.5,0.1,0.6,0.2c0.2,0.1,0.4,0.3,0.4,0.6V5v0.5H13H9H8.5V5z
        M17.5,7v11c0,0.8-0.7,1.5-1.5,1.5H6c-0.8,0-1.5-0.7-1.5-1.5V7V6.5H5h12h0.5V7z"
      />
    </svg>
  );
};

export default IconTrash;
