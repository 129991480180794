import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCoordinates, getCounty, getLocalities, getStreet, getZona } from '../../redux/localities/localitiesThunk';
import { addResidentialComplex } from '../../redux/residentialComplexes/residentialComplexesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import useHelper, { getValue, haveUserPermission, parseValues } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import { Coordinate, SelectValue } from '../../models/Properties';
import { ResidentialComplexForm } from '../../models/ResidentialComplexes';
import './AddResidentialComplex.scss';
import MapChoice from '../../components/MapChoice';
import Map from '../../components/Map';
import Input from '../../components/Input';
import FormSelect from '../../components/FormSelect';
import StreetAddModal from '../../components/StreetAddModal';
import { clearStreets, clearTowns, clearZona } from '../../redux/localities/localities-slice';


const AddResidentialComplex: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();

  const { users } = useAppSelector(state => state.users);
  const { localities, county, streets, zona } = useAppSelector(
    state => state.localities,
  );
  const { auth } = useAppSelector(state => state.auth);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.ResidentialComplexList),
  );
  const [formData, setFormData] = useState<ResidentialComplexForm>({
    employeeId: auth?.id,
    status: 1,
  });
  const [formDataErrors, setFormDataErrors] = useState<any>({});
  const [coordinates, setCoordinates] = useState<Coordinate>({
    type: 'PIN',
    lat: 44.26,
    lng: 26.6,
  });

  useEffect(() => {
    dispatch(getUsers({ roles: 'AGENCY', limit: -1 }));
    dispatch(getCounty({ parent: 0 }));
  }, []);

  useEffect(() => {
    if (formData.countyId) {
      dispatch(getLocalities({ parent: formData.countyId })).then(res => {
        parseValues(res.payload)?.length > 0 &&
        handleString('townId', +parseValues(res.payload)[0].value);
      });
    } else {
      dispatch(clearTowns());
      dispatch(clearStreets());
      dispatch(clearZona());
      handleString('zonaId', null);
      handleString('streetId', null);
      handleString('townId', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.countyId]);

  useEffect(() => {
    if (formData.townId) {
        dispatch(getStreet({ town: formData.townId }));
    } else {
      dispatch(clearStreets());
      dispatch(clearZona());
      handleString('zonaId', null);
      handleString('streetId', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.townId]);

  useEffect(() => {
    if (formData.streetId) {
        dispatch(getZona({ street: formData.streetId }));
    } else {
      handleString('zonaId', null);
      dispatch(clearZona());
    }

    getCoords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.streetId]);

  const getCoords = () => {
    if (formData?.countyId && formData?.townId && formData?.streetId) {
      dispatch(
        getCoordinates({
          county: formData.countyId,
          town: formData.townId,
          street: formData.streetId,
        }),
      ).then(res => {
        if (
          res.type === 'localities/getCoordinates/fulfilled' &&
          res.payload.lat
        ) {
          setCoordinates({
            lat: res.payload.lat,
            lng: res.payload.lng,
            type: 'PIN',
          });
        }
      });
    }
  };

  const handleChange = (key: string, e: SelectValue | null) => {
    setFormData({
      ...formData,
      [key]: Number(e?.value),
    });
  };

  const handleString = (key: string, e: string | number | null) => {
    setFormData({
      ...formData,
      [key]: e,
    });
  };

  const submitForm = () => {

    dispatch(addResidentialComplex(formData)).then(res => {
      if (res.payload.id) {
        navigate('/residential-complexes/' + res.payload.id);
        setFormDataErrors({});
        setFormData({});
      } else {
        setFormDataErrors(res.payload);
        diplayErrorMessages(res.payload);
      }
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Adauga ansamblu rezidential'
          subtitle='Completeaza toate campurile obligatorii'
          isbutton={false}
          backBtn={true}
          btnText=''
        />
        {havePermission ? (
          <>
            <div className='section-container'>
              <div className="bg-white residential-left">
                <h2 className="heading-block">Localizare</h2>
                <form action="" className="form-block">
                  <div className="form-item">
                    <Input
                      labelText={true}
                      value={formData?.title ?? ''}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('title', e.target.value)
                      }
                      inlineLabel="Nume Ansamblu"
                      error={formDataErrors?.title}
                    />
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      labelText={true}
                      placeholder='Select Options'
                      inlineLabel='Agent'
                      isSearchable={true}
                      isClearable={true}
                      value={
                        formData?.employeeId
                          ? getValue(users, formData?.employeeId)
                          : null
                      }
                      error={formDataErrors?.employeeId}
                      options={parseValues(users) ?? []}
                      onChange={(e: SelectValue) => handleChange('employeeId', e)}
                    />
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Judet'
                      value={
                        formData?.countyId
                          ? getValue(county, formData?.countyId)
                          : null
                      }
                      error={formDataErrors?.countyId}
                      options={parseValues(county) ?? []}
                      onChange={(e: SelectValue) => handleChange('countyId', e)}
                    />
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Localitate'
                      value={
                        formData?.townId
                          ? getValue(localities, formData?.townId)
                          : null
                      }
                      error={formDataErrors?.townId}
                      options={parseValues(localities) ?? []}
                      onChange={(e: SelectValue) =>
                        handleString('townId', +e?.value)
                      }
                    />
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      labelText={true}
                      inlineLabel='Strada'
                      isClearable={true}
                      options={parseValues(streets) ?? []}
                      onChange={(e: SelectValue) => handleChange('streetId', e)}
                      value={
                        formData.streetId
                          ? getValue(streets, formData.streetId)
                          : null
                      }
                      error={formDataErrors?.streetId}
                    />
                    <p className='link' onClick={() => setIsModalOpen(true)}>
                      Propune Strada/Zona
                    </p>
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Zona'
                      value={
                        formData?.zoneId ? getValue(zona, formData?.zoneId) : null
                      }
                      error={formDataErrors?.zoneId}
                      options={parseValues(zona) ?? []}
                      onChange={(e: SelectValue) => handleChange('zoneId', e)}
                    />
                  </div>
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.streetNumber ?? ''}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('streetNumber', e.target.value)
                      }
                      inlineLabel='Nr. strada'
                      error={formDataErrors?.streetNumber}
                    />
                  </div>
                </form>
              </div>
              <div className="bg-blue residential-right ">
                <MapChoice
                  coordinates={coordinates}
                  setCoordinates={setCoordinates}
                />
                <div className='map'>
                  <Map
                    coordinates={coordinates}
                    setCoordinates={setCoordinates}
                  />
                </div>
              </div>
              <div className='form-buttons bg-white'>
                <button
                  className='button-blue button-lg button-spin'
                  onClick={submitForm}
                >
                  Adauga
                </button>
              </div>
            </div>
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <StreetAddModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default AddResidentialComplex;
