import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Input from '../Input/Input';
import './FilterListCompany.scss';
import FormSelect from "../FormSelect";
import AlphabetFilter from "../AlphabetFilter";
import {SelectValue} from "../../models/Properties";

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  sortProperty: (field: string) => void;
  sort: string;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  closeModal?: () => void;
  filterByLettersOnMobile: boolean;
  setFilterByLettersOnMobile: (p: boolean) => void;
}

const FilterListCompany: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  sort,
  sortProperty,
  filtersForm,
  setFiltersForm,
  closeModal,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectMobileFilter = (e: SelectValue) => {
    if (e.value === (filterByLettersOnMobile ? '1' : '0')) {
      setFilterByLettersOnMobile(e.value === '0');
      setFiltersForm({});
      setCheckedAll && setCheckedAll(false);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const sortByProperty = (property: string) => {
    if (sortProperty)
      if (sort === property) {
        sortProperty(`-${property}`);
      } else if (sort === `-${property}`) {
        sortProperty('');
      } else {
        sortProperty(property);
      }
  }

  const options = [
    { value: '0', label: 'Filtrare dupa litere' },
    { value: '1', label: 'Filtrare dupa campuri' },
  ];

  return (
    <div className='filter-list-container filter-list-company'>
      <form>
        <div className='form-item hide-on-desktop'>
          <FormSelect
              labelText={false}
              placeholder='Filtrare dupa'
              isSearchable={false}
              options={options}
              className='select-blue'
              onChange={onSelectMobileFilter}
              value={filterByLettersOnMobile ? options[0] : options[1]}
          />
        </div>
        {filterByLettersOnMobile && (
            <div className='alphabet-wrapper hide-on-desktop'>
              <AlphabetFilter
                  filtersForm={filtersForm}
                  setFiltersForm={setFiltersForm}
              />
            </div>
        )}
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
          <div className='form-item-group-label'>Selecteaza toate</div>
        </Form.Group>

        {(!isMobile || !filterByLettersOnMobile) && (
          <>
            <div
              className={`filter-item arrows-label-icon ${sort === 'title' ? 'active-bottom' : ''} ${sort === '-title' ? 'active-top' : ''}`}
              id='title-input'
            >
              <label
                onClick={() => sortByProperty('title')}
              >
                Nume
              </label>
              <Input
                inlineLabel='Nume'
                placeholder='Search'
                type='search'
                labelText={false}
                value={filtersForm?.name ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFiltersForm({ ...filtersForm, name: e.target.value });
                }}
              />
            </div>
            <div className='filter-item'>
              <Input
                inlineLabel='Telefon'
                placeholder='Search'
                type='search'
                labelText={true}
                value={filtersForm?.phone ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFiltersForm({ ...filtersForm, phone: e.target.value })
                }
              />
            </div>
            <div className='filter-item'>
              <Input
                inlineLabel='E-mail'
                placeholder='Search'
                type='search'
                labelText={true}
                value={filtersForm?.email ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFiltersForm({ ...filtersForm, email: e.target.value })
                }
              />
            </div>
            <div className='filter-item hide-mobile'>
              <label>Adresa</label>
            </div>
            <div
              className={`filter-item arrows-label-icon ${sort === 'dateCreated' ? 'active-bottom' : ''} ${sort === '-dateCreated' ? 'active-top' : ''} hide-mobile`}
            >
              <div className='date-picker'>
                <label
                  onClick={() => sortByProperty('dateCreated')}
                >
                  Data adaugare
                </label>
              </div>
            </div>
          </>
        )}
        <div className='bottom-filter-section'>
          <div className='form-btns-section'>
            <button
              className='reset-btn'
              onClick={e => {
                setFiltersForm({});
                e.preventDefault();
                closeModal && closeModal();
              }}
            >
              Reseteaza
            </button>
            <button
              className='filter-btn btn-blue'
              onClick={e => {
                e.preventDefault();
                closeModal && closeModal();
              }}
            >
              Filtreaza
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterListCompany;
