import React from 'react';
import Select from 'react-select';

import './Select.scss';

//TODO: Resolve interface for this component
const defaultOptions = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];
const AppSelect: React.FC<any> = ({
  labelText,
  inlineLabel,
  id,
  label,
  options,
  ...props
}) => {
  return (
    <>
      <div className='form-item-group'>
        {labelText && (
          <label className='form-item-group-label' htmlFor={id}>
            {inlineLabel}
          </label>
        )}
        <Select
          options={options ? options : defaultOptions}
          {...props}
          classNames={{
            control: state =>
              state.isFocused
                ? 'form-item-control-select'
                : 'form-item-control-select',
          }}
          classNamePrefix='react-select'
        />
        <div className='invalid-feedback' role='alert'></div>
        <div className='valid-feedback'></div>
      </div>
    </>
  );
};

export default AppSelect;
