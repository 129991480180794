import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AnnouncesListItem from '../../components/AnnouncesListItem';
import AnouncesListCard from '../../components/AnouncesMobile/AnouncesListCard/AnouncesListCard';
import ModalMobile from '../../components/AnouncesMobile/ModalMobile/ModalMobile';
import FilterCategory from '../../components/FilterCategory';
import FilterList from '../../components/FilterList/FilterList';
import FilterListHeader from '../../components/FilterListHeader';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './Announces.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCategories } from '../../redux/categories/categoriesThunk';
import {
  getAnnouncesFilters,
  getAnnounces,
  saveAnnounce,
  checkAnnouncesCount,
} from '../../redux/announces/announcesThunk';
import { checkAll, toggleChecked } from '../../redux/announces/announces-slice';
import Spinner from '../../components/Spinner';
import { Announce } from '../../models/Announces';
import { useNavigate } from 'react-router-dom';
import AnnouncesCountPopup from '../../components/AnnouncesCountPopup';

interface Props {}

const Announces: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const { announces, totalCount, loading, newAnnouncesCount } = useAppSelector(
    state => state.announces,
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [filtersForm, setFiltersForm] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.AdList),
  );
  const [prevState, setPrevState] = useState({
    currentPage: 0,
    itemsPerPage: 24,
  });
  const [hasNewAnnounces, setHasNewAnnounces] = useState(false);

  const activeCategoryRef = useRef(activeCategory);
  const filtersFormRef = useRef(filtersForm);

  useEffect(() => {
    const intervalId = setInterval(() => checkNewAnnounces(), 60000);

    dispatch(getCategories());
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / itemsPerPage);

      setPageCount(pageCount);
    }
  }, [totalCount, itemsPerPage]);

  useEffect(() => {
    activeCategory === 0
      ? dispatch(getAnnouncesFilters())
      : dispatch(getAnnouncesFilters(activeCategory));

    setFiltersForm(undefined);
    activeCategoryRef.current = activeCategory;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    let newState = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
    };

    if (
      JSON.stringify(prevState.currentPage) !==
      JSON.stringify(newState.currentPage) ||
      JSON.stringify(prevState.itemsPerPage) !==
      JSON.stringify(newState.itemsPerPage)
    ) {
      loadAnnounces()
      setPrevState(newState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    } else {
      loadAnnounces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, filtersForm]);

  useEffect(() => {
    setHasNewAnnounces(newAnnouncesCount > totalCount);
  }, [newAnnouncesCount])

  useEffect(() => {
    filtersFormRef.current = filtersForm;
  }, [filtersForm]);


  const loadAnnounces= () => {
    setHasNewAnnounces(false);
    dispatch(getAnnounces(getFilters()));
    closeModal();
    dispatch(checkAll(true));
    setCheckedAll(false);
  }

  const checkNewAnnounces = () => {
    dispatch(checkAnnouncesCount({
      offset: 0,
      limit: 0,
      category: activeCategoryRef.current,
      filters: filtersFormRef.current,
    }))
  };

  const getFilters = () => {
    return {
      offset: currentPage + 1,
      limit: itemsPerPage,
      category: activeCategory,
      filters: filtersForm,
    };
  }


  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const item = announces.find(item => item.id === id);

      if (item && item.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (announces.some((announce: any) => announce.checked)) {
      setTitleConfirmModal(`${titleAction} anunturi`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} anunturile selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin un anunt!');
    }
  };

  const removeConfirmedItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const arhiveConfirmedItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere anunturi') {
      removeConfirmedItems();
    } else if (titleConfirmModal === 'Arhivare anunturi') {
      arhiveConfirmedItems();
    }
  };

  const save = async(id: number) => {
    await dispatch(saveAnnounce(id))
      .then(res => {
        if (res?.payload?.id) {
          navigate('/properties/' + res?.payload?.id);
          toast.success('Anuntul a fost salvat cu succes!');
        } else if (res?.payload === 'ANNOUNCEMENT_IMPORTED') {
          toast.error('Acest anunt a fost salvat anterior');
        } else {
          toast.error('Eroare la salvarea anuntului!');

        }
      });
  }

  return (
    <div className='flex-container announces-page display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header title='Anunturi' subtitle='' isbutton={false} btnText='' />
        {havePermission ? (
          <>
            <div className={isMobile ? 'mobile-container main-wrapper-mobile' : 'desktop-container'}>
              <FilterCategory
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                filtersForm={filtersForm}
                setFiltersForm={setFiltersForm}
              />

              { loading ? (
                <div className='container'>
                  <Spinner />
                </div>
              ) : (
                <>
                  <FilterListHeader
                    pageCount={pageCount}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalCount={totalCount}
                    count={announces?.length}
                    showCheckedItems={showCheckedItems}
                    checkedItemsLength={announces.filter(item => item.checked).length}
                    setShowCheckedItems={setShowCheckedItems}
                    setItemsPerPage={setItemsPerPage}
                    setCurrentPage={setCurrentPage}
                    handlePageClick={handlePageClick}
                    showActions={false}
                  />

                  {!isMobile && <FilterList
                    setCheckedAll={(e: boolean) => {
                      setCheckedAll(e);

                      if (e) {
                        handleCheckboxChange(undefined, true);
                      }
                      else {
                        dispatch(checkAll(false));
                      }
                    }}
                    isCheckedAll={isCheckedAll}
                  />
                  }
                  <div className='list-items-container'>
                    {announces
                      .filter(item => !showCheckedItems || item.checked)
                      .map((item: Announce) => {
                         return isMobile ?
                        <AnouncesListCard
                          key={item.id}
                          item={item}
                          handleCheckboxChange={handleCheckboxChange}
                          save={() => save(item.id)}
                        /> :
                          <AnnouncesListItem
                            key={item.description}
                            item={item}
                            handleCheckboxChange={handleCheckboxChange}
                            save={() => save(item.id)}
                          />
                      })}
                    {!announces.some(item => !showCheckedItems || item.checked) && (
                      <p className='empty-text'>
                        {showCheckedItems
                          ? '0 Anunturi selectati'
                          : 'Cautarea ta a returnat 0 Rezultate'}
                      </p>
                    )}
                  </div>
                  {!showCheckedItems && pageCount > 1 && (
                    <Pagination
                      isSelectPage={false}
                      pageCount={pageCount}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      setItemsPerPage={setItemsPerPage}
                      handlePageClick={handlePageClick}
                    />
                  )}
                </>
              )}
              {hasNewAnnounces && (
                <AnnouncesCountPopup
                  count={newAnnouncesCount - totalCount}
                  onButtonClick={loadAnnounces} />
              )}

              <FixedActions
                ModalAction={ModalAction}
                ModalFilter={ModalFilter}
                ModalSearch={ModalSearch}
              />
              <ModalMobile
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                isAction={isAction}
                isSearch={isSearch}
                isFilter={isFilter}
              />
            </div>
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default Announces;
