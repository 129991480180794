import { createSlice } from '@reduxjs/toolkit';
import {
  getActiveAgentsCount, getLastAnnouncesCount, getLastRequestsCount,
} from './dashboardThunk';

type PropertiesState = {
  activeAgentsCount: number;
  lastAnnouncesCount: number;
  lastRequestsCount: number;
  error: any;
};

const initialState: PropertiesState = {
  activeAgentsCount: 0,
  lastAnnouncesCount: 0,
  lastRequestsCount: 0,
  error: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getActiveAgentsCount.fulfilled, (state, { payload }) => {
        state.activeAgentsCount = payload;
        state.error = null;
      })
      .addCase(getActiveAgentsCount.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getLastAnnouncesCount.fulfilled, (state, { payload }) => {
        state.lastAnnouncesCount = payload;
        state.error = null;
      })
      .addCase(getLastAnnouncesCount.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getLastRequestsCount.fulfilled, (state, { payload }) => {
        state.lastRequestsCount = payload;
        state.error = null;
      })
      .addCase(getLastRequestsCount.rejected, (state, action) => {
        state.error = action.payload;
      })
  },
});

export const {
  clearError,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
