import React, { useEffect, useState } from 'react';

import './AgentProfileTabs.scss';

interface Props {
  openedFirstTab: (openFirstTab: boolean) => void;
}

const AgentProfileTabs: React.FC<Props> = ({ openedFirstTab }) => {
  const [activeTab, setActiveTab] = useState(false);

  useEffect(() => {
    openedFirstTab(activeTab);
  }, [activeTab]);

  return (
    <div className='agent-profile-tabs'>
      <div className='tabs-container'>
        <div
          className={`all-list tab-item ${activeTab && 'active'}`}
          onClick={() => setActiveTab(true)}
        >
          <div className='tab-icon'>
            <svg
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              viewBox='0 0 251 93'
              width={251}
              height={93}
            >
              <path d='M174.2,0h-154C9,0,0,9,0,20.1V93h239.4l-47.7-82.9C188.1,3.9,181.4,0,174.2,0z' />
              <path d='M251,93h-1.3C250.5,93,251,93,251,93z' />
              <path d='M233.3,82.4l6.1,10.5h10.4C246.7,92.8,238.7,91.5,233.3,82.4z' />
            </svg>
          </div>
          <p>Profil Agent</p>
        </div>
        <div
          className={`selected-list tab-item ${!activeTab && 'active'}`}
          onClick={() => setActiveTab(false)}
        >
          <div className='tab-icon'>
            <svg
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              viewBox='0 0 271 93'
              width={271}
              height={93}
            >
              <g>
                <path d='M194.2,0h-154C29,0,20,9,20,20.1v53.1C18.9,93,0,93,0,93h20h239.3l-47.7-82.9C208.1,3.9,201.4,0,194.2,0z' />
                <path d='M269.7,93c0.8,0,1.3,0,1.3,0H269.7z' />
                <path d='M253.3,82.4l6.1,10.5h10.4C266.7,92.8,258.7,91.5,253.3,82.4z' />
              </g>
            </svg>
          </div>
          <p>Modifica Date Agent</p>
        </div>
      </div>
    </div>
  );
};

export default AgentProfileTabs;
