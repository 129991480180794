interface Props {
  className?: string;
}

const IconEye: React.FC<Props> = props => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 22 16'
      {...props}
    >
      <path
        d='M20.4,7.8c-0.3-0.5-1.3-2.2-3-3.6c-1.6-1.5-3.9-2.7-6.7-2.6C8.1,1.6,6,2.8,4.5,4.1C3,5.5,2,7,1.6,7.8
    C1.5,8,1.5,8.1,1.6,8.3c0.3,0.5,1.3,2.1,2.9,3.5c1.6,1.4,3.8,2.7,6.6,2.7h0.2c2.6-0.1,4.7-1.2,6.2-2.6C19,10.5,20,9,20.4,8.2
    C20.5,8.1,20.5,7.9,20.4,7.8z M19.2,8.3c-1,1.6-3.7,5.1-8,5.2c-2.3,0-4.2-0.9-5.6-2C4.2,10.3,3.2,9,2.8,8.3L2.6,8l0.2-0.3
    c1-1.6,3.8-5.1,8-5.2c2.3-0.1,4.2,0.9,5.6,2c1.4,1.1,2.4,2.5,2.9,3.2L18.8,8L19.2,8.3z'
      />
      <path
        d='M11,5C9.3,5,8,6.3,8,8s1.3,3,3,3s3-1.3,3-3S12.7,5,11,5z M11,10c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2
        S12.1,10,11,10z'
      />
    </svg>
  );
};

export default IconEye;
