import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Input from '../../components/Input/Input';
import { ResidentialComplexForm } from '../../models/ResidentialComplexes';
import './AddSpecificationsForm.scss';


interface Props {
  formData: ResidentialComplexForm;
  setFormData: (form: ResidentialComplexForm) => void;
}

const placeHolders = [
  { key: '(Ex: Arhitect)', value: '(Ex: S.C. Arhitect SRL)' },
  { key: '(Ex: Dezvoltator)', value: '(Ex: S.C. Dezvoltator SRL)' },
  { key: '(Ex: Incepere Constructie)', value: '(Ex: An, Luna)' },
  { key: '(Ex: Finalizare Constructie)', value: '(Ex: An, Luna)' },
  { key: '(Ex: Tip Locuinta)', value: '(Ex: Apartament 2/5 camere, Casa)' },
  { key: '(Ex: Tip Imobil)', value: '(Ex: Bloc P+4)' },
  { key: '(Ex: Suprafete Utile)', value: '(Ex: 35mp)' },
];

const AddSpecificationsForm: React.FC<Props> = ({ formData, setFormData }) => {
  const handleAdditionalInfoInputChange = (
    index: number,
    key: string,
    value: string,
  ) => {
    const newAdditionalInfo = [
      ...(formData.additionalInformation?.map(i => {
        return { key: i?.key, value: i?.value };
      }) || []),
    ];

    if (!newAdditionalInfo[index]) {
      newAdditionalInfo[index] = { key: key, value: value };
    } else {
      newAdditionalInfo[index].key = key;
      newAdditionalInfo[index].value = value;
    }

    setFormData({
      ...formData,
      additionalInformation: newAdditionalInfo,
    });
  };

  return (
    <div className='display-flex ads-form-container'>
      <Container>
        <Row>
          <Col className='ads-form-container '>
            <fieldset className='row fieldset-block row-93'>
              <Col lg='12'>
                <legend className='heading-block'>
                  Informatii arhitect/constructor
                </legend>
              </Col>
              <Col lg='12'>
                <fieldset>
                  <div className='filters-aligning-block'>
                    {[...Array(7)].map((_, index) => (
                      <div className='fieldset-group' key={index}>
                        <Input
                          labelText={false}
                          placeholder={placeHolders[index].key}
                          type='text'
                          max={60}
                          id={`additional-info-key-${index}`}
                          value={
                            formData.additionalInformation?.[index]
                              ? formData.additionalInformation[index].key
                              : ''
                          }
                          onChange={e =>
                            handleAdditionalInfoInputChange(
                              index,
                              e.target.value,
                              formData.additionalInformation?.[index]
                                ? formData.additionalInformation[index].value ||
                                    ''
                                : '',
                            )
                          }
                        />
                        <span className='icon-minus' aria-hidden='true'></span>
                        <Input
                          labelText={false}
                          placeholder={placeHolders[index].value}
                          type='text'
                          max={60}
                          id={`additional-info-value-${index}`}
                          value={
                            formData.additionalInformation?.[index]
                              ? formData.additionalInformation[index].value
                              : ''
                          }
                          onChange={e =>
                            handleAdditionalInfoInputChange(
                              index,
                              formData.additionalInformation?.[index]
                                ? formData.additionalInformation[index].key ||
                                    ''
                                : '',
                              e.target.value,
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                </fieldset>
              </Col>

              <Col lg='12'>
                <hr className='fieldset-block__hr' />
              </Col>
            </fieldset>
            <fieldset className='row fieldset-block-bottom row-91'>
              <Col lg='12'>
                <legend className='heading-block'>Caracterisici</legend>
              </Col>
              <Col lg='4'>
                <Input
                  labelText={true}
                  inlineLabel='Alte informatii'
                  placeholder='Ex: 24 blocuri de locuinte'
                  type='text'
                  max={60}
                  id='other-info'
                  value={formData.characteristics?.[0]}
                  onChange={(e: any) => {
                    let newCs = [...(formData.characteristics || [])];

                    newCs[0] = e.target.value;
                    setFormData({
                      ...formData,
                      characteristics: newCs,
                    });
                  }}
                />
              </Col>
              <Col lg='4'>
                <Input
                  labelText={true}
                  inlineLabel='Alte informatii'
                  placeholder='Ex: Parcare subterana'
                  type='text'
                  max={60}
                  id='underground-parking'
                  value={formData.characteristics?.[1]}
                  onChange={(e: any) => {
                    let newCs = [...(formData.characteristics || [])];

                    newCs[1] = e.target.value;
                    setFormData({
                      ...formData,
                      characteristics: newCs,
                    });
                  }}
                />
              </Col>
              <Col lg='4'>
                <Input
                  labelText={true}
                  inlineLabel='Alte informatii'
                  placeholder='Ex: Transport gratuit'
                  type='text'
                  max={60}
                  id='free-shipping'
                  value={formData.characteristics?.[2]}
                  onChange={(e: any) => {
                    let newCs = [...(formData.characteristics || [])];

                    newCs[2] = e.target.value;
                    setFormData({
                      ...formData,
                      characteristics: newCs,
                    });
                  }}
                />
              </Col>
              <Col lg='4'>
                <Input
                  labelText={true}
                  inlineLabel='Alte informatii'
                  type='text'
                  max={60}
                  id='other-information-2'
                  value={formData.characteristics?.[3]}
                  onChange={(e: any) => {
                    let newCs = [...(formData.characteristics || [])];

                    newCs[3] = e.target.value;
                    setFormData({
                      ...formData,
                      characteristics: newCs,
                    });
                  }}
                />
              </Col>
              <Col lg='4'>
                <Input
                  labelText={true}
                  inlineLabel='Alte informatii'
                  type='text'
                  max={60}
                  id='other-information-3'
                  value={formData.characteristics?.[4]}
                  onChange={(e: any) => {
                    let newCs = [...(formData.characteristics || [])];

                    newCs[4] = e.target.value;
                    setFormData({
                      ...formData,
                      characteristics: newCs,
                    });
                  }}
                />
              </Col>
              <Col lg='4'>
                <Input
                  labelText={true}
                  inlineLabel='Alte informatii'
                  type='text'
                  max={60}
                  id='other-information-4'
                  value={formData.characteristics?.[5]}
                  onChange={(e: any) => {
                    let newCs = [...(formData.characteristics || [])];

                    newCs[5] = e.target.value;
                    setFormData({
                      ...formData,
                      characteristics: newCs,
                    });
                  }}
                />
              </Col>
            </fieldset>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddSpecificationsForm;
