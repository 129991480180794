import moment from 'moment';
import React, { useState } from 'react';
import Gallery from '../../components/Gallery';
import GalleryModal from '../../components/GalleryModal';
import { useAppSelector } from '../../redux/hooks';
import { getImagePath } from '../../helpers/useHelper';
import { PropertyForm } from '../../models/Properties';
import Avatar from '../../assets/img/avatar.png';
import './ResidentialComplexGeneral.scss';


interface Props {
  formData: PropertyForm;
}

const ResidentialComplexGeneral: React.FC<Props> = ({ formData }) => {
  const { residentialComplex } = useAppSelector(
    state => state.residentialComplexes,
  );

  const [isOpened, setOpened] = useState(false);
  const { contacts } = useAppSelector(state => state.contacts);

  const closeModal = () => {
    setOpened(false);
  };

  return (
    <div className='residential-complex-general-sm'>
      <div className={`residential-complex-general-container bg-white `}>
        <Gallery
          onClick={() => setOpened(true)}
          images={residentialComplex?.media?.filter(
            media =>
              (media.status === undefined || media.status === 1) &&
              media.type === 'IMAGES' &&
              media.media?.path,
          )}
        />
        <div className={`residential-complex-general-info-bottom `}>
          <div
            className={`residential-complex-general-info-container item-details-container `}
          >
            <div className='residential-complex-general-info'>
              <table>
                <tbody>
                  {residentialComplex?.rangeSurface && (
                    <tr>
                      <th className='residential-complex-general-info__heading'>
                        Suprafata:
                      </th>
                      <td className='residential-complex-general-info__content'>
                        {residentialComplex?.rangeSurface}
                      </td>
                    </tr>
                  )}
                  {residentialComplex?.rangePrice && (
                    <tr>
                      <th className='residential-complex-general-info__heading'>
                        Preturi:
                      </th>
                      <td className='residential-complex-general-info__content'>
                        {residentialComplex?.rangePrice}
                      </td>
                    </tr>
                  )}
                  {residentialComplex?.rooms && (
                    <tr>
                      <th className='residential-complex-general-info__heading'>
                        Camere:
                      </th>
                      <td className='residential-complex-general-info__content'>
                        {residentialComplex?.rooms}
                      </td>
                    </tr>
                  )}
                  {residentialComplex?.dateUpdated ? (
                    <tr>
                      <th className='residential-complex-general-info__heading'>
                        Ultima modificare:
                      </th>
                      <td className='residential-complex-general-info__content'>
                        {moment(residentialComplex?.dateUpdated).format(
                          'DD.MM.YYYY',
                        )}
                      </td>
                    </tr>
                  ) : residentialComplex?.dateCreated ? (
                    <tr>
                      <th className='residential-complex-general-info__heading'>
                        Adaugat la:
                      </th>
                      <td className='residential-complex-general-info__content'>
                        {moment(residentialComplex?.dateCreated).format(
                          'DD.MM.YYYY',
                        )}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
            {residentialComplex?.employee && (
              <div className='user-general-info'>
                {residentialComplex?.employee?.profile?.avatar?.path ? (
                  <div className='image-block'>
                    <img
                      src={getImagePath(
                        residentialComplex?.employee?.profile?.avatar?.path,
                      )}
                      alt=''
                    />
                  </div>
                ) : (
                  <div className='image-block'>
                    <img src={Avatar} alt='' />
                  </div>
                )}
                <div className='content-info'>
                  <p className='name'>
                    {residentialComplex?.employee?.profile?.fullName}
                  </p>
                  <span className='info'>
                    {residentialComplex?.employee?.profile?.phoneNumbers?.join(
                      ', ',
                    )}
                  </span>
                </div>
              </div>
            )}
            {(formData.contacts?.length ?? 0) > 0 && (
              <div className='associated-contacts'>
                <h3 className='heading'>Contacte asociate</h3>
                <ul className='associated-contacts-list'>
                  {contacts &&
                    contacts.length > 0 &&
                    contacts?.map(item => {
                      if (
                        formData.contacts?.find(
                          contact => contact.id === item.id,
                        )
                      )
                        return (
                          <li
                            className='associated-contacts-list__item'
                            key={item.id}
                          >
                            <a
                              href={`/contacts/${item.id}`}
                              target={'_blank'}
                              className='label-item-rounded-info-sm'
                              aria-label='Vezi pagina utilizatorului Lauren Jensen'
                            >
                              {item?.name + ' ' + item.surname}
                            </a>
                          </li>
                        );
                      else return null;
                    })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <GalleryModal
        closeModal={closeModal}
        isModalOpen={isOpened}
        images={
          residentialComplex?.media?.filter(
            media =>
              media.status === 1 && media.type === 'MAIN' && media.media?.path,
          ) ?? []
        }
      />
    </div>
  );
};

export default ResidentialComplexGeneral;
