import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  EmailTemplate,
  EmailTemplateListItem,
  EmailTemplateRequestDto,
} from '../../models/EmailTemplate';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

type EmailtemplatesResponse = {
  emailTemplates: EmailTemplateListItem[];
  totalCount: number;
};

export const getEmailTemplates = createAsyncThunk<EmailtemplatesResponse, any>(
  'crm/getEmailTemplates',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/email-templates', {
        params: data
      });
      const totalCount = response.headers['x-total-count'];

      return { emailTemplates: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getEmailTemplate = createAsyncThunk<EmailTemplate, any>(
  'crm/emailTemplate',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/email-templates/${id}`);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const addEmailTemplate = createAsyncThunk<
  EmailTemplate,
  EmailTemplateRequestDto
>('crm/emailTemplate/add', async (emailTemplate, { rejectWithValue }) => {
  try {
    const response = await appAxios.post(
      `api/v1/crm/email-templates`,
      emailTemplate.body,
    );

    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const editEmailTemplate = createAsyncThunk<
  EmailTemplate,
  EmailTemplateRequestDto
>('crm/emailTemplate/edit', async (emailTemplate, { rejectWithValue }) => {
  try {
    const response = await appAxios.put(
      `api/v1/crm/email-templates/${emailTemplate.id}`,
      emailTemplate.body,
    );

    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const deleteEmailTemplate = createAsyncThunk<
  EmailTemplateListItem[],
  any
>('crm/emailTemplate/delete', async (id, { rejectWithValue }) => {
  try {
    const response = await appAxios.delete(`api/v1/crm/email-templates/${id}`);

    return { ...response.data, id };
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});
