import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormSelect from '../../components/FormSelect';
import { SelectValue } from '../../models/Properties';

interface Props {
  formData: any;
  handleString: (key: string, value: string | null) => void;
}

const TVABlock: React.FC<Props> = ({ formData, handleString }) => {
  const vatOptions = [
    { value: 5, label: '5%' },
    { value: 19, label: '19%' },
  ];

  return (
    <fieldset className='row fieldset-block'>
      <Col lg='12'>
        <legend className='heading-block'>TVA</legend>
      </Col>
      <Col lg='12' className='check-group-container'>
        <div className='form-item'>
          <Form.Check
            type='radio'
            label='Nu se aplica TVA'
            id='no-tva'
            name='tva'
            value='NO_VAT'
            checked={formData?.vatType === 'NO_VAT'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleString('vatType', e.target.value)
            }
          />
        </div>
        <div className='form-item'>
          <Form.Check
            type='radio'
            label='TVA inclus'
            id='with-tva'
            name='tva'
            value='VAT_INCLUDED'
            checked={formData?.vatType === 'VAT_INCLUDED'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleString('vatType', e.target.value)
            }
          />
        </div>
        <div className='form-item'>
          <Form.Check
            type='radio'
            label='+TVA'
            id='no-tva-additional'
            name='tva'
            value='VAT_NOT_INCLUDED'
            checked={formData?.vatType === 'VAT_NOT_INCLUDED'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleString('vatType', e.target.value)
            }
          />
        </div>
        <div className='form-item'>
          <Form.Check
            type='radio'
            label='Nespecificat'
            id='not-specified'
            name='tva'
            value='NOT_SPECIFIED'
            checked={formData?.vatType === 'NOT_SPECIFIED'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleString('vatType', e.target.value)
            }
          />
        </div>
      </Col>
      <Col lg='12'>
        <hr className='fieldset-block__hr' />
      </Col>
    </fieldset>
  );
};

export default TVABlock;
