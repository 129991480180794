import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReactDatePicker from 'react-datepicker';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input/Input';
import MultiselectCheckboxes from '../../components/MultiselectCheckboxes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearTowns, clearZona } from '../../redux/localities/localities-slice';
import {
  getCounty,
  getLocalities,
  getLocations,
  getSpecificTypes,
  getZones,
} from '../../redux/localities/localitiesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import {
  getRequestFiltersParsed,
  getValue,
  parseValues,
} from '../../helpers/useHelper';
import { Filter, SelectValue, ValueFilter } from '../../models/Properties';
import './RequestCharacteristics.scss';
import { getLeadSources } from '../../redux/contacts/contactsThunk';

const options = [
  {
    label: 'Activa',
    value: 1,
  },
  {
    label: 'Inactiva',
    value: 0,
  },
  {
    label: 'Tranzactionata',
    value: 8,
  },
  {
    label: 'Arhivata',
    value: 4,
  },
];

interface Props {
  formData: any;
  setFormData: (form: any) => void;
  requestFilters: any;
  setRequestFilters: (form: any) => void;
}

const comOptions = [
  { value: 'da', label: 'Da' },
  { value: 'nu', label: 'Nu' },
];

const currencyOptions = [
  { value: '1', label: 'LEI' },
  { value: '2', label: 'EURO' },
];

const payOptions = [
  { value: 'CASH', label: 'Cash' },
  { value: 'CREDIT', label: 'Credit' },
];

const RequestCharacteristics: React.FC<Props> = ({
  formData,
  setFormData,
  requestFilters,
  setRequestFilters,
}) => {
  const dispatch = useAppDispatch();
  const { getRequestState } = useAppSelector(state => state.requests);
  const { locations, county, localities, zona, specificTypes } = useAppSelector(
    state => state.localities,
  );
  const { users } = useAppSelector(state => state.users);
  const { leadSources } = useAppSelector(state => state.contacts);

  const [countyId, setCountyId] = useState<number | null>(
    formData.countyId ?? null,
  );
  const [townId, setTownId] = useState<number | null>(formData.townId ?? null);
  const [requestContractSigningDate, setRequestContractSigningDate] =
    useState<Date | null>(
      formData?.dateSigned ? new Date(formData?.dateSigned) : null,
    );

  useEffect(() => {
    dispatch(getCounty({ parent: 0 }));
    dispatch(getSpecificTypes());
    dispatch(getUsers({ limit: -1 }));
    dispatch(getLocations());
    dispatch(getLeadSources());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData.countyId && !countyId) {
      setCountyId(formData.countyId);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.countyId]);

  useEffect(() => {
    if (formData.townId && !townId) {
      setTownId(formData.townId);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.townId]);

  useEffect(() => {
    if (formData.countyId !== countyId) {
      dispatch(clearTowns());
      dispatch(clearZona());
      setFormData({
        ...formData,
        countyId: countyId ?? undefined,
        townId: undefined,
        zones: [],
      });
    }
    if (countyId) {
      dispatch(getLocalities({ parent: countyId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countyId]);

  useEffect(() => {
    if (formData.townId !== townId) {
      dispatch(clearZona());
      setFormData({ ...formData, townId: townId ?? undefined, zones: [] });
    }
    if (townId) {
      dispatch(getZones({ parent: townId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townId]);

  const handleDateRequest = (e: Date | null) => {
    setFormData({
      ...formData,
      dateSigned: e ? moment(e).format('YYYY-MM-DD') : '',
    });
  };

  useEffect(() => {
    setRequestContractSigningDate(
      formData?.dateSigned ? new Date(formData?.dateSigned) : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    getRequestState.request?.category?.filters &&
      setFormData({
        ...formData,
        filters: getRequestFiltersParsed(
          getRequestState.request?.category?.filters,
          requestFilters,
        ).filter(item => item.id),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestFilters, getRequestState.request?.category.filters]);

  const parseValuesFilter = (values: ValueFilter[]) => {
    if (values.length > 0) {
      return values.map(filter => {
        return {
          value: filter.id,
          label: filter.title,
        };
      });
    } else return [];
  };

  const handleChangeFilter = (
    key: string | number,
    e: string | number | SelectValue | boolean,
  ) => {
    if (e) setRequestFilters({ ...requestFilters, [key]: e });
    else
      setRequestFilters({
        ...requestFilters,
        [key]: null,
      });
  };

  const handleChangeRange = (
    key: string | number,
    object: string,
    e: string | number | SelectValue,
  ) => {
    if (e)
      setRequestFilters({
        ...requestFilters,
        [key]: { ...requestFilters[key], [object]: e },
      });
    else {
      if (object === 'to' && requestFilters[key]?.from)
        setRequestFilters({
          ...requestFilters,
          [key]: { from: requestFilters[key]?.from },
        });
      else if (object === 'from' && requestFilters[key]?.to)
        setRequestFilters({
          ...requestFilters,
          [key]: { to: requestFilters[key]?.to },
        });
      else {
        setRequestFilters({
          ...requestFilters,
          [key]: null,
        });
      }
    }
  };

  return (
    <div className='display-flex ads-form-container'>
      <Container>
        <Row>
          <Col className='ads-form-container-block ads-form-request'>
            <fieldset className='row fieldset-block row-93'>
              <Col lg='12'>
                <legend className='heading-block'>Criterii</legend>
                <p>
                  Pentru o mai buna evidentiere, completeaza cat mai corect
                  toate informatiile din aceasta pagina.
                </p>
              </Col>
              <Col lg='12'>
                <legend className='subheading-block'>
                  Criterii Solicitare
                </legend>
              </Col>
              <Col lg='12'>
                <fieldset className='row filters-aligning'>
                  <Col lg='4'>
                    <div className='form-item'>
                      <FormSelect
                        labelText={true}
                        placeholder='Select Option'
                        inlineLabel={'Agent:'}
                        isSearchable={true}
                        isClearable={true}
                        value={
                          formData?.employeeId
                            ? getValue(users, formData?.employeeId)
                            : null
                        }
                        options={users.length > 0 ? parseValues(users) : []}
                        onChange={(e: SelectValue) =>
                          setFormData({ ...formData, employeeId: e?.value })
                        }
                      />
                    </div>
                  </Col>
                  <Col lg='4'>
                    <div className='form-item'>
                      <MultiselectCheckboxes
                        labelText={true}
                        placeholder='Select Option'
                        inlineLabel={'Agent asociat'}
                        isSearchable={true}
                        isClearable={true}
                        value={formData?.associatedEmployees?.map((id: number) => String(id)) ?? []}
                        options={users.length > 0 ? parseValues(users) : []}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            associatedEmployees: e.map((id: string) => Number(id)),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg='4'>
                    <div className='form-item'>
                      <FormSelect
                        labelText={true}
                        placeholder='Select Option'
                        inlineLabel={'Sursa:'}
                        isSearchable={true}
                        isClearable={true}
                        value={
                          formData?.sourceLeads?.[0]
                            ? getValue(leadSources, formData?.sourceLeads[0])
                            : null
                        }
                        options={leadSources && parseValues(leadSources)}
                        onChange={(e: SelectValue) => {
                          let newLeadSources = (formData.sourceLeads ?? [])
                            .map((lead: any, index: number) =>
                              index === 0 ? Number(e?.value) : Number(lead)
                            );

                          setFormData({
                            ...formData,
                            sourceLeads: newLeadSources,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg='4'>
                    <div className='form-item'>
                      <FormSelect
                        labelText={true}
                        placeholder='Select Option'
                        inlineLabel={'Sursa 2:'}
                        isSearchable={true}
                        isClearable={true}
                        value={
                          formData?.sourceLeads?.[1]
                            ? getValue(leadSources, formData?.sourceLeads[1])
                            : null
                        }
                        options={leadSources && parseValues(leadSources)}
                        onChange={(e: SelectValue) => {
                          let newLeadSources = (formData.sourceLeads ?? [])
                            .map((lead: any, index: number) =>
                              index === 1 ? Number(e?.value) : Number(lead)
                            );

                          setFormData({
                            ...formData,
                            sourceLeads: newLeadSources,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg='4'>
                    <FormSelect
                      labelText={true}
                      inlineLabel='Tip tranzactie:'
                      isDisabled={true}
                      value={{
                        label:
                          getRequestState.request?.transactionType === 'BUY'
                            ? 'Cumparare'
                            : 'Inchiriere',
                        value: getRequestState.request?.transactionType,
                      }}
                    />
                  </Col>
                  <Col lg='4'>
                    <FormSelect
                      labelText={true}
                      inlineLabel='Tip Proprietate:'
                      isDisabled={true}
                      value={{
                        label: getRequestState.request?.category.title,
                        value: getRequestState.request?.category.id,
                      }}
                    />
                  </Col>
                  {getRequestState.request?.category?.filters &&
                    getRequestState.request?.category?.filters.length > 0 &&
                    getRequestState.request?.category?.filters
                      .filter((filter: Filter) => filter.requestShowOnAdd)
                      .map((filter: Filter) => {
                        if (filter.requestTypeAdd === 'SELECT_OPTIONS') {
                          return (
                            <Col lg='4' key={filter.id}>
                              <MultiselectCheckboxes
                                onChange={(e: SelectValue) =>
                                  handleChangeFilter(filter.id, e)
                                }
                                labelText={true}
                                inlineLabel={filter.title}
                                value={requestFilters?.[filter.id]}
                                options={
                                  filter.values &&
                                  parseValuesFilter(filter.values)
                                }
                              />
                            </Col>
                          );
                        }
                        if (filter.requestTypeAdd === 'SPECIFIC_TYPE')
                          return (
                            <Col lg='4'>
                              <MultiselectCheckboxes
                                onChange={(e: any) =>
                                  setFormData({
                                    ...formData,
                                    specificTypes: e.map((s: any) => Number(s)),
                                  })
                                }
                                labelText={true}
                                inlineLabel={filter.title}
                                value={formData.specificTypes?.map((s: any) => String(s))}
                                key={filter.id}
                                options={
                                  specificTypes && parseValues(specificTypes)
                                }
                              />
                            </Col>
                          );
                        if (filter.requestTypeAdd === 'RANGE_SELECT') {
                          return (
                            <Col lg='4'>
                              <div className='form-group' key={filter.id}>
                                <label>{filter.title}</label>
                                <div className='flex-range'>
                                  <FormSelect
                                    labelText={false}
                                    options={
                                      filter.values &&
                                      parseValues(filter.values)
                                    }
                                    isClearable={true}
                                    value={requestFilters?.[filter.id]?.from}
                                    onChange={(e: SelectValue) =>
                                      handleChangeRange(filter.id, 'from', e)
                                    }
                                  />
                                  <FormSelect
                                    labelText={false}
                                    options={
                                      filter.values &&
                                      parseValues(filter.values)
                                    }
                                    isClearable={true}
                                    value={requestFilters?.[filter.id]?.to}
                                    onChange={(e: SelectValue) =>
                                      handleChangeRange(filter.id, 'to', e)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          );
                        }
                        if (filter.requestTypeAdd === 'RANGE')
                          return (
                            <Col lg='4'>
                              <div className='form-group' key={filter.id}>
                                <label className='form-item-group-label'>
                                  {filter.title}
                                </label>
                                <div className='flex-range'>
                                  <Input
                                    labelText={false}
                                    placeholder='De la'
                                    type='number'
                                    inputMode='numeric'
                                    onWheel={e => e.currentTarget.blur()}
                                    value={
                                      requestFilters?.[filter.id]?.from ?? ''
                                    }
                                    onInput={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleChangeRange(
                                        filter.id,
                                        'from',
                                        e.target.value,
                                      )
                                    }
                                  />
                                  <Input
                                    labelText={false}
                                    placeholder='Pana la'
                                    type='number'
                                    inputMode='numeric'
                                    onWheel={e => e.currentTarget.blur()}
                                    value={
                                      requestFilters?.[filter.id]?.to ?? ''
                                    }
                                    onInput={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleChangeRange(
                                        filter.id,
                                        'to',
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          );
                        if (
                          filter.requestTypeAdd === 'SELECT' ||
                          filter.requestTypeAdd === 'EMPLOYEE'
                        )
                          return (
                            <Col lg='4' key={filter.id}>
                              <FormSelect
                                value={requestFilters?.[filter.id] ?? ''}
                                onChange={(e: SelectValue) =>
                                  handleChangeFilter(filter.id, e)
                                }
                                labelText={true}
                                inlineLabel={filter.title}
                                options={
                                  filter.values &&
                                  parseValuesFilter(filter.values)
                                }
                              />
                            </Col>
                          );
                        if (filter.requestTypeAdd === 'INPUT')
                          return (
                            <Col lg='4'>
                              <div className='form-group' key={filter.id}>
                                <label className='form-item-group-label'>
                                  {filter.title}
                                </label>
                                <div className='flex-input'>
                                  <Input
                                    labelText={false}
                                    value={requestFilters?.[filter.id] ?? ''}
                                    onInput={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleChangeFilter(
                                        filter.id,
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          );
                        if (filter.requestTypeAdd === 'CHECKBOX')
                          return (
                            <Col lg='4'>
                              <Form.Group id='formGridCheckbox' key={filter.id}>
                                <Form.Check
                                  type='checkbox'
                                  label={filter.title}
                                  checked={requestFilters?.[filter.id]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleChangeFilter(
                                      filter.id,
                                      e.target.checked ? 1 : 0,
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                          );
                        if (
                          filter.requestTypeAdd ===
                          'FLOOR_EXCLUDED_P_LAST_CHECKBOX'
                        )
                          return (
                            <Col lg='4'>
                              <Form.Group id='formGridCheckbox' key={filter.id}>
                                <Form.Check
                                  type='checkbox'
                                  label={filter.title}
                                  checked={requestFilters?.[filter.id]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleChangeFilter(
                                      filter.id,
                                      e.target.checked ? 1 : 0,
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                          );
                        if (filter.requestTypeAdd === 'LOCATION')
                          return (
                            <Col lg='4'>
                              <MultiselectCheckboxes
                                onChange={(e: SelectValue) =>
                                  handleChangeFilter(filter.id, e)
                                }
                                labelText={true}
                                inlineLabel={filter.title}
                                value={formData?.[filter.id]}
                                key={filter.id}
                                options={locations && parseValues(locations)}
                              />
                            </Col>
                          );
                        else return null;
                      })}
                  <Col lg='4'>
                    <FormSelect
                      options={options}
                      onChange={(e: SelectValue) => {
                        setFormData({ ...formData, status: e.value ?? 0 });
                      }}
                      inlineLabel={'Status'}
                      labelText={true}
                      placeholder={'Selecteaza'}
                      menuIsOpen={true}
                      isSearchable={false}
                      value={
                        options.find(
                          option => option.value === formData.status,
                        ) ?? null
                      }
                      classNamePrefix='react-select'
                    />
                  </Col>
                  <Col lg='4'>
                    <div className='form-item'>
                      <div className='form-item-group form-item-group-textarea'>
                        <label
                          htmlFor='description'
                          className='form-item-group-label'
                        >
                          Descriere
                        </label>
                        <textarea
                          value={formData?.description}
                          onInput={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                          rows={3}
                          className='form-item-control'
                          placeholder='Alte detalii'
                        ></textarea>
                      </div>
                    </div>
                  </Col>
                </fieldset>
              </Col>
            </fieldset>
            <fieldset className='row fieldset-block row-52'>
              <Col lg='12'>
                <legend className='subheading-block'>Localizare</legend>
              </Col>
              <Col lg='12' className='buget-group-container'>
                <div className='form-item'>
                  <FormSelect
                    placeholder='Select'
                    labelText={true}
                    isClearable={true}
                    inlineLabel='Judet:'
                    value={
                      formData?.countyId
                        ? getValue(county, formData?.countyId)
                        : null
                    }
                    options={parseValues(county) ?? []}
                    onChange={(e: SelectValue) => {
                      setCountyId(+e?.value);
                    }}
                  />
                </div>
                <div className='form-item'>
                  <FormSelect
                    placeholder='Select Options'
                    labelText={true}
                    isClearable={true}
                    inlineLabel='Localitate:'
                    value={
                      formData?.townId
                        ? getValue(localities, formData?.townId)
                        : null
                    }
                    options={parseValues(localities) ?? []}
                    onChange={(e: SelectValue) => {
                      setTownId(+e?.value);
                    }}
                  />
                </div>
                <div className='form-item'>
                  <MultiselectCheckboxes
                    onChange={(e: string[]) =>
                      setFormData({ ...formData, zones: e })
                    }
                    labelText={true}
                    inlineLabel={'Zona:'}
                    value={formData?.zones?.map((zona: any) => String(zona))}
                    isClearable={true}
                    options={zona && parseValues(zona)}
                  />
                </div>
              </Col>
            </fieldset>
            <fieldset className='row fieldset-block row-52'>
              <Col lg='12'>
                <legend className='subheading-block'>Buget</legend>
              </Col>
              <Col lg='12' className='buget-group-container'>
                <div className='form-item'>
                  <div className='form-group'>
                    <label className='form-item-group-label'>Pret </label>
                    <div className='flex-range'>
                      <Input
                        labelText={false}
                        placeholder='Min'
                        type='number'
                        value={formData.prices?.min}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFormData({
                            ...formData,
                            prices: { ...formData.prices, min: e.target.value },
                          })
                        }
                      />
                      <Input
                        labelText={false}
                        placeholder='Max'
                        type='number'
                        value={formData.prices?.max ?? ''}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFormData({
                            ...formData,
                            prices: { ...formData.prices, max: e.target.value },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Selecteaza'
                    inlineLabel={'Moneda'}
                    value={
                      formData?.currencyId
                        ? {
                            value: formData?.currencyId,
                            label: formData?.currencyId === 1 ? 'LEI' : 'EURO',
                          }
                        : null
                    }
                    isClearable={true}
                    options={currencyOptions}
                    onChange={(e: SelectValue) =>
                      setFormData({
                        ...formData,
                        currencyId: Number(e?.value),
                      })
                    }
                  />
                </div>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Selecteaza'
                    inlineLabel={'Modalitate de plata'}
                    isClearable={true}
                    value={
                      formData?.payMethod
                        ? {
                            value: formData?.payMethod,
                            label:
                              formData?.payMethod === 'CASH'
                                ? 'Cash'
                                : 'Credit',
                          }
                        : null
                    }
                    options={payOptions}
                    onChange={(e: SelectValue) =>
                      setFormData({
                        ...formData,
                        payMethod: e?.value,
                      })
                    }
                  />
                </div>
                <div className='form-item form-item-textarea'>
                  <div className='form-item-group'>
                    <label className='form-item-group-label' htmlFor=''>
                      Comentarii:
                    </label>
                    <div className={`form-item-group-block`}>
                      <textarea
                        name=''
                        id=''
                        placeholder='Adauga comentarii despre Buget'
                        value={formData?.budgetComment}
                        onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setFormData({
                            ...formData,
                            budgetComment: e.target.value,
                          })
                        }
                        rows={4}
                        className={`form-item-control `}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </Col>
            </fieldset>
            <fieldset className='row fieldset-block row-52'>
              <Col lg='12'>
                <legend className='subheading-block'>Contract</legend>
              </Col>
              <Col lg='12' className='contract-group-container'>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Selecteaza'
                    inlineLabel={'Contract Semnat '}
                    isSearchable={true}
                    value={{
                      value:
                        formData?.signedContract === 1 ||
                        formData?.signedContract === true
                          ? 'da'
                          : 'nu',
                      label:
                        formData?.signedContract === 1 ||
                        formData?.signedContract === true
                          ? 'Da'
                          : 'Nu',
                    }}
                    options={comOptions}
                    onChange={(e: SelectValue) =>
                      setFormData({
                        ...formData,
                        signedContract: e.value === 'da' ? 1 : 0,
                      })
                    }
                  />
                </div>
                <div className='form-item'>
                  <div className='date-picker'>
                    <label>Data semnarii</label>
                    <ReactDatePicker
                      selected={requestContractSigningDate}
                      onChange={date => handleDateRequest(date)}
                    />
                  </div>
                </div>
                <div className='form-item'>
                  <Input
                    labelText={true}
                    inlineLabel={'Nr.contract'}
                    value={formData?.contractNumber ?? ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormData({
                        ...formData,
                        contractNumber: e.target.value,
                      })
                    }
                    id='commission-proc'
                  />
                </div>
                <div className='form-item'>
                  <Input
                    labelText={true}
                    type='number'
                    inlineLabel={'Comision'}
                    value={formData?.commission ?? ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormData({
                        ...formData,
                        commission: Number(e.target.value),
                      })
                    }
                    id='commission-proc'
                  />
                </div>
              </Col>
            </fieldset>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequestCharacteristics;
