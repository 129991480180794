interface Props {}

const IconPriceHistory: React.FC<Props> = props => {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.9705 10.2157C20.9705 10.2156 20.9705 10.2155 20.9704 10.2154L17.0056 0.259949C16.9214 0.04842 16.6816 -0.0548451 16.4701 0.0293025C16.47 0.0293541 16.4698 0.0294056 16.4697 0.0295086L0.384616 6.43442C0.319019 6.46276 0.262131 6.508 0.219722 6.56551C0.0859512 6.63502 0.00144283 6.77261 0 6.92333V17.6415C0 17.8691 0.184579 18.0537 0.412236 18.0537H17.7261C17.9538 18.0537 18.1384 17.8691 18.1384 17.6415V11.7877L20.7404 10.7514C20.9519 10.667 21.0549 10.4272 20.9705 10.2157ZM17.3139 17.2292H0.824472V7.33557H17.3139V17.2292ZM6.87816 6.51316L13.7806 3.76272C14.3967 4.41024 15.2503 4.77826 16.144 4.78176L16.8324 6.51316H6.87816ZM18.1384 10.9022V6.92333C18.1384 6.69567 17.9538 6.51109 17.7261 6.51109H17.7196L16.846 4.31718C16.8363 4.29888 16.825 4.28146 16.8122 4.26523C16.768 4.06669 16.5841 3.93122 16.3814 3.94781C15.5498 4.02361 14.7364 3.67331 14.2201 3.01698C14.0916 2.85843 13.8658 2.81901 13.6912 2.92464C13.6716 2.92696 13.6522 2.93057 13.6331 2.93536L5.20613 6.29096C5.10415 6.33095 5.02258 6.41025 4.97981 6.51109H2.42065L16.3922 0.94797L20.0524 10.1388L18.1384 10.9022Z"
        fill="black" />
      <path
        d="M1.94326 14.4463C2.74362 14.6829 3.36991 15.3084 3.60746 16.1085C3.65956 16.2831 3.82017 16.4028 4.00238 16.4028C4.02377 16.4015 4.045 16.3983 4.06587 16.3933C4.08436 16.3986 4.10328 16.4025 4.12234 16.4049H14.016C14.033 16.4027 14.0498 16.3992 14.0663 16.3946C14.2723 16.4447 14.4798 16.3184 14.53 16.1124C14.5301 16.1116 14.5303 16.1109 14.5305 16.1101C14.7674 15.3093 15.3938 14.6829 16.1947 14.4459C16.3889 14.3866 16.5096 14.193 16.4775 13.9925C16.483 13.9725 16.4869 13.9521 16.4894 13.9315V10.6336C16.487 10.6129 16.4829 10.5925 16.4771 10.5726C16.5094 10.372 16.3886 10.1783 16.1943 10.1191C15.3934 9.88222 14.7672 9.25557 14.5309 8.45449C14.4712 8.25894 14.2758 8.13795 14.0741 8.1717C14.0551 8.16639 14.0356 8.16253 14.016 8.16016H4.12234C4.10158 8.16263 4.08101 8.16675 4.06092 8.17252C3.86042 8.14057 3.66698 8.26121 3.60746 8.45532C3.37037 9.25603 2.74403 9.88222 1.94326 10.1191C1.74905 10.1785 1.62837 10.372 1.66047 10.5726C1.65511 10.5926 1.65124 10.613 1.64893 10.6336V13.9315C1.6513 13.9508 1.65516 13.97 1.66047 13.9888C1.62666 14.1906 1.7476 14.3863 1.94326 14.4463ZM2.4734 10.8009C3.30117 10.4685 3.95724 9.8124 4.28971 8.98463H13.8482C14.1808 9.8124 14.8371 10.4685 15.6649 10.8009V13.7641C14.8374 14.0969 14.1815 14.7529 13.8486 15.5804H4.28971C3.95688 14.7529 3.30091 14.0969 2.4734 13.7641V10.8009Z"
        fill="black" />
      <path
        d="M9.06918 14.7554C10.4352 14.7554 11.5426 13.6481 11.5426 12.282C11.5426 10.916 10.4352 9.80859 9.06918 9.80859C7.70313 9.80859 6.59576 10.916 6.59576 12.282C6.5971 13.6475 7.7037 14.7541 9.06918 14.7554ZM9.06918 10.6331C9.97986 10.6331 10.7181 11.3713 10.7181 12.282C10.7181 13.1927 9.97986 13.931 9.06918 13.931C8.1585 13.931 7.42024 13.1927 7.42024 12.282C7.42024 11.3713 8.1585 10.6331 9.06918 10.6331Z"
        fill="black" />
      <path
        d="M4.53461 12.9008C4.8761 12.9008 5.15297 12.6239 5.15297 12.2824C5.15297 11.9409 4.8761 11.6641 4.53461 11.6641C4.19313 11.6641 3.91626 11.9409 3.91626 12.2824C3.91626 12.624 4.19313 12.9008 4.53461 12.9008ZM4.53461 12.0763C4.64844 12.0763 4.74073 12.1686 4.74073 12.2824C4.74073 12.3962 4.64844 12.4885 4.53461 12.4885C4.42079 12.4885 4.3285 12.3962 4.3285 12.2824C4.3285 12.1686 4.42079 12.0763 4.53461 12.0763Z"
        fill="black" />
      <path
        d="M13.6038 12.9008C13.9453 12.9008 14.2221 12.6239 14.2221 12.2824C14.2221 11.9409 13.9453 11.6641 13.6038 11.6641C13.2623 11.6641 12.9854 11.9409 12.9854 12.2824C12.9854 12.624 13.2623 12.9008 13.6038 12.9008ZM13.6038 12.0763C13.7176 12.0763 13.8099 12.1686 13.8099 12.2824C13.8099 12.3962 13.7176 12.4885 13.6038 12.4885C13.4899 12.4885 13.3976 12.3962 13.3976 12.2824C13.3976 12.1686 13.4899 12.0763 13.6038 12.0763Z"
        fill="black" />
    </svg>

  );
};

export default IconPriceHistory;
