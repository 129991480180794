interface Props {}

const IconAlert: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 21"
      {...props}
    >
      <path
        d="M22.1,15.4L14.5,2.6C14,1.8,13,1.3,12,1.3s-2,0.5-2.5,1.3L1.9,15.4c-0.5,0.8-0.5,1.7,0,2.5
      c0.5,0.9,1.4,1.4,2.5,1.4h15.3c1.1,0,2-0.5,2.5-1.4C22.6,17.1,22.6,16.1,22.1,15.4z M21.3,17.4c-0.3,0.6-1,0.9-1.6,0.9H4.3
      c-0.7,0-1.3-0.3-1.6-0.9c-0.2-0.3-0.4-0.9,0-1.5l7.7-12.7c0.3-0.6,1-0.8,1.6-0.8s1.2,0.2,1.6,0.8l7.7,12.7
      C21.7,16.5,21.5,17.1,21.3,17.4z M12,14.3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5S12.3,14.3,12,14.3z M12,7.3
      c-0.3,0-0.5,0.2-0.5,0.5v4c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-4C12.5,7.5,12.3,7.3,12,7.3z"
      />
    </svg>
  );
};

export default IconAlert;
