interface Props {}

const IconGalleryRight: React.FC<Props> = props => {
  return (
    <svg
      width='5'
      height='9'
      viewBox='0 0 5 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.833519 8.3325C0.620192 8.3325 0.406864 8.25084 0.244368 8.08834C-0.0814562 7.76252 -0.0814562 7.23587 0.244368 6.91004L0.30104 6.85337C1.79834 5.35607 1.81947 2.93506 0.348532 1.41186C0.0293742 1.0802 0.0385406 0.552712 0.369365 0.233554C0.701023 -0.085604 1.22851 -0.0764376 1.54767 0.253554L4.76591 3.5868C5.08174 3.91429 5.07757 4.43344 4.75591 4.7551L1.42267 8.08834C1.26017 8.25084 1.04685 8.3325 0.833519 8.3325Z'
        fill='black'
      />
    </svg>
  );
};

export default IconGalleryRight;
