import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AgentsListItem from '../../components/AgentsListItem';
import AgentsModal from '../../components/AgentsModal/AgentsModal';
import AlphabetFilter from '../../components/AlphabetFilter';
import FilterListAgents from '../../components/FilterListAgents/FilterListAgents';
import FilterListHeaderAgents from '../../components/FilterListHeaderAgents';
import FixedActions from '../../components/FixedActions/FixedActions';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { checkAll, toggleChecked } from '../../redux/users/users-slice';
import { deleteUser, getUsers } from '../../redux/users/usersThunk';
import { getLoggedUserId } from '../../helpers/useHelper';
import { Agent } from '../../models/Users';
import IconPlus from '../../icons/IconPlus';
import './Agents.scss';

interface Props {}

const Agents: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialized = useRef(false);

  const { users, count, loading } = useAppSelector(state => state.users);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [filterByLettersOnMobile, setFilterByLettersOnMobile] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(24);
  const [filtersForm, setFiltersForm] = useState({ q: '' });
  const [prevState, setPrevState] = useState({
    page: 0,
    pageSize: 24,
    filtersForm: { q: '' },
  });
  const [pageCount, setPageCount] = useState(Math.ceil(count / pageSize));

  useEffect(() => {
    if (count) {
      const pageCount = Math.ceil(count / pageSize);

      setPageCount(pageCount);
    }
  }, [count, pageSize]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getLocalAgents();
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  const editItems = () => {
    if (users.some(user => user.checked)) {
      navigate(`/edit-agent/${users.filter(user => user.checked)[0].id}`);
    } else {
      toast.error('Alegeti un agent!');
    }
  };

  const removeItems = () => {
    if (users.some(user => user.checked)) {
      if (
        users.some(
          user => user.checked && getLoggedUserId() === user.id.toString(),
        )
      ) {
        toast.error('Nu poate fi sters agentul cu care sunteti logat!');
      } else {
        setShowDeleteModal(true);
      }
    } else {
      toast.error('Alegeti un agent!');
    }
  };

  const deleteAgents = async () => {
    const deletePromises = users
      .filter(agent => agent.checked)
      .map(agent => deleteAgent(agent));

    await Promise.all(deletePromises);

    dispatch(checkAll(false));
    closeModal();
    getLocalAgents();
  };

  const deleteAgent = async (agent: Agent) => {
    await dispatch(deleteUser(agent.id));
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const user = users.find(user => user.id === id);

      if (user && user.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let newState = {
      page: page,
      pageSize: pageSize,
      filtersForm: filtersForm,
    };

    if (
      JSON.stringify(prevState.page) !== JSON.stringify(newState.page) ||
      JSON.stringify(prevState.pageSize) !== JSON.stringify(newState.pageSize)
    ) {
      setCheckedAll(false);
      getLocalAgents();
      setPrevState(newState);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    let newState = {
      page: page,
      pageSize: pageSize,
      filtersForm: filtersForm,
    };
    if (
      JSON.stringify(prevState.filtersForm) !==
      JSON.stringify(newState.filtersForm)
    ) {
      isCheckedAll && setCheckedAll(false);
      showCheckedItems && setShowCheckedItems(false);
      if (page > 0) {
        setPage(0);
      } else {
        getLocalAgents();
        setPrevState(newState);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filtersForm)]);

  const getLocalAgents = () => {
    dispatch(
      getUsers({
        roles: 'AGENCY',
        limit: pageSize,
        offset: page + 1,
        ...filtersForm,
      }),
    );
  };

  return (
    <>
      <div className='display-flex contact-filter-list-container'>
        <div className='alphabet-wrapper'>
          <AlphabetFilter
            filtersForm={filtersForm}
            setFiltersForm={setFiltersForm}
          />
        </div>
        <div className='list-wrapper'>
          <FilterListHeaderAgents
            pageCount={pageCount}
            currentPage={page}
            itemsPerPage={pageSize}
            totalCount={count}
            count={users?.length}
            setItemsPerPage={setPageSize}
            setPage={setPage}
            handlePageClick={handlePageClick}
            showCheckedItems={showCheckedItems}
            checkedItemsLength={users.filter(user => user.checked).length}
            setShowCheckedItems={setShowCheckedItems}
            editItems={editItems}
            removeItems={removeItems}
          />
          {!isMobile && (
            <FilterListAgents
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);
                e
                  ? handleCheckboxChange(undefined, true)
                  : dispatch(checkAll(false));
              }}
              isCheckedAll={isCheckedAll}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
            />
          )}
          {loading ? (
            <div className='container'>
              <Spinner />
            </div>
          ) : (
            <>
              <div className='list-items-container'>
                {users
                  .filter(user => !showCheckedItems || user.checked)
                  .map((agent: Agent) => (
                    <AgentsListItem
                      key={`${agent.id} ${agent?.fullName ?? ''}`}
                      agent={agent}
                      handleCheckboxChange={handleCheckboxChange}
                      onDeletedUser={getLocalAgents}
                    />
                  ))}
                {!users.some(user => !showCheckedItems || user.checked) && (
                  <p className='empty-text'>
                    {showCheckedItems
                      ? '0 Agenti selectati'
                      : 'Cautarea ta a returnat 0 Rezultate'}
                  </p>
                )}
              </div>
              {!showCheckedItems && pageCount > 1 && (
                <Pagination
                  isSelectPage={false}
                  currentPage={page}
                  itemsPerPage={pageSize}
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                  setItemsPerPage={setPageSize}
                />
              )}
            </>
          )}
        </div>
      </div>
      <FixedActions
        disabledActions={users.every(user => !user.checked)}
        ModalAction={ModalAction}
        ModalFilter={ModalFilter}
        ModalSearch={ModalSearch}
      />
      <div
        className='add-btn'
        onClick={() => {
          window.scrollTo(0, 0);
          navigate('/add-agent');
        }}
      >
        <IconPlus />
      </div>
      <AgentsModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        isAction={isAction}
        isSearch={isSearch}
        isFilter={isFilter}
        setCheckedAll={(e: boolean) => {
          setCheckedAll(e);
          if (e) {
            handleCheckboxChange(undefined, true);
          } else {
            dispatch(checkAll(false));
          }
        }}
        isCheckedAll={isCheckedAll}
        editItems={editItems}
        removeItems={removeItems}
        filtersForm={filtersForm}
        setFiltersForm={setFiltersForm}
        filterByLettersOnMobile={filterByLettersOnMobile}
        setFilterByLettersOnMobile={setFilterByLettersOnMobile}
      />
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere agenti'
        body='Esti sigur ca doresti sa stergi agentii selectati?'
        onConfirmed={deleteAgents}
        hideModal={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default Agents;
