import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '../../../assets/svg/closemodal.svg';
import ActionsMobileProperty from '../../ActionsMobileProperty';
import FiltersForm from '../../FilterCategory/FilterForm/FiltersForm';
import SearchMobile from '../../SearchMobile';
import './ModalMobile.scss';

interface Props {
  isModalOpen: boolean;
  isAction: boolean;
  isSearch: boolean;
  isFilter: boolean;
  closeModal: () => void;
  removeItems?: () => void;
  exportItems?: () => void;
  editItems?: () => void;
  arhiveItems?: () => void;
  restoreItems?: () => void;
  activeCategory?: number;
  setActiveCategory?: (e: number) => void;
  filtersForm?: any;
  setFiltersForm?: (e: any) => void;
  setCheckedAll?: (all: boolean) => void;
  letterFilter?: any;
  setLetterFilter?: (e: any) => void;
  filterByLettersOnMobile?: boolean;
  setFilterByLettersOnMobile?: (p: boolean) => void;
}

const ModalMobile: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  isAction,
  isSearch,
  isFilter,
  activeCategory,
  setActiveCategory,
  filtersForm,
  setFiltersForm,
  removeItems,
  editItems,
  exportItems,
  restoreItems,
  arhiveItems,
  setCheckedAll,
  letterFilter,
  setLetterFilter,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={
          isFilter || isAction ? 'mobile-modal filter-overflow' : 'mobile-modal'
        }
      >
        <Modal.Body>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          {isSearch && <SearchMobile />}
          {isAction && (
            <ActionsMobileProperty
              removeItems={removeItems}
              editItems={editItems}
              exportItems={exportItems}
              arhiveItems={arhiveItems}
              restoreItems={restoreItems}
            />
          )}
          {isFilter && (
            <FiltersForm
              activeCategory={activeCategory}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              setActiveCategory={setActiveCategory}
              setCheckedAll={setCheckedAll}
              letterFilter={letterFilter}
              setLetterFilter={setLetterFilter}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalMobile;
