export interface Permission {
  id: number;
  name: string;
  displayName: string;
  group: string;
  groupName: string;
  items: Permission[];
}

export enum Permissions {
  AdList = 1,
  Dashboard = 2,
  RequestList = 3,
  AdTagList = 4,
  RequestTagList = 5,
  AdAlertList = 6,
  ContactAdd = 7,
  ContactMyList = 8,
  ContactAgencyList = 9,
  CompanyList = 10,
  PropertyAdd = 11,
  PropertyMyList = 12,
  PropertyAgencyList = 13,
  PropertyUpdate = 14,
  ResidentialComplexList = 15,
  ClientMesageList = 16,
  RequestAdd = 17,
  RequestMyList = 18,
  RequestAgencyList = 19,
  EmailOfferTemplateList = 20,
  ActivityAdd = 21,
  ActivityMyList = 22,
  ActivityCalendar = 23,
  Reports = 24,
  EmployeeAdd = 25,
  EmployeeList = 26,
  AlocateSlot = 27,
  EmployeeProfile = 28,
  AgencyAdministrator = 30,
}
