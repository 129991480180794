import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Buttons from '../../components/Buttons';
import Header from '../../components/Header';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getActivitiesTypes } from '../../redux/activities/activitiesThunk';
import { getContacts } from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getProperties } from '../../redux/properties/propertiesThunk';
import { getRequest, updateRequest } from '../../redux/requests/requestsThunk';
import useHelper, {
  getRequestFiltersState,
  parseRequest,
} from '../../helpers/useHelper';
import { Filter } from '../../models/Properties';
import { RequestEditForm } from '../../models/Requests';
import AddContactsForm from '../AddContactsForm/AddContactsForm';
import AdsActivityForm from '../AdsActivityForm/AdsActivityForm';
import AdsGeneralRequest from '../AdsGeneralRequest';
import RequestAssociatedProperties from '../RequestAssociatedProperties';
import RequestCharacteristics from '../RequestCharacteristics';
import RequestHistory from '../RequestHistory';
import RequestMatchingProperties from '../RequestMatchingProperties';
import './AddRequest.scss';

interface Props {}

const tabs = [
  {
    label: 'Criterii',
    value: 'criterii',
  },
  {
    label: 'Contacte',
    value: 'contacte',
  },
  {
    label: 'Activitati',
    value: 'activitati',
  },
  {
    label: 'Istoric',
    value: 'istoric',
  },
  {
    label: 'Matching properietati',
    value: 'proprietati',
  },
  {
    label: 'Propr. Propuse',
    value: 'propuse',
  },
];

const AddRequest: React.FC<Props> = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const { contacts } = useAppSelector(state => state.contacts);
  const { getRequestState, updateRequestState } = useAppSelector(
    state => state.requests,
  );
  const isMobile = window.innerWidth < 1081;
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [formData, setFormData] = useState<RequestEditForm>({});
  const [requestFilters, setRequestFilters] = useState<any>({});

  useEffect(() => {
    fetchRequestId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(
    () => {
      let initialForm: any = null;

      getRequestState.request?.category?.filters?.forEach(filter => {
        let value: any = null;
        getRequestState.request?.requestHasFilters?.forEach(item => {
          if (item?.filter?.id === filter.id) {
            if (item.value) value = item.value;

            if (item.filter.requestTypeAdd === 'RANGE') {
              value = { from: item.value, to: item.toValue };
            }
            if (item.filterValue) {
              if (item.filter.requestTypeAdd === 'RANGE_SELECT') {
                value = {
                  from: {
                    label: item.filterValue?.title,
                    value: item.filterValue?.id,
                  },
                  to: {
                    label: item.toFilterValue?.title,
                    value: item.toFilterValue?.id,
                  },
                };
              } else
                value = {
                  label: item.filterValue?.title,
                  value: item.filterValue?.id,
                };
              if (item.filter.requestTypeAdd === 'SELECT_OPTIONS') {
                if (value?.length > 0) {
                  value.push(item.filterValue.id);
                } else {
                  value = [item.filterValue.id];
                }
              }
            }
          } else if (filter.children.length > 0) {
            filter.children?.forEach((child: Filter) => {
              let childValue;
              if (item?.filter?.id === child.id) {
                if (item.value)
                  if (item.filter.requestTypeAdd === 'INPUT') {
                    childValue = item.value;
                  } else childValue = item.value === '1' ? 1 : 0;
              }
              if (child.requestShowOnAdd && childValue) {
                value = {
                  ...value,
                  [child.id]: childValue,
                };
              }
            });
          }
        });

        if (filter.children.length > 0) {
          initialForm = {
            ...initialForm,
            ...value,
          };
        } else if (filter.requestShowOnAdd) {
          initialForm = {
            ...initialForm,
            [filter.id]: value,
          };
        }
      });
      initialForm && setRequestFilters(initialForm);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getRequestState.request?.category?.filters,
      getRequestState.request?.requestHasFilters,
    ],
  );

  useEffect(() => {
    const categoryFilters = getRequestState.request?.category?.filters

    if(categoryFilters) {
      const filters = getRequestFiltersState(categoryFilters, requestFilters)
        .filter(item => item.id)

      setFormData({...formData, filters});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestFilters, getRequestState.request?.category.filters]);

  const fetchRequestId = async () => {
    if (id && getRequestState.request?.id !== Number(id)) {
      try {
        const result = await dispatch(getRequest(id));

        unwrapResult(result);

        if (result.payload.id) {
          let initialForm = parseRequest(result.payload);

          setFormData(initialForm);
          dispatch(getActivitiesTypes());
          dispatch(getProperties({}));
          contacts.length === 0 && dispatch(getContacts({ limit: -1 }));
        }
      } catch (err: any) {}
    }
  };


  const renderTabContent = (type: string) => {
    switch (type) {
      case 'criterii': {
        return (
          <RequestCharacteristics
            formData={formData}
            setFormData={setFormData}
            requestFilters={requestFilters}
            setRequestFilters={setRequestFilters}
          />
        );
      }
      case 'contacte':
        return (
          <AddContactsForm formData={formData} setFormData={setFormData} />
        );
      case 'activitati':
        return (
          <AdsActivityForm
            fieldName='requests'
            fieldId={getRequestState.request?.id}
          />
        );
      case 'istoric':
        return <RequestHistory />;
      case 'proprietati':
        return (
          <RequestMatchingProperties
            formData={formData}
            requestFilters={requestFilters}
          />
        );
      case 'propuse':
        return <RequestAssociatedProperties />;
    }

    return null;
  };

  const submitForm = async () => {
    if (getRequestState.request?.id && formData)
      try {
        let form = {
          ...formData,
          contacts:
            formData.contacts?.map((contact: any) => {
              return { id: contact.id, tags: contact?.tags ?? [] };
            }) ?? [],
        };

        dispatch(
          updateRequest({
            id: getRequestState.request?.id,
            form: form,
          }),
        ).then(result => {
          if (result.payload?.id) {
            toast.success('Solicitarea a fost actualizata cu succes!');
          }
          else {
            diplayErrorMessages(result.payload);
          }
        });
      } catch (err: any) {}
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        {!getRequestState.loading && (
          <Header
            title={
              getRequestState.request?.id
                ? 'ID SOLICITARE ' +
                  getRequestState.request?.id +
                  ' ' +
                  (getRequestState.request?.transactionType === 'BUY'
                    ? 'Cumparare'
                    : 'Inchiriere') +
                  ' ' +
                  getRequestState.request?.category.title
                : ''
            }
            subtitle={'Completeaza toate campurile obligatorii'}
            backBtn={true}
            isbutton={false}
            btnText=''
          />
        )}
        {getRequestState.loading ? (
          <Spinner />
        ) : (
          getRequestState.request?.id && (
            <div className='section-container'>
              <AdsGeneralRequest
                formData={formData}
                setFormData={setFormData}
              />
              <div className='ads-general-lg'>
                <div className=' bg-blue'>
                  <div className='ads-container bg-white'>
                    {isMobile ? (
                      <div>
                        <select
                          className='select-tabs '
                          value={activeTab}
                          onChange={e => setActiveTab(e.target.value)}
                        >
                          {tabs.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                        {activeTab && renderTabContent(activeTab)}
                      </div>
                    ) : (
                      <Tabs
                        id='ads-tabs'
                        className='ads-form-tabs'
                        activeKey={activeTab}
                        onSelect={e => e && setActiveTab(e)}
                      >
                        {tabs.map(tab => (
                          <Tab
                            key={tab.value}
                            eventKey={tab.value}
                            title={tab.label}
                          >
                            {tab.value === activeTab &&
                              renderTabContent(tab.value)}
                          </Tab>
                        ))}
                      </Tabs>
                    )}
                  </div>
                </div>
                <Buttons
                  nextStep={() =>
                    tabs.findIndex(tab => tab.value === activeTab) !==
                      tabs.length - 1 &&
                    setActiveTab(
                      tabs[tabs.findIndex(tab => tab.value === activeTab) + 1]
                        .value,
                    )
                  }
                  disabledNext={activeTab === tabs[tabs.length - 1].value}
                  submitLoading={updateRequestState.loadingSave}
                  submit={() => submitForm()}
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AddRequest;
