import Form from 'react-bootstrap/Form';
import ReactPaginate from 'react-paginate';

import IconArrowLeft from '../../icons/IconArrowLeft';
import IconArrowRight from '../../icons/IconArrowRight';
import './Pagination.scss';

interface Props {
  isSelectPage: boolean;
  pageCount?: number;
  currentPage?: number;
  itemsPerPage?: number;
  setItemsPerPage?: (e: number) => void;
  handlePageClick?: (selectedItem: { selected: number }) => void;
}

const Paginations: React.FC<Props> = ({
  isSelectPage,
  pageCount,
  currentPage,
  itemsPerPage,
  setItemsPerPage,
  handlePageClick,
}) => {
  return pageCount &&
    pageCount > 1 &&
    currentPage !== undefined &&
    itemsPerPage ? (
    <div className='pagination-container'>
      <div className='list-component-pagination'>
        <ReactPaginate
          breakLabel='...'
          onPageChange={handlePageClick}
          //   pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          forcePage={currentPage}
          nextLabel={<IconArrowRight />}
          previousLabel={<IconArrowLeft />}
        />
      </div>

      {isSelectPage && (
        <div className='page-number-container'>
          <Form.Group controlId='formGridState'>
            <Form.Select
              defaultValue={itemsPerPage}
              onChange={e =>
                setItemsPerPage && setItemsPerPage(Number(e.target.value))
              }
            >
              <option value={24}>24</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </Form.Select>
          </Form.Group>
        </div>
      )}
    </div>
  ) : null;
};

export default Paginations;
