import moment from 'moment';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { getImagePath } from '../../helpers/useHelper';
import { Property } from '../../models/Properties';
import House from '../../assets/img/house.png';
import ButtonSquare from '../ButtonSquare';
import './RequestPropertyImoAPListItem.scss';

interface Props {
  item: Property;
  checked?: boolean;
  handleCheckboxChange?: (id: number) => void;
}

const RequestPropertyImoAPListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
}) => {
  return (
    item && (
      <>
        <div className={`request-associated-imoAP-item bg-blue `} key={item.id}>
          <div className='request-associated-imoAP-item--column'>
            <div className='checkbox-container'>
              <Form.Group id='formGridCheckbox'>
                <Form.Check
                  type='checkbox'
                  checked={checked}
                  readOnly
                  onClick={() =>
                    handleCheckboxChange && handleCheckboxChange(item.id)
                  }
                />
              </Form.Group>
            </div>
          </div>
          <div className='request-associated-imoAP-item--column'>
            <div className='image'>
              {item?.media?.filter((image: any) => image.type === 'MAIN')[0]
                ?.media?.path ? (
                <img
                  src={getImagePath(
                    item?.media?.filter(
                      (image: any) => image.type === 'MAIN',
                    )[0]?.media?.path ?? '',
                  )}
                  alt={`${item?.id}`}
                />
              ) : (
                <img src={House} alt='' />
              )}
            </div>
          </div>
          <div className='request-associated-imoAP-item--column'>
            <div className='details-container'>
              <div className='title-text'>
                {(item.title ? item.title + ',' : undefined) ??
                  item.categoryTitle +
                    (item?.zoneTitle ? ', zona ' + item?.zoneTitle + ',' : '')}
              </div>
              {item?.prices &&
                item?.prices?.length > 0 &&
                item?.prices.map(price => {
                  if (price.id === 1 && price.sellingPrice) {
                    return (
                      <div className='rent-text'>
                        <span>VANZARE - </span>
                        {price.sellingPrice} {item.currencySymbol ?? '€'}
                      </div>
                    );
                  } else if (price.id === 2 && price.rentalMonthPrice) {
                    return (
                      <div className='rent-text'>
                        <span>INCHIRIERE - </span>
                        {price.rentalMonthPrice} {item.currencySymbol ?? '€'} / luna
                      </div>
                    );
                  } else if (price.id === 3 && price.rentalDayPrice) {
                    return (
                      <div className='rent-text'>
                        <span>REGIM HOTELIER - </span>
                        {price.rentalDayPrice} {item.currencySymbol ?? '€'} / zi
                      </div>
                    );
                  } else if (price.id === 4 && price.sellingPriceSqm) {
                    return (
                      <div className='rent-text'>
                        <span>VANZARE MP - </span>
                        {price.sellingPriceSqm} {item.currencySymbol ?? '€'} / mp
                      </div>
                    );
                  } else if (price.id === 5 && price.rentalMonthPriceSqm) {
                    return (
                      <div className='rent-text'>
                        <span>INCHIRIERE MP- </span>
                        {price.rentalMonthPriceSqm} {item.currencySymbol ?? '€'} / mp
                      </div>
                    );
                  }
                })}
            </div>
            <div className='pills-details'>
              {item?.filterSurfaceUValue && (
                <span className='label-item-tag'>
                  SU: {item?.filterSurfaceUValue}mp
                </span>
              )}

              {item?.filterStaircaseValue && (
                <span className='label-item-tag'>
                  Etaj: {item?.filterStaircaseValue}
                </span>
              )}
              {item?.filterYearValue && (
                <span className='label-item-tag'>
                  AN: {item?.filterYearValue}
                </span>
              )}
            </div>
          </div>
          <div className='request-associated-imoAP-item--column'>
            <div className='date-text'>
              {moment(item.dateUpdated).format('DD.MM.YYYY HH:mm')}
            </div>
            <div className='description-text'>{item.description}</div>
            {item.employeePhoneNumbers &&
              item.employeePhoneNumbers.filter(phone => phone !== '').length >
                0 && (
                <div className='pills-details'>
                  {item.employeePhoneNumbers
                    .filter(phone => phone !== '')
                    .map(phone => (
                      <span className='label-item-tag'>{phone}</span>
                    ))}
                </div>
              )}
          </div>

          <div className='request-associated-imoAP-item--column'>
            <ButtonSquare btnSize='md' save={true} saveAction={() => {}} />
          </div>
        </div>
      </>
    )
  );
};

export default RequestPropertyImoAPListItem;
