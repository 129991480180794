import { Link } from "react-router-dom";
import "./AlocatedSlots.scss";
import slotsData from "./slots.json";
import totalsData from "./totals.json";
import Table from "react-bootstrap/Table";
import IconArrowUpRight from "../../icons/IconArrowUpRight";
import FiltreIcon from "../../assets/svg/filtericon.svg";

interface Props {}
interface Slots {
    agent: string;
    basic: string;
    premium: string;
    up: string;
    spotlight: string;
    video: string;
    virtualtur: string;
}
interface TotalItem {
    value: string;
    left: string;
}
interface Totals {
    title: string;
    basic: TotalItem;
    premium: TotalItem;
    up: TotalItem;
    spotlight: TotalItem;
    video: TotalItem;
    virtualtur: TotalItem;
}

const AlocatedSlots: React.FC<Props> = () => {
    const slots: Slots[] = slotsData as Slots[];
    const totals: Totals[] = totalsData as Totals[];
    return (
        <div className="alocated-slots-container">
            <div className="table-item alocated-list-table">
                <div className="slots-header display-flex">
                    <h5 className="title-section">Sloturi alocate</h5>
                    <div className="alocated-filters">
                        <div className="filter-container">
                            <input type="text" placeholder="Filter" className="form-item-control-filter"/>
                            <button className="form-item-control-button" aria-label="Filtreaza">
                               <img src={FiltreIcon} alt="filter" />
                            </button>
                        </div>
                        <Link to="/" className="btn-blue">
                            Alocare sloturi
                        </Link>
                    </div>
                </div>
                <div className="alocated-table">
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Agent</th>
                                <th>Basic</th>
                                <th>Premium</th>
                                <th>Up</th>
                                <th>Spotlight</th>
                                <th>Video</th>
                                <th>Tur virtual</th>
                            </tr>
                        </thead>
                        <tbody>
                            {slots?.map((slot, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link to={slot.agent}>
                                            {slot.agent} 
                                            <IconArrowUpRight/>
                                        </Link>
                                    </td>
                                    <td>
                                        <span>{slot.basic}</span>
                                    </td>
                                    <td>
                                        <span>{slot.premium}</span>
                                    </td>
                                    <td>
                                        <span>{slot.up}</span>
                                    </td>
                                    <td>
                                        <span>{slot.spotlight}</span>
                                    </td>
                                    <td>
                                        <span>{slot.video}</span>
                                    </td>
                                    <td>
                                        <span>{slot.virtualtur}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="table-item alocated-list-totals">
                <Table striped bordered hover>
                    <tbody>
                        {totals?.map((total, index) => (
                            <tr key={index}>
                                <td>{total.title}</td>
                                <td>
                                    <div className="total-item">
                                        <span className="total-value">
                                            {total.basic.value}
                                        </span>
                                        <p className="total-left">
                                            {total.basic.left}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="total-item">
                                        <span className="total-value">
                                            {total.premium.value}
                                        </span>
                                        <p className="total-left">
                                            {total.premium.left}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="total-item">
                                        <span className="total-value">
                                            {total.up.value}
                                        </span>
                                        <p className="total-left">
                                            {total.up.left}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="total-item">
                                        <span className="total-value">
                                            {total.spotlight.value}
                                        </span>
                                        <p className="total-left">
                                            {total.spotlight.left}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="total-item">
                                        <span className="total-value">
                                            {total.video.value}
                                        </span>
                                        <p className="total-left">
                                            {total.video.left}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="total-item">
                                        <span className="total-value">
                                            {total.virtualtur.value}
                                        </span>
                                        <p className="total-left">
                                            {total.virtualtur.left}
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default AlocatedSlots;
