import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import IconEarth from '../../icons/IconEarth';
import IconLabe from '../../icons/IconLabel';
import IconList from '../../icons/IconList';
import IconPhoneThin from '../../icons/IconPhoneThin';
import './ParticularRequestItem.scss';

interface Props {}

const ParticularRequestItem: React.FC<Props> = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div
      className={
        isChecked
          ? 'announces-list-item particular-item active'
          : 'announces-list-item particular-item'
      }
    >
      <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div>
      <div className='list-item-content'>
        <div className='item-container'>
          <div className='list-item-header'>
            <div className='column-item'>
              <div className='mobile-text'>Data</div>
              <div className='label-item-info-lg label-item-mobile'>
                30.10.2023 08:38
              </div>
            </div>
            <div className='column-item'>
              <div className='mobile-text'>Tip solicitare</div>
              <div className='label-item-info-lg label-item-mobile'>Cumpar</div>
            </div>
            <div className='column-item'>
              <div className='mobile-text'>Tip Imobil</div>
              <div className='label-item-info-lg label-item-mobile'>
                Apartament
              </div>
            </div>
            <div className='column-item'>
              <div className='mobile-text'>Nr. Camere</div>
              <div className='label-item-info-lg label-item-mobile'>
                Min 3 camere
              </div>
            </div>
          </div>
          <div className='item-details'>
            <div className='item-info'>
              <ul>
                <li>
                  Tip Imobil: <span>Apartament</span>
                  <span className='sugestion-text'></span>
                </li>
                <li>
                  Tip Imobil: <span>Cumpar</span>
                </li>
                <li>
                  Pret maxim:
                  <span className='sugestion-text'>6000$</span>
                </li>
                <li>
                  Pret minim:
                  <span className='sugestion-text'>5000$</span>
                </li>
              </ul>
            </div>
            <div className='item-info'>
              <ul>
                <li>
                  Oras: <span>Cluj-Napoca</span>{' '}
                  <span className='sugestion-text'></span>
                </li>
                <li>
                  Zona: <span>Cluj-Napoca</span>{' '}
                </li>
              </ul>
            </div>
            <div className='item-description'>
              Dreptatii, Orsova, Virtutii, Regie, dec., et. 3/4, 61 mp, 1991,
              apartament de 2 camere, 3/4, an constructie 1991, situat pe strada
              Dealului nr. 8, zona Dreptatii, Orsova, Virtutii, intrare Splaiul
              Independentei. Renovat integral, parchet, usi de lemn, ac,
              mobilat.{' '}
              <div className='item-info-tags'>
                <a href='/' aria-label='' className='label-item-link'>
                  <IconPhoneThin />
                  0766109631
                </a>
                <a href='/' aria-label='' className='label-item-link'>
                  <IconEarth />
                  Internet
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='line-border'></div>
        <div className='item-icon-links'>
          <button className='button-square-md' aria-label=''>
            <IconLabe />
          </button>
          <button className='button-square-md' aria-label=''>
            <IconList />
          </button>
          <button className='button-square-md' aria-label=''>
            <IconPhoneThin />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ParticularRequestItem;
