import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input/Input';
import { useAppSelector } from '../../redux/hooks';
import { getFilter } from '../../helpers/useHelper';
import { Price, PropertyForm, SelectValue } from '../../models/Properties';

interface Props {
  prices?: Price[];
  formData?: PropertyForm;
  setFormData: (e: PropertyForm) => void;
  currency?: SelectValue;
  setCurrency: (e: SelectValue) => void;
  currencyOptions: any;
}

const PriceBlock: React.FC<Props> = ({
  prices,
  formData,
  setFormData,
  currency,
  setCurrency,
  currencyOptions,
}) => {
  const { property } = useAppSelector(state => state.properties);
  const [sellingPrice, setSellingPrice] = useState<Price | null>(
    prices?.find(price => price.id === 1) ?? null,
  );
  const [rentMonthPrice, setRentMonthPrice] = useState<Price | null>(
    prices?.find(price => price.id === 2) ?? null,
  );
  const [rentDayPrice, setRentDayPrice] = useState<Price | null>(
    prices?.find(price => price.id === 3) ?? null,
  );
  const [sellingMPPrice, setSellingMPPrice] = useState<Price | null>(
    prices?.find(price => price.id === 4) ?? null,
  );
  const [rentMonthMPPrice, setRentMonthMPPrice] = useState<Price | null>(
    prices?.find(price => price.id === 5) ?? null,
  );

  useEffect(() => {
    setSellingPrice(formData?.prices?.find(price => price.id === 1) ?? null);

    setRentMonthPrice(formData?.prices?.find(price => price.id === 2) ?? null);

    setRentDayPrice(formData?.prices?.find(price => price.id === 3) ?? null);

    setSellingMPPrice(formData?.prices?.find(price => price.id === 4) ?? null);

    setRentMonthMPPrice(
      formData?.prices?.find(price => price.id === 5) ?? null,
    );
  }, [formData?.prices]);

  const handleChangePrice = (
    id: number,
    key: string,
    e: string | boolean | number | null,
  ) => {
    const newPrices = formData?.prices?.filter(price => price.id !== id) ?? [];
    let rentPrice = formData?.prices?.find(price => price.id === id);
    if (rentPrice) {
      rentPrice = { ...rentPrice, [key]: e };
      newPrices.push(rentPrice);
    } else {
      rentPrice = { id: id, [key]: e };
      newPrices.push(rentPrice);
    }
    setFormData({
      ...formData,
      prices: newPrices,
    });
  };

  return (
    <fieldset className='row fieldset-block row-93'>
      <Col lg='12'>
        <div className='form-item form-item-flex'>
          <legend className='heading-block'>Pret</legend>
          <FormSelect
            placeholder='Select Options'
            isSearchable={false}
            value={currency}
            options={currencyOptions}
            onChange={(e: SelectValue) => setCurrency(e)}
          />
        </div>
      </Col>
      {property?.category?.pricesTypes &&
        property.category.pricesTypes.filter(price => price.title === 'SALE')
          ?.length > 0 && (
          <Col lg='4'>
            <div className='form-item mb-lg row'>
              <Col lg='4'>
                <Form.Check
                  type='checkbox'
                  label='Vanzare'
                  id='sale'
                  name='Vanzare'
                  checked={sellingPrice?.status === 1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangePrice(1, 'status', e.target.checked ? 1 : 0);
                  }}
                />
              </Col>
              {sellingPrice?.status === 1 && (
                <Col lg='4'>
                  <Form.Check
                    type='checkbox'
                    label='Licitatie'
                    id='auction'
                    name='Licitatie'
                    checked={
                      sellingPrice?.hasAuction === 1 ||
                      sellingPrice?.hasAuction === true
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangePrice(1, 'hasAuction', e.target.checked);
                    }}
                  />
                </Col>
              )}
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel={`Pret vanzare (${currency?.label})`}
                type='number'
                min={0}
                value={sellingPrice?.sellingPrice ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangePrice(1, 'sellingPrice', e.target.value);
                }}
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Pret vanzare/mp'
                type='text'
                disabled
                id='price-mp'
                value={
                  sellingPrice
                    ? sellingPrice?.sellingPrice &&
                      getFilter(9, property).exists
                      ? sellingPrice?.sellingPrice /
                        getFilter(9, property).value
                      : sellingPrice?.sellingPriceSqm ??
                        'Nu ati completat suprafata sau pretul'
                    : ''
                }
              />
            </div>
          </Col>
        )}
      {property?.category?.pricesTypes &&
        property.category.pricesTypes.filter(price => price.title === 'SALE_MP')
          ?.length > 0 && (
          <Col lg='4'>
            <div className='form-item mb-lg row'>
              <Col lg='4'>
                <Form.Check
                  type='checkbox'
                  label='Vanzare MP'
                  id='saleMp'
                  name='Vanzare MP'
                  checked={sellingMPPrice?.status === 1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangePrice(4, 'status', e.target.checked ? 1 : 0);
                  }}
                />
              </Col>
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel={`Pret vanzare mp (${currency?.label})`}
                type='number'
                min={0}
                value={sellingMPPrice?.sellingPriceSqm ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangePrice(
                    4,
                    'sellingPriceSqm',
                    Number(e.target.value),
                  );
                }}
              />
            </div>
          </Col>
        )}
      {property?.category?.pricesTypes &&
        property.category.pricesTypes.filter(
          price => price.title === 'RENT_MONTH',
        )?.length > 0 && (
          <Col lg='4'>
            <div className='form-item mb-lg'>
              <Form.Check
                type='checkbox'
                label='Inchiriere'
                id='rent'
                name='Inchiriere'
                checked={rentMonthPrice?.status === 1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangePrice(2, 'status', e.target.checked ? 1 : 0);
                }}
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel={`Pret inchiriere/luna (${currency?.label})`}
                type='number'
                min={0}
                id='currency-rent-price'
                value={rentMonthPrice?.rentalMonthPrice ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangePrice(
                    2,
                    'rentalMonthPrice',
                    Number(e.target.value),
                  );
                }}
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Pret inchiriere mp/luna'
                type='text'
                id='price--rent-mp'
                disabled
                value={
                  rentMonthPrice
                    ? rentMonthPrice?.rentalMonthPrice &&
                      getFilter(9, property).exists
                      ? rentMonthPrice?.rentalMonthPrice /
                        getFilter(9, property).value
                      : rentMonthPrice?.rentalMonthPriceSqm ??
                        'Nu ati completat suprafata sau pretul'
                    : ''
                }
              />
            </div>
          </Col>
        )}
      {property?.category?.pricesTypes &&
        property.category.pricesTypes.filter(price => price.title === 'RENT_MP')
          ?.length > 0 && (
          <Col lg='4'>
            <div className='form-item mb-lg row'>
              <Col lg='4'>
                <Form.Check
                  type='checkbox'
                  label='Inchiriere MP'
                  id='rentMp'
                  name='Inchiriere MP'
                  checked={rentMonthMPPrice?.status === 1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangePrice(5, 'status', e.target.checked ? 1 : 0);
                  }}
                />
              </Col>
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel={`Pret inchiriere mp (${currency?.label})`}
                type='number'
                min={0}
                value={rentMonthMPPrice?.rentalMonthPriceSqm ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangePrice(
                    5,
                    'rentalMonthPriceSqm',
                    Number(e.target.value),
                  );
                }}
              />
            </div>
          </Col>
        )}
      {property?.category?.pricesTypes &&
        property.category.pricesTypes.filter(
          price => price.title === 'RENT_DAY',
        )?.length > 0 && (
          <Col lg='4'>
            <div className='form-item mb-lg'>
              <Form.Check
                type='checkbox'
                label='Regim hotelier'
                id='rent-2'
                name='Regim hotelier'
                checked={rentDayPrice?.status === 1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangePrice(3, 'status', e.target.checked ? 1 : 0);
                }}
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel={`Pret inchiriere/zi (${currency?.label})`}
                type='number'
                min={0}
                id='currency-rent-price-2'
                value={rentDayPrice?.rentalDayPrice ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangePrice(
                    3,
                    'rentalDayPrice',
                    Number(e.target.value),
                  );
                }}
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Pret inchiriere mp/zi'
                type='text'
                id='price-rent-mp-2'
                value={
                  rentDayPrice
                    ? rentDayPrice?.rentalDayPrice &&
                      getFilter(9, property).exists
                      ? rentDayPrice?.rentalDayPrice /
                        getFilter(9, property).value
                      : rentDayPrice?.rentalDayPriceSqm ??
                        'Nu ati completat suprafata sau pretul'
                    : ''
                }
                disabled
              />
            </div>
          </Col>
        )}
      <Col lg='12'>
        <hr className='fieldset-block__hr' />
      </Col>
    </fieldset>
  );
};

export default PriceBlock;
