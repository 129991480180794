interface Props {}

const IconCalendar: React.FC<Props> = (props) => {
  return (
      <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label='Calendar'
      {...props}
    >
      <path
        id='Color'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.29167 16.6668C7.29167 16.0939 7.76042 15.6252 8.33333 15.6252C8.90625 15.6252 9.375 16.0939 9.375 16.6668C9.375 17.2397 8.90625 17.7085 8.33333 17.7085C7.76042 17.7085 7.29167 17.2397 7.29167 16.6668ZM12.5 15.6252H16.6667C17.2396 15.6252 17.7083 16.0939 17.7083 16.6668C17.7083 17.2397 17.2396 17.7085 16.6667 17.7085H12.5C11.9271 17.7085 11.4583 17.2397 11.4583 16.6668C11.4583 16.0939 11.9271 15.6252 12.5 15.6252ZM18.75 20.8335H6.25C5.67604 20.8335 5.20833 20.3658 5.20833 19.7918V13.5418H19.7917V19.7918C19.7917 20.3658 19.324 20.8335 18.75 20.8335ZM6.25 6.25016H7.29167V7.29183C7.29167 7.86475 7.76042 8.3335 8.33333 8.3335C8.90625 8.3335 9.375 7.86475 9.375 7.29183V6.25016H15.625V7.29183C15.625 7.86475 16.0938 8.3335 16.6667 8.3335C17.2396 8.3335 17.7083 7.86475 17.7083 7.29183V6.25016H18.75C19.324 6.25016 19.7917 6.71787 19.7917 7.29183V11.4585H5.20833V7.29183C5.20833 6.71787 5.67604 6.25016 6.25 6.25016ZM18.75 4.16683H17.7083V3.12516C17.7083 2.55225 17.2396 2.0835 16.6667 2.0835C16.0937 2.0835 15.625 2.55225 15.625 3.12516V4.16683H9.375V3.12516C9.375 2.55225 8.90625 2.0835 8.33333 2.0835C7.76042 2.0835 7.29167 2.55225 7.29167 3.12516V4.16683H6.25C4.52708 4.16683 3.125 5.56891 3.125 7.29183V19.7918C3.125 21.5147 4.52708 22.9168 6.25 22.9168H18.75C20.4729 22.9168 21.875 21.5147 21.875 19.7918V7.29183C21.875 5.56891 20.4729 4.16683 18.75 4.16683Z'
      />
    </svg>
  );
};

export default IconCalendar;
