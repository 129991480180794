import { createAsyncThunk } from '@reduxjs/toolkit';
import { Filters } from '../../models/Properties';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';


export const getResidentialComplexes = createAsyncThunk<any, Filters | null>(
  'crm/getResidentialComplexes',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/residential-complexes', {
        params: { ...params, status: '0,1' },
      });
      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getResidentialComplex = createAsyncThunk<any, any>(
  'crm/getResidentialComplex',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        `api/v1/crm/residential-complexes/${id}`,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getAssociatedProperties = createAsyncThunk<any, any>(
  'crm/residentialComplex/associatedProperties',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/properties`, {
        params: {
          residentialComplex: id,
          limit: -1,
        },
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const addResidentialComplex = createAsyncThunk<any, any>(
  'crm/residentialComplex/add',
  async (complex, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        `api/v1/crm/residential-complexes`,
        complex,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const editResidentialComplex = createAsyncThunk<any, any>(
  'crm/residentialComplex/edit',
  async (complex, { rejectWithValue }) => {
    try {
      const response = await appAxios.put(
        `api/v1/crm/residential-complexes/${complex.id}`,
        complex.body,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeResidentialComplex = createAsyncThunk<any[], any>(
  'crm/residentialComplex/remove',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete(
        `api/v1/crm/residential-complexes/${id}`,
      );

      return { ...response.data, id };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeResidentialComplexes = createAsyncThunk<string, string>(
  'crm/removeResidentialComplexes',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete(
        'api/v1/crm/residential-complexes',
        {
          params: {
            ids: ids,
          },
        },
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
