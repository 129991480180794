import { unwrapResult } from '@reduxjs/toolkit';
import * as locale from 'date-fns/locale';
import moment from 'moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { DateRangePicker } from 'react-date-range';
import ReactDatePicker from 'react-datepicker';
import {
  getContacts,
  getLeadSources,
} from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearStreets,
  clearTowns,
  clearZona,
} from '../../redux/localities/localities-slice';
import {
  getCounty,
  getLocalities,
  getLocations,
  getSpecificTypes,
  getStreet,
  getZona,
} from '../../redux/localities/localitiesThunk';
import { getPricesTypes } from '../../redux/properties/propertiesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import useDebounce from '../../helpers/useDebounce';
import { getValue, parseValues } from '../../helpers/useHelper';
import { Filter, SelectValue } from '../../models/Properties';
import FormSelect from '../FormSelect';
import Input from '../Input/Input';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import Spinner from '../Spinner';
import './RequestsFilter.scss';

const statusOptions = [
  {
    label: 'Activa',
    value: '1',
  },
  {
    label: 'Inactiva',
    value: '2',
  },
  {
    label: 'Tranzactionata',
    value: '3',
  },
  {
    label: 'Arhivata',
    value: '4',
  },
];

const pricesTypes = [
  {
    label: 'Cumparare',
    value: 'BUY',
  },
  { label: 'Inchiriere', value: 'RENT' },
];
interface Props {
  setFiltersForm?: (e: any) => void;
}

const RequestsFilter: React.FC<Props> = ({ setFiltersForm }) => {
  const { filters, loading } = useAppSelector(
    state => state.requests,
  ).requestsFilterState;
  const { requestsListState } = useAppSelector(state => state.requests);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({});
  const [countyId, setCountyId] = useState<string | null>(null);
  const [townId, setTownId] = useState<string | null>(null);
  const [streetId, setStreetId] = useState<string[] | null>(null);
  const { users } = useAppSelector(state => state.users);
  const { contacts, leadSources } = useAppSelector(state => state.contacts);
  const { locations, localities, county, streets, zona, specificTypes } =
    useAppSelector(state => state.localities);
  const [showCalendar, setShowCalendar] = useState<any>(false);
  const [contactsQuery, setContactsQuery] = useState<string | undefined>(
    undefined,
  );

  const debouncedContactSearch = useDebounce(contactsQuery ?? null, 300);

  const myRef = useRef<any>();

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getSpecificTypes());
    dispatch(getUsers({}));
    dispatch(getCounty({ parent: 0 }));
    dispatch(getLeadSources());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getContacts({ q: contactsQuery }));
    // eslint-disable-next-line
  }, [debouncedContactSearch]);

  useEffect(() => {
    let initialForm = {};

    filters?.forEach(filter => {
      if (
        filter.requestTypeView === 'RANGE' ||
        filter.requestTypeView === 'RANGE_DATE' ||
        filter.requestTypeView === 'RANGE_SELECT' ||
        filter.requestTypeView === 'PRICE'
      )
        initialForm = { ...initialForm, [filter.id]: { from: '', to: '' } };
      else initialForm = { ...initialForm, [filter.id]: '' };
    });

    setFormData(initialForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getLocalitiesHandler = async () => {
    let town = filters?.find(filter => filter.requestTypeView === 'TOWN')?.id;
    let street = filters?.find(
      filter => filter.requestTypeView === 'STREET',
    )?.id;
    let zona = filters?.find(filter => filter.requestTypeView === 'ZONE')?.id;

    if (countyId) {
      try {
        const result = await dispatch(getLocalities({ parent: countyId }));

        unwrapResult(result);

        if (parseValues(result.payload)?.length > 0) {
          let clearObject = {};
          if (town)
            clearObject = {
              ...clearObject,
              [town]: parseValues(result.payload)[0],
            };
          if (street) clearObject = { ...clearObject, [street]: [] };
          if (zona) clearObject = { ...clearObject, [zona]: [] };
          clearObject && setFormData({ ...formData, ...clearObject });
          setTownId(parseValues(result.payload)[0].value);
        }
      } catch (err: any) {}
    }
  };

  useEffect(() => {
    let town = filters?.find(filter => filter.requestTypeView === 'TOWN')?.id;
    let street = filters?.find(
      filter => filter.requestTypeView === 'STREET',
    )?.id;
    let zona = filters?.find(filter => filter.requestTypeView === 'ZONE')?.id;

    if (countyId && town !== undefined) {
      getLocalitiesHandler();
    } else {
      dispatch(clearTowns());
      dispatch(clearStreets());
      dispatch(clearZona());
      let clearObject = {};
      if (town) clearObject = { ...clearObject, [town]: null };
      if (street) clearObject = { ...clearObject, [street]: [] };
      if (zona) clearObject = { ...clearObject, [zona]: [] };
      clearObject && setFormData({ ...formData, ...clearObject });

      setTownId(null);
      setStreetId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countyId]);

  useEffect(() => {
    let zona = filters?.find(filter => filter.requestTypeView === 'ZONE')?.id;

    if (townId) {
      dispatch(getStreet({ town: townId }));

      if (zona !== undefined) {
        dispatch(getZona({ parent: townId }));
      }

      let clearObject = {};

      if (zona) {
        clearObject = { ...clearObject, [zona]: [] };
      }

      clearObject && setFormData({ ...formData, ...clearObject });
    } else {
      dispatch(clearStreets());
      dispatch(clearZona());
      setStreetId(null);
    }

    let clearObject = {};

    if (zona) {
      clearObject = { ...clearObject, [zona]: [] };
    }

    clearObject && setFormData({ ...formData, ...clearObject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townId]);

  useEffect(() => {
    let zona = filters?.find(filter => filter.requestTypeView === 'ZONE')?.id;

    if (
      townId &&
      streetId?.length &&
      streetId?.length > 0 &&
      zona !== undefined
    ) {
      dispatch(getZona({ street: streetId.join(','), parent: townId }));
    } else {
      if (zona && townId) dispatch(getZona({ parent: townId }));
    }
    zona && setFormData({ ...formData, [zona]: [] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streetId]);

  const handleSelect = (id: number, ranges: any) => {
    setShowCalendar(null);
    setFormData({
      ...formData,
      [id]: {
        ...formData[id],
        from: moment(ranges.selection?.startDate).format('YYYY-MM-DD'),
        to: moment(ranges.selection?.endDate).format('YYYY-MM-DD'),
      },
    });
  };

  const handleChange = (
    key: string | number,
    e: string | number | SelectValue | boolean | null | string[],
  ) => {
    setFormData({ ...formData, [key]: e });
  };

  const handleChangeRange = (
    key: string | number,
    object: string,
    e: string | number | boolean | SelectValue,
  ) => {
    setFormData({ ...formData, [key]: { ...formData[key], [object]: e } });
  };

  const resetFilters = (e: FormEvent) => {
    e.preventDefault();

    let initialForm = {};

    filters?.forEach(filter => {
      if (
        filter.requestTypeView === 'RANGE' ||
        filter.requestTypeView === 'RANGE_SELECT' ||
        filter.requestTypeView === 'PRICE'
      ) {
        initialForm = { ...initialForm, [filter.id]: { from: '', to: '' } };
      } else if (
        filter.requestTypeView === 'SELECT_OPTIONS' ||
        filter.requestTypeView === 'LOCATION' ||
        filter.requestTypeView === 'STREET' ||
        filter.requestTypeView === 'SPECIFIC_TYPE' ||
        filter.requestTypeView === 'ZONE' ||
        filter.requestTypeView === 'OFFER_TYPE'
      ) {
        initialForm = { ...initialForm, [filter.id]: [] };
      } else {
        initialForm = { ...initialForm, [filter.id]: null };
      }
    });

    setFormData(initialForm);

    setFiltersForm && setFiltersForm(null);
  };

  const submitFilters = (e: FormEvent) => {
    e.preventDefault();

    let currentObject: any[] = [];
    filters?.forEach(filter => {
      if (
        formData[filter.id] &&
        formData[filter.id] !== null &&
        formData[filter.id] !== ''
      ) {
        if (
          filter.requestTypeView === 'RANGE' ||
          filter.requestTypeView === 'PRICE'
        ) {
          if (
            formData[filter.id]?.from !== '' &&
            formData[filter.id]?.from !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formData[filter.id]?.from,
            };
          if (
            formData[filter.id]?.to !== '' &&
            formData[filter.id]?.to !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formData[filter.id]?.to,
            };
        }
        if (filter.requestTypeView === 'RANGE_SELECT') {
          if (
            formData[filter.id]?.from?.value &&
            formData[filter.id]?.from?.value !== '' &&
            formData[filter.id]?.from?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formData[filter.id]?.from?.value,
            };
          if (
            formData[filter.id]?.to?.value &&
            formData[filter.id]?.to?.value !== '' &&
            formData[filter.id]?.to?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formData[filter.id]?.to?.value,
            };
        } else if (
          filter.requestTypeView === 'COUNTY' ||
          filter.requestTypeView === 'TOWN'
        ) {
          if (formData[filter.id]?.value)
            currentObject[filter.id] = formData[filter.id]?.value;
        } else if (filter.requestTypeView === 'SELECT') {
          currentObject[filter.id] = formData[filter.id]?.value;
        } else if (filter.requestTypeView === 'EMPLOYEE') {
          if (formData[filter.id]?.value?.value)
            currentObject[filter.id] = {
              id: formData[filter.id]?.value?.value,
              associated: formData[filter.id]?.associated,
            };
        } else if (
          filter.requestTypeView === 'SELECT_OPTIONS' ||
          filter.requestTypeView === 'LOCATION' ||
          filter.requestTypeView === 'STREET' ||
          filter.requestTypeView === 'SPECIFIC_TYPE' ||
          filter.requestTypeView === 'ZONE' ||
          filter.requestTypeView === 'OFFER_TYPE'
        ) {
          if (formData[filter.id]?.length > 0)
            formData[filter.id].forEach((value: number) => {
              currentObject[filter.id] =
                currentObject[filter.id]?.length > 0
                  ? [...currentObject[filter.id], value]
                  : [value];
            });
        } else {
          currentObject[filter.id] = formData[filter.id];
        }
      }
    });

    currentObject?.length > 0 &&
      setFiltersForm &&
      setFiltersForm(currentObject);
  };

  const renderContent = (filter: Filter) => {
    switch (filter.requestTypeView) {
      case 'SELECT_OPTIONS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'OFFER_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={pricesTypes}
          />
        );
      case 'SELECT':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'LOCATION':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={locations && parseValues(locations)}
          />
        );
      case 'COUNTY':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setCountyId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={county && parseValues(county)}
          />
        );
      case 'TOWN':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setTownId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={localities && parseValues(localities)}
          />
        );
      case 'STREET':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => {
              handleChange(filter.id, e);
              setStreetId(e ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={streets && parseValues(streets)}
          />
        );
      case 'ZONE':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            isClearable={true}
            options={zona && parseValues(zona)}
          />
        );

      case 'SPECIFIC_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={specificTypes && parseValues(specificTypes)}
          />
        );

      case 'CONTACT':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={contacts && parseValues(contacts)}
          />
        );

      case 'SOURCE_LEAD':
        return (
          <FormSelect
            value={
              formData?.[filter.id]
                ? getValue(leadSources, formData?.[filter.id])
                : null
            }
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e.value);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={leadSources && parseValues(leadSources)}
          />
        );

      case 'STATUS':
        return (
          <FormSelect
            value={
              formData?.[filter.id]
                ? statusOptions.find(
                    option => Number(option.value) === formData?.[filter.id],
                  )
                : null
            }
            onChange={(e: SelectValue) => {
              handleChange(filter.id, Number(e?.value) ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={statusOptions}
          />
        );
      case 'EMPLOYEE':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formData?.[filter.id]?.value ?? ''}
              onChange={(e: SelectValue) =>
                handleChangeRange(filter.id, 'value', e)
              }
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={users && parseValues(users)}
            />
            <Form.Group id='formGridCheckbox' key={filter.id}>
              <Form.Check
                type='checkbox'
                label={'Agent asociat'}
                checked={
                  formData?.[filter.id]?.associated === 1 ||
                  formData?.[filter.id]?.associated === true
                    ? true
                    : false
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'associated', e.target.checked)
                }
              />
            </Form.Group>
          </div>
        );
      case 'INPUT':
      case 'OWNER_PHONE':
      case 'ID':
      case 'STREET_NUMBER':
      case 'KEYWORDS':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                value={formData?.[filter.id] || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(filter.id, e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE':
      case 'PRICE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={formData?.[filter.id]?.from || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'from', e.target.value)
                }
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={formData?.[filter.id]?.to || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'to', e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_SELECT':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formData?.[filter.id]?.from}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'from', e)
                }
              />
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formData?.[filter.id]?.to}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'to', e)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_DATE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input range-date date-column'>
              {showCalendar === filter.id && (
                <div className='range-calendar' ref={myRef}>
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: formData?.[filter.id]?.from
                          ? new Date(formData?.[filter.id]?.from)
                          : undefined,
                        endDate: formData?.[filter.id]?.to
                          ? new Date(formData?.[filter.id]?.to)
                          : undefined,
                        key: 'selection',
                      },
                    ]}
                    locale={locale.enUS}
                    onChange={e => {
                      handleSelect(filter.id, e);
                    }}
                  />
                </div>
              )}
              <ReactDatePicker
                placeholderText={
                  formData?.[filter.id]?.from && formData?.[filter.id]?.to
                    ? `${moment(formData?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formData?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : ''
                }
                value={
                  formData?.[filter.id]?.from &&
                  formData?.[filter.id]?.to &&
                  showCalendar !== filter.id
                    ? `${moment(formData?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formData?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : ''
                }
                selected={null}
                onCalendarOpen={() => setShowCalendar(filter.id)}
                onChange={() => {}}
              />
            </div>
          </div>
        );
      case 'CHECKBOX':
      case 'VIRTUAL_TUR_CHECKBOX':
      case 'VIDEO_CHECKBOX':
      case 'FLOOR_EXCLUDED_NOT_SPECIFIC_CHECKBOX':
      case 'FLOOR_EXCLUDED_P_LAST_CHECKBOX':
      case 'HAS_CONTRACT_DATA_CHECKBOX':
      case 'EXCLUSIVENESS_CHECKBOX':
        return (
          <Form.Group id='formGridCheckbox' key={filter.id}>
            <Form.Check
              type='checkbox'
              label={filter.title}
              checked={
                formData?.[filter.id] === 1 || formData?.[filter.id] === true
                  ? formData?.[filter.id]
                  : false
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(filter.id, e.target.checked)
              }
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <div className='filter-form-container requests-filter-container'>
      <div className='filter-container'>
        <div className='header-section display-flex'>
          <h5 className='title-section'>Cautare avansata</h5>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <form>
            <div className='flex-container-request'>
              <div className={`flex-rows`}>
                {filters && filters.length > 0 && (
                  <>
                    <div className='filters-column'>
                      {filters
                        .filter(filters => filters.requestGroup === 1)
                        .slice(0, filters.length)
                        .map((filter: Filter) => renderContent(filter))}
                    </div>
                    <div className='filters-column'>
                      {filters
                        .filter(filters => filters.requestGroup === 2)
                        .map((filter: Filter) => renderContent(filter))}
                    </div>
                    <div className='filters-column'>
                      {filters
                        .filter(filters => filters.requestGroup === 3)
                        .map((filter: Filter) => renderContent(filter))}
                    </div>
                    <div className='filters-column'>
                      {filters
                        .filter(filters => filters.requestGroup === 4)
                        .map((filter: Filter) => renderContent(filter))}
                    </div>
                    <div className='filters-column'>
                      {filters
                        .filter(filters => filters.requestGroup === 5)
                        .map((filter: Filter) => renderContent(filter))}
                    </div>
                  </>
                )}
              </div>{' '}
            </div>
            <div className='form-btns-section'>
              <button className='reset-btn' onClick={e => resetFilters(e)}>
                Reseteaza{' '}
              </button>
              <button
                className='filter-btn button-blue button-md'
                onClick={e => submitFilters(e)}
              >
                {requestsListState.loading && (
                  <Spinner className='btn-blue-spinner' />
                )}
                Filtreaza
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default RequestsFilter;
