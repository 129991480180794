import { configureStore } from '@reduxjs/toolkit';
import activitiesSlice from './activities/activities-slice';
import authSlice from './auth/auth-slice';
import categoriesSlice from './categories/categories-slice';
import companiesSlice from './companies/companies-slice';
import contactsSlice from './contacts/contacts-slice';
import currenciesSlice from './currencies/currencies-slice';
import emailTemplatesSlice from './emailTemplates/emailTemplates-slice';
import languagesSlice from './language/language-slice';
import localitiesSlice from './localities/localities-slice';
import mediaSlice from './media/media-slice';
import permissionsSlice from './permissions/permissions-slice';
import propertiesSlice from './properties/properties-slice';
import requestsSlice from './requests/requests-slice';
import residentialComplexesSlice from './residentialComplexes/residentialComplexes-slice';
import specializationSlice from './specialization/specialization-slice';
import usersSlice from './users/users-slice';
import announcesSlice from './announces/announces-slice';
import notificationsSlice from './notifications/notifications-slice';
import dashboardSlice from './dashboard/dashboard-slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    categories: categoriesSlice,
    properties: propertiesSlice,
    localities: localitiesSlice,
    users: usersSlice,
    activities: activitiesSlice,
    media: mediaSlice,
    languages: languagesSlice,
    specializations: specializationSlice,
    contacts: contactsSlice,
    companies: companiesSlice,
    emailTemplates: emailTemplatesSlice,
    permissions: permissionsSlice,
    residentialComplexes: residentialComplexesSlice,
    requests: requestsSlice,
    currencies: currenciesSlice,
    announces: announcesSlice,
    notifications: notificationsSlice,
    dashboard: dashboardSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
