import React, { useState } from "react";
import SideNav from "../../components/SideNav";
import Header from "../../components/Header";
import FilterParticularCategory from "../../components/FilterParticularCategory/FilterParticularCategory";
import FilterListHeader from "../../components/FilterListHeader";
import FilterParticularRequestList from "../../components/FilterParticularRequestList/FilterParticularRequestList";
import ParticularRequestItem from "../../components/ParticularRequestItem";
import Pagination from "../../components/Pagination";
import FixedActions from "../../components/FixedActions/FixedActions";
import ParticularRequestModal from "../../components/ParticularRequestModal/ParticularRequestModal";
import "./ParticularRequests.scss";
interface Props {}

const ParticularRequests: React.FC<Props> = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    const ModalAction = () => {
        setIsModalOpen(true);
        setIsAction(true);
        setIsSearch(false);
        setIsFilter(false);
    };
    const ModalFilter = () => {
        setIsModalOpen(true);
        setIsFilter(true);
        setIsAction(false);
        setIsSearch(false);
    };
    const ModalSearch = () => {
        setIsModalOpen(true);
        setIsSearch(true);
        setIsFilter(false);
        setIsAction(false);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="flex-container display-flex">
            <SideNav />
            <div className="display-flex flex-column main-wrapper">
                <Header
                    title="Anunturi"
                    subtitle=""
                    isbutton={false}
                    btnText=""
                />
                <FilterParticularCategory />
                <FilterListHeader />
                <FilterParticularRequestList />
                <div className="list-items-container">
                    <ParticularRequestItem />
                    <ParticularRequestItem />
                    <ParticularRequestItem />
                    <ParticularRequestItem />
                </div>
                <Pagination isSelectPage={false} />
                <FixedActions
                    ModalAction={ModalAction}
                    ModalFilter={ModalFilter}
                    ModalSearch={ModalSearch}
                />
                <ParticularRequestModal
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    isAction={isAction}
                    isSearch={isSearch}
                    isFilter={isFilter}
                />
            </div>
        </div>
    );
};

export default ParticularRequests;
