import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';

import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input';

import { getRentBlock } from '../../helpers/useHelper';

import {
  Price,
  PropertyForm,
  RentBlockType,
  SelectValue,
} from '../../models/Properties';

interface Props {
  type: string;
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
  handleChange: (key: string, value: SelectValue | null) => void;
  handleNumber: (key: string, value: number) => void;
}

const CommisionRentBlock: React.FC<Props> = ({
  type,
  formData,
  handleChange,
  handleNumber,
  setFormData,
}) => {
  const [rentPrice, setRentPrice] = useState<Price | null>(
    formData?.prices?.find((price: Price) => price.id === 2) ??
      formData?.prices?.find((price: Price) => price.id === 5) ??
      null,
  );
  const [rentBlock, setRentBlock] = useState<RentBlockType | null>(
    getRentBlock(formData?.prices?.find((price: Price) => price.id === 2)) ??
      getRentBlock(formData?.prices?.find((price: Price) => price.id === 5)) ??
      null,
  );
  const [rentalContractSigningDate, setRentContractSigningDate] =
    useState<Date | null>(
      rentPrice?.rentalContractSigningDate
        ? new Date(rentPrice?.rentalContractSigningDate)
        : null,
    );

  useEffect(() => {
    setRentPrice(
      formData?.prices?.find((price: Price) => price.id === 2) ??
        formData?.prices?.find((price: Price) => price.id === 5) ??
        null,
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.prices]);

  useEffect(() => {
    setRentContractSigningDate(
      rentBlock?.rentalContractSigningDate
        ? new Date(rentBlock?.rentalContractSigningDate)
        : null,
    );
    const newPrices =
      formData?.prices?.map(price => {
        if (price.id === 2 || price.id === 5) {
          return { ...price, ...rentBlock };
        } else return price;
      }) ?? [];

    setFormData({
      ...formData,
      prices: newPrices,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentBlock]);

  const comOptions = [
    { value: 'da', label: 'Da' },
    { value: 'nu', label: 'Nu' },
  ];

  const handleChangeRent = (
    key: string,
    e: string | boolean | number | null,
  ) => {
    setRentBlock({
      ...rentBlock,
      [key]: e,
    });
  };
  const handleDateRent = (e: Date | null) => {
    setRentBlock({
      ...rentBlock,
      rentalContractSigningDate: e ? moment(e).format('YYYY-MM-DD') : '',
    });
  };

  useEffect(() => {
    rentPrice?.rentalMonthPrice &&
      rentBlock?.rentalPercentageCommission &&
      handleChangeRent(
        'rentalCommission',
        (rentPrice?.rentalMonthPrice / 100) *
          rentBlock?.rentalPercentageCommission,
      ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentPrice?.rentalMonthPrice, rentBlock?.rentalPercentageCommission]);

  return (
    <fieldset className='row fieldset-block'>
      <Col lg='12'>
        <legend className='heading-block'>
          COMISION AGENT - {type.toUpperCase()}
          <span className='span-title'> (vizibil in portaluri)</span>
        </legend>
      </Col>
      <Col lg='12' className='commision-group-container'>
        <Col lg='4' className='fieldset-br'>
          <div className='form-item'>
            <FormSelect
              labelText={true}
              placeholder='Select Options'
              inlineLabel={'Contract de ' + type + '<br/>Comision Semnat '}
              isSearchable={true}
              value={{
                value:
                  rentBlock?.hasRentContract === 1 ||
                  rentBlock?.hasRentContract === true
                    ? true
                    : false,
                label:
                  rentBlock?.hasRentContract === 1 ||
                  rentBlock?.hasRentContract === true
                    ? 'Da'
                    : 'Nu',
              }}
              options={comOptions}
              onChange={(e: SelectValue) =>
                handleChangeRent(
                  'hasRentContract',
                  e.value === 'da' ? true : false,
                )
              }
            />
          </div>
        </Col>
        <Col lg='4' className='fieldset-br'>
          <div className='form-item'>
            <Input
              labelText={true}
              type='number'
              inlineLabel={'Comision ' + type + '(%)'}
              placeholder='In procent - ex: 2%'
              value={rentPrice?.rentalPercentageCommission ?? ''}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeRent(
                  'rentalPercentageCommission',
                  Number(e.target.value),
                )
              }
              id='commission-proc'
            />
            <Input
              labelText={true}
              inlineLabel='Comision Calculat'
              value={rentBlock?.rentalCommission ?? ''}
              disabled
            />
          </div>
        </Col>
        <Col lg='4'>
          <div className='form-item'>
            <Input
              labelText={true}
              inlineLabel={'Nr.contract<br/>*' + type}
              value={rentBlock?.rentalContractNumber ?? ''}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeRent('rentalContractNumber', e.target.value)
              }
              id='commission-proc'
            />
            <div className='form-item'>
              <div className='date-picker'>
                <label>Data semnarii</label>
                <DatePicker
                  selected={rentalContractSigningDate}
                  onChange={date => handleDateRent(date)}
                />
              </div>
            </div>
          </div>
        </Col>
      </Col>
      <Col lg='12'>
        *Prin completarea acestor date, declarati pe proprie raspundere ca
        informatiile cu privire la contract sunt reale.
        <hr className='fieldset-block__hr' />
      </Col>
    </fieldset>
  );
};

export default CommisionRentBlock;
