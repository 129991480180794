import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddAgentForm from '../../components/AddAgentForm';
import AgentInfo from '../../components/AgentInfo';
import AgentPermision from '../../components/AgentPermision';
import AgentProfileTabs from '../../components/AgentProfileTabs';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCounty } from '../../redux/localities/localitiesThunk';
import { getPermissions } from '../../redux/permissions/permissionsThunk';
import { getSpecializations } from '../../redux/specialization/specializationThunk';
import { clearUser } from '../../redux/users/users-slice';
import { addUser, editUser, getUser } from '../../redux/users/usersThunk';
import {
  getImagePath,
  getLoggedUserId,
  haveUserPermission,
} from '../../helpers/useHelper';
import { AgentRequestDto } from '../../models/AgentsDto';
import { Permissions } from '../../models/Permissions';
import './AddAgent.scss';

interface Props {}

const AddAgent: React.FC<Props> = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, loading } = useAppSelector(state => state.users);
  const { specializations } = useAppSelector(state => state.specializations);
  const { permissions } = useAppSelector(state => state.permissions);
  const { county } = useAppSelector(state => state.localities);

  const [formData, setFormData] = useState<AgentRequestDto>({});
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [formDataErrors, setFormDataErrors] = useState<any>({});
  const [agentPermissions, setAgentPermissions] = useState<number[]>([]);
  const [showFirstTab, setShowFirstTab] = useState<boolean>(false);
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.EmployeeAdd),
  );

  useEffect(() => {
    dispatch(clearUser());

    if (agentId) {
      dispatch(getUser(agentId));
    }

    loadFormOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (
      agentPermissions &&
      localStorage.getItem('userId') !== user?.id.toString()
    ) {
      setFormData((form: any) => {
        form.body.permissions = agentPermissions;

        return { ...form };
      });
    }
    // eslint-disable-next-line
  }, [agentPermissions]);

  const loadFormOptions = () => {
    specializations.length === 0 &&
      dispatch(getSpecializations({ limit: 20, offset: 1 }));
    county.length === 0 && dispatch(getCounty({ parent: 0 }));
    permissions.length === 0 && dispatch(getPermissions());
  };

  const saveAgent = async () => {
    if (user) {
      await dispatch(editUser(formData)).then(processResponse);
    } else {
      await dispatch(addUser(formData)).then(processResponse);
    }
  };

  const processResponse = (response: any) => {
    if (response.error) {
      toast.error(
        `Eroare la salvarea agentului ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
      );
      setFormDataErrors(response.payload);
    } else {
      toast.success(
        `Succes!! Agentul a fost ${user ? 'modificat' : 'adaugat'} cu succes.`,
      );
      navigate('/agents');
    }
  };

  const resetForm = () => {
    setFormData({
      id: user?.id || 0,
      body: {
        status: user?.status || 0,
        fullName: user?.profile?.fullName || '',
        phoneNumbers: user?.profile?.phoneNumbers || [''],
        email: user?.email || '',
        birthday: user?.profile?.birthday?.substring(0, 10) || '2000-10-10',
        identificationNumber: user?.profile?.identificationNumber || '',
        description: user?.profile?.description || '',
        position: user?.profile?.position || '',
        languages: user?.profile?.languages?.map(lang => lang.id) || [],
        specializations:
          user?.profile?.specializations?.map(spec => spec.id) || [],
        mediaId: user?.profile?.avatar?.id || 0,
        countyId: user?.profile?.county?.id || 0,
        townId: user?.profile?.town?.id || 0,
        zones: user?.profile?.zones?.map(zone => zone.id) || [],
        permissions: user?.permissions?.map(permission => permission.id) || [],
        password: '',
        confirmPassword: '',
      },
    });

    if (user?.profile?.avatar?.path) {
      const imagePath = getImagePath(user?.profile?.avatar?.path);
      setProfileImage(imagePath);
    } else {
      setProfileImage(null);
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper agent-container'>
        <Header
          title={agentId ? 'Editeaza Agent' : 'Adauga Agent'}
          subtitle='De aici poti modifica setarile agentie tale, defini agenti, proprietati etc.'
          isbutton={false}
          backBtn={true}
          btnText=''
        />
        {havePermission ? (
          <>
            {agentId && <AgentProfileTabs openedFirstTab={setShowFirstTab} />}
            {loading ? (
              <div className='container'>
                <Spinner />
              </div>
            ) : showFirstTab ? (
              <AgentInfo user={user} />
            ) : (
              <div className='display-flex flex-add-agent'>
                <AddAgentForm
                  user={user}
                  formData={formData}
                  setFormData={setFormData}
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                  saveAgent={saveAgent}
                  resetForm={resetForm}
                  formDataErrors={formDataErrors}
                />
                {getLoggedUserId() !== agentId &&
                  haveUserPermission(Permissions.AgencyAdministrator) && (
                    <AgentPermision
                      userPermissions={
                        user?.permissions?.map(permission => permission.id) ??
                        []
                      }
                      onChangedPermissions={setAgentPermissions}
                    />
                  )}
                <div className='btns-container-mobile'>
                  <button className='button-blue button-md' onClick={saveAgent}>
                    Salveaza
                  </button>
                  <button className='reset-btn' onClick={resetForm}>
                    Reseteaza
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default AddAgent;
