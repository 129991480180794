interface Props {}

const IconList: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M18.5,5.4L18.5,5.4c0-0.2-0.1-0.4-0.3-0.5l-8-3.4c-0.1,0-0.3,0-0.4,0L1.8,5C1.6,5,1.5,5.2,1.5,5.4v0
        c0,0.2,0.1,0.3,0.2,0.4l3.1,1.8L1.8,9C1.6,9,1.5,9.2,1.5,9.4v0c0,0.2,0.1,0.3,0.2,0.4l3.1,1.8L1.8,13c-0.2,0.1-0.3,0.2-0.3,0.4v0
        c0,0.2,0.1,0.3,0.2,0.4l8,4.6c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1l8-4.6c0.2-0.1,0.2-0.2,0.2-0.4v0c0-0.2-0.1-0.4-0.3-0.4l-3.1-1.3
        l3.1-1.8c0.2-0.1,0.3-0.3,0.2-0.5S18.4,9,18.2,9l-3.1-1.3l3.1-1.8C18.4,5.8,18.5,5.6,18.5,5.4z M16.9,13.5l-6.9,4l-6.9-4L6,12.3
        l3.8,2.2c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1l3.8-2.2L16.9,13.5z M16.9,9.5l-6.9,4l-6.9-4L6,8.3l3.8,2.2c0.1,0,0.2,0.1,0.2,0.1
        s0.2,0,0.2-0.1L14,8.3L16.9,9.5z M10,9.4l-6.9-4L10,2.5l6.9,2.9L10,9.4z"
      />
    </svg>
  );
};

export default IconList;
