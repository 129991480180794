import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EmailTemplatesListItem from '../../components/EmailTemplatesListItem';
import EmailTemplatesModal from '../../components/EmailTemplatesModal/EmailTemplatesModal';
import FilterListEmailTemplates from '../../components/FilterListEmailTemplates/FilterListEmailTemplates';
import FilterListHeaderEmailTemplates from '../../components/FilterListHeaderEmailTemplates';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import ModalEmailTemplateItem from '../../components/ModalEmailTemplateItem';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import {
  deleteEmailTemplate,
  getEmailTemplate,
  getEmailTemplates,
} from '../../redux/emailTemplates/emailTemplatesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { haveUserPermission } from '../../helpers/useHelper';
import { EmailTemplateListItem } from '../../models/EmailTemplate';
import { Permissions } from '../../models/Permissions';
import IconPlus from '../../icons/IconPlus';
import './EmailTemplates.scss';
import { checkAll, toggleChecked } from '../../redux/emailTemplates/emailTemplates-slice';
import { Property } from '../../models/Properties';
import PropertyListItem from '../../components/PropertyListItem';

interface Props {}

const EmailTemplates: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { emailTemplates, count, loadingTemplates } = useAppSelector(
    state => state.emailTemplates,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(24);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isOpenTemplateModal, setOpenTemplateModal] = useState(false);
  const [isReadOnlyTemplateModal, setReadOnlyTemplateModal] = useState(false);
  const [sort, setSort] = useState('');
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.EmailOfferTemplateList),
  );
  const [filtersForm, setFiltersForm] = useState({});
  const [filterByLettersOnMobile, setFilterByLettersOnMobile] = useState(false);
  const [prevState, setPrevState] = useState({
    page: 0,
    pageSize: 24,
    sort: '',
  });

  useEffect(() => {
    let newState = {
      page: page,
      pageSize: pageSize,
      sort: sort,
    };

    if (
      JSON.stringify(prevState.page) !== JSON.stringify(newState.page) ||
      JSON.stringify(prevState.pageSize) !==
        JSON.stringify(newState.pageSize) ||
      JSON.stringify(prevState.sort) !== JSON.stringify(newState.sort)
    ) {
      getLocalTemplates();
      setPrevState(newState);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sort]);

  useEffect(() => {
    if (page === 0) {
      getLocalTemplates();
    } else {
      setPage(0);
    }

    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm]);

  const getLocalTemplates = () => {
    dispatch(
      getEmailTemplates({
        limit: pageSize,
        offset: page + 1,
        sort: sort,
        ...filtersForm,
      }),
    );
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  const openTemplateModal = (templateId: number) => {
    dispatch(getEmailTemplate(templateId));
    setOpenTemplateModal(true);
  };

  const showTemplate = (templateId: number) => {
    setReadOnlyTemplateModal(true);
    openTemplateModal(templateId);
  };

  const editTemplate = (templateId: number) => {
    setReadOnlyTemplateModal(false);
    openTemplateModal(templateId);
  };

  const editSelectedItems = () => {
    if (emailTemplates.some(item => item.checked)) {
      editTemplate(emailTemplates.filter(item => item.checked)[0].id);
      dispatch(checkAll(false));
      closeModal();
    } else {
      toast.error('Alegeti un template!');
    }
  };

  const removeSelectedItems = () => {
    if (emailTemplates.some(item => item.checked)) {
      setShowDeleteModal(true);
    } else {
      toast.error('Alegeti un template!');
    }
  };

  const deleteTemplates = async () => {
    const deletePromises = emailTemplates
      .filter(item => item.checked).map(template =>
      deleteTemplate(template),
    );
    await Promise.all(deletePromises);

    dispatch(checkAll(false));
    closeModal();
    getLocalTemplates();
  };

  const deleteTemplate = async (template: EmailTemplateListItem) => {
    await dispatch(deleteEmailTemplate(template.id));
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const item = emailTemplates.find(item => item.id === id);

      if (item && item.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Template-uri email'
          subtitle='Lista Template-uri disponibile pentru oferte email'
          isbutton={havePermission}
          btnText='Adauga Template'
          btnEvent={() => setOpenTemplateModal(true)}
        />
        {havePermission ? (
          <>
            <FilterListHeaderEmailTemplates
              pageCount={Math.ceil(count / pageSize)}
              currentPage={page}
              itemsPerPage={pageSize}
              totalCount={count}
              count={emailTemplates?.length}
              setItemsPerPage={e => setPageSize(e)}
              handlePageClick={handlePageClick}
              showCheckedItems={showCheckedItems}
              checkedItemsLength={emailTemplates.filter(item => item.checked).length}
              setShowCheckedItems={setShowCheckedItems}
              editItems={editSelectedItems}
              removeItems={removeSelectedItems}
            />
            <FilterListEmailTemplates
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) {
                  handleCheckboxChange(undefined, true);
                } else {
                  dispatch(checkAll(false));
                }
              }}
              isCheckedAll={isCheckedAll}
              sortProperty={setSort}
              sort={sort}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
            />
            {loadingTemplates ? (
              <div className='container'>
                <Spinner />
              </div>
            ) : (
              <>
                <div className='list-items-container'>
                  {emailTemplates
                    .filter(item => !showCheckedItems || item.checked)
                    .map((item: EmailTemplateListItem) => (
                      <EmailTemplatesListItem
                        key={`${item.id} ${item.title}`}
                        template={item}
                        checked={item.checked}
                        handleCheckboxChange={handleCheckboxChange}
                        onDeletedTemplate={getLocalTemplates}
                        onShowTemplate={() => showTemplate(item.id)}
                        onEditTemplate={() => editTemplate(item.id)}
                      />
                    ))}
                  {!emailTemplates.some(item => !showCheckedItems || item.checked) && (
                    <p className='empty-text'>
                      {showCheckedItems
                        ? '0 Template-uri selectate'
                        : 'Cautarea ta a returnat 0 Rezultate'}
                    </p>
                  )}
                </div>
                <Pagination
                  isSelectPage={false}
                  currentPage={page}
                  itemsPerPage={pageSize}
                  pageCount={Math.ceil(count / pageSize)}
                  handlePageClick={handlePageClick}
                  setItemsPerPage={e => setPageSize(e)}
                />
              </>
            )}
            <FixedActions
              disabledActions={emailTemplates.every(item => !item.checked)}
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <div className='add-btn' onClick={() => setOpenTemplateModal(true)}>
              <IconPlus />
            </div>
            <EmailTemplatesModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) {
                  handleCheckboxChange(undefined, true)
                } else {
                  dispatch(checkAll(false));
                }
              }}
              isCheckedAll={isCheckedAll}
              editItems={editSelectedItems}
              removeItems={removeSelectedItems}
              sortProperty={setSort}
              sort={sort}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <ModalEmailTemplateItem
        handleClose={() => setOpenTemplateModal(false)}
        showModal={isOpenTemplateModal}
        onSaved={() => getLocalTemplates()}
        isReadOnly={isReadOnlyTemplateModal}
      />
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere email template-uri'
        body='Esti sigur ca doresti sa stergi email template-urile selectate?'
        onConfirmed={deleteTemplates}
        hideModal={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default EmailTemplates;
