import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AnnouncesAlertsModal from '../../components/AnnouncesAlertsModal/AnnouncesAlertsModal';
import AnnouncesListItemAlerts from '../../components/AnnouncesListItemAlerts';
import FilterListAlerts from '../../components/FilterListAlerts/FilterListAlerts';
import FilterListHeader from '../../components/FilterListHeader';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './AnnouncesAlerts.scss';

interface Props {}

const AnnouncesAlerts: React.FC<Props> = () => {
  const announceAlerts : any[] = []; //todo replace with useSelector when api will be implemented

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.AdAlertList),
  );

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (announceAlerts.some((announceAlert) => announceAlert.checked)) {
      setTitleConfirmModal(`${titleAction} alerte anunturi`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} alertele selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin o alerta!');
    }
  };

  const removeConfirmedItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const arhiveConfirmedItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere alerte anunturi') {
      removeConfirmedItems();
    } else if (titleConfirmModal === 'Arhivare alerte anunturi') {
      arhiveConfirmedItems();
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Alerte ImoAP'
          subtitle='Alertele vor fi trimise pe email'
          isbutton={havePermission}
          btnText='Adauga Alerta'
        />
        {havePermission ? (
          <>
            <FilterListHeader
              removeItems={() => openConfirmModal('Stergere', 'stergi')}
              arhiveItems={() => openConfirmModal('Arhivare', 'arhivezi')}
            />
            <FilterListAlerts />
            <div className='list-items-container'>
              <AnnouncesListItemAlerts />
              <AnnouncesListItemAlerts />
              <AnnouncesListItemAlerts />
              <AnnouncesListItemAlerts />
            </div>
            <Pagination isSelectPage={false} />
            <FixedActions
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <AnnouncesAlertsModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default AnnouncesAlerts;
