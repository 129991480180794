import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from '../../components/Spinner';
import { useAppSelector } from '../../redux/hooks';
import { Permission } from '../../models/Permissions';
import PlusIcon from '../../assets/svg/Plus.svg';
import DropDownArrow from '../../assets/svg/arrowdropdown.svg';
import MinusIcon from '../../assets/svg/minusicon.svg';
import './AgentPermision.scss';

interface Props {
  userPermissions: number[];
  onChangedPermissions: (ids: number[]) => void;
}

const AgentPermision: React.FC<Props> = ({
  userPermissions,
  onChangedPermissions,
}) => {
  const { permissions, loading } = useAppSelector(state => state.permissions);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [agencyAdminPermissionId, setAgencyAdminPermissionId] = useState(0);

  const AGENCY_ADMIN = 'AGENCY_ADMINISTRATOR';

  useEffect(() => {
    if (permissions) {
      setAgencyAdminPermissionId(
        permissions.find(permission => permission.name === AGENCY_ADMIN)?.id ||
          0,
      );
    }
  }, [permissions]);

  useEffect(() => {
    onChangedPermissions(checkedItems);
    // eslint-disable-next-line
  }, [checkedItems]);

  useEffect(() => {
    if (!loading) {
      setCheckedItems([...userPermissions]);
    }
    // eslint-disable-next-line
  }, [loading]);

  const handleDropdownClick = (dropdown: string) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const handleCheckboxChange = (permission: Permission): void => {
    if (permission.name === AGENCY_ADMIN) {
      if (
        isCheckedAll &&
        checkedItems.some(item => item === agencyAdminPermissionId)
      ) {
        uncheckAll();
      } else {
        checkAllWithAgencyAdmin(true);
      }
    } else if (checkedItems.some(item => item === permission.id)) {
      setCheckedItems([
        ...checkedItems.filter(
          item => item !== permission.id && item !== agencyAdminPermissionId,
        ),
      ]);
    } else {
      setCheckedItems([permission.id, ...checkedItems]);
    }
  };

  const handleHeaderCheckboxChange = (permission: Permission): void => {
    const ids = permission.items.map(p => p.id);

    if (checkedItems.some(item => ids.includes(item))) {
      setCheckedItems([
        ...checkedItems.filter(
          item => !ids.includes(item) && item !== agencyAdminPermissionId,
        ),
      ]);
    } else {
      setCheckedItems([...checkedItems, ...ids]);
    }
  };

  const checkAllWithAgencyAdmin = (withAgencyAdmin: boolean) => {
    let permissionsToCheck = [...permissions];

    if (!withAgencyAdmin) {
      permissionsToCheck = permissionsToCheck.filter(
        permission => permission.name !== AGENCY_ADMIN,
      );
    }

    setCheckedItems(
      permissionsToCheck.flatMap(permission =>
        permission.items
          ? [...permission.items.map(subItem => subItem.id)]
          : permission.id,
      ),
    );

    setCheckedAll(true);
  };

  const uncheckAll = () => {
    setCheckedItems([]);
    setCheckedAll(false);
  };

  return (
    <div className='agent-permision-container'>
      {loading ? (
        <div className='container'>
          <Spinner />
        </div>
      ) : (
        <div className='agent-permision-wrapper'>
          <h4>Permisiuni Agent</h4>
          <div className='accordion-section'>
            <div className='accordion-section-list'>
              {permissions.map((permission: Permission) =>
                permission.items && permission.items.length > 0 ? (
                  <div
                    className={`multiple-checkbox-container ${
                      activeDropdown === permission.name ? 'active' : ''
                    }`}
                  >
                    <div className='dropdown-header'>
                      <span
                        className='toggle-icon'
                        onClick={() => handleDropdownClick(permission.name)}
                      >
                        <img src={PlusIcon} alt='PlusIcon' />
                        <img src={MinusIcon} alt='PlusIcon' />
                      </span>
                      <Form.Group id='formGridCheckbox'>
                        <Form.Check>
                          <Form.Check.Input
                            type='checkbox'
                            className={
                              permission.items
                                .map(p => p.id)
                                .every(value => checkedItems.includes(value))
                                ? ''
                                : 'indeterminate'
                            }
                            checked={checkedItems.some(item =>
                              permission.items.map(p => p.id).includes(item),
                            )}
                            readOnly
                            onClick={() =>
                              handleHeaderCheckboxChange(permission)
                            }
                          />
                          <Form.Check.Label
                            onClick={() => handleDropdownClick(permission.name)}
                          >
                            {permission.displayName}
                            <img
                              src={DropDownArrow}
                              alt='DropDownArrow'
                              className='dropdown-icon'
                            />
                          </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </div>
                    <div className='extensive-container'>
                      {permission.items.map((permissionItem: Permission) => (
                        <Form.Group id='formGridCheckbox'>
                          <Form.Check
                            type='checkbox'
                            label={permissionItem.displayName}
                            checked={checkedItems.includes(permissionItem.id)}
                            readOnly
                            onClick={() => handleCheckboxChange(permissionItem)}
                          />
                        </Form.Group>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Form.Group id='formGridCheckbox'>
                    <Form.Check
                      type='checkbox'
                      label={permission.displayName}
                      checked={checkedItems.includes(permission.id)}
                      readOnly
                      onClick={() => handleCheckboxChange(permission)}
                    />
                  </Form.Group>
                ),
              )}
            </div>
          </div>
          <div className='btns-section'>
            <button
              className='button-blue button-md'
              onClick={() =>
                isCheckedAll ? uncheckAll() : checkAllWithAgencyAdmin(false)
              }
            >
              {isCheckedAll ? 'Debifeaza' : 'Bifeaza'} Toate
            </button>
            <button
              className='reset-btn'
              onClick={() => setCheckedItems([...userPermissions])}
            >
              Reseteaza
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentPermision;
