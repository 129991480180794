import { createSlice } from '@reduxjs/toolkit';
import {
  getAnnouncesFilters,
  getAnnounces, saveAnnounce, checkAnnouncesCount,
} from './announcesThunk';
import { Announce, Filter } from '../../models/Announces';

type AnnouncesState = {
  announces: Announce[];
  announce: Announce | null;
  filters: Filter[];
  totalCount: number;
  newAnnouncesCount: number;
  loading: boolean;
  loadingFilters: boolean;
  error: any;
};

const initialState: AnnouncesState = {
  announces: [],
  filters: [],
  announce: null,
  totalCount: 0,
  newAnnouncesCount: 0,
  loading: false,
  loadingFilters: false,
  error: null,
};

const announcesSlice = createSlice({
  name: 'announces',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    toggleChecked(state, action) {
      const item = state.announces.find(item => item.id === action.payload);

      if (item) {
        item.checked = !item.checked;
      }
    },
    checkAll(state, action) {
      state.announces.forEach(item => (item.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAnnounces.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnnounces.fulfilled, (state, { payload }) => {
        state.totalCount = payload.totalCount;
        state.announces = payload.data;
        state.newAnnouncesCount = 0;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAnnounces.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getAnnouncesFilters.pending, state => {
        state.loadingFilters = true;
        state.error = null;
      })
      .addCase(getAnnouncesFilters.fulfilled, (state, { payload }) => {
        state.filters = payload;
        state.loadingFilters = false;
        state.error = null;
      })
      .addCase(getAnnouncesFilters.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingFilters = false;
      })
      .addCase(saveAnnounce.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveAnnounce.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveAnnounce.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(checkAnnouncesCount.fulfilled, (state, { payload }) => {
        state.newAnnouncesCount = payload;
      })

  },
});

export const { clearError, toggleChecked, checkAll } = announcesSlice.actions;

export default announcesSlice.reducer;
