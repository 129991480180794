import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';

import Input from '../../components/Input';

interface Props {
  formData: any;
  handleString: (key: string, value: string) => void;
  handleNumber: (key: string, value: number) => void;
}

const CommisionCollaborators: React.FC<Props> = ({
  formData,
  handleString,
  handleNumber,
}) => {
  const [rentUntil, setRentUntil] = useState<Date | null>(
    formData.rentUntil ? new Date(formData?.rentUntil) : null,
  );

  useEffect(() => {
    setRentUntil(formData.rentUntil ? new Date(formData?.rentUntil) : null);
  }, [formData]);

  const handleChangeDate = (date: Date | null) => {
    handleString('rentUntil', date ? moment(date).format('YYYY-MM-DD') : '');
  };

  return (
    <Row className='row-93'>
      <Col lg='4' className='fieldset-br'>
        <fieldset className='row fieldset-block '>
          <Col lg='12'>
            <legend className='heading-block'>Comision promovare</legend>
          </Col>
          <Col>
            <div className='form-item mb-lg'>
              <Form.Check
                type='checkbox'
                label='Coloborare la vanzare'
                id='commission-1'
                checked={formData?.hasCollaborationOnSales}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleNumber(
                    'hasCollaborationOnSales',
                    e.target.checked ? 1 : 0,
                  )
                }
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Comision (%)'
                placeholder='In procent - ex: 2%'
                value={formData?.collaboratorPercentageCommission ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleString(
                    'collaboratorPercentageCommission',
                    e.target.value,
                  )
                }
                id='commission-proc'
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Comision fix'
                placeholder='Suma fixa'
                value={formData?.collaboratorFixCommission ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleString('collaboratorFixCommission', e.target.value)
                }
                id='commission-fix'
              />
            </div>
            <div className='form-item'>
              <Form.Check
                type='checkbox'
                label='Exclusivitate'
                id='exclusiveness'
                checked={formData?.isExclusiveCollaboration}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleNumber(
                    'isExclusiveCollaboration',
                    e.target.checked ? 1 : 0,
                  )
                }
              />
            </div>
            <div className='form-item'>
              <Form.Check
                type='checkbox'
                label='Comission 0 la cumparator'
                id='commission-buyer'
                checked={formData?.hasZeroCommissionToBuyer}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleNumber(
                    'hasZeroCommissionToBuyer',
                    e.target.checked ? 1 : 0,
                  )
                }
              />
            </div>
          </Col>
        </fieldset>
      </Col>
      <Col lg='4'>
        <fieldset className='row fieldset-block '>
          <Col lg='12'>
            <legend className='heading-block'>Alte detalii</legend>
          </Col>
          <Col>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Chiria incasata lunar (€)'
                placeholder='In procent - ex: 2%'
                id='rent-collected'
                value={formData?.rentCollectedMonthly ?? ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleString('rentCollectedMonthly', e.target.value)
                }
              />
            </div>
            <div className='form-item'>
              <div className='date-picker'>
                <label>Inchiriat pana la</label>
                <DatePicker
                  selected={rentUntil}
                  onChange={date => handleChangeDate(date)}
                />
              </div>
            </div>
          </Col>
        </fieldset>
      </Col>
      <Col lg='12'>
        <hr className='fieldset-block__hr' />
      </Col>
    </Row>
  );
};

export default CommisionCollaborators;
