import { createSlice } from '@reduxjs/toolkit';
import { CurrencyItem } from '../../models/Currencies';
import { getCurrencies } from './currenciesThunk';

type CurrencyState = {
  currencies: CurrencyItem[];
  loadingCurrency: boolean;
  error: any;
};

const initialState: CurrencyState = {
  currencies: [],
  loadingCurrency: false,
  error: null,
};

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCurrencies.pending, state => {
        state.loadingCurrency = true;
        state.error = null;
      })
      .addCase(getCurrencies.fulfilled, (state, { payload }) => {
        state.currencies = payload.currencies;
        state.loadingCurrency = false;
        state.error = null;
      })
      .addCase(getCurrencies.rejected, (state, action) => {
        state.error = null;
        state.loadingCurrency = false;
      });
  },
});

export default currenciesSlice.reducer;
