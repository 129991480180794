interface Props {}

const IconPhoneThin: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        d="M20.4,15.7c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.3l-6-1.4h0c-0.2,0-0.3,0-0.5,0.1c0,0,0,0,0,0
          c0,0,0,0-0.1,0.1c-0.1,0.2-0.3,0.5-0.6,1.1l-0.2,0.4L12.2,15C9.9,14,8.1,12.1,7,9.8L6.8,9.4l0.4-0.2c0.6-0.3,0.9-0.5,1.1-0.6
          c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0C8.6,8.4,8.7,8.2,8.6,8l-1.4-6c0-0.2-0.2-0.3-0.3-0.4C6.7,1.7,6.5,1.6,6.3,1.6h0
          c-0.2,0-0.5-0.1-0.7-0.1c-2.3,0-4.1,1.8-4.1,4.1c0,8.2,6.7,14.9,14.9,14.9c2.3,0,4.1-1.8,4.1-4.1C20.5,16.2,20.5,15.9,20.4,15.7z
          M16.4,19.5c-7.7,0-13.9-6.2-13.9-13.9c0-1.7,1.4-3.1,3.1-3.1c0.1,0,0.3,0,0.4,0l0.4,0l0.1,0.3l1.1,4.6l0.1,0.4L7.2,8.1
          C7,8.2,6.9,8.3,6.7,8.4C6.2,8.6,5.9,8.8,5.8,8.9C5.7,9,5.7,9.1,5.7,9.1c0,0,0,0.1,0,0.2l0,0l0,0c1.1,3.2,3.7,5.8,6.9,6.9h0l0,0
          c0.2,0.1,0.2,0.1,0.3,0c0,0,0.1,0,0.2-0.1c0.2-0.1,0.3-0.4,0.6-0.9v0c0.1-0.2,0.2-0.4,0.3-0.5l0.2-0.3l0.4,0.1l4.6,1.1l0.3,0.1
          l0,0.4c0,0.1,0,0.3,0,0.4C19.5,18.1,18.1,19.5,16.4,19.5z"
      />
    </svg>
  );
};

export default IconPhoneThin;
