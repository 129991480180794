interface Props {}

const IconPrev: React.FC<Props> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.454 11.5C14.017 11.5 17.231 13.134 19.264 15.759C18.329 11.89 14.734 8.99998 10.454 8.99998C9.901 8.99998 9.454 8.55298 9.454 7.99998V6.07698L4.63 10.251L9.454 14.463V12.5C9.454 11.947 9.901 11.5 10.454 11.5ZM20.221 21C19.76 21 19.358 20.685 19.249 20.237C18.359 16.588 15.228 13.952 11.454 13.553V14.674C11.454 15.38 11.035 16.009 10.361 16.314C9.63 16.647 8.777 16.527 8.184 16.01L3.118 11.586C2.725 11.242 2.5 10.755 2.5 10.25C2.5 9.74498 2.725 9.25798 3.118 8.91398L8.184 4.48998C8.777 3.97298 9.63 3.85298 10.361 4.18598C11.035 4.49098 11.454 5.11998 11.454 5.82598V7.04398C17.078 7.53698 21.5 12.15 21.5 17.75C21.5 18.56 21.396 19.396 21.192 20.236C21.084 20.685 20.683 21 20.221 21Z'
        fill='#1D1956'
      />
    </svg>
  );
};

export default IconPrev;
