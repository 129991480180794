import React from 'react';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getImagePath } from '../../helpers/useHelper';
import { Media } from '../../models/Properties';
import Listitem from '../../assets/img/house.png';
import IconGalleryLeft from '../../icons/IconGalleryLeft';
import IconGalleryRight from '../../icons/IconGalleryRight';
import './Gallery.scss';

interface Props {
  images?: Media[];
  onClick?: () => void;
}

const Gallery: React.FC<Props> = ({ images, onClick }) => {
  return (
    <>
      <div className='gallery-block'>
        <button className='gallery__nav gallery__prev'>
          <IconGalleryLeft />
        </button>
        <button className='gallery__nav gallery__next'>
          <IconGalleryRight />
        </button>

        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          navigation={{
            nextEl: '.gallery__next',
            prevEl: '.gallery__prev',
          }}
          onClick={onClick}
          pagination
          // onInit={swiper => { swiper.html('Swiper initialized!'), console.log('Swiper initialized!', swiper) }}
          // onSwiper={(swiper) => {let contents = this.innerHTML = "Hello"}}
          // onSlideChange={() => }
          // onSwiper={(swiper) => }
          // onSwiper={(swiper) => {console.log(swiper.slides.length);}}
        >
          {images && images.length > 0 ? (
            images?.map(
              image =>
                image.media?.path && (
                  <SwiperSlide>
                    <img src={getImagePath(image.media?.path)} alt='' />
                  </SwiperSlide>
                ),
            )
          ) : (
            <SwiperSlide>
              <img src={Listitem} alt='' />
            </SwiperSlide>
          )}

          <span className='gallery__count'>{images?.length} imagini</span>
        </Swiper>
      </div>
    </>
  );
};

export default Gallery;
