import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';
import { Filters } from '../../models/Announces';

export const getAnnounces = createAsyncThunk<any, Filters | any>(
  'auth/getAnnounces',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/announcements', { params });

      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);


// filters

export const getAnnouncesFilters = createAsyncThunk<any, number | undefined>(
  'auth/getAnnouncesFilters',
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/filters', {
        params: { category: categoryId, limit: -1, type: 'AD' },
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);


export const saveAnnounce = createAsyncThunk<any, number | undefined>(
  'crm/saveAnnounce',
  async (announceId, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(`api/v1/crm/announcements/${announceId}/save`);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);



export const checkAnnouncesCount = createAsyncThunk<any, Filters | any>(
  'crm/checkAnnouncesCount',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/announcements', { params });

      return response.headers['x-total-count'];
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);