import axios from 'axios';
import { Organization, OrganizationForm } from '../models/Users';

const handleError = (error: any, rejectWithValue: any) => {
  if (axios.isAxiosError(error)) {
    if (error?.response?.data.errors) {
      const errors = error.response.data.errors;
      const allErrorMessages = errors
        .map((errorObj: any) => Object.values(errorObj))
        .join(' ');

      return rejectWithValue(allErrorMessages);
    }

    if (error?.response?.data.error) {
      return rejectWithValue(error.response.data.error);
    }

    return rejectWithValue(error.message);
  } else {
    return rejectWithValue(error);
  }
};

const parseOrganization = (organization: Organization): OrganizationForm => {
  return {
    countyId: organization?.county?.id,
    zones: organization?.zones?.map(zona => `${zona.id}`),
    counties: organization?.counties?.map(county => `${county.id}`),
    cityId: organization?.city?.id,
    cui: organization?.cui,
    cif: organization?.cif,
    logoId: organization?.logo?.id,
    description: organization?.description,
    address: organization?.address,
    bank: organization?.bank,
    bankAccount: organization?.bankAccount,
    phoneEmployee: organization?.phoneEmployee,
    email: organization?.email,
    phoneNumbers: organization?.phoneNumbers ?? [''],
    name: organization?.name,
  };
};

export { handleError, parseOrganization };
