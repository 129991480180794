import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import { getImagePath } from '../../helpers/useHelper';
import Avatar from '../../assets/img/avatar.png';
import IconEnvelope from '../../icons/IconEnvelope';
import IconPhone from '../../icons/IconPhone';
import './SugestedContacts.scss';

interface Props {}

const SugestedContacts: React.FC<Props> = () => {
  const { contacts } = useAppSelector(state => state.contacts);

  return (
    <div className='sugested-contacts-container'>
      <div className='sugested-contacts-wrapper'>
        <h4>Contacte Posibile</h4>
        {contacts?.map(contact => (
          <div className='sugested-contact-item'>
            <div className='img-block'>
              {contact.avatarPath ? (
                <img src={getImagePath(contact.avatarPath)} alt='' />
              ) : (
                <img src={Avatar} alt='' />
              )}{' '}
            </div>
            <div className='info-contact'>
              <span className='name'>
                {' '}
                {contact.name + ' ' + contact.surname}
              </span>
              {contact.phoneNumbers.map(
                phone =>
                  phone.phone && (
                    <a
                      href={'tel:' + phone.phone}
                      aria-label=''
                      className='number'
                    >
                      <IconPhone />
                      {phone.phone}
                    </a>
                  ),
              )}
              {contact.internationalPhoneNumber && (
                <a
                  href={'tel:' + contact.internationalPhoneNumber}
                  aria-label=''
                  className='number'
                >
                  <IconPhone />
                  {contact.internationalPhoneNumber}{' '}
                </a>
              )}{' '}
              {contact.emails?.map(email => (
                <a href={'mailto:' + email} aria-label='' className='mail'>
                  <IconEnvelope />
                  {email}
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SugestedContacts;
