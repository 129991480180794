import React from 'react';

import { SelectValue } from '../../models/Properties';

import FormSelect from '../FormSelect';
import './Actions.scss';

const Actions: React.FC<any> = ({
  labelText,
  inlineLabel,
  id,
  label,
  options,
  getValue,
  ...props
}) => {
  return (
    <>
      <div className='form-item-group'>
        {labelText && (
          <label className='form-item-group-label' htmlFor={id}>
            {inlineLabel}
          </label>
        )}
        <FormSelect
          options={options}
          onChange={(e: SelectValue) => {
            getValue(e);
          }}
          menuIsOpen={true}
          isSearchable={false}
          value={null}
          {...props}
          classNamePrefix='react-select'
        />
        <div className='invalid-feedback' role='alert'></div>
        <div className='valid-feedback'></div>
      </div>
    </>
  );
};

export default Actions;
