import "./FilterParticularCategory.scss";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import FilterParticularyForm from "../FilterParticularyForm/FilterParticularyForm";

interface Props {}

const FilterParticularCategory: React.FC<Props> = () => {
    return (
        <div className="filter-container-category">
            <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Toate Proprietatile"
            >
                <Row>
                    <Col sm={2} className="tab-section">
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="Toate Proprietatile">
                                    Toate Proprietatile
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Apartamente">
                                    Apartamente
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Case/Vile Terenuri">
                                    Case/Vile Terenuri
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Spatii Birouri">
                                    Spatii Birouri
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Spatii Comerciale">
                                    Spatii Comerciale
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Spatii Industriale">
                                    Spatii Industriale
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Terenuri agricole">
                                    Terenuri agricole
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={10} className="filter-form-section">
                        <Tab.Content>
                            <Tab.Pane eventKey="Toate Proprietatile">
                                <FilterParticularyForm />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Apartamente">
                                <FilterParticularyForm />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Case/Vile Terenuri">
                                <FilterParticularyForm />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Spatii Birouri">
                                <FilterParticularyForm />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Spatii Comerciale">
                                <FilterParticularyForm />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Spatii Industriale">
                                <FilterParticularyForm />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Terenuri agricole">
                                <FilterParticularyForm />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};

export default FilterParticularCategory;
