import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { getImagePath } from '../../helpers/useHelper';
import { RequestListType } from '../../models/Requests';
import Avatar from '../../assets/img/avatar.png';
import ProfileIcon from '../../assets/svg/profileIcon.svg';
import IconEnvelope from '../../icons/IconEnvelope';
import IconPencil from '../../icons/IconPencil';
import IconPhone from '../../icons/IconPhone';
import './RequestListItem.scss';

interface Props {
  item: RequestListType;
  checked?: boolean;
  handleCheckboxChange?: (id: number) => void;
}

const RequestListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
}) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  return (
    item && (
      <div
        className={
          checked
            ? 'announces-list-item request-item active'
            : 'request-item announces-list-item'
        }
      >
        <div className='mobile-lines'>
          <div className='line-border'></div>
          <div className='checkbox-container'>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                checked={checked}
                readOnly
                onClick={() =>
                  handleCheckboxChange && handleCheckboxChange(item.id)
                }
              />
            </Form.Group>
          </div>
          <div className='line-border'></div>
        </div>
        <div className='list-item-content'>
          <div className='column-item'>
            <div className='mobile-text'>ID</div>
            <div className='info-id label-item-mobile'>{item.id}</div>
          </div>
          <div className='column-item'>
            <div className='mobile-text'>Modificat</div>
            <div className='info-date-modify label-item-mobile'>
              {moment(item.dateUpdated).format('DD.MM.YYYY')}
            </div>
          </div>
          <div className='column-item profile-column'>
            {item.contacts?.length > 0 && (
              <>
                <div className='mobile-text'>Contact</div>
                <div className='info-pofile-container'>
                  <div className='info-img'>
                    <img src={ProfileIcon} alt='ProfileIcon' />
                  </div>
                  <div className='info-contact-details'>
                    <div className='name'>
                      {item.contacts[0].name + ' ' + item.contacts[0].surname}
                    </div>
                    <div className='phone-numbers'>
                      {item.contacts[0].phoneNumbers.map(
                        phone =>
                          phone.phone && (
                            <a href={'tel:' + phone.phone} aria-label=''>
                              <IconPhone />
                              {phone.phone}
                            </a>
                          ),
                      )}
                      {item.contacts[0].internationalPhoneNumber && (
                        <a
                          href={
                            'tel:' + item.contacts[0].internationalPhoneNumber
                          }
                          aria-label=''
                        >
                          <IconPhone />
                          {item.contacts[0].internationalPhoneNumber}
                        </a>
                      )}
                    </div>
                    <div className='mail'>
                      {item.contacts[0].emails.map(
                        email =>
                          email && (
                            <a href={'mailto:' + email} aria-label=''>
                              <IconEnvelope />
                              {email}
                            </a>
                          ),
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='column-item'>
            <div className='mobile-text'>Tip Proprietari</div>
            <div className='info-type label-item-mobile'>
              {item.categoryTitle}
            </div>
          </div>
          <div className='column-item'>
            <div className='mobile-text'>Tip tranzactie</div>
            <div className='info-transaction label-item-mobile'>
              {item.transactionType === 'BUY' ? 'Cumparare' : 'Inchiriere'}
            </div>
          </div>
          {(!isMobile || item.description) && (
            <div className='column-item'>
              <div className='mobile-text'>Ce cauta </div>
              <div className='info-search label-item-mobile'>
                {item.description}
              </div>
            </div>
          )}
          {(!isMobile || item.budgetComment) && (
            <div className='column-item'>
              <div className='mobile-text'>Buget/Plata</div>
              <div className='info-budget label-item-mobile'>
                {item.budgetComment}
              </div>
            </div>
          )}
          <div className='column-item'>
            <div className='mobile-text'>Agent</div>
            <div className='info-agent'>
              <div className='info-image'>
                {item.employeeAvatarPath ? (
                  <img src={getImagePath(item.employeeAvatarPath)} alt='' />
                ) : (
                  <img src={Avatar} alt='' />
                )}
              </div>
              <div className='info-name'>{item.employeeFullName}</div>
            </div>
          </div>
          <div className='line-border'></div>
          <div className='info-icons'>
            <button
              className='button-square-md'
              aria-label=''
              onClick={() => navigate('/requests/' + item.id)}
            >
              <IconPencil />
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default RequestListItem;
