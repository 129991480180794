import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { deleteEmailTemplate } from '../../redux/emailTemplates/emailTemplatesThunk';
import { useAppDispatch } from '../../redux/hooks';
import { EmailTemplateListItem } from '../../models/EmailTemplate';
import IconEye from '../../icons/IconEye';
import IconPencil from '../../icons/IconPencil';
import IconTrash from '../../icons/IconTrash';
import ModalConfirm from '../ModalConfirm';
import './EmailTemplatesListItem.scss';

interface Props {
  template: EmailTemplateListItem;
  onShowTemplate: any;
  onEditTemplate: any;
  onDeletedTemplate: any;
  checked: boolean;
  handleCheckboxChange: (id: number) => void;
}

const EmailTemplatesListItem: React.FC<Props> = ({
  template,
  onShowTemplate,
  onEditTemplate,
  onDeletedTemplate,
  checked,
  handleCheckboxChange,
}) => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteTemplate = () => {
    setShowDeleteModal(false);
    dispatch(deleteEmailTemplate(template.id)).then(() => {
      onDeletedTemplate();
    });
  };

  return (
    <>
      <div
        className={`announces-list-item item-email ${checked ? ' active' : ''}`}
      >
        <div className='mobile-lines'>
          <div className='line-border'></div>
          <div className='checkbox-container'>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                checked={checked}
                readOnly
                onClick={() => handleCheckboxChange(template.id)}
              />
            </Form.Group>
          </div>
          <div className='line-border'></div>
        </div>
        <div className='list-item-content'>
          <div className='column-item hide-mobile'>
            <div className='mobile-text'>ID</div>
            <div className='info-text label-item-mobile'>{template.id}</div>
          </div>
          <div className='column-item'>
            <div className='mobile-text'>Nume</div>
            <div className='info-text label-item-mobile'>{template.name}</div>
          </div>
          <div className='column-item'>
            <div className='mobile-text'>Limba</div>
            <div className='info-text label-item-mobile'>
              {template.languageTitle}
            </div>
          </div>
          <div className='column-item'>
            <div className='mobile-text'>Titlu</div>
            <div className='info-text label-item-mobile'>{template.title}</div>
          </div>
          <div className='column-item'>
            <div className='mobile-text'>Descriere</div>
            <div className='info-text label-item-mobile'>
              {template.description}
            </div>
          </div>
          <div className='line-border'></div>
          <div className='info-icons'>
            <button
              className='button-square-md'
              aria-label=''
              onClick={onShowTemplate}
            >
              <IconEye />
            </button>
            <button
              className='button-square-md'
              aria-label=''
              onClick={() => setShowDeleteModal(true)}
            >
              <IconTrash />
            </button>
            <button
              className='button-square-md'
              aria-label=''
              onClick={onEditTemplate}
            >
              <IconPencil />
            </button>
          </div>
        </div>
      </div>
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere email template'
        body='Esti sigur ca doresti sa stergi acest email template?'
        onConfirmed={onDeleteTemplate}
        hideModal={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default EmailTemplatesListItem;
