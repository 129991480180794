import { createSlice } from '@reduxjs/toolkit';
import { ActivityItem } from '../../models/Activities';
import {
  Contact,
  ContactDetailsType,
  LeadSource,
  Tag,
} from '../../models/Contacts';
import { Property, TypeText } from '../../models/Properties';
import {
  createContact,
  editContact,
  getContact,
  getContactTopActivities,
  getContactTopProperties,
  getContacts,
  getContactsTags,
  getContactsTypes,
  getLeadSources,
  getUserContacts,
} from './contactsThunk';

type ContactsState = {
  contacts: Contact[];
  topActivities: ActivityItem[];
  topProperties: Property[];
  leadSources: LeadSource[];
  contactsTags: Tag[];
  contactsTypes: TypeText[];
  contact: ContactDetailsType | null;
  totalCount: number;
  loading: boolean;
  loadingSave: boolean;
  loadingContact: boolean;
  error: any;
};

const initialState: ContactsState = {
  contacts: [],
  topActivities: [],
  topProperties: [],
  leadSources: [],
  contactsTags: [],
  contactsTypes: [],
  totalCount: 0,
  contact: null,
  loading: false,
  loadingSave: false,
  loadingContact: false,
  error: null,
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearTags(state) {
      state.contactsTags = [];
    },
    clearContact(state) {
      state.contact = null;
      state.topProperties = [];
      state.topActivities = [];
    },
    clearContacts(state) {
      state.contacts = [];
    },
    toggleChecked(state, action) {
      const item = state.contacts.find(item => item.id === action.payload);

      if (item) {
        item.checked = !item.checked;
      }
    },
    checkAll(state, action) {
      state.contacts.forEach(item => (item.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getContacts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContacts.fulfilled, (state, { payload }) => {
        state.contacts = payload.data;
        state.totalCount = payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getUserContacts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserContacts.fulfilled, (state, { payload }) => {
        state.contacts = payload.data;
        state.totalCount = payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUserContacts.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getContact.pending, state => {
        state.loadingContact = true;
        state.error = null;
      })
      .addCase(getContact.fulfilled, (state, { payload }) => {
        state.contact = payload;
        state.loadingContact = false;
        state.error = null;
      })
      .addCase(getContact.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingContact = false;
      })
      .addCase(getContactTopProperties.pending, state => {
        state.topProperties = [];
      })
      .addCase(getContactTopProperties.fulfilled, (state, action) => {
        state.topProperties = action.payload;
      })
      .addCase(getContactTopProperties.rejected, state => {
        state.topProperties = [];
      })
      .addCase(getContactTopActivities.pending, state => {
        state.topActivities = [];
      })
      .addCase(getContactTopActivities.fulfilled, (state, action) => {
        state.topActivities = action.payload;
      })
      .addCase(getContactTopActivities.rejected, state => {
        state.topActivities = [];
      })
      .addCase(getContactsTags.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContactsTags.fulfilled, (state, { payload }) => {
        state.contactsTags = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getContactsTags.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getContactsTypes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContactsTypes.fulfilled, (state, { payload }) => {
        state.contactsTypes = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getContactsTypes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getLeadSources.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLeadSources.fulfilled, (state, { payload }) => {
        state.leadSources = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getLeadSources.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createContact.pending, state => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(createContact.fulfilled, (state, { payload }) => {
        state.loadingSave = false;
        state.error = null;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingSave = false;
      })
      .addCase(editContact.pending, state => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(editContact.fulfilled, (state, { payload }) => {
        state.loadingSave = false;
        state.error = null;
      })
      .addCase(editContact.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingSave = false;
      });
  },
});

export const { clearError, clearTags, clearContact, clearContacts, toggleChecked, checkAll } =
  contactsSlice.actions;

export default contactsSlice.reducer;
