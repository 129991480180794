import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ActivityAddModal from '../../components/ActivityAddModal';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input/Input';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from '../../components/Spinner';
import {
  getActivities,
  getActivitiesTypes,
} from '../../redux/activities/activitiesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useDebounce from '../../helpers/useDebounce';
import { getImagePath, getValue, parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import IconSearch from '../../icons/IconSearch';
import AdsAllActivity from '../AdsAllActivity/AdsAllActivity';
import './AdsActivityForm.scss';

interface Props {
  fieldName: string;
  fieldId?: string | number;
}

const AdsActivityForm: React.FC<Props> = ({ fieldName, fieldId }) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityClicked, setActivityClicked] = useState<number | null>(null);
  const [activityFilter, setActivityFilter] = useState<number | null>(null);
  const [activitySearch, setActivitySearch] = useState<string | null>(null);
  const { activitiesTypes, loading, activitiesLoading, totalCount } =
    useAppSelector(state => state.activities);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(Math.ceil(totalCount / pageSize));
  const [requestParams, setRequestParams] = useState<any>({});

  const debounceActivitySearch = useDebounce(activitySearch, 300);

  useEffect(() => {
    dispatch(getActivitiesTypes());
  }, []);

  useEffect(() => {
    if (fieldId) {
      prepareRequestParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, page]);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / pageSize);

      setPageCount(pageCount);
    }
  }, [totalCount]);

  useEffect(() => {
    if (page > 0) {
      setPage(0);
    } else {
      prepareRequestParams();
    }
  }, [activityFilter, debounceActivitySearch]);

  useEffect(() => {
    if (Object.keys(requestParams).length > 0) {
      dispatch(getActivities(requestParams));
    }
  }, [JSON.stringify(requestParams)]);

  const prepareRequestParams = () => {
    setRequestParams({
      [fieldName]: fieldId,
      offset: page + 1,
      limit: pageSize,
      typeId: activityFilter ?? undefined,
      q: activitySearch ?? undefined,
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActivityClicked(null);
    prepareRequestParams();
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  return (
    <div className='display-flex ads-form-container'>
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col xl={12}>
              <h2 className='heading-block'>Adauga Activitate</h2>
            </Col>
            <Col xl={12}>
              <ul className='ads-select-list'>
                {activitiesTypes?.map(type => (
                  <li key={type.id}>
                    <div
                      className='ads-select-list__item'
                      onClick={() => {
                        setIsModalOpen(true);
                        setActivityClicked(type.id);
                      }}
                    >
                      <img src={getImagePath(type.iconPath)} alt={type.title} />
                      <span>{type.title}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </Col>
            <Col xl={12}>
              <fieldset className='row row-16'>
                <Col xl={12}>
                  <legend className='heading-block'>Toate Activitatele</legend>
                </Col>
                <Col xl={4} className='col-ads-sm'>
                  <div className='form-item form-item-search mb-lg'>
                    <Input
                      type='search'
                      placeholder='Cauta Activitate'
                      value={activitySearch ?? ''}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setActivitySearch(e.target.value)
                      }
                    />
                    <button className='form-item-group-icon'>
                      <IconSearch />
                    </button>
                  </div>
                </Col>
                <Col xl='4' className='col-ads-sm'>
                  <div className='form-item mb-lg'>
                    <FormSelect
                      placeholder='Filtreaza'
                      value={
                        activityFilter &&
                        getValue(activitiesTypes, activityFilter)
                      }
                      isClearable={true}
                      smallArrow={true}
                      options={parseValues(activitiesTypes)}
                      onChange={(e: SelectValue) => {
                        if (e) {
                          setActivityFilter(Number(e.value));
                        } else {
                          setActivityFilter(null);
                        }
                      }}
                    />
                  </div>
                </Col>
              </fieldset>
            </Col>
            <Col xl={12} style={{ position: 'relative' }}>
              {activitiesLoading ? (
                <Spinner />
              ) : (
                <>
                  <AdsAllActivity refreshActivities={prepareRequestParams} />
                  {pageCount > 1 && (
                    <Pagination
                      isSelectPage={false}
                      currentPage={page}
                      itemsPerPage={pageSize}
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                      setItemsPerPage={setPageSize}
                    />
                  )}
                </>
              )}{' '}
            </Col>
          </Row>
        </Container>
      )}
      {activityClicked && (
        <ActivityAddModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          activityType={activityClicked}
          fieldName={fieldName}
          fieldId={fieldId}
        />
      )}
    </div>
  );
};

export default AdsActivityForm;
