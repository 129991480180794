import React, { useEffect } from 'react';
import { Coordinate } from '../../models/Properties';
import IconPin from '../../icons/IconPin';
import './MapChoice.scss';

interface Props {
  coordinates: Coordinate;
  setCoordinates: (e: Coordinate) => void;
  className?: string;
}

const MapChoice: React.FC<Props> = ({
  coordinates,
  setCoordinates,
  className,
}) => {
  return (
    <div className={`bg-white block-buttons ${className}`}>
      <h2 className='heading-block'>Amplasament proprietate pe harta</h2>

      <div className='flex-btns'>
        <button
          className={`button-md ${coordinates?.type === 'PIN' ? 'button-blue' : 'button-border'}`}
          onClick={() =>
            setCoordinates({
              ...coordinates,
              type: 'PIN',
              pins: undefined,
              lat:
                coordinates?.lat ?? Number(coordinates?.pins?.[1]?.lat) - 0.01,
              lng:
                coordinates?.lng ?? Number(coordinates?.pins?.[1]?.lng) - 0.01,
            })
          }
        >
          <IconPin />
          Afiseaza Pin
        </button>
        <button
          className={`button-md ${coordinates?.type === 'CIRCLE' ? 'button-blue' : 'button-border'}`}
          onClick={() =>
            setCoordinates({
              ...coordinates,
              type: 'CIRCLE',
              pins: undefined,
              lat:
                coordinates?.lat ?? Number(coordinates?.pins?.[1]?.lat) - 0.01,
              lng:
                coordinates?.lng ?? Number(coordinates?.pins?.[1]?.lng) - 0.01,
            })
          }
        >
          <IconPin />
          Afiseaza Aria
        </button>
      </div>
    </div>
  );
};

export default MapChoice;
