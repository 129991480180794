import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';


// upload image

export const uploadImage = createAsyncThunk<any, any>(
  'auth/uploadImage',
  async (data, { rejectWithValue }) => {
    try {
      let fileType = 'DOCUMENT';
      if (data.file.type.startsWith('image')) {
        fileType = 'IMAGE';
      }
      const response = await appAxios.post(
        'api/v1/crm/media/upload',
        { file: data.file },
        {
          params: { type: fileType },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            data.setProgress(progressEvent.loaded);
          },
        },
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
