import React from 'react';
import './MissingAccess.scss';

interface Props {}

const MissingAccess: React.FC<Props> = () => {
  return (
    <div className='missing-access-container'>
      <p>În acest moment nu ai acces la această secțiune.</p>
      <p>
        Pentru mai multe detalii te rugăm să contactezi Administratorul
        agenției, care îți poate acorda acces în cadrul acestei secțiuni.
      </p>
      <p>O zi frumoasă!</p>
    </div>
  );
};

export default MissingAccess;
