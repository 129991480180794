import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import {
  clearAssociatedContacts,
  clearAssociatedProperties,
  clearAssociatedRequests,
  setAssociatedContacts,
  setAssociatedProperties,
  setAssociatedRequests,
} from '../../redux/activities/activities-slice';
import { getContacts } from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useDebounce from '../../helpers/useDebounce';
import { getValue, parseValues, showError } from '../../helpers/useHelper';
import { ActivityForm } from '../../models/Activities';
import { Contact } from '../../models/Contacts';
import { Property, SelectValue } from '../../models/Properties';
import CalculatorIcon from '../../assets/svg/asociate.svg';
import PersonIcon from '../../assets/svg/personinput.svg';
import IconTimes from '../../icons/IconTimes';
import FormSelect from '../FormSelect';
import Input from '../Input/Input';
import ProprietySelect from '../ProprietySelect';
import Spinner from '../Spinner';
import './AddActivityForm.scss';
import { getRequests } from '../../redux/requests/requestsThunk';
import { RequestListType } from '../../models/Requests';

interface Props {
  saveActivity: () => void;
  resetForm: () => void;
  formData: ActivityForm;
  setFormData: (form: ActivityForm) => void;
}

const AddActivityForm: React.FC<Props> = ({
  saveActivity,
  resetForm,
  formData,
  setFormData,
}) => {
  const dispatch = useAppDispatch();

  const { auth } = useAppSelector(state => state.auth);
  const { users } = useAppSelector(state => state.users);
  const { contacts } = useAppSelector(state => state.contacts);
  const { properties } = useAppSelector(state => state.properties);
  const { requests } = useAppSelector(state => state.requests).requestsListState;
  const {
    activitiesTypes,
    activity,
    error,
    loading,
    associatedContacts,
    associatedProperties,
    associatedRequests
  } = useAppSelector(state => state.activities);

  const [contactsQuery, setContactsQuery] = useState<string | undefined>(
    undefined,
  );
  const [requestsQuery, setRequestsQuery] = useState<string | undefined>(
    undefined,
  );
  const debouncedContactSearch = useDebounce(contactsQuery ?? null, 300);
  const debouncedRequestsSearch = useDebounce(requestsQuery ?? null, 300);

  const typesOptions = [
    { label: 'Popup', value: 'POPUP' },
    { label: 'Email', value: 'EMAIL' },
  ];

  const unitOptions = [
    { label: 'Ore', value: 'HOURS' },
    { label: 'Minute', value: 'MINUTES' },
    { label: 'Zile', value: 'DAYS' },
  ];

  useEffect(() => {
    dispatch(getContacts({limit: 10, offset: 1}));
    dispatch(getRequests({limit: 10, offset: 1}));

    return () => {
      dispatch(clearAssociatedContacts());
      dispatch(clearAssociatedProperties());
      dispatch(clearAssociatedRequests());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    contactsQuery && dispatch(getContacts({ q: contactsQuery, limit: 10 }));
    // eslint-disable-next-line
  }, [debouncedContactSearch]);

  useEffect(() => {
    requestsQuery && dispatch(getRequests({ 'filters[54]': requestsQuery, limit: 10 }));
    // eslint-disable-next-line
  }, [debouncedRequestsSearch]);

  useEffect(() => {
    if (activity) {
      setFormData({
        ...formData,
        dateStart: activity.dateStart,
        dateEnd: activity.dateEnd,
        allDay: activity.allDay,
      });
    } else {
      setFormData({
        ...formData,
        dateStart: undefined,
        dateEnd: undefined,
        allDay: 0,
      });
    }
    // eslint-disable-next-line
  }, [activity]);

  const addNotification = () => {
    setFormData({
      ...formData,
      notifications: formData?.notifications
        ? [
            ...formData?.notifications,
            {
              type: 'POPUP',
              offsetTime: 10,
              durationUnit: 'MINUTES',
            },
          ]
        : [
            {
              type: 'POPUP',
              offsetTime: 10,
              durationUnit: 'MINUTES',
            },
          ],
    });
  };

  const updateNotification = (index: number, key: string, value: string) => {
    formData?.notifications &&
      setFormData({
        ...formData,
        notifications: [
          ...formData?.notifications.map((notification, id) => {
            if (index === id)
              return {
                ...notification,

                [key]: value,
              };
            else return notification;
          }),
        ],
      });
  };

  const removeNotification = (index: number, e: React.MouseEvent) => {
    e.preventDefault();
    formData?.notifications &&
      setFormData({
        ...formData,
        notifications: [
          ...formData?.notifications?.filter(
            (notification, id) => index !== id,
          ),
        ],
      });
  };

  const handleOnChange = (e: any, inputName: string) => {
    switch (inputName) {
      case 'propriety':
        if (!associatedProperties.some(ap => ap.id.toString() === e.value)) {
          setFormData({ ...formData,
            properties: [...(formData?.properties || []), Number(e.value)]
          });

          dispatch(setAssociatedProperties([...associatedProperties,
            properties.find(property => property.id.toString() === e.value)]));
        }
        break;
      case 'requests':
        if (!associatedRequests.some(ar => ar.id.toString() === e.value))
        {
          setFormData({ ...formData,
            requests: [...(formData?.requests || []), Number(e.value)]});

          dispatch(setAssociatedRequests([...associatedRequests,
            requests.find(request => request.id.toString() === e.value)]));
        }
        break;
      case 'contacts' :
        if (!associatedContacts.some(ac => ac.id.toString() === e.value)) {
          setFormData({ ...formData,
            contacts: [...(formData?.contacts || []), Number(e.value)]});

          dispatch(setAssociatedContacts([...associatedContacts,
            contacts.find(contact => contact.id.toString() === e.value)]));
        }
        break;
    }
  }

  return (
    <div className='add-activity-container'>
      <div className='form-container'>
        <div className='form-block'>
          <h4 className='title'>Activitate</h4>
          <form>
            <div className='form-line-section'>
              {!auth?.roles?.includes('EMPLOYEE') && (
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Select Options'
                    inlineLabel='Agentul'
                    isSearchable={true}
                    className={'rotate-arrow'}
                    value={
                      formData?.employeeId
                        ? getValue(users, formData?.employeeId)
                        : null
                    }
                    options={parseValues(users) ?? []}
                    onChange={(e: SelectValue) =>
                      setFormData({
                        ...formData,
                        employeeId: Number(e.value),
                      })
                    }
                  />
                </div>
              )}{' '}
              <div className='form-item'>
                <FormSelect
                  labelText={true}
                  placeholder='Select Options'
                  inlineLabel='Tip'
                  className={error?.typeId ? 'error' : ''}
                  value={
                    formData?.typeId
                      ? getValue(activitiesTypes, formData?.typeId)
                      : null
                  }
                  options={parseValues(activitiesTypes)}
                  onChange={(e: SelectValue) =>
                    setFormData({ ...formData, typeId: Number(e.value) })
                  }
                />{' '}
                {error?.typeId && (
                  <p className='error-message'>{showError(error.typeId)}</p>
                )}
              </div>{' '}
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.title ?? ''}
                  classInput={error?.title ? 'error' : ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  inlineLabel='Titlu'
                />
                {error?.title && (
                  <p className='error-message'>{showError(error.title)}</p>
                )}
              </div>
              <div className='input-group'>
                <ProprietySelect
                  type='text'
                  placeholder='Search'
                  id='ads-more'
                  isSearchable={true}
                  className={'rotate-arrow'}
                  inlineLabel={'Proprietati Asociate'}
                  labelText={true}
                  clearOnSelect={true}
                  onChange={(e: SelectValue) => handleOnChange(e, 'propriety')}
                />

                <div className='add-block'>
                  {associatedProperties.map((item: Property) => {
                    return (
                      <div className='quantity'>
                        <span className=' label-item-rounded-info-sm'>
                          <img src={CalculatorIcon} alt='' />
                          {item.id}{' '}
                        </span>
                        <span
                          className='close'
                          onClick={() => {
                            setFormData({
                              ...formData,
                              properties: formData.properties?.filter(
                                (property: number) => item.id !== property,
                              ),
                            });
                            dispatch(
                              setAssociatedProperties(
                                associatedProperties.filter(
                                  property => property.id !== item.id,
                                ),
                              ),
                            );
                          }}
                        >
                          <IconTimes />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='input-group'>
                <FormSelect
                  type='text'
                  placeholder='Search'
                  id='ads-more'
                  isSearchable={true}
                  className={'rotate-arrow'}
                  inlineLabel={'Solicitări Asociate'}
                  labelText={true}
                  options={parseValues(requests)}
                  onInputChange={(e: string) => {
                    setRequestsQuery(e);
                  }}
                  clearOnSelect={true}
                  onChange={(e: SelectValue) => handleOnChange(e, 'requests')}
                />

                <div className='add-block'>
                  {associatedRequests.map((item: RequestListType) => {
                    return (
                      <div
                        className='quantity'
                        key={'request' + item.id}
                      >
                        <span className=' label-item-rounded-info-sm'>
                          {item?.id}
                        </span>
                        <span
                          className='close'
                          onClick={() => {
                            setFormData({
                              ...formData,
                              requests: formData.requests?.filter(
                                (request: number) => item.id !== request,
                              ),
                            });
                            dispatch(
                              setAssociatedRequests(
                                associatedRequests.filter(
                                  request => request.id !== item.id,
                                ),
                              ),
                            );
                          }}
                        >
                          <IconTimes />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='input-group'>
                <FormSelect
                  type='text'
                  placeholder='Search'
                  id='ads-more'
                  isSearchable={true}
                  className={'rotate-arrow'}
                  inlineLabel={'Contacte Asociate'}
                  labelText={true}
                  options={parseValues(contacts)}
                  onInputChange={(e: string) => {
                    setContactsQuery(e);
                  }}
                  clearOnSelect={true}
                  onChange={(e: SelectValue) => handleOnChange(e, 'contacts')}
                />

                <div className='add-block'>
                  {associatedContacts.map((item: Contact) => {
                    return (
                      <div className='quantity'>
                        <span className=' label-item-rounded-info-sm'>
                          <img src={PersonIcon} alt='' />
                          {item?.name + ' ' + item?.surname}
                        </span>
                        <span
                          className='close'
                          onClick={() => {
                            setFormData({
                              ...formData,
                              contacts: formData.contacts?.filter(
                                (contact: number) => item.id !== contact,
                              ),
                            });
                            dispatch(
                              setAssociatedContacts(
                                associatedContacts.filter(
                                  contact => contact.id !== item.id,
                                ),
                              ),
                            );
                          }}
                        >
                          <IconTimes />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className='form-line-section'>
              <div className='flex-input'>
                <div className='date-picker date'>
                  <label>Data Inceput</label>
                  <div className='flex-range'>
                    <DatePicker
                      selected={
                        formData.dateStart ? new Date(formData.dateStart) : null
                      }
                      onChange={date =>
                        setFormData({
                          ...formData,
                          dateStart: date?.toString(),
                        })
                      }
                      className={error?.dateStart ? 'error' : ''}
                    />
                  </div>
                  {error?.dateStart && (
                    <p className='error-message'>
                      {showError(error.dateStart)}
                    </p>
                  )}
                </div>
                {formData.allDay !== 1 && (
                  <div className='date-picker date'>
                    <label>Ora Inceput</label>
                    <div className='flex-range'>
                      <DatePicker
                        wrapperClassName='hours'
                        selected={
                          formData.dateStart
                            ? new Date(formData.dateStart)
                            : null
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat='HH:mm'
                        timeIntervals={60}
                        dateFormat='HH:mm'
                        timeCaption='time'
                        onChange={date =>
                          setFormData({
                            ...formData,
                            dateStart: date?.toString(),
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='flex-input'>
                <div className='date-picker date'>
                  <label>Data Sfarsit</label>
                  <div className='flex-range'>
                    <DatePicker
                      selected={
                        formData.dateEnd ? new Date(formData.dateEnd) : null
                      }
                      onChange={(date: Date | null) => {
                        if (date !== null) {
                          const endDate = new Date(date);

                          endDate.setHours(23, 59, 59, 999);
                          setFormData({
                            ...formData,
                            dateEnd: endDate.toString(),
                          });
                        }
                      }}
                      className={error?.dateEnd ? 'error' : ''}
                    />
                  </div>
                  {error?.dateEnd && (
                    <p className='error-message'>{showError(error.dateEnd)}</p>
                  )}
                </div>
                {formData.allDay !== 1 && (
                  <div className='date-picker date'>
                    <label>Ora Sfarsit</label>
                    <div className='flex-range'>
                      <DatePicker
                        wrapperClassName='hours'
                        selected={
                          formData.dateEnd ? new Date(formData.dateEnd) : null
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat='HH:mm'
                        timeIntervals={60}
                        dateFormat='HH:mm'
                        timeCaption='time'
                        onChange={date =>
                          setFormData({
                            ...formData,
                            dateEnd: date?.toString(),
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <Form.Group id='formGridCheckbox'>
                <Form.Check
                  type='checkbox'
                  label='Toata Ziua'
                  checked={formData.allDay === 1}
                  readOnly
                  onChange={() =>
                    setFormData({
                      ...formData,
                      allDay: formData.allDay ? 0 ** formData.allDay : 1,
                    })
                  }
                />
              </Form.Group>
              <div className='notification-group'>
                <p>Notificare</p>
                <div className='input-group-flex input-group-flex-sm'>
                  {formData?.notifications?.map((notification, index) => (
                    <div className='input-flex'>
                      <div className='form-item form-item-md'>
                        <FormSelect
                          labelText={false}
                          value={
                            notification.type
                              ? {
                                  value: notification.type,
                                  label: typesOptions.find(
                                    option =>
                                      option.value === notification.type,
                                  )?.label,
                                }
                              : null
                          }
                          options={typesOptions}
                          onChange={(e: SelectValue) =>
                            updateNotification(index, 'type', e.value)
                          }
                        />
                      </div>{' '}
                      <div className='form-item form-item-sm'>
                        <Input
                          value={notification.offsetTime}
                          type='number'
                          max={100}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateNotification(
                              index,
                              'offsetTime',
                              e.target.value,
                            )
                          }
                        />
                      </div>{' '}
                      <div className='form-item form-item-md'>
                        <FormSelect
                          labelText={false}
                          value={
                            notification.durationUnit
                              ? {
                                  value: notification.durationUnit,
                                  label: unitOptions.find(
                                    option =>
                                      option.value ===
                                      notification.durationUnit,
                                  )?.label,
                                }
                              : null
                          }
                          options={unitOptions}
                          onChange={(e: SelectValue) =>
                            updateNotification(index, 'durationUnit', e.value)
                          }
                        />
                      </div>
                      <button
                        className='close'
                        onClick={e => removeNotification(index, e)}
                      >
                        <IconTimes />
                      </button>
                    </div>
                  ))}
                  {(!formData?.notifications ||
                    (formData?.notifications &&
                      formData.notifications.length < 3)) && (
                    <div
                      className='add-notification'
                      onClick={() => addNotification()}
                    >
                      + Adauga Notificare
                    </div>
                  )}
                </div>
              </div>
              <div className='form-item-group'>
                <label className='form-item-group-label' htmlFor=''>
                  Detalii despre Activitate
                </label>
                <div className={`form-item-group-block`}>
                  <textarea
                    name=''
                    id=''
                    className={`form-item-control `}
                    value={formData?.details ?? ''}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setFormData({ ...formData, details: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='btns-container'>
        <button
          className='button-blue button-md'
          onClick={e => {
            e.preventDefault();
            saveActivity();
          }}
        >
          {loading ? <Spinner className='btn-blue-spinner' /> : null}
          Salveaza
        </button>
        <button
          className='reset-btn'
          onClick={e => {
            e.preventDefault();
            resetForm();
          }}
        >
          Reseteaza
        </button>
      </div>
    </div>
  );
};

export default AddActivityForm;
