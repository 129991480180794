import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import PricesHistoryTable from '../../components/PricesHistoryTable';
import { useAppSelector } from '../../redux/hooks';

interface Serie {
  name: string;
  data: number[];
}

const EvolutionBlock: React.FC = () => {
  const { property } = useAppSelector(state => state.properties);
  const [dates, setDates] = useState<string[]>([]);
  const [data, setData] = useState<Serie[]>([]);

  useEffect(() => {
    let list: string[] = [];
    let listData: Serie[] = [];

    property?.prices.forEach(price => {
      let name;
      switch (price.id) {
        case 1:
          name = 'Vanzare';
          break;
        case 2:
          name = 'Inchiriere';
          break;
        case 3:
          name = 'Regim hotelier';
          break;
        case 4:
          name = 'Vanzare MP';
          break;
        case 5:
          name = 'Inchiriere MP';
          break;
        default:
          name = '';
      }
      price.history.forEach((date: any) => {
        if (!dates.includes(moment(date.dateCreated).format('DD-MM-YYYY'))) {
          list.push(moment(date.dateCreated).format('DD-MM-YYYY'));
        }
      });
      listData.push({
        name: name,
        data: price.history?.map((value: any) => value.price),
      });
    });
    setDates(list);
    setData(listData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  const graph = {
    options: {
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#FF1654', '#247BA0'],

      stroke: {
        width: [4, 4],
      },
      plotOptions: {
        bar: {
          columnWidth: '20%',
        },
      },
      xaxis: {
        categories: dates,
      },
      yaxis: [
        {
          axisBorder: {
            show: true,
            color: '#FF1654',
          },
          labels: {
            style: {
              colors: '#FF1654',
            },
          },
          title: {
            text: 'Pret',
            style: {
              color: '#FF1654',
            },
          },
        },
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false,
        },
      },
      legend: {
        offsetX: 40,
      },
    },
    series: data,
  };

  return (
    <Row className='grafic-container'>
      <Col lg='12'>
        <h2 className='heading-block'>GRAFIC EVOLUTIE PRET</h2>
      </Col>
      <Col lg='12'>
        <div className='grafic-block'>
          {property?.prices.filter(price => price?.history?.length > 0) &&
            property?.prices.filter(price => price?.history?.length > 0)
              ?.length > 0 && (
              <Chart
                options={graph.options}
                series={graph.series}
                type='line'
                width='100%'
              />
            )}
        </div>
      </Col>
      <Col lg='12'>
        {property?.prices.filter(price => price?.history?.length > 0) &&
          property?.prices.filter(price => price?.history?.length > 0)?.length >
            0 && <PricesHistoryTable prices={property?.prices} />}
      </Col>
    </Row>
  );
};

export default EvolutionBlock;
