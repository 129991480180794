import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useAppSelector } from '../../redux/hooks';
import useDebounce from '../../helpers/useDebounce';
import { getValue, parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import Search from '../../assets/svg/Search.svg';
import FormSelect from '../FormSelect';
import Input from '../Input';
import './FilterListContacts.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
}

const FilterListContacts: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  filtersForm,
  setFiltersForm,
}) => {
  const [query, setQuery] = useState('');
  const { contactsTypes } = useAppSelector(state => state.contacts);
  const debouncedTitleSearch = useDebounce(query ?? '', 300);

  useEffect(() => {
    setFiltersForm({ ...filtersForm, q: debouncedTitleSearch });
  }, [debouncedTitleSearch]);

  return (
    <div className='filter-list-container contact-list-filter'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
        </Form.Group>
        <div className='filter-item'>
          <div className='search-container'>
            <label className='form-item-group-label'>Contact</label>
            <div className='form-item-group-block '>
              <span className='search-before'>Cautare rapida</span>
              <div className='form-item'>
                <Input
                  labelText={false}
                  value={query}
                  type={'search'}
                  placeholder='Nume, prenume, telefon, email'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setQuery(e?.target?.value)
                  }
                />
              </div>
              <img className='icon' src={Search} alt='' />
            </div>
          </div>
        </div>
        <div className='filter-item'>
          <FormSelect
            labelText={true}
            placeholder='Select Option'
            inlineLabel={'Tip contact'}
            isSearchable={true}
            isClearable={true}
            value={
              filtersForm?.type && getValue(contactsTypes, filtersForm?.type)
            }
            options={contactsTypes?.length > 0 && parseValues(contactsTypes)}
            onChange={(e: SelectValue) =>
              setFiltersForm({
                ...filtersForm,
                type: e ? Number(e.value) : undefined,
              })
            }
          />{' '}
        </div>
        <div className='filter-item'>
          <p></p>
        </div>
      </form>
    </div>
  );
};

export default FilterListContacts;
