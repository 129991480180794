import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import useDebounce from '../../helpers/useDebounce';
import { getValue, parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import ArrowFilter from '../../assets/svg/arrowfilter.svg';
import IconPlus from '../../icons/IconPlus';
import FormSelect from '../FormSelect';
import './Calendar.scss';

interface Props {
  activeDate: any;
  setActiveDate: (e: any) => void;
  selectedUser: any;
  setSelectedUser: (e: any) => void;
}

const CalendarItem: React.FC<Props> = ({
  activeDate,
  setActiveDate,
  selectedUser,
  setSelectedUser,
}: Props) => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector(state => state.users);
  const [usersQuery, setUsersQuery] = useState('');
  const [prevUsersQuery, setPrevUsersQuery] = useState('');
  const debouncedUserSearch = useDebounce(usersQuery, 300);
  const navigate = useNavigate();

  useEffect(() => {
    if (prevUsersQuery !== usersQuery) {
      dispatch(getUsers({ q: usersQuery ?? null }));
      setPrevUsersQuery(usersQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUserSearch]);

  const tileDisabled = ({ date, view, activeStartDate }: any) => {
    if (view === 'month') {
      const viewedMonth = activeStartDate.getMonth();
      const dateMonth = date.getMonth();

      return viewedMonth !== dateMonth;
    }
    return false;
  };

  const allUsersOption = { value: 'ALL_USERS', label: 'Toti agentii' };
  const userOptions = parseValues(users) ?? [];
  const options = [allUsersOption, ...userOptions];

  return (
    <div className='activity-calendar-container'>
      <div className='filter-agent-container'>
        <FormSelect
          type='text'
          labelText={true}
          placeholder='Select Options'
          inlineLabel='Filtreaza dupa Agentii'
          isSearchable={true}
          isClearable={true}
          className={'custom-select'}
          value={selectedUser ?? null}
          options={options}
          onInputChange={(e: string) => {
            setUsersQuery(e);
          }}
          clearOnSelect={false}
          onChange={(e: SelectValue) => {
            setSelectedUser(
              e?.value === 'ALL_USERS' ? e : getValue(users, e?.value) || null,
            );
          }}
        />

        <div className='arrow-btn'>
          <img src={ArrowFilter} alt='' />
        </div>
      </div>
      <div className='calendar-container'>
        <Calendar
          onChange={e => {
            setActiveDate({ date: e, changeView: true });
          }}
          value={activeDate.date}
          locale={'ro'}
          tileDisabled={tileDisabled}
        />
        <div className='add-btn' onClick={() => navigate('/add-activity')}>
          <IconPlus />
        </div>
      </div>
    </div>
  );
};

export default CalendarItem;
