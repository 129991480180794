import { createSlice } from '@reduxjs/toolkit';
import {
  RequestDetails,
  RequestListType,
  SingleRequest,
} from '../../models/Requests';
import {
  createRequest,
  getRequest,
  getRequestFilters,
  getRequests,
  sendEmail,
  updateRequest,
} from './requestsThunk';

type PropertiesState = {
  request: RequestDetails | null;
  loadingSave: boolean;
  error: any;
  requestsListState: {
    requests: RequestListType[];
    loading: boolean;
    error: any;
    totalCount: number;
  };
  requestsFilterState: {
    filters: any[];
    loading: boolean;
    error: any;
  };
  createRequestState: {
    request: RequestDetails | null;
    loadingSave: boolean;
    error: any;
  };
  updateRequestState: {
    request: SingleRequest | null;
    loadingSave: boolean;
    error: any;
  };
  getRequestState: {
    request: SingleRequest | null;
    loading: boolean;
    error: any;
  };
  sendEmailState: {
    loading: boolean;
    error: any;
  };
};

const initialState: PropertiesState = {
  request: null,
  loadingSave: false,
  error: null,
  requestsListState: {
    requests: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  requestsFilterState: {
    filters: [],
    loading: false,
    error: null,
  },
  createRequestState: { request: null, loadingSave: false, error: null },
  updateRequestState: { request: null, loadingSave: false, error: null },
  getRequestState: { request: null, loading: false, error: null },
  sendEmailState: {
    loading: false,
    error: null,
  },
};

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    clearError(state) {
      state.createRequestState.error = null;
    },
    clearRequests(state) {
      state.requestsListState.requests = [];
    },
    toggleChecked(state, action) {
      const item = state.requestsListState.requests.find(item => item.id === action.payload);

      if (item) {
        item.checked = !item.checked;
      }
    },
    checkAll(state, action) {
      state.requestsListState.requests.forEach(item => (item.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createRequest.pending, state => {
        state.createRequestState.loadingSave = true;
        state.createRequestState.error = null;
      })
      .addCase(createRequest.fulfilled, (state, { payload }) => {
        state.createRequestState.request = payload;
        state.createRequestState.loadingSave = false;
        state.createRequestState.error = null;
      })
      .addCase(createRequest.rejected, (state, action) => {
        state.createRequestState.error = action.payload;
        state.createRequestState.loadingSave = false;
      })
      .addCase(getRequests.pending, state => {
        state.requestsListState.loading = true;
        state.requestsListState.error = null;
      })
      .addCase(getRequests.fulfilled, (state, { payload }) => {
        state.requestsListState.requests = payload.data;
        state.requestsListState.totalCount = payload.totalCount;
        state.requestsListState.loading = false;
        state.requestsListState.error = null;
      })
      .addCase(getRequests.rejected, (state, action) => {
        state.requestsListState.error = action.payload;
        state.requestsListState.loading = false;
      })
      .addCase(getRequestFilters.pending, state => {
        state.requestsFilterState.loading = true;
        state.requestsFilterState.error = null;
      })
      .addCase(getRequestFilters.fulfilled, (state, { payload }) => {
        state.requestsFilterState.filters = payload;
        state.requestsFilterState.loading = false;
        state.requestsFilterState.error = null;
      })
      .addCase(getRequestFilters.rejected, (state, action) => {
        state.requestsFilterState.error = action.payload;
        state.requestsFilterState.loading = false;
        state.createRequestState.error = action.payload;
        state.createRequestState.loadingSave = false;
      })
      .addCase(getRequest.pending, state => {
        state.getRequestState.loading = true;
        state.getRequestState.error = null;
      })
      .addCase(getRequest.fulfilled, (state, { payload }) => {
        state.getRequestState.request = payload;
        state.getRequestState.loading = false;
        state.getRequestState.error = null;
      })
      .addCase(getRequest.rejected, (state, action) => {
        state.getRequestState.error = action.payload;
        state.getRequestState.loading = false;
      })
      .addCase(updateRequest.pending, state => {
        state.updateRequestState.loadingSave = true;
        state.updateRequestState.error = null;
      })
      .addCase(updateRequest.fulfilled, (state, { payload }) => {
        state.updateRequestState.request = payload;
        state.updateRequestState.loadingSave = false;
        state.updateRequestState.error = null;
      })
      .addCase(updateRequest.rejected, (state, action) => {
        state.updateRequestState.error = action.payload;
        state.updateRequestState.loadingSave = false;
      })
      .addCase(sendEmail.pending, state => {
        state.sendEmailState.loading = true;
        state.sendEmailState.error = null;
      })
      .addCase(sendEmail.fulfilled, state => {
        state.sendEmailState.loading = false;
        state.sendEmailState.error = null;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.sendEmailState.error = action.payload;
        state.sendEmailState.loading = false;
      });
  },
});

export const { clearError, clearRequests, toggleChecked, checkAll } = requestsSlice.actions;

export default requestsSlice.reducer;
