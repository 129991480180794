import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ActivityAddModal from '../../components/ActivityAddModal';
import ActivityInfoModal from '../../components/ActivityInfoModal';
import Calendar from '../../components/Calendar';
import FullCalendar from '../../components/FullCalendar';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getActivities } from '../../redux/activities/activitiesThunk';
import { getContacts } from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './ActivityCalendar.scss';

interface Props {}

const ActivityCalendar: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { contacts } = useAppSelector(state => state.contacts);
  const { auth } = useAppSelector(state => state.auth);
  const { activitiesLoading } = useAppSelector(state => state.activities);
  const [activeDate, setActiveDate] = useState<any>({
    date: null,
    changeView: false,
  });
  const [activityId, setActivityId] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any | null>({
    label: auth?.profile?.fullName,
    value: auth?.profile?.id,
  });

  useEffect(() => {
    dispatch(
      getActivities(
        selectedUser && selectedUser.value === 'ALL_USERS'
          ? {}
          : selectedUser
            ? { employee: selectedUser?.value }
            : {},
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  useEffect(() => {
    contacts.length === 0 && dispatch(getContacts({ limit: -1 }));
    dispatch(getUsers({ q: null }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Calendar Evenimente'
          subtitle='Gestioneza din aceasta sectiune toate activitatele'
          isbutton={haveUserPermission(Permissions.ActivityCalendar)}
          btnEvent={() => navigate('/add-activity')}
          btnText='Adauga Activitate'
        />
        {haveUserPermission(Permissions.ActivityCalendar) ? (
          activitiesLoading ? (
            <Spinner />
          ) : (
            <div className='display-flex flex-calendar-activity'>
              <Calendar
                activeDate={activeDate}
                setActiveDate={setActiveDate}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
              <FullCalendar
                activeDate={activeDate}
                setIsModalOpen={setIsModalOpen}
                setActiveDate={setActiveDate}
                setActivityId={setActivityId}
                setInfoModalOpen={setInfoModalOpen}
              />
            </div>
          )
        ) : (
          <MissingAccess />
        )}
      </div>
      {isModalOpen && (
        <ActivityAddModal
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          activityType={null}
          date={activeDate.date}
          fieldName={'contact'}
        />
      )}
      <ActivityInfoModal
        isModalOpen={infoModalOpen}
        closeModal={() => {
          setInfoModalOpen(false);
          setActivityId(null);
        }}
        activityId={activityId}
      />
    </div>
  );
};

export default ActivityCalendar;
