import React from 'react';
import Stackicon from '../../assets/svg/penicon.svg';
import SaveIcon from '../../assets/svg/saveitem.svg';
import GlobeIcon from '../../assets/svg/shareitem.svg';
import WarningIcon from '../../assets/svg/trash.svg';
import './ActionsMobileProperty.scss';

interface Props {
  removeItems?: () => void;
  exportItems?: () => void;
  editItems?: () => void;
  arhiveItems?: () => void;
  restoreItems?: () => void;
}
const ActionsMobileProperty: React.FC<Props> = ({
  removeItems,
  editItems,
  restoreItems,
  arhiveItems,
  exportItems,
}) => {
  return (
    <>
      <div className='actions-container'>
        {editItems && (
          <div
            className='action-item'
            onClick={e => {
              e.preventDefault();
              editItems();
            }}
          >
            <p>Modifica anunt </p>
            <img src={Stackicon} alt='Stackicon' />
          </div>
        )}
        {exportItems && (
          <div
            className='action-item'
            onClick={e => {
              e.preventDefault();
              exportItems();
            }}
          >
            <p>Exporta</p>
            <img src={GlobeIcon} alt='Phoneicon' />
          </div>
        )}
        {arhiveItems && (
          <div
            className='action-item'
            onClick={e => {
              e.preventDefault();
              arhiveItems();
            }}
          >
            <p>Arhiveaza</p>
            <img src={SaveIcon} alt='Phoneicon' />
          </div>
        )}

        {removeItems && (
          <div
            className='action-item'
            onClick={e => {
              e.preventDefault();
              removeItems();
            }}
          >
            <p>Sterge</p>
            <img src={WarningIcon} alt='WarningIcon' />
          </div>
        )}
        {restoreItems && (
          <div
            className='action-item'
            onClick={e => {
              e.preventDefault();
              restoreItems();
            }}
          >
            <p>Restaureaza</p>
            <img src={SaveIcon} alt='SaveIcon' />
          </div>
        )}
      </div>
    </>
  );
};

export default ActionsMobileProperty;
