import { createSlice } from '@reduxjs/toolkit';
import { Specialization } from '../../models/Specialization';
import { getSpecializations } from './specializationThunk';

type SpecializationState = {
  specializations: Specialization[];
  loading: boolean;
  error: any;
};

const initialState: SpecializationState = {
  specializations: [],
  loading: false,
  error: null,
};

const specializationsSlice = createSlice({
  name: 'specializations',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSpecializations.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSpecializations.fulfilled, (state, { payload }) => {
        state.specializations = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSpecializations.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = specializationsSlice.actions;

export default specializationsSlice.reducer;
