import { createSlice } from '@reduxjs/toolkit';
import {
  EmailTemplate,
  EmailTemplateListItem,
} from '../../models/EmailTemplate';
import {
  addEmailTemplate,
  deleteEmailTemplate,
  editEmailTemplate,
  getEmailTemplate,
  getEmailTemplates,
} from './emailTemplatesThunk';

type EmailTemplatesState = {
  emailTemplates: EmailTemplateListItem[];
  emailTemplate: null | EmailTemplate;
  count: number;
  loadingTemplates: boolean;
  loadingTemplate: boolean;
  error: any;
};

const initialState: EmailTemplatesState = {
  emailTemplates: [],
  count: 0,
  emailTemplate: null,
  loadingTemplates: false,
  loadingTemplate: false,
  error: null,
};

const emailTemplatesSlice = createSlice({
  name: 'emailTemplates',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearTemplate(state) {
      state.emailTemplate = null;
    },
    toggleChecked(state, action) {
      const item = state.emailTemplates.find(item => item.id === action.payload);

      if (item) {
        item.checked = !item.checked;
      }
    },
    checkAll(state, action) {
      state.emailTemplates.forEach(item => (item.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getEmailTemplates.pending, state => {
        state.loadingTemplates = true;
        state.error = null;
      })
      .addCase(getEmailTemplates.fulfilled, (state, { payload }) => {
        state.emailTemplates = payload.emailTemplates;
        state.count = payload.totalCount;
        state.loadingTemplates = false;
        state.error = null;
      })
      .addCase(getEmailTemplates.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplates = false;
      })
      .addCase(getEmailTemplate.pending, state => {
        state.emailTemplate = null;
        state.loadingTemplate = true;
        state.error = null;
      })
      .addCase(getEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate = payload;
        state.loadingTemplate = false;
        state.error = null;
      })
      .addCase(getEmailTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplate = false;
      })
      .addCase(addEmailTemplate.pending, state => {
        state.loadingTemplate = true;
        state.error = null;
      })
      .addCase(addEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate = payload;
        state.loadingTemplate = false;
        state.error = null;
      })
      .addCase(addEmailTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplate = false;
      })
      .addCase(editEmailTemplate.pending, state => {
        state.loadingTemplate = true;
        state.error = null;
      })
      .addCase(editEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate = payload;
        state.loadingTemplate = false;
        state.error = null;
      })
      .addCase(editEmailTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplate = false;
      })

      .addCase(deleteEmailTemplate.pending, state => {
        state.loadingTemplates = true;
        state.error = null;
      })
      .addCase(deleteEmailTemplate.fulfilled, state => {
        state.loadingTemplates = false;
        state.error = null;
      })
      .addCase(deleteEmailTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingTemplates = false;
      });
  },
});

export const { clearError, clearTemplate, toggleChecked, checkAll } = emailTemplatesSlice.actions;

export default emailTemplatesSlice.reducer;
