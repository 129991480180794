import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconDisketteStrong from '../../icons/IconDisketteStrong';
import IconNext from '../../icons/IconNext';
import IconPrev from '../../icons/IconPrev';
import Spinner from '../Spinner';
import './Buttons.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  btnSize?: string;
  nextStep: () => void;
  submit: () => void;
  submitLoading?: boolean;
  disabledNext?: boolean;
  // labelText?: boolean;
  // inlineLabel?: string
}

const Buttons: React.FC<Props> = ({
  onChange,
  nextStep,
  submit,
  disabledNext = false,
  submitLoading = false,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className='form-buttons bg-white ads-general-buttons'>
        {!disabledNext && (
          <div
            onClick={() => {
              nextStep();
              window.scrollTo(0, 0);
            }}
            className='button-border button-lg ads-general-button-next'
          >
            <span className='icon'>
              <IconNext />
            </span>
            <span className=''>Pasul urmator</span>
          </div>
        )}
        <div
          onClick={() => submit()}
          className='button-blue button-lg ads-general-button-save'
        >
          {submitLoading ? (
            <span className='icon loading'>
              <Spinner className='btn-blue-spinner' />
            </span>
          ) : (
            <span className='icon'>
              <IconDisketteStrong />
            </span>
          )}
          <span>Salveaza</span>
        </div>
        <div
          onClick={() => navigate(-1)}
          className='button-border button-lg ads-general-button-back '
        >
          <span className='icon'>
            <IconPrev />
          </span>
          <span className=''>Inapoi</span>
        </div>
      </div>
    </>
  );
};

export default Buttons;
