import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Input from '../../components/Input/Input';
import { useAppSelector } from '../../redux/hooks';
import { Media } from '../../models/Properties';
import { ResidentialComplexForm } from '../../models/ResidentialComplexes';
import ImagesForm from '../ImagesForm';
import './AddImagesAreaPriceForm.scss';


interface Props {
  formData: ResidentialComplexForm;
  formDataErrors: any;
  setFormData: (form: ResidentialComplexForm) => void;
}

const AddImagesAreaPriceForm: React.FC<Props> = ({
  formData,
  formDataErrors,
  setFormData,
}) => {
  const { residentialComplex } = useAppSelector(
    state => state.residentialComplexes,
  );
  const [priceList, setPriceList] = useState<Media[]>([]);
  const [constructionPlans, setConstructionPlans] = useState<Media[]>([]);
  const [technicalSpecifications, setTechnicalSpecifications] = useState<
    Media[]
  >([]);

  useEffect(() => {
    setPriceList(
      residentialComplex?.media?.filter(
        mediaFile => mediaFile.type === 'PRICE_LIST',
      ) ?? [],
    );

    setConstructionPlans(
      residentialComplex?.media?.filter(
        mediaFile => mediaFile.type === 'CONSTRUCTION_PLANS',
      ) ?? [],
    );

    setTechnicalSpecifications(
      residentialComplex?.media?.filter(
        mediaFile => mediaFile.type === 'TECHNICAL_SPECIFICATIONS',
      ) ?? [],
    );
  }, [residentialComplex]);

  useEffect(() => {
    let imagesObject: Media[] = [];

    priceList.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;
      imagesObject.push({ ...image, mediaId: mediaId, type: 'PRICE_LIST' });
    });
    constructionPlans.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;

      imagesObject.push({
        ...image,
        mediaId: mediaId,
        type: 'CONSTRUCTION_PLANS',
      });
    });
    technicalSpecifications.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;

      imagesObject.push({
        ...image,
        mediaId: mediaId,
        type: 'TECHNICAL_SPECIFICATIONS',
      });
    });
    setFormData({ ...formData, media: imagesObject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceList, constructionPlans, technicalSpecifications]);

  return (
    <div className='flex-container add-images-area-form display-flex'>
      <Container>
        <Row className='row-91'>
          <Col xl='12' className='content-block mb-3'>
            <h2 className='heading-block'>Suprafata/Pret</h2>
          </Col>
          <Col lg='4'>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Suprafata'
                type='text'
                max={60}
                id='surface'
                value={formData.rangeSurface}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, rangeSurface: e.target.value })
                }
                error={formDataErrors?.rangeSurface}
              />
            </div>
          </Col>
          <Col lg='4'>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Preturi'
                type='text'
                placeholder='Ex: 38.000-70.000 Euro'
                max={60}
                id='video-2'
                value={formData.rangePrice}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, rangePrice: e.target.value })
                }
                error={formDataErrors?.rangePrice}
              />
            </div>
          </Col>
          <Col lg='4'>
            <div className='form-item'>
              <fieldset className='form-item-block'>
                <div className='col-label-lg'>
                  <legend className='form-item-group-label'>
                    Preturi (In Euro)
                  </legend>
                </div>

                <div className='col-half'>
                  <Input
                    labelText={false}
                    inlineLabel=''
                    placeholder='Min'
                    type='text'
                    max={60}
                    id='min'
                    value={formData.rangePriceFrom}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormData({
                        ...formData,
                        rangePriceFrom: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='col-half'>
                  <Input
                    labelText={false}
                    inlineLabel=''
                    placeholder='Max'
                    type='text'
                    max={60}
                    id='max'
                    value={formData.rangePriceTo}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormData({
                        ...formData,
                        rangePriceTo: e.target.value,
                      })
                    }
                  />
                </div>
              </fieldset>
            </div>
          </Col>
          <Col lg='4'>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Nr. Camere'
                placeholder='Ex: Garsoniere, 2, 3 camere'
                type='text'
                max={60}
                id='no-rooms'
                value={formData.rooms}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, rooms: e.target.value })
                }
                error={formDataErrors?.rooms}
              />
            </div>
          </Col>
          <Col lg='12'>
            <hr className='fieldset-block__hr' />
          </Col>

          <Col lg='12' className='content-block'>
            <legend className='heading-block'>Upload fisiere</legend>
          </Col>
        </Row>
        <Row className='row-38'>
          <Col lg={4} className='fieldset-br'>
            <div className='heading-block-sm'>
              <h3 className='title'>Lista preturi</h3>
            </div>
            <ImagesForm
              images={priceList}
              setImages={setPriceList}
              onlyDocAndPdf={true}
            />
          </Col>
          <Col lg={4} className='fieldset-br'>
            <div className='heading-block-sm'>
              <h3 className='title'>Planurile constructiei</h3>
            </div>
            <ImagesForm
              images={constructionPlans}
              setImages={setConstructionPlans}
              onlyDocAndPdf={true}
            />
          </Col>
          <Col lg={4}>
            <div className='heading-block-sm'>
              <h3 className='title'>Specificatii tehnice</h3>
            </div>
            <ImagesForm
              images={technicalSpecifications}
              setImages={setTechnicalSpecifications}
              onlyDocAndPdf={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddImagesAreaPriceForm;
