import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';

import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input';

import { getSaleBlock } from '../../helpers/useHelper';

import {
  Price,
  PropertyForm,
  SaleBlockType,
  SelectValue,
} from '../../models/Properties';

interface Props {
  type: string;
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
  handleChange: (key: string, value: SelectValue | null) => void;
  handleNumber: (key: string, value: number) => void;
}

const CommisionSaleBlock: React.FC<Props> = ({
  type,
  formData,
  handleChange,
  handleNumber,
  setFormData,
}) => {
  const [salePrice, setSalePrice] = useState<Price | null>(
    formData?.prices?.find((price: Price) => price.id === 1) ??
      formData?.prices?.find((price: Price) => price.id === 4) ??
      null,
  );
  const [saleBlock, setSaleBlock] = useState<SaleBlockType | null>(
    getSaleBlock(formData?.prices?.find((price: Price) => price.id === 1)) ??
      getSaleBlock(formData?.prices?.find((price: Price) => price.id === 4)) ??
      null,
  );
  const [saleContractSigningDate, setSaleContractSigningDate] =
    useState<Date | null>(
      salePrice?.saleContractSigningDate
        ? new Date(salePrice?.saleContractSigningDate)
        : null,
    );

  useEffect(() => {
    setSalePrice(
      formData?.prices?.find((price: Price) => price.id === 1) ??
        formData?.prices?.find((price: Price) => price.id === 4) ??
        null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.prices]);

  useEffect(() => {
    setSaleContractSigningDate(
      saleBlock?.saleContractSigningDate
        ? new Date(saleBlock?.saleContractSigningDate)
        : null,
    );
    const newPrices =
      formData?.prices?.map(price => {
        if (price.id === 1 || price.id === 4) {
          return { ...price, ...saleBlock };
        } else return price;
      }) ?? [];

    setFormData({
      ...formData,
      prices: newPrices,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleBlock]);

  const comOptions = [
    { value: 'da', label: 'Da' },
    { value: 'nu', label: 'Nu' },
  ];

  const handleChangeSale = (
    key: string,
    e: string | boolean | number | null,
  ) => {
    setSaleBlock({
      ...saleBlock,
      [key]: e,
    });
  };

  const handleDateSale = (e: Date | null) => {
    setSaleBlock({
      ...saleBlock,
      saleContractSigningDate: e ? moment(e).format('YYYY-MM-DD') : '',
    });
  };

  useEffect(() => {
    salePrice?.sellingPrice &&
      saleBlock?.salePercentageCommission &&
      handleChangeSale(
        'saleCommission',
        (salePrice?.sellingPrice / 100) * saleBlock?.salePercentageCommission,
      ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salePrice?.sellingPrice, saleBlock?.salePercentageCommission]);

  return (
    <fieldset className='row fieldset-block'>
      <Col lg='12'>
        <legend className='heading-block'>
          COMISION AGENT - {type.toUpperCase()}
          <span className='span-title'> (vizibil in portaluri)</span>
        </legend>
      </Col>
      <Col lg='12' className='commision-group-container'>
        <Col lg='4' className='fieldset-br'>
          <div className='form-item'>
            <FormSelect
              labelText={true}
              placeholder='Select Options'
              inlineLabel={'Contract de ' + type + '<br/>Comision Semnat '}
              isSearchable={true}
              value={{
                value:
                  saleBlock?.hasSellingContract === 1 ||
                  saleBlock?.hasSellingContract === true
                    ? true
                    : false,
                label:
                  saleBlock?.hasSellingContract === 1 ||
                  saleBlock?.hasSellingContract === true
                    ? 'Da'
                    : 'Nu',
              }}
              options={comOptions}
              onChange={(e: SelectValue) =>
                handleChangeSale(
                  'hasSellingContract',
                  e.value === 'da' ? true : false,
                )
              }
            />
          </div>
        </Col>
        <Col lg='4' className='fieldset-br'>
          <div className='form-item'>
            <Input
              labelText={true}
              type='number'
              inlineLabel={'Comision ' + type + '(%)'}
              placeholder='In procent - ex: 1%'
              value={saleBlock?.salePercentageCommission ?? ''}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeSale(
                  'salePercentageCommission',
                  Number(e.target.value),
                )
              }
              id='commission-proc'
            />
            <Input
              labelText={true}
              inlineLabel='Comision Calculat'
              value={saleBlock?.saleCommission ?? ''}
              disabled
            />
          </div>
        </Col>
        <Col lg='4'>
          <div className='form-item'>
            <Input
              labelText={true}
              inlineLabel={'Nr.contract<br/>*' + type}
              value={saleBlock?.saleContractNumber ?? ''}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeSale('saleContractNumber', e.target.value)
              }
              id='commission-proc'
            />
            <div className='form-item'>
              <div className='date-picker'>
                <label>Data semnarii</label>
                <DatePicker
                  selected={saleContractSigningDate}
                  onChange={date => handleDateSale(date)}
                />
              </div>
            </div>
          </div>
        </Col>
      </Col>
      <Col lg='12'>
        *Prin completarea acestor date, declarati pe proprie raspundere ca
        informatiile cu privire la contract sunt reale.
        <hr className='fieldset-block__hr' />
      </Col>
    </fieldset>
  );
};

export default CommisionSaleBlock;
