import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailTemplateListItem } from '../../models/EmailTemplate';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getCurrencies = createAsyncThunk<any>(
  'crm/getCurrencies',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/currencies', {
        params: data,
      });
      const totalCount = response.headers['x-total-count'];

      return { currencies: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
