interface Props {}

const IconArrowUpRight: React.FC<Props> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9932 1.049C11.9932 0.5 11.5493 0.053 10.9993 0.05L3.00625 0H3.00025C2.45025 0 2.00425 0.444 2.00025 0.994C1.99625 1.546 2.44125 1.997 2.99425 2L8.55225 2.035L0.29325 10.293C-0.09775 10.684 -0.09775 11.317 0.29325 11.707C0.48825 11.903 0.74425 12 1.00025 12C1.25625 12 1.51225 11.903 1.70725 11.707L9.99525 3.419L10.0002 9.001C10.0002 9.553 10.4482 10 11.0002 10H11.0013C11.5533 10 12.0002 9.551 12.0002 8.999L11.9932 1.049Z"
      />
    </svg>
  );
};

export default IconArrowUpRight;
