import { Link } from 'react-router-dom';
import './NotificationsCards.scss';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getActiveAgentsCount,
  getLastAnnouncesCount,
  getLastRequestsCount,
} from '../../redux/dashboard/dashboardThunk';
import IconAnnounces from '../../icons/IconAnnounces';
import IconFile from '../../icons/IconFile';
import IconPersons from '../../icons/IconPersons';

interface Props {}

const NotificationsCards: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const { activeAgentsCount , lastAnnouncesCount, lastRequestsCount} = useAppSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(getActiveAgentsCount());
    dispatch(getLastRequestsCount());
    dispatch(getLastAnnouncesCount());

  }, [])

  return (
    <div className='notifications-cards-container display-flex'>
      <Link to='/ads' className='notification-item'>
        <IconAnnounces/>
        <span>{lastAnnouncesCount}</span>
        <p>Anunturi noi in ultimele 24 ore</p>
      </Link>
      <Link to='/requests' className='notification-item'>
        <IconFile/>
        <span>{lastRequestsCount}</span>
        <p>Solicitări noi in ultimele 24 ore</p>
      </Link>
      <Link to='/agents' className='notification-item'>
        <IconPersons/>
        <span>{activeAgentsCount}</span>
        <p>Agenti activi</p>
      </Link>
    </div>
  );
};

export default NotificationsCards;
