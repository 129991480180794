import React, { useState } from 'react';
import { showError } from '../../helpers/useHelper';
import IconClear from '../../icons/IconClear';
import IconEye from '../../icons/IconEye';
import IconEyeCut from '../../icons/IconEyeCut';
import './Input.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  info?: string;
  labelText?: boolean;
  inlineLabel?: string;
  id?: string;
  classInput?: string;
  error?: string;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isPassword?: boolean;
  icon?: any;
  removeItem?: () => void;
  isClearable?: boolean;
}

const Input: React.FC<Props> = ({
  onChange,
  labelText,
  inlineLabel = '',
  classInput,
  label,
  id,
  error,
  handleKeyDown,
  isPassword,
  icon,
  removeItem,
  isClearable,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className='form-item-group'>
        {labelText === true && (
          <label
            className='form-item-group-label'
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: inlineLabel }}
          ></label>
        )}
        <div
          className={`form-item-group-block ${classInput ? classInput : ''} ${(isPassword || icon || isClearable) && 'input-with-icon'} ${error && 'error'}`}
        >
          <input
            className={`form-item-control `}
            id={id}
            {...props}
            onKeyDown={e => handleKeyDown && handleKeyDown(e)}
            onChange={onChange}
            type={
              isPassword && !showPassword ? 'password' : props['type'] ?? 'text'
            }
          />

          {isPassword && (
            <div
              className={`eye-icon`}
              onClick={() => {
                setShowPassword(current => !current);
              }}
            >
              {showPassword ? <IconEyeCut /> : <IconEye />}
            </div>
          )}
          {icon && (
            <div
              className={`eye-icon`}
              onClick={() => {
                removeItem && removeItem();
              }}
            >
              {icon}
            </div>
          )}
          {isClearable && (
            <div
              className={`clear-icon`}
              onClick={() => {
                removeItem && removeItem();
              }}
            >
              <div style={{ padding: '0px 5px' }}>
                <IconClear />
              </div>
            </div>
          )}
        </div>
        {error && <p className='error-message'>{showError(error)}</p>}{' '}
        <div className='invalid-feedback' role='alert'>
          Please provide a valid zip.
        </div>
        <div className='valid-feedback'></div>
      </div>
    </>
  );
};

export default Input;
