import React ,{useState} from "react";
import "./SwiperDashboard.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import TitleIcon from "../../assets/svg/swipercardtitle.svg";
import ImobilIcon from "../../assets/svg/imobiliareicon.svg";
import Titrez from "../../assets/svg/titrez.svg";
import IconArrowUpStrong from "../../icons/IconArrowUpStrong";

interface Props {}

const SwiperDashboard: React.FC<Props> = () => {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
      setActive(!isActive);
    };
    return (
        <div className="swiper-dashboard-container">
            <div className="slider__prev">
                <IconArrowUpStrong/>
            </div>
            <Swiper 
                direction={"horizontal"}
                pagination={{
                    clickable: true,
                }}
                spaceBetween={15}
                navigation={{
                    nextEl: ".slider__next",
                    prevEl: ".slider__prev",
                }}
                breakpoints={{
                    992: {
                        slidesPerView: 2,
                        direction: "horizontal",
                    },
                    1081: {
                        direction: "vertical",
                        slidesPerView: 3,
                    },
                }}
                modules={[Navigation]}
                className="dashboard-swiper"
            >
                <SwiperSlide className={`${isActive ? 'active': ''}`}  >
                    <div className="slider-header-section">
                        <h6>
                            Titrez.ro
                            <img src={Titrez} alt="" />
                        </h6>
                        <button className="btn-blue"  onClick={toggleClass}>
                            Vezi detalii
                        </button>
                    </div>

                    <div className="slider-table display-flex">
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Basic</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Premium</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Up!</p> <span> (300/300)</span>
                            </div>
                        </div>
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Spotlight</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Video</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Top virtual</p> <span> (300/300)</span>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-header-section">
                        <h6>
                            Imobilare.ro
                            <img src={ImobilIcon} alt="" />
                        </h6>
                        <button className="btn-blue">
                            Vezi detalii
                        </button>
                    </div>

                    <div className="slider-table display-flex">
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Basic</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Premium</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Up!</p> <span> (300/300)</span>
                            </div>
                        </div>
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Spotlight</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Video</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Top virtual</p> <span> (300/300)</span>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-header-section">
                        <h6>
                            Imopedia.ro
                            <img src={TitleIcon} alt="" />
                        </h6>
                        <button className="btn-blue">
                            Vezi detalii
                        </button>
                    </div>

                    <div className="slider-table display-flex">
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Basic</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Premium</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Up!</p> <span> (300/300)</span>
                            </div>
                        </div>
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Spotlight</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Video</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Top virtual</p> <span> (300/300)</span>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-header-section">
                        <h6>
                            Imopedia.ro
                            <img src={TitleIcon} alt="" />
                        </h6>
                        <button className="btn-blue">
                            Vezi detalii
                        </button>
                    </div>

                    <div className="slider-table display-flex">
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Basic</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Premium</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Up!</p> <span> (300/300)</span>
                            </div>
                        </div>
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Spotlight</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Video</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Top virtual</p> <span> (300/300)</span>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className={`${isActive ? 'active': ''}`}>
                    <div className="slider-header-section">
                        <h6>
                            Imopedia.ro
                            <img src={TitleIcon} alt="" />
                        </h6>
                        <button className="btn-blue">
                            Vezi detalii
                        </button>
                    </div>

                    <div className="slider-table display-flex">
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Basic</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Premium</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Up!</p> <span> (300/300)</span>
                            </div>
                        </div>
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Spotlight</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Video</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Top virtual</p> <span> (300/300)</span>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-header-section">
                        <h6>
                            Imopedia.ro
                            <img src={TitleIcon} alt="" />
                        </h6>
                        <button className="btn-blue">
                            Vezi detalii
                        </button>
                    </div>

                    <div className="slider-table display-flex">
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Basic</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Premium</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Up!</p> <span> (300/300)</span>
                            </div>
                        </div>
                        <div className="column-item-swiper">
                            <div className="rwo-item">
                                <p>Spotlight</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Video</p> <span> (300/300)</span>
                            </div>
                            <div className="rwo-item">
                                <p>Top virtual</p> <span> (300/300)</span>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="slider__next">
               <IconArrowUpStrong/>
            </div>
        </div>
    );
};

export default SwiperDashboard;
