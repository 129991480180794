import React from 'react';
import Modal from 'react-bootstrap/Modal';
import FilterListEmailTemplates from '../../components/FilterListEmailTemplates';
import CloseIcon from '../../assets/svg/closemodal.svg';
import ActionsMobileEmailTemplate from '../ActionsMobileEmailTemplate';
import SearchMobile from '../SearchMobile';
import './EmailTemplatesModal.scss';

interface Props {
  isModalOpen: boolean;
  isAction: boolean;
  isSearch: boolean;
  isFilter: boolean;
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  closeModal: () => void;
  removeItems?: () => void;
  editItems?: () => void;
  sortProperty: (field: string) => void;
  sort: string;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  filterByLettersOnMobile: boolean;
  setFilterByLettersOnMobile: (p: boolean) => void;
}

const EmailTemplatesModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  isAction,
  isSearch,
  isFilter,
  isCheckedAll,
  setCheckedAll,
  removeItems,
  editItems,
  sort,
  sortProperty,
  filtersForm,
  setFiltersForm,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
}) => (
  <>
    <Modal
      show={isModalOpen}
      onHide={closeModal}
      animation={true}
      className={
        isFilter || isAction ? 'mobile-modal filter-overflow' : 'mobile-modal'
      }
    >
      <Modal.Body>
        <div className='close-btn-modal' onClick={closeModal}>
          <img src={CloseIcon} alt='CloseIcon' />
        </div>
        {isSearch && <SearchMobile />}
        {isAction && (
          <ActionsMobileEmailTemplate
            removeItems={removeItems}
            editItems={editItems}
          />
        )}
        {isFilter && (
          <FilterListEmailTemplates
            isCheckedAll={isCheckedAll}
            setCheckedAll={setCheckedAll}
            sort={sort}
            sortProperty={sortProperty}
            filtersForm={filtersForm}
            setFiltersForm={setFiltersForm}
            filterByLettersOnMobile={filterByLettersOnMobile}
            setFilterByLettersOnMobile={setFilterByLettersOnMobile}
          />
        )}
      </Modal.Body>
    </Modal>
  </>
);

export default EmailTemplatesModal;
