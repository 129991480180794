import { useState } from 'react';
import AlocatedSlots from '../../components/AlocatedSlots';
import DropdownBrands from '../../components/DropdownBrands';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import NotificationsCards from '../../components/NotificationsCards';
import SideNav from '../../components/SideNav';
import SwiperDashboard from '../../components/SwiperDashboard';
import { useAppSelector } from '../../redux/hooks';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './Home.scss';

interface Props {}

const Home: React.FC<Props> = () => {
  const { auth } = useAppSelector(state => state.auth);
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.Dashboard),
  );

  return (
    <div className='flex-container home-page display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title={`Good morning, ${auth?.profile?.fullName}!`}
          subtitle=''
          isbutton={false}
          btnText=''
        />
        {havePermission ? (
          <>
            <NotificationsCards />
            <div className='display-flex table-swiper'>
              <AlocatedSlots />
              <SwiperDashboard />
              <DropdownBrands />
            </div>
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default Home;
