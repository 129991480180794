interface Props {}

const IconRemove: React.FC<Props> = props => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.25186 6.00008L11.0482 2.20363C11.394 1.85785 11.394 1.29895 11.0482 0.953179C10.7025 0.607404 10.1436 0.607404 9.79781 0.953179L6.00143 4.74963L2.20506 0.953179C1.85929 0.607404 1.30041 0.607404 0.954638 0.953179C0.608871 1.29895 0.608871 1.85785 0.954638 2.20363L4.75101 6.00008L0.954638 9.79653C0.608871 10.1423 0.608871 10.7012 0.954638 11.047C1.12708 11.2194 1.35347 11.3061 1.57985 11.3061C1.80624 11.3061 2.03262 11.2194 2.20506 11.047L6.00143 7.25053L9.79781 11.047C9.97025 11.2194 10.1966 11.3061 10.423 11.3061C10.6494 11.3061 10.8758 11.2194 11.0482 11.047C11.394 10.7012 11.394 10.1423 11.0482 9.79653L7.25186 6.00008Z'
        fill='white'
      />
    </svg>
  );
};

export default IconRemove;
