import { createSlice } from '@reduxjs/toolkit';
import { Company } from '../../models/Companies';
import {
  addCompany,
  arhiveCompanies,
  deleteCompany,
  editCompany,
  getCompanies,
  getCompany,
} from './companiesThunk';

type CompaniesState = {
  companies: Company[];
  company: null | Company;
  count: number;
  loadingList: boolean;
  loadingItem: boolean;
  error: any;
};

const initialState: CompaniesState = {
  companies: [],
  company: null,
  count: 0,
  loadingList: false,
  loadingItem: false,
  error: null,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearCompany(state) {
      state.company = null;
    },
    toggleChecked(state, action) {
      const companyId = action.payload;
      const company = state.companies.find(company => company.id === companyId);

      if (company) {
        company.checked = !company.checked;
      }
    },
    checkAll(state, action) {
      state.companies.forEach(company => (company.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCompanies.pending, state => {
        state.loadingList = true;
        state.error = null;
      })
      .addCase(getCompanies.fulfilled, (state, { payload }) => {
        state.companies = payload.companies;
        state.count = payload.totalCount;
        state.loadingList = false;
        state.error = null;
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingList = false;
      })
      .addCase(getCompany.pending, state => {
        state.company = null;
        state.loadingItem = true;
        state.error = null;
      })
      .addCase(getCompany.fulfilled, (state, { payload }) => {
        state.company = payload;
        state.loadingItem = false;
        state.error = null;
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingItem = false;
      })
      .addCase(addCompany.pending, state => {
        state.loadingItem = true;
        state.error = null;
      })
      .addCase(addCompany.fulfilled, (state, { payload }) => {
        state.company = payload;
        state.loadingItem = false;
        state.error = null;
      })
      .addCase(addCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingItem = false;
      })
      .addCase(editCompany.pending, state => {
        state.loadingItem = true;
        state.error = null;
      })
      .addCase(editCompany.fulfilled, (state, { payload }) => {
        state.company = payload;
        state.loadingItem = false;
        state.error = null;
      })
      .addCase(editCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingItem = false;
      })
      .addCase(deleteCompany.pending, state => {
        state.loadingList = true;
        state.error = null;
      })
      .addCase(deleteCompany.fulfilled, state => {
        state.loadingList = false;
        state.error = null;
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingList = false;
      })
      .addCase(arhiveCompanies.pending, state => {
        state.loadingList = true;
        state.error = null;
      })
      .addCase(arhiveCompanies.fulfilled, state => {
        state.loadingList = false;
        state.error = null;
      })
      .addCase(arhiveCompanies.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingList = false;
      });
  },
});

export const { clearError, clearCompany, toggleChecked, checkAll } =
  companiesSlice.actions;

export default companiesSlice.reducer;
