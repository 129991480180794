import React from 'react';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import IconAdd from '../../icons/IconAdd';
import IconCheck from '../../icons/IconCheck';
import IconCheckBox from '../../icons/IconCheckBox';
import IconDiskette from '../../icons/IconDiskette';
import IconEye from '../../icons/IconEye';
import IconEyeCut from '../../icons/IconEyeCut';
import IconPencil from '../../icons/IconPencil';
import IconTrash from '../../icons/IconTrash';
import './ButtonSquare.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  btnSize?: string;
  renames?: boolean;
  remove?: boolean;
  view?: boolean;
  checkedBtn?: boolean;
  addBtn?: boolean;
  save?: boolean;
  renamesProgress?: boolean;
  isActiveView?: boolean;
  setIsActiveView?: () => void;
  removeAction?: () => void;
  saveAction?: () => void;
  editAction?: () => void;
  checkAction?: () => void;
}

const ButtonSquare: React.FC<Props> = ({
  onChange,
  btnSize,
  renames,
  remove,
  view,
  checkedBtn,
  addBtn,
  renamesProgress,
  isActiveView,
  setIsActiveView,
  removeAction,
  editAction,
  checkAction,
  saveAction,
  save,
  ...props
}) => {
  const [isActive, setIsActive] = useState(isActiveView ?? false);

  const toggleIsActive = () => {
    setIsActive(current => !current);
    setIsActiveView && setIsActiveView();
  };

  return (
    <>
      <div className='action-buttons-list'>
        {addBtn === true && (
          <>
            <button
              className={`button-square-${btnSize}`}
              aria-label=''
              data-tooltip-id='add'
              data-tooltip-content='Adauga'
            >
              <IconAdd />
            </button>
            <Tooltip id='add' />
          </>
        )}
        {checkedBtn === true && checkAction && (
          <>
            <button
              className={`button-square-${btnSize}`}
              aria-label=''
              data-tooltip-id='edit-status'
              data-tooltip-content='Modifica statut'
              onClick={() => checkAction()}
            >
              <IconCheckBox />
            </button>
            <Tooltip id='edit-status' />
          </>
        )}
        {renames === true && editAction && (
          <>
            <button
              className={`button-square-${btnSize}`}
              aria-label='Redenumește'
              data-tooltip-id='edit'
              data-tooltip-content='Editeaza'
              onClick={() => editAction()}
            >
              {renamesProgress ? <IconCheck /> : <IconPencil />}
            </button>
            <Tooltip id='edit' />
          </>
        )}
        {remove === true && removeAction && (
          <>
            <button
              className={`button-square-${btnSize}`}
              aria-label='șterg'
              data-tooltip-id='remove'
              data-tooltip-content='Sterge'
              onClick={() => removeAction()}
            >
              <IconTrash />
            </button>
            <Tooltip id='remove' />
          </>
        )}
        {save === true && saveAction && (
          <button
            className={`button-square-${btnSize}`}
            aria-label='salvez'
            onClick={() => saveAction()}
          >
            <IconDiskette />
          </button>
        )}
        {view === true && (
          <>
            <button
              className={`button-square-${btnSize} ${isActive ? 'active' : ''}`}
              data-tooltip-id='view'
              data-tooltip-content='Vizioneaza'
              aria-label=''
              onClick={toggleIsActive}
            >
              {isActive ? <IconEyeCut /> : <IconEye />}
            </button>
            <Tooltip id='view' />
          </>
        )}
      </div>
    </>
  );
};

export default ButtonSquare;
