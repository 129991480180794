import React from 'react';
import Form from 'react-bootstrap/Form';
import { useAppSelector } from '../../redux/hooks';
import { Feature } from '../../models/Categories';
import { PropertyForm } from '../../models/Properties';
import './CheckboxGroup.scss';

interface Props {
  id: number;
  formData: PropertyForm;
  handleCheck: (id: number) => void;
}

const CheckboxGroup: React.FC<Props> = ({ id, formData, handleCheck }) => {
  const { property } = useAppSelector(state => state.properties);

  return (
    <div className='checkbox-group-list'>
      {property?.category?.features
        .find(feature => feature.id === id)
        ?.childs?.map((checkbox: Feature, index: number) => (
          <div
            className='checkbox-group-list__item checkbox-group-list__item-sm bg-gray'
            key={index}
          >
            <fieldset className='checkbox-group-fieldset'>
              {checkbox?.title && (
                <legend className='heading-block'>{checkbox.title}</legend>
              )}
              {checkbox.childs?.map((item: Feature, index: number) => (
                <div className='form-item' key={index}>
                  <Form.Check
                    type={checkbox.type === 'RADIO' ? 'radio' : 'checkbox'}
                    label={item?.title}
                    id={`${item?.id}-${index}`}
                    checked={formData.features?.includes(item.id)}
                    readOnly
                    onClick={() => {
                      handleCheck(item.id);

                      if(checkbox.type === 'RADIO' && checkbox.childs) {
                        checkbox.childs
                          .filter(childItem=> childItem.id !== item.id && formData.features?.includes(childItem.id))
                          .forEach(chidlItem => handleCheck(chidlItem.id))
                      }
                    }}
                  />
                </div>
              ))}
            </fieldset>
          </div>
        ))}
    </div>
  );
};

export default CheckboxGroup;
