import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Buttons from '../../components/Buttons';
import Header from '../../components/Header';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getActivitiesTypes } from '../../redux/activities/activitiesThunk';
import { getCategories } from '../../redux/categories/categoriesThunk';
import {
  getContacts,
  getLeadSources,
} from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getCounty,
  getResidentialComplexes,
} from '../../redux/localities/localitiesThunk';
import {
  getProperty,
  updateProperty,
} from '../../redux/properties/propertiesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import useHelper, {
  getFiltersState,
  parseProperty,
} from '../../helpers/useHelper';
import { PropertyForm, SelectValue } from '../../models/Properties';
import AddContactsForm from '../AddContactsForm/AddContactsForm';
import AddImagesForm from '../AddImagesForm/AddImagesForm';
import AdsActivityForm from '../AdsActivityForm/AdsActivityForm';
import AdsGeneral from '../AdsGeneral/AdsGeneral';
import CharacteristicsForm from '../CharacteristicsForm/CharacteristicsForm';
import FeaturesForm from '../FeaturesForm/FeaturesForm';
import Localizate from '../Localizate/Localizate';
import PriceForm from '../PriceForm/PriceForm';
import PromotionForm from '../PromotionForm/PromotionForm';
import RequestsForm from '../RequestsForm/RequestsForm';
import './AddProperty.scss';

interface Props {}

const tabs = [
  {
    label: 'Localizare',
    value: 'localizare',
  },
  {
    label: 'Caracteristici',
    value: 'caracteristici',
  },
  {
    label: 'Pret',
    value: 'pret',
  },
  {
    label: 'Optionale',
    value: 'optionale',
  },
  {
    label: 'Imagini',
    value: 'imagini',
  },
  {
    label: 'Promovare',
    value: 'promovare',
  },
  {
    label: 'Activitati',
    value: 'activitati',
  },
  {
    label: 'Contacte',
    value: 'contacte',
  },
  {
    label: 'Solicitări',
    value: 'solicitări',
  },
];

const AddProperty: React.FC<Props> = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();
  const { isFromAgencyList } = useLocation().state || {};

  const { contacts } = useAppSelector(state => state.contacts);
  const { property, loading, loadingSave } = useAppSelector(
    state => state.properties,
  );
  const isMobile = window.innerWidth < 1081;
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [formData, setFormData] = useState<PropertyForm>({});

  useEffect(() => {
    if (searchParams.get('clone')) {
      toast.success('Proprietatea a fost clonata cu success!');
    }
  }, [searchParams]);

  useEffect(() => {
    if (id) {
      dispatch(getProperty(id)).then(res => {
        if (res.payload.id) {
          let initialForm = parseProperty(res.payload);

          setFormData(initialForm);
          dispatch(getCategories());
          dispatch(getUsers({ roles: 'AGENCY', limit: -1, offset: 1 }));
          dispatch(getCounty({ parent: 0 }));
          dispatch(getLeadSources());
          dispatch(getActivitiesTypes());
          contacts.length === 0 && dispatch(getContacts({ limit: -1 }));
          property?.category?.additionalFields?.includes(
            'residentialComplex',
          ) && dispatch(getResidentialComplexes({}));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleChange = (key: string, e: SelectValue | null) => {
    setFormData({
      ...formData,
      [key]: Number(e?.value),
    });
  };

  const handleCheck = (key: string, e: boolean | null) => {
    setFormData({
      ...formData,
      [key]: e,
    });
  };

  const handleString = (key: string, e: string | null) => {
    setFormData({
      ...formData,
      [key]: e,
    });
  };

  const renderTabContent = (type: string) => {
    switch (type) {
      case 'localizare': {
        return (
          <Localizate
            formData={formData}
            setFormData={setFormData}
            handleChange={(key, value) => handleChange(key, value)}
            handleString={(key, value) => handleString(key, value)}
          />
        );
      }
      case 'caracteristici':
        return (
          <CharacteristicsForm
            formData={formData}
            setFormData={setFormData}
            handleChange={(key, value) => handleChange(key, value)}
            handleString={(key, value) => handleString(key, value)}
          />
        );
      case 'pret':
        return (
          <PriceForm
            formData={formData}
            setFormData={setFormData}
            handleCheck={(key, value) => handleCheck(key, value)}
            handleChange={(key, value) => handleChange(key, value)}
            handleString={(key, value) => handleString(key, value)}
          />
        );
      case 'optionale':
        return <FeaturesForm formData={formData} setFormData={setFormData} />;
      case 'imagini':
        return <AddImagesForm formData={formData} setFormData={setFormData} />;
      case 'promovare':
        return <PromotionForm />;
      case 'activitati':
        return <AdsActivityForm fieldName='property' fieldId={property?.id} />;
      case 'contacte':
        return (
          <AddContactsForm formData={formData} setFormData={setFormData} />
        );
      case 'solicitări':
        return <RequestsForm fieldName={'property'} fieldId={property?.id} />;
    }

    return null;
  };

  const submitForm = () => {
    property?.id &&
      formData &&
      dispatch(
        updateProperty({
          id: property?.id,
          form: {
            ...formData,
            filters: formData.filters ?? getFiltersState(property),
          },
        }),
      ).then(res => {
        if (res.payload?.id) {
          toast.success('Proprietatea a fost actualizata cu succes!');

          if (isFromAgencyList) {
            navigate('/agency-properties');
          } else {
            navigate('/my-properties');
          }
        }
        else diplayErrorMessages(res.payload);
      });
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        {!loading && (
          <Header
            title={property?.id ? 'ID ' + property?.id : ''}
            subtitle={
              property?.id
                ? `${property?.category?.title}${property?.street?.title ? ', ' + property?.street.title : ''}${property?.streetNumber ? ' nr. ' + property?.streetNumber : ''}`
                : 'Nu sunt date despre proprietate!'
            }
            backBtn={true}
            isbutton={false}
            btnText=''
          />
        )}
        {loading ? (
          <Spinner />
        ) : (
          property?.id && (
            <div className='section-container'>
              <AdsGeneral formData={formData} />
              <div className='ads-general-lg'>
                <div className=' bg-blue'>
                  <div className='ads-container bg-white'>
                    {isMobile ? (
                      <div>
                        <select
                          className='select-tabs '
                          value={activeTab}
                          onChange={e => setActiveTab(e.target.value)}
                        >
                          {tabs.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                        {activeTab && renderTabContent(activeTab)}
                      </div>
                    ) : (
                      <Tabs
                        id='ads-tabs'
                        className='ads-form-tabs'
                        activeKey={activeTab}
                        onSelect={e => e && setActiveTab(e)}
                      >
                        {tabs.map(tab => (
                          <Tab
                            key={tab.value}
                            eventKey={tab.value}
                            title={tab.label}
                          >
                            {renderTabContent(tab.value)}
                          </Tab>
                        ))}
                      </Tabs>
                    )}
                  </div>
                </div>
                <Buttons
                  nextStep={() =>
                    tabs.findIndex(tab => tab.value === activeTab) !==
                      tabs.length - 1 &&
                    setActiveTab(
                      tabs[tabs.findIndex(tab => tab.value === activeTab) + 1]
                        .value,
                    )
                  }
                  disabledNext={activeTab === tabs[tabs.length - 1].value}
                  submitLoading={loadingSave}
                  submit={() => submitForm()}
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AddProperty;
