import { useMapEvents } from 'react-leaflet';

import { Coordinate } from '../../models/Properties';

interface Props {
  coordinates: Coordinate;
  setCoordinates: (e: Coordinate) => void;
}
const MapEvents = ({ coordinates, setCoordinates }: Props) => {
  useMapEvents({
    click(e: any) {
      setCoordinates({
        type: coordinates.type,
        lat: e.latlng.lat,
        lng: e.latlng.lng,
      });
    },
  });
  return false;
};

export default MapEvents;
