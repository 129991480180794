import React from 'react';
import Stackicon from '../../assets/svg/penicon.svg';
import WarningIcon from '../../assets/svg/trash.svg';
import './ActionsMobileEmailTemplate.scss';

interface Props {
  removeItems?: () => void;
  editItems?: () => void;
}
const ActionsMobileEmailTemplate: React.FC<Props> = ({
  removeItems,
  editItems,
}) => {
  return (
    <>
      <div className='actions-container'>
        <div
          className='action-item'
          onClick={e => {
            editItems && editItems();
          }}
        >
          <p>Modifica template </p>
          <img src={Stackicon} alt='Stackicon' />
        </div>
        <div
          className='action-item'
          onClick={e => {
            removeItems && removeItems();
          }}
        >
          <p>Sterge</p>
          <img src={WarningIcon} alt='WarningIcon' />
        </div>
      </div>
    </>
  );
};

export default ActionsMobileEmailTemplate;
