import { useState } from 'react';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import PropertiesUpdatesGrafic from '../../components/PropertiesUpdatesGrafic';
import SideNav from '../../components/SideNav';
import SwiperDashboard from '../../components/SwiperDashboard';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './PropertyUpdates.scss';

interface Props {}

const PropertyUpdates: React.FC<Props> = () => {
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.PropertyUpdate),
  );

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Actualizeara anunturi'
          subtitle='Vezi statistici despre anunturile tale si actualizeaza-le'
          isbutton={false}
          btnText=''
        />
        {havePermission ? (
          <div className='display-flex flex-property-updates'>
            <PropertiesUpdatesGrafic />
            <SwiperDashboard />
          </div>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default PropertyUpdates;
