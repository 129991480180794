import React from 'react';
import Form from 'react-bootstrap/Form';
import './FilterListPropertiesDB.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  sortProperty: (field: string) => void;
  sort: string;
}

const FilterListPropertiesDB: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  sort,
  sortProperty,
}) => {
  return (
    <div className='filter-list-container property-list request-property-list'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
        </Form.Group>
        <div className='filter-item'>
          <p>Photo</p>
        </div>
        <div className='filter-item'>
          <p>Adresa</p>
        </div>
        <div className='filter-item'>
          <p>Detalii Proprietate</p>
        </div>

        <div
          className={`filter-item arrows-label-icon ${sort === 'price' ? 'active-bottom' : ''} ${sort === '-price' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'price'
              ? sortProperty('-price')
              : sort === '-price'
                ? sortProperty('')
                : sortProperty('price')
          }
        >
          <label>Pret</label>
        </div>

        <div className='filter-item'>
          <label>Actiuni</label>
        </div>
      </form>
    </div>
  );
};

export default FilterListPropertiesDB;
