import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useAppSelector } from '../../redux/hooks';
import { Media, PropertyForm } from '../../models/Properties';
import ImagesForm from '../ImagesForm';
import './AddImagesCompanyLogoForm.scss';

interface Props {
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
}

const AddImagesCompanyLogoForm: React.FC<Props> = ({
  formData,
  setFormData,
}) => {
  const { residentialComplex } = useAppSelector(
    state => state.residentialComplexes,
  );
  const [background, setBackground] = useState<Media[]>([]);
  const [logo, setLogo] = useState<Media[]>([]);
  const [images, setImages] = useState<Media[]>([]);

  useEffect(() => {
    setBackground(
      residentialComplex?.media?.filter(
        mediaFile => mediaFile.type === 'BACKGROUND',
      ) ?? [],
    );

    setLogo(
      residentialComplex?.media?.filter(
        mediaFile => mediaFile.type === 'LOGO',
      ) ?? [],
    );

    setImages(
      residentialComplex?.media?.filter(
        mediaFile => mediaFile.type === 'IMAGES',
      ) ?? [],
    );
  }, [residentialComplex]);

  useEffect(() => {
    let imagesObject: Media[] = [];

    background.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;
      imagesObject.push({ ...image, mediaId: mediaId, type: 'BACKGROUND' });
    });
    logo.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;
      imagesObject.push({ ...image, mediaId: mediaId, type: 'LOGO' });
    });
    images.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;
      imagesObject.push({ ...image, mediaId: mediaId, type: 'IMAGES' });
    });
    setFormData({ ...formData, media: imagesObject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [background, logo, images]);

  return (
    <div className='flex-container add-images-area-form display-flex'>
      <Container>
        <Row className='row-38'>
          <Col lg='12' className='content-block'>
            <h2 className='heading-block'>Cover photo si logo companie</h2>
            <p>
              Dimensiunea trebuie sa fie 923x83 pentru cover photo si 232x232
              pentru Logo Companie.
            </p>
          </Col>
          <Col lg={4} className='fieldset-br'>
            <div className='heading-block-sm'>
              <h3 className='title'>Adauga Background</h3>
            </div>
            <ImagesForm images={background} setImages={setBackground} />
          </Col>
          <Col lg={4}>
            <div className='heading-block-sm'>
              <h3 className='title'>Adauga logo</h3>
            </div>
            <ImagesForm images={logo} setImages={setLogo} />
          </Col>
          <Col lg='12' className='content-block mt-45'>
            <h2 className='heading-block'>Imagini ansamblu rezidential</h2>
            <p>
              Pune descriere fiecarei poze in parte pentru o mai buna indexare
              in motoarele de cautare. Trage fotografiile pentru aranjare.
            </p>
          </Col>
          <Col lg={4}>
            <div className='heading-block-sm'>
              <h3 className='title'>Lista schite</h3>
              <span>Adauga aici schitele si planurile proprietatii</span>
            </div>
            <ImagesForm images={images} setImages={setImages} getPDF={true} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddImagesCompanyLogoForm;
