import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import Input from '../../components/Input';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { clearCompany } from '../../redux/companies/companies-slice';
import {
  deleteCompany,
  editCompany,
  getCompany,
} from '../../redux/companies/companiesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { haveUserPermission } from '../../helpers/useHelper';
import { Company } from '../../models/Companies';
import { Permissions } from '../../models/Permissions';
import './EditCompany.scss';


interface Props {}

const EditCompany: React.FC<Props> = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { company, loadingItem } = useAppSelector(state => state.companies);

  const [formData, setFormData] = useState<Company>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formDataErrors, setFormDataErrors] = useState<any>({});
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.CompanyList),
  );

  useEffect(() => {
    dispatch(clearCompany());

    if (companyId) {
      dispatch(getCompany(companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line
  }, [company]);

  const saveCompany = async () => {
    await dispatch(editCompany(formData)).then(processResponse);
  };

  const processResponse = (response: any) => {
    if (response.error) {
      toast.error(
        `Eroare la salvarea companiei. ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
      );
      setFormDataErrors(response.payload);
    } else {
      toast.success(`Succes!! Companie a fost modificata cu succes.`);
      navigate('/companies');
    }
  };

  const onInputChange = (event: any) => {
    const { value, name } = event.target;

    setFormData((formData: any) => {
      formData[name] = value;

      return { ...formData };
    });
  };

  const resetForm = () => {
    setFormData({
      id: company?.id || 0,
      title: company?.title || '',
      details: company?.details || '',
      website: company?.website || '',
      address: company?.address || '',
      phoneNumber: company?.phoneNumber || '',
      email: company?.email || '',
    });
  };

  const removeCompany = () => {
    dispatch(deleteCompany(formData.id)).then((res : any) => {
      if(res?.payload?.error) {
        toast.error(`Eroare la stergerea companiei ${formData.title}: ${res.payload.error}`);
      }
      else {
        toast.success(`Compania ${formData.title} a fost stearsa cu succes` );
        navigate('/companies');
      }
    });
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper company-container'>
        <Header
          title='Modifica datele companiei'
          subtitle='Poti sterge o companie doar daca esti administrator sau a fost adaugata de tine.'
          isbutton={false}
          backBtn={true}
          btnText=''
        />
        {havePermission ? (
          <>
            {loadingItem ? (
              <div className='container'>
                <Spinner />
              </div>
            ) : (
              <div className='display-flex flex-edit-company'>
                <div className='edit-company-container'>
                  <div className='form-container'>
                    <h4 className='title'>Informatii</h4>
                    <form>
                      <div className='form-line-section'>
                        <Input
                          name='title'
                          labelText={true}
                          inlineLabel='Nume'
                          type='text'
                          value={formData?.title || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.title}
                        />
                      </div>
                      <div className='form-line-section'>
                        <Input
                          name='phoneNumber'
                          labelText={true}
                          inlineLabel='Telefon'
                          type='text'
                          value={formData?.phoneNumber || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.phoneNumber}
                        />
                      </div>
                      <div className='form-line-section'>
                        <Input
                          name='email'
                          labelText={true}
                          inlineLabel='E-mail'
                          type='text'
                          value={formData?.email || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.email}
                        />
                      </div>
                      <div className='form-line-section'>
                        <Input
                          name='address'
                          labelText={true}
                          inlineLabel='Adresa'
                          type='text'
                          value={formData?.address || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.address}
                        />
                      </div>
                      <div className='form-line-section'>
                        <Input
                          name='website'
                          labelText={true}
                          inlineLabel='Web site'
                          type='text'
                          value={formData?.website || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.website}
                        />
                      </div>
                      <div className='form-line-section'>
                        <Input
                          name='details'
                          labelText={true}
                          inlineLabel='Detalii'
                          type='text'
                          value={formData?.details || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.details}
                        />
                      </div>
                    </form>
                  </div>
                  <div className='btns-container'>
                    <button
                      className='reset-btn button-md'
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Sterge
                    </button>
                    <button
                      className='button-blue button-md'
                      onClick={saveCompany}
                    >
                      Salveaza
                    </button>
                  </div>
                </div>
                <ModalConfirm
                  showModal={showDeleteModal}
                  title='Stergere companie'
                  body='Doriti sa stergeti compania si orice asociere cu aceasta?'
                  onConfirmed={removeCompany}
                  hideModal={() => setShowDeleteModal(false)}
                />
              </div>
            )}
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default EditCompany;
