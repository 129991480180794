import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input/Input';
import MultiselectCheckboxes from '../../components/MultiselectCheckboxes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { SelectValue } from '../../models/Properties';
import { ResidentialComplexForm } from '../../models/ResidentialComplexes';
import './ResidentialComplexDetails.scss';

const statusOptions = [
  { value: 0, label: 'Nepublicat' },
  { value: 1, label: 'Publicat' },
];

interface Props {
  formData: ResidentialComplexForm;
  formDataErrors: any;
  setFormData: (form: ResidentialComplexForm) => void;
  handleChange: (key: string, value: SelectValue | null) => void;
  handleString: (key: string, value: string | null) => void;
}

const CharacteristicsForm: React.FC<Props> = ({
  formData,
  formDataErrors,
  handleChange,
  handleString,
  setFormData,
}) => {
  const dispatch = useAppDispatch();
  const { residentialComplex } = useAppSelector(
    state => state.residentialComplexes,
  );
  const { categories } = useAppSelector(state => state.categories);
  const { pricesTypes } = useAppSelector(state => state.properties);

  return (
    <div className='display-flex residential-complex-form-container'>
      <Container>
        <Row className='row-91'>
          <Col lg='12'>
            <legend className='heading-block'>Descriere</legend>
          </Col>
            <Col lg='6'>
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.title ?? ''}
                  // classInput={error?.title ? 'error' : ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleString('title', e.target.value)
                  }
                  inlineLabel='Nume Proiect'
                  error={formDataErrors?.title}
                />
              </div>
              <div className='form-item'>
                <div className='form-item-group form-item-group-textarea'>
                  <label
                    htmlFor='description'
                    className='form-item-group-label'
                  >
                    Descriere
                  </label>
                  <textarea
                    value={formData?.description}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleString('description', e.target.value)
                    }
                    className='form-item-control'
                  ></textarea>
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <div className='form-item'>
                <Input
                  labelText={true}
                  inlineLabel='Web Site'
                  type='url'
                  value={formData?.website}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleString('website', e.target.value)
                  }
                />
              </div>
              <div className='form-item'>
                <Input
                  labelText={true}
                  inlineLabel='Link Video'
                  type='url'
                  value={formData?.videoUrl}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleString('videoUrl', e.target.value)
                  }
                />
              </div>
              <div className='form-item'>
                <MultiselectCheckboxes
                  onChange={(e: number[]) => {
                    setFormData({
                      ...formData,
                      categories: e,
                    });
                  }}
                  labelText={true}
                  inlineLabel={'Tip Imobil'}
                  value={formData?.categories ? formData.categories : null}
                  options={categories.map(item => {
                    return {
                      value: item.id,
                      label: item.title,
                    };
                  })}
                  error={formDataErrors?.categories}
                />
              </div>
              <div className='form-item'>
                <MultiselectCheckboxes
                  onChange={(e: number[]) => {
                    setFormData({
                      ...formData,
                      offerTypes: e,
                    });
                  }}
                  labelText={true}
                  inlineLabel={'Tip Oferta'}
                  value={formData?.offerTypes ? formData.offerTypes : null}
                  options={pricesTypes.map(item => {
                    return {
                      value: item.id,
                      label:
                        item.title === 'SALE'
                          ? 'Vanzare'
                          : item.title === 'RENT_MONTH'
                            ? 'Inchiriere'
                            : 'Regim hotelier',
                    };
                  })}
                  error={formDataErrors?.offerTypes}
                />
              </div>
              <div className='form-item'>
                <FormSelect
                  placeholder='Select Options'
                  labelText={true}
                  inlineLabel='Status'
                  options={statusOptions}
                  value={statusOptions.find(
                    option => option.value === formData?.status,
                  )}
                  onChange={(e: any) =>
                    setFormData({ ...formData, status: e.value })
                  }
                  error={formDataErrors?.status}
                />
              </div>
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CharacteristicsForm;
