import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useAppSelector } from '../../redux/hooks';
import { PropertyForm } from '../../models/Properties';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import './FeaturesForm.scss';

interface Props {
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
}

const FeaturesForm: React.FC<Props> = ({ formData, setFormData }) => {
  const { property } = useAppSelector(state => state.properties);
  const isMobile = window.innerWidth < 1081;
  const [activeTab, setActiveTab] = useState<string>(
    String(property?.category?.features[0]?.id) ?? '',
  );

  const handleCheck = (id: number) => {
    let ids: number[] = formData?.features ?? [];
    if (ids?.includes(id)) ids = ids?.filter(nr => nr !== id);
    else ids.length > 0 ? ids.push(id) : (ids = [id]);

    setFormData({
      ...formData,
      features: ids,
    });
  };

  const renderTabContent = (id: number) => {
    return (
      <CheckboxGroup id={id} formData={formData} handleCheck={handleCheck} />
    );
  };

  return (
    <div className='display-flex ads-form-container'>
      <Container>
        <Row>
          <Col lg='12' className='content-block'>
            <h2 className='heading-block'>Optionale</h2>
            <p>
              Pentru o mai buna evidentiere, completeaza cat mai corect toate
              informatiile suplimentare.
            </p>
          </Col>
          {property?.category?.features &&
            property?.category?.features.length > 0 && (
              <Col lg='12'>
                {isMobile ? (
                  <div>
                    <select
                      className='select-tabs'
                      onChange={e => setActiveTab(e.target.value)}
                    >
                      {property?.category?.features &&
                        property.category.features.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.title}
                          </option>
                        ))}
                    </select>

                    {activeTab && renderTabContent(Number(activeTab))}
                  </div>
                ) : (
                  <Tabs id='ads-tabs' className='ads-form-tabs'>
                    {property?.category?.features &&
                      property.category.features.map(tab => (
                        <Tab key={tab.id} eventKey={tab.id} title={tab.title}>
                          {renderTabContent(tab.id)}
                        </Tab>
                      ))}
                  </Tabs>
                )}
              </Col>
            )}
        </Row>
      </Container>
    </div>
  );
};

export default FeaturesForm;
