import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './ModalConfirm.scss';
import IconTimes from '../../icons/IconTimes';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showModal: boolean;
  title: string;
  body: string;
  hideModal: () => void;
  onConfirmed: () => void;
}

const ModalItem: React.FC<Props> = ({
  showModal,
  title,
  body,
  onConfirmed,
  hideModal,
}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={hideModal}
        animation={false}
        className='confirm-modal'
      >
        <button className='close-modal' aria-label='Închide' onClick={hideModal}>
          <IconTimes/>
        </button>
        <Modal.Header>
          <Modal.Title className='text-center w-100'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <button className='reset-btn button-md' onClick={hideModal}>
            Nu
          </button>
          <button
            className='button-blue button-md'
            onClick={() => {
              hideModal();
              onConfirmed();
            }}
          >
            Da
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalItem;
