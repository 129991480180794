import { Icon, LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import {Circle, MapContainer, Marker, Pane, TileLayer, useMap} from 'react-leaflet';
import { Coordinate } from '../../models/Properties';
import marker from '../../assets/img/pin.svg';
import MapEvents from './MapEvents';

interface Props {
  coordinates: Coordinate;
  setCoordinates: (e: Coordinate) => void;
}

const myIcon = new Icon({
  iconUrl: marker,
  iconSize: [32, 32],
});

const Map: React.FC<Props> = ({ coordinates, setCoordinates }) => {
  const calculateCenter = () =>
    coordinates?.lat && coordinates?.lng
      ? { lat: Number(coordinates.lat), lng: Number(coordinates.lng) }
      : {
          lat: Number(coordinates?.pins?.[1]?.lat) - 0.01,
          lng: Number(coordinates?.pins?.[1]?.lng) - 0.01,
        };

  const [center, setCenter] = useState<LatLngExpression>(calculateCenter());

  useEffect(() => {
    setCenter(calculateCenter());
  }, [coordinates]);

  const MapRecenter = () => {
    const map = useMap();

    useEffect(() => {
        map.setView(center, map.getZoom());
    }, [center, map]);

    return null;
  };


    return (
    ((coordinates?.lat && coordinates?.lng) || coordinates.pins) && (
      <MapContainer zoom={13} scrollWheelZoom={false} center={center}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <MapEvents coordinates={coordinates} setCoordinates={setCoordinates} />
        <MapRecenter />
        {coordinates?.type === 'PIN' && (
          <Marker position={center} icon={myIcon}></Marker>
        )}
        {coordinates?.type === 'CIRCLE' && (
          <Pane name='yellow-rectangle' style={{ zIndex: 499 }}>
            <Circle
              center={center}
              radius={1000}
              pathOptions={{ color: 'blue' }}
            />
          </Pane>
        )}
      </MapContainer>
    )
  );
};

export default Map;
