import React, { useEffect, useState } from 'react';
import Select, {
  ClearIndicatorProps,
  type DropdownIndicatorProps,
  components,
} from 'react-select';
import { showError } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import Search from '../../assets/svg/Search.svg';
import IconArrowUp from '../../icons/IconArrowUp';
import IconClear from '../../icons/IconClear';
import './FormSelect.scss';

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <IconArrowUp className='rotate' />
    </components.DropdownIndicator>
  );
};

const SearchIndicator: React.FC<DropdownIndicatorProps> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Search} alt='' />
    </components.DropdownIndicator>
  );
};

const ClearIndicator: React.FC<ClearIndicatorProps> = props => {
  const { children = <IconClear />, innerProps } = props;
  return (
    <div {...innerProps}>
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const FormSelect: React.FC<any> = ({
  options = [],
  labelText,
  inlineLabel,
  id,
  key,
  value,
  handleKeyDown,
  className,
  onMenuClose,
  smallArrow,
  error,
  rotateArrow,
  searchIndicator,
  clearOnSelect = false,
  isClearable = false,
  ...props
}) => {
  const [values, setValues] = useState<SelectValue | null>();
  const [open, setOpen] = useState(false);
  const [components, setComponents] = useState<any>({
    ClearIndicator: ClearIndicator,
  });

  useEffect(() => {
    setValues(value === '' ? null : value);
  }, [value]);

  useEffect(() => {
    if (smallArrow)
      setComponents({
        ClearIndicator: ClearIndicator,
        DropdownIndicator: DropdownIndicator,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallArrow]);

  useEffect(() => {
    if (searchIndicator)
      setComponents({
        ClearIndicator: ClearIndicator,
        DropdownIndicator: SearchIndicator,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIndicator]);

  return (
    <>
      <div className='form-item-group' key={key}>
        {labelText && (
          <label
            className='form-item-group-label'
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: inlineLabel }}
          ></label>
        )}
        <Select
          onChange={(e: SelectValue) => {
            setValues(e);
          }}
          options={options}
          value={values}
          isClearable={isClearable}
          {...props}
          onKeyDown={e => {
            if (handleKeyDown) {
              handleKeyDown(e);
              setOpen(false);
              setValues(null);
            }
          }}
          noOptionsMessage={e => {
            if (options?.length === 0) return 'No options';
          }}
          components={components}
          className={`${className} ${error ? 'error' : ''}`}
          menuIsOpen={open}
          onMenuOpen={() => setOpen(true)}
          onMenuClose={() => {
            onMenuClose && onMenuClose();
            if (clearOnSelect) setValues(null);

            setOpen(false);
          }}
          classNames={{
            control: state =>
              state.isFocused
                ? 'form-item-control-select'
                : 'form-item-control-select',
          }}
          classNamePrefix='react-select'
        />
        {error && <p className='error-message'>{showError(error)}</p>}{' '}
      </div>
    </>
  );
};

export default FormSelect;
