import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getValue, parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconPin from '../../icons/IconPin';
import FormSelect from '../FormSelect';
import Input from '../Input';
import Spinner from '../Spinner';
import './StreetAddModal.scss';
import { clearTowns } from '../../redux/localities/localities-slice';
import { getLocalities, suggestStreet } from '../../redux/localities/localitiesThunk';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}

export interface FormData {
  countyId: number;
  townId: number;
  zone: string;
  street: string;
  comments: string;
}

const StreetAddModal: React.FC<Props> = ({ isModalOpen, closeModal }) => {
  const dispatch = useAppDispatch();
  const { county, localities, loading } = useAppSelector(state => state.localities);
  const [formData, setFormData] = useState<FormData>({
    countyId: 0,
    townId: 0,
    zone: '',
    street: '',
    comments: '',
  });
  const [formDataErrors, setFormDataErrors] = useState<any>({});

  useEffect(() => {
    if (formData.countyId) {
      dispatch(getLocalities({ parent: formData.countyId })).then(res => {
        parseValues(res.payload)?.length > 0 &&
        setFormData({
          ...formData,
          'townId': Number(parseValues(res.payload)[0].value),
        });
      });
    } else {
      dispatch(clearTowns());
      setFormData({
        ...formData,
        'townId': 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.countyId]);
  const handleInput = (key: string, e: string) => {
    setFormData({ ...formData, [key]: e });
  };

  const resetForm = () => {
    setFormData({
      countyId: 0,
      townId: 0,
      zone: '',
      street: '',
      comments: '',
    });
  };

  const closeStreetModal = () => {
    resetForm();
    closeModal();
  };

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(suggestStreet(formData)).then(res => {
      if (res.payload?.id) {
        toast.success('Propunerea a fost trimisa cu success!');
        closeStreetModal();
      } else {
        toast.error(`Eroare la crearea sugestiei de adresa`,);
        setFormDataErrors(res.payload);
      }
    });
  };

  return (
    <>
      {' '}
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'mobile-modal streets-modal'}
      >
        <Modal.Header>
          {' '}
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          <span>PROPUNE STRADA/ZONA</span>
        </Modal.Header>

        <Modal.Body>
          <div className='add-street-modal'>
            <div className='modal-warning'>
              <IconPin />
              <span>
                Daca crezi ca strada pe care se afla imobilul dorit nu se afla
                pe harta, o poti propune folosind acest formular.
              </span>
            </div>
            <div className='form-item'>
              <FormSelect
                placeholder='Select Options'
                labelText={true}
                isClearable={true}
                inlineLabel='Judet'
                value={
                  formData?.countyId
                    ? getValue(county, formData?.countyId)
                    : null
                }
                error={formDataErrors?.countyId}
                options={parseValues(county) ?? []}
                onChange={(e: SelectValue) => {
                  setFormData({
                    ...formData,
                    'countyId': Number(e?.value),
                  });
                }
                }
              />
            </div>
            <div className='form-item'>
              <FormSelect
                placeholder='Select Options'
                labelText={true}
                isClearable={true}
                value={
                  formData?.townId
                    ? getValue(localities, formData?.townId)
                    : null
                }
                error={formDataErrors?.townId}
                options={parseValues(localities) ?? []}
                onChange={(e: SelectValue) => {
                  setFormData({
                    ...formData,
                    'townId': Number(e?.value),
                  });
                }
                }
                inlineLabel='Localitate'
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                value={formData?.zone}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInput('zone', e.target.value)
                }
                inlineLabel='Zona'
              />
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                value={formData?.street}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInput('street', e.target.value)
                }
                inlineLabel='Strada'
              />
            </div>
            <div className='form-item'>
              <div className='form-item-group'>
                <label
                  htmlFor='content-subject'
                  className='form-item-group-label'
                >
                  Alte comentarii
                </label>
                <textarea
                  id='email-subject'
                  className='form-item-control'
                  value={formData?.comments}
                  onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleInput('comments', e.target.value)
                  }
                  rows={4}
                ></textarea>
              </div>
            </div>

            <div className='btns-container'>
              <button
                onClick={closeStreetModal}
                className='button-border button-md modal-button'
              >
                <span className=''>Anuleaza</span>
              </button>
              <button
                className='button-blue button-md modal-button'
                onClick={submitForm}
              >
                {loading ? <Spinner className='btn-blue-spinner' /> : null}
                <span>Trimite</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StreetAddModal;
