import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';
import { Specialization } from './../../models/Specialization';

export const getSpecializations = createAsyncThunk<Specialization[], any>(
  'crm/getSpecializations',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/specializations', {
        params: { ...data, sort: 'priority' },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
