interface Props {}

const IconBell: React.FC<Props> = props => {
  return (
     <svg
     width='25'
     height='25'
     viewBox='0 0 25 25'
     fill='none'
     xmlns='http://www.w3.org/2000/svg'
     aria-label='Notifications'
     {...props}
   >
     <path
       id='Color'
       fillRule='evenodd'
       clipRule='evenodd'
       d='M5.74441 16.6666L6.97358 15.4354C7.36733 15.0416 7.58399 14.5187 7.58399 13.9624V9.09057C7.58399 7.67703 8.19858 6.32599 9.27149 5.38641C10.3527 4.43849 11.7298 4.02182 13.1642 4.21037C15.5882 4.53224 17.4163 6.72391 17.4163 9.30932V13.9624C17.4163 14.5187 17.6329 15.0416 18.0257 15.4343L19.2559 16.6666H5.74441ZM14.5829 19.1052C14.5829 20.0416 13.6288 20.8333 12.4996 20.8333C11.3705 20.8333 10.4163 20.0416 10.4163 19.1052V18.7499H14.5829V19.1052ZM21.3757 15.8416L19.4996 13.9624V9.30932C19.4996 5.68328 16.8934 2.60307 13.4371 2.14578C11.435 1.87912 9.4142 2.49057 7.89858 3.81974C6.37358 5.15516 5.50066 7.07599 5.50066 9.09057L5.49962 13.9624L3.62358 15.8416C3.13503 16.3312 2.99024 17.0593 3.25483 17.6979C3.52045 18.3375 4.13816 18.75 4.82983 18.75H8.33295V19.1052C8.33295 21.2072 10.2017 22.9166 12.4996 22.9166C14.7975 22.9166 16.6663 21.2072 16.6663 19.1052V18.75H20.1694C20.8611 18.75 21.4777 18.3375 21.7423 17.6989C22.0079 17.0593 21.8642 16.3302 21.3757 15.8416Z'
     />
   </svg>
  );
};

export default IconBell;
