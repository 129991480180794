import React from 'react';
import Form from 'react-bootstrap/Form';
import Input from '../Input/Input';
import Select from '../Select';
import './FilterParticularRequestList.scss';

interface Props {}

const FilterParticularRequestList: React.FC<Props> = () => {
  return (
    <div className='filter-list-container particular-list-item'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check type='checkbox' />
        </Form.Group>
        <div className='filter-item arrows-label-icon'>
          <label>Data</label>
          <div className='flex-input'>
            <Input placeholder='De la' labelText={false} />
            <Input placeholder='Pana la' labelText={false} />
          </div>
        </div>
        <div className='filter-item arrows-label-icon'>
          <Select labelText={true} inlineLabel='Tip solicitare' />
        </div>
        <div className='filter-item arrows-label-icon'>
          <Select labelText={true} inlineLabel={'Tip Imobil'} />
        </div>
        <div className='filter-item arrows-label-icon'>
          <Select labelText={true} inlineLabel='Nr. Camere' />
        </div>
      </form>
    </div>
  );
};

export default FilterParticularRequestList;
