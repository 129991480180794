import React from 'react';
import Modal from 'react-bootstrap/Modal';
import FilterListAgents from '../../components/FilterListAgents/FilterListAgents';
import CloseIcon from '../../assets/svg/closemodal.svg';
import ActionsMobileAgent from '../ActionsMobileAgent';
import SearchMobile from '../SearchMobile';
import './AgentsModal.scss';

interface Props {
  isModalOpen: boolean;
  isAction: boolean;
  isSearch: boolean;
  isFilter: boolean;
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  closeModal: () => void;
  removeItems?: () => void;
  editItems?: () => void;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  filterByLettersOnMobile: boolean;
  setFilterByLettersOnMobile: (p: boolean) => void;
}

const AgentsModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  isAction,
  isSearch,
  isFilter,
  isCheckedAll,
  setCheckedAll,
  removeItems,
  editItems,
  filtersForm,
  setFiltersForm,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={
          isFilter || isAction ? 'mobile-modal filter-overflow' : 'mobile-modal'
        }
      >
        <Modal.Body>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          {isSearch && <SearchMobile />}
          {isAction && (
            <ActionsMobileAgent
              removeItems={removeItems}
              editItems={editItems}
            />
          )}
          {isFilter && (
            <FilterListAgents
              isCheckedAll={isCheckedAll}
              setCheckedAll={setCheckedAll}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
              onSubmit={closeModal}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AgentsModal;
