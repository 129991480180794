import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContactDetailsForm, ContactsForm } from '../../models/Contacts';
import { Filters } from '../../models/Properties';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getContacts = createAsyncThunk<any, Filters | undefined>(
  'auth/getContacts',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/contacts', {
        params: setDefaultLimitForFilters(params),
      });
      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getUserContacts = createAsyncThunk<any, Filters | undefined>(
  'auth/user/getContacts',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/users/contacts', {
        params: setDefaultLimitForFilters(params),
      });

      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getContact = createAsyncThunk<any, string | number>(
  'auth/getContact',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/contacts/' + id);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getContactsTags = createAsyncThunk<any, string>(
  'auth/getContactsTags',
  async (q, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/contacts-tags', {
        params: { q },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getContactTopActivities = createAsyncThunk<any, string | number>(
  'crm/contact/topActivities',
  async (contactId, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/activities', {
        params: { limit: 3, contact: contactId },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getContactTopProperties = createAsyncThunk<any, string | number>(
  'crm/contact/topProperties',
  async (contactId, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/properties', {
        params: { limit: 3, contact: contactId },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getContactsTypes = createAsyncThunk<any>(
  'auth/getContactsTypes',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/contacts-types', {
        params: setDefaultLimitForFilters({}),
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getLeadSources = createAsyncThunk<any>(
  'auth/getLeadSources',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/lead-sources', {
        params: setDefaultLimitForFilters({}),
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const createContact = createAsyncThunk<
  any,
  ContactsForm | ContactDetailsForm
>('auth/createContact', async (data, { rejectWithValue }) => {
  try {
    const response = await appAxios.post('api/v1/crm/contacts', data);
    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const editContact = createAsyncThunk<
  any,
  ContactsForm | ContactDetailsForm
>('auth/editContact', async (data, { rejectWithValue }) => {
  try {
    const response = await appAxios.put('api/v1/crm/contacts/' + data.id, data);
    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

export const removeContacts = createAsyncThunk<any, string>(
  'auth/removeContacts',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete('api/v1/crm/contacts', {
        params: { ids: ids },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const arhiveContacts = createAsyncThunk<any, string>(
  'auth/arhiveContacts',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/contacts/archive?ids=' + ids,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const restoreContacts = createAsyncThunk<any, string>(
  'auth/restoreContacts',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/contacts/restore?ids=' + ids,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

const setDefaultLimitForFilters = (params: Filters | undefined): Filters => {
  const finalParams = { ...params };

  if (!finalParams.limit) {
    finalParams.limit = -1;
  }

  return finalParams;
};
