import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Input from '../../components/Input/Input';
import { useAppSelector } from '../../redux/hooks';
import { Media, PropertyForm } from '../../models/Properties';
import ImagesForm from '../ImagesForm';
import './AddImagesForm.scss';

interface Props {
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
}

const AddImagesForm: React.FC<Props> = ({ formData, setFormData }) => {
  const { property } = useAppSelector(state => state.properties);
  const [images3D, setImages3D] = useState<Media[]>([]);
  const [imagesPropriety, setImagesPropriety] = useState<Media[]>([]);
  const [imagesScheme, setImagesScheme] = useState<Media[]>([]);

  useEffect(() => {
    setImages3D(
      property?.media?.filter(mediaFile => mediaFile.type === 'RENDERINGS') ??
        [],
    );
    setImagesPropriety(
      property?.media?.filter(mediaFile => mediaFile.type === 'MAIN') ?? [],
    );
    setImagesScheme(
      property?.media?.filter(mediaFile => mediaFile.type === 'SKETCHES') ?? [],
    );
  }, [property]);

  useEffect(() => {
    let imagesObject: Media[] = [];

    images3D.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;
      imagesObject.push({ ...image, mediaId: mediaId, type: 'RENDERINGS' });
    });
    imagesPropriety.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;
      imagesObject.push({ ...image, mediaId: mediaId, type: 'MAIN' });
    });
    imagesScheme.forEach((image: Media) => {
      let mediaId = image?.mediaId ?? image?.media?.id;
      imagesObject.push({ ...image, mediaId: mediaId, type: 'SKETCHES' });
    });
    setFormData({ ...formData, media: imagesObject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images3D, imagesPropriety, imagesScheme]);

  return (
    <div className='flex-container display-flex'>
      <Container>
        <Row className='row-54'>
          <Col xl='12' className='content-block mb-36'>
            <h2 className='heading-block'>Video</h2>
            <p>
              Pentru un impact mai mare, este recomandat sa incarci un clip de
              prezentare si un tur virtual.
            </p>
          </Col>
          <Col lg='6'>
            <div className='form-item mb-lg'>
              <Input
                labelText={true}
                inlineLabel='Link video'
                type='text'
                max={60}
                id='video'
                value={formData.videoLink}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, videoLink: e.target.value })
                }
              />
              <p className='form-item-info'>
                <small>
                  Foloseste un link de <a href='/'>Youtube</a> sau
                  <a href='/'>Vimeo</a> de forma: https://www.youtube.com/watch
                  ......
                </small>
              </p>
            </div>
          </Col>
          <Col lg='6'>
            <div className='form-item mb-lg'>
              <Input
                labelText={true}
                inlineLabel='Link tur virtual'
                type='text'
                max={60}
                id='video'
                value={formData.virtualTourLink}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, virtualTourLink: e.target.value })
                }
              />
              <p className='form-item-info'>
                <small>
                  Foloseste un link de embed{' '}
                  <a target='_blank' href='Panoee.com'>
                    Panoee.com
                  </a>
                  ,
                  <a target='_blank' href='Matterport.com'>
                    Matterport.com
                  </a>{' '}
                  ,{' '}
                  <a target='_blank' href='Cupix.com'>
                    Cupix.com
                  </a>
                  ,
                  <a target='_blank' href='Ricoh360.com'>
                    Ricoh360.com
                  </a>
                  ,{' '}
                  <a target='_blank' href='Kuula.co'>
                    Kuula.co
                  </a>
                  ,
                  <a target='_blank' href='Panoro.ro'>
                    Panoro.ro
                  </a>{' '}
                  sau altul, de forma: https://tour.panoee.com/neptune/ ......
                </small>
              </p>
            </div>
          </Col>
          <Col lg='12' className='content-block mb-6'>
            <p>
              * Pentru <span className='link'>oferta personalizata</span>,{' '}
              <span className='link'>Randari 3D</span> , ne puteti contacta la
              adresa <a href='mailto:sales@titirez.ro'>sales@titirez.ro</a>
            </p>
          </Col>

          <Col lg='12' className='content-block mb-42'>
            <legend className='heading-block'>Imagini</legend>
            <p>
              Pune descriere fiecarei poze in parte pentru o mai buna indexare
              in motoarele de cautare. Trage fotografiile pentru aranjare.
            </p>
          </Col>
        </Row>
        <Row className='row-38'>
          <Col lg={4} className='fieldset-br'>
            <div className='heading-block-sm'>
              <h3 className='title'>Randari 3D</h3>
            </div>
            <ImagesForm images={images3D} setImages={setImages3D} />
          </Col>
          <Col lg={4} className='fieldset-br'>
            <div className='heading-block-sm'>
              <h3 className='title'>Fotografii proprietate</h3>
            </div>
            <ImagesForm
              images={imagesPropriety}
              setImages={setImagesPropriety}
            />
          </Col>
          <Col lg={4}>
            <div className='heading-block-sm'>
              <h3 className='title'>Lista schite</h3>
              <span>Adauga aici schitele si planurile proprietatii</span>
            </div>
            <ImagesForm
              images={imagesScheme}
              setImages={setImagesScheme}
              getPDF={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddImagesForm;
