import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import useDebounce from '../../helpers/useDebounce';
import { SelectValue } from '../../models/Properties';
import Search from '../../assets/svg/Search.svg';
import AlphabetFilter from '../AlphabetFilter';
import FormSelect from '../FormSelect';
import Input from '../Input/Input';
import Select from '../Select';
import './FilterListAgents.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  filterByLettersOnMobile: boolean;
  setFilterByLettersOnMobile: (p: boolean) => void;
  onSubmit?: () => void;
}

const statusOptions = [
  { value: 1, label: 'Activ' },
  { value: 0, label: 'Inactiv' },
];

const FilterListAgents: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  filtersForm,
  setFiltersForm,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
  onSubmit,
}) => {
  const [query, setQuery] = useState(filtersForm?.q ?? null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  const debouncedUserSearch = useDebounce(query, 300);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFiltersForm({ ...filtersForm, q: debouncedUserSearch });
  }, [debouncedUserSearch]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const onSelectMobileFilter = (e: SelectValue) => {
    if (e.value === (filterByLettersOnMobile ? '1' : '0')) {
      setFilterByLettersOnMobile(e.value === '0');
      setCheckedAll && setCheckedAll(false);
    }
  };

  const options = [
    { value: '0', label: 'Filtrare dupa litere' },
    { value: '1', label: 'Filtrare dupa campuri' },
  ];

  return (
    <div className='filter-list-container agents-list-filter'>
      <form onSubmit={(event: any) => event.preventDefault()}>
        <div className='form-item hide-on-desktop'>
          <FormSelect
            labelText={false}
            placeholder='Filtrare dupa'
            isSearchable={false}
            options={options}
            className='select-blue'
            onChange={onSelectMobileFilter}
            value={filterByLettersOnMobile ? options[0] : options[1]}
          />
        </div>
        {filterByLettersOnMobile && (
          <div className='alphabet-wrapper hide-on-desktop'>
            <AlphabetFilter
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
            />
          </div>
        )}
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            className='input-center'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
          <div className='form-item-group-label'>Selecteaza toate</div>
        </Form.Group>
        {(!isMobile || !filterByLettersOnMobile) && (
          <>
            <div className='filter-item'>
              <div className='search-container'>
                <label className='form-item-group-label'>Contact</label>
                <div className='form-item-group-block '>
                  <span className='search-before'>Cautare rapida</span>
                  <input
                    type='search'
                    className='form-item-control'
                    placeholder='Nume, Prenume, email sau telefon'
                    name='query'
                    value={query}
                    onChange={(event: any) => setQuery(event.target.value)}
                  />
                  <button className='icon' aria-label='Cauta' type='submit'>
                    <img src={Search} alt='' />
                  </button>
                </div>
              </div>
            </div>
            <div className='filter-item'>
              <Input
                inlineLabel='Proprietati'
                placeholder='Search'
                labelText={true}
              />
            </div>
            <div className='filter-item'>
              <Input
                inlineLabel='Solicitări'
                placeholder='Search'
                labelText={true}
              />
            </div>
            <div className='filter-item'>
              <Input
                inlineLabel='Oferte'
                placeholder='Search'
                labelText={true}
              />
            </div>
            <div className='filter-item'>
              <Input
                inlineLabel='Contacte'
                placeholder='Search'
                labelText={true}
              />
            </div>
            <div className='filter-item'>
              <Select
                labelText={true}
                inlineLabel='Status'
                placeholder='Select'
                options={statusOptions}
              />
            </div>
            <div className='filter-item'>
              <p>Actiuni</p>
            </div>
          </>
        )}
        <div className='bottom-filter-section'>
          <div className='form-line-section'></div>
          <div className='form-btns-section'>
            <button
              className='reset-btn'
              onClick={() => {
                setFiltersForm({ ...filtersForm, q: '' });
                onSubmit && onSubmit();
              }}
            >
              Reseteaza
            </button>
            <button
              className='filter-btn btn-blue'
              onClick={() => onSubmit && onSubmit()}
            >
              Filtreaza
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterListAgents;
