import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import AlphabetFilter from '../../components/AlphabetFilter';
import { useAppSelector } from '../../redux/hooks';
import { getValue, parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import FormSelect from '../FormSelect';
import Input from '../Input/Input';
import Spinner from '../Spinner';
import './ContactsFilter.scss';


interface ContactsFilterForm {
  q?: string;
  company?: number;
  type?: number;
  associatedEmployee?: number;
  leadSource?: number;
  dateAdded?: string;
}

interface Props {
  filtersForm: any;
  loadingBtn?: boolean;
  setLoadingBtn?: (e: boolean) => void;
  setFiltersForm: (e: any) => void;
  isCheckedAll?: boolean;
  setCheckedAll?: (all: boolean) => void;
  filterByLettersOnMobile: boolean;
  setFilterByLettersOnMobile: (p: boolean) => void;
  onSubmit?: () => void;
}

const ContactsFilter: React.FC<Props> = ({
  filtersForm,
  setFiltersForm,
  loadingBtn,
  isCheckedAll,
  setCheckedAll,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
  onSubmit,
  setLoadingBtn,
}) => {
  const [formData, setFormData] = useState<ContactsFilterForm>(filtersForm);
  const { companies } = useAppSelector(state => state.companies);
  const { users } = useAppSelector(state => state.users);
  const { leadSources, contactsTypes } = useAppSelector(
    state => state.contacts,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormData(filtersForm);
  }, [filtersForm]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (JSON.stringify(formData) !== JSON.stringify(filtersForm)) {
      filter(formData);
      setLoadingBtn && setLoadingBtn(true);
    }
  };

  const filter = (formData: ContactsFilterForm) => {
    formData && setFiltersForm(formData);
    setCheckedAll && setCheckedAll(false);
    onSubmit && onSubmit();
  };

  const resetForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFiltersForm({});
    setCheckedAll && setCheckedAll(false);
  };

  const onSelectChange = (filterName: string, filterValue: string | null) => {
    if (filterValue) {
      setFormData({
        ...formData,
        [filterName]: Number(filterValue),
      });
    } else {
      filter({ ...formData, [filterName]: undefined });
    }
  };

  const onSelectMobileFilter = (e: SelectValue) => {
    if (e.value === (filterByLettersOnMobile ? '1' : '0')) {
      setFilterByLettersOnMobile(e.value === '0');
      setFormData({});
      setCheckedAll && setCheckedAll(false);
    }
  };

  const options = [
    { value: '0', label: 'Filtrare dupa litere' },
    { value: '1', label: 'Filtrare dupa campuri' },
  ];

  return (
    <div className='filter-form-container contacts-filter-container'>
      <div className='filter-container'>
        {!isMobile && (
          <div className='header-section display-flex'>
            <h5 className='title-section'>Cautare Rapida</h5>
          </div>
        )}
        <form>
          <div className='form-item hide-on-desktop'>
            <FormSelect
              labelText={false}
              placeholder='Filtrare dupa'
              isSearchable={false}
              options={options}
              className='select-blue'
              onChange={onSelectMobileFilter}
              value={filterByLettersOnMobile ? options[0] : options[1]}
            />
          </div>
          {filterByLettersOnMobile && (
            <div className='alphabet-wrapper hide-on-desktop'>
              <AlphabetFilter
                filtersForm={filtersForm}
                setFiltersForm={setFiltersForm}
              />
            </div>
          )}
          <Form.Group id='formGridCheckbox' className='mobile-text'>
            <Form.Check
              type='checkbox'
              checked={isCheckedAll}
              onChange={() => setCheckedAll && setCheckedAll(!isCheckedAll)}
            />
            <div className='form-item-group-label'>Selecteaza toate</div>
          </Form.Group>
          {(!isMobile || !filterByLettersOnMobile) && (
            <>
              <div className='form-line-section'>
                <div className='form-item'>
                  <Input
                    labelText={true}
                    value={formData?.q ?? ''}
                    type={'search'}
                    placeholder='Nume, prenume, telefon, email'
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '') {
                        filter({ ...formData, q: '' });
                      } else {
                        setFormData({ ...formData, q: e.target.value });
                      }
                    }}
                    inlineLabel='Cautare'
                  />
                </div>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Select Option'
                    inlineLabel={'Companie'}
                    isSearchable={true}
                    isClearable={true}
                    value={
                      formData?.company
                        ? getValue(companies, formData?.company)
                        : null
                    }
                    options={companies.length > 0 ? parseValues(companies) : []}
                    onChange={(e: SelectValue) =>
                      onSelectChange('company', e?.value)
                    }
                  />
                </div>
              </div>
              <div className='form-line-section'>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Select Option'
                    inlineLabel={'Tip contact'}
                    isSearchable={true}
                    isClearable={true}
                    value={
                      formData?.type
                        ? getValue(contactsTypes, formData?.type)
                        : null
                    }
                    options={
                      contactsTypes?.length > 0 && parseValues(contactsTypes)
                    }
                    onChange={(e: SelectValue) =>
                      onSelectChange('type', e?.value)
                    }
                  />
                </div>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Select Option'
                    inlineLabel={'Agent asociat'}
                    isSearchable={true}
                    isClearable={true}
                    value={
                      formData?.associatedEmployee
                        ? getValue(users, formData?.associatedEmployee)
                        : null
                    }
                    options={users.length > 0 ? parseValues(users) : []}
                    onChange={(e: SelectValue) =>
                      onSelectChange('associatedEmployee', e?.value)
                    }
                  />
                </div>
              </div>
              <div className='form-line-section'>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Select Option'
                    inlineLabel={'Sursa Lead'}
                    isSearchable={true}
                    isClearable={true}
                    value={
                      formData?.leadSource
                        ? getValue(leadSources, formData?.leadSource)
                        : null
                    }
                    options={
                      leadSources.length > 0 ? parseValues(leadSources) : []
                    }
                    onChange={(e: SelectValue) =>
                      onSelectChange('leadSource', e?.value)
                    }
                  />
                </div>
                <div className='form-item form-item-group'>
                  <label className='form-item-group-label'>
                    Data adaugarii
                  </label>
                  <DatePicker
                    selected={
                      formData?.dateAdded ? new Date(formData?.dateAdded) : null
                    }
                    onChange={date => {
                      if (date) {
                        setFormData({
                          ...formData,
                          dateAdded: moment(date).format('YYYY-MM-DD'),
                        });
                      } else {
                        filter({ ...formData, dateAdded: undefined });
                      }
                    }}
                  />
                </div>{' '}
              </div>
            </>
          )}
          <div className='bottom-filter-section'>
            <div className='form-btns-section'>
              <button className='reset-btn' onClick={e => resetForm(e)}>
                Reseteaza{' '}
              </button>
              <button
                className='filter-btn btn-blue'
                onClick={e => handleSubmit(e)}
              >
                {loadingBtn && <Spinner className='btn-blue-spinner' />}
                Filtreaza
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactsFilter;
