interface Props {}

const IconNext: React.FC<Props> = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.546 11.5C9.983 11.5 6.769 13.134 4.736 15.759C5.671 11.89 9.266 8.99998 13.546 8.99998C14.099 8.99998 14.546 8.55298 14.546 7.99998V6.07698L19.37 10.251L14.546 14.463V12.5C14.546 11.947 14.099 11.5 13.546 11.5ZM3.779 21C4.24 21 4.642 20.685 4.751 20.237C5.641 16.588 8.772 13.952 12.546 13.553V14.674C12.546 15.38 12.965 16.009 13.639 16.314C14.37 16.647 15.223 16.527 15.816 16.01L20.882 11.586C21.275 11.242 21.5 10.755 21.5 10.25C21.5 9.74498 21.275 9.25798 20.882 8.91398L15.816 4.48998C15.223 3.97298 14.37 3.85298 13.639 4.18598C12.965 4.49098 12.546 5.11998 12.546 5.82598V7.04398C6.922 7.53698 2.5 12.15 2.5 17.75C2.5 18.56 2.604 19.396 2.808 20.236C2.916 20.685 3.317 21 3.779 21Z"
      fill="#1D1956"
    />
  </svg>
  );
};

export default IconNext;
