import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip';
import { getFilter, getImagePath } from '../../helpers/useHelper';
import { ContactsDetails, Property } from '../../models/Properties';
import Avatar from '../../assets/img/avatar.png';
import House from '../../assets/img/house_square.png';
import IconArrowDown from '../../icons/IconArrowDown';
import IconEye from '../../icons/IconEye';
import IconPencil from '../../icons/IconPencil';
import GalleryModal from '../GalleryModal';
import PricesHistoryModal from '../PricesHistoryModal';
import './PropertyListItem.scss';
import { useLocation } from 'react-router-dom';


interface Props {
  item: Property;
  checked?: boolean;
  handleCheckboxChange?: (id: number) => void;
  showChecked?: boolean;
  isExpanded?: number | null;
  setExpanded?: (e: number | null) => void;
}

const PropertyListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
  showChecked = true,
  isExpanded,
  setExpanded,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpened, setOpened] = useState(false);
  const [descTab, setDescTab] = useState(0);
  const [openedPrices, setPricesModal] = useState(false);
  const [extendCard, setExtendCard] = useState(window.innerWidth >= 1080);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExtendCard = () => {
    setExtendCard(!extendCard);
  };

  const handleResize = () => {
    setExtendCard(window.innerWidth >= 1080);
  };

  const closeModal = () => {
    setOpened(false);
  };

  const image =
    item?.media?.filter(
      media => media.status === 1 && media.type === 'MAIN' && media.media?.path,
    ) &&
    item?.media?.filter(
      media => media.status === 1 && media.type === 'MAIN' && media.media?.path,
    ).length > 0
      ? item?.media?.filter(
          media =>
            media.status === 1 && media.type === 'MAIN' && media.media?.path,
        )[0] ?? ''
      : null;

  return (
    item && (
      <>
        <div
          onClick={(e: any) => {
            if (
              e.target?.className !== 'form-check-input' &&
              e.target?.className !== 'button-square-md' &&
              e.target?.className !==
                'fade mobile-modal prices-modal modal show' &&
              e.target?.className?.baseVal !== 'button-square-svg'
            )
              setExpanded &&
                setExpanded(isExpanded === item.id ? null : item.id);
          }}
          key={item.id}
          className={`announces-list-item property-item ${checked ? 'active' : ''} ${isExpanded === item.id ? 'expanded' : ''} ${extendCard ? '' : 'close'}`}
        >
          {showChecked && (
            <div className='mobile-lines'>
              <div className='line-border'></div>
              <div className='checkbox-container'>
                <Form.Group id='formGridCheckbox'>
                  <Form.Check
                    type='checkbox'
                    checked={checked}
                    readOnly
                    onClick={() =>
                      handleCheckboxChange && handleCheckboxChange(item.id)
                    }
                  />
                </Form.Group>
              </div>
              <div className='line-border'></div>
            </div>
          )}
          <div className='property-item-details'>
            <div className='property-item-top'>
              <div className='img-title-section'>
                {image && image.media?.path ? (
                  <div
                    className='img-container'
                    onClick={() => setOpened(true)}
                  >
                    <img src={getImagePath(image.media?.path)} alt='' />
                  </div>
                ) : (
                  <div className='img-container'>
                    {' '}
                    <img src={House} alt='' />
                  </div>
                )}{' '}
                <div className='details-container'>
                  {item?.title && (
                    <div className='title-text'>{item?.title}</div>
                  )}
                  {item?.prices &&
                    item?.prices?.length > 0 &&
                    item?.prices.map(price => {
                      if (price.id === 1 && price.sellingPrice)
                        return (
                          <div className='rent-text'>
                            <span>VANZARE</span>
                            {price.sellingPrice} {item.currencySymbol ?? '€'}
                          </div>
                        );
                      if (price.id === 2 && price.rentalMonthPrice)
                        return (
                          <div className='rent-text'>
                            <span>INCHIRIERE</span>
                            {price.rentalMonthPrice} {item.currencySymbol ?? '€'} / luna
                          </div>
                        );
                      if (price.id === 3 && price.rentalDayPrice)
                        return (
                          <div className='rent-text'>
                            <span>REGIM HOTELIER</span>
                            {price.rentalDayPrice} {item.currencySymbol ?? '€'} / zi
                          </div>
                        );
                      if (price.id === 4 && price.sellingPriceSqm)
                        return (
                          <div className='rent-text'>
                            <span>VANZARE MP</span>
                            {price.sellingPriceSqm} {item.currencySymbol ?? '€'} / mp
                          </div>
                        );
                      if (price.id === 5 && price.rentalMonthPriceSqm)
                        return (
                          <div className='rent-text'>
                            <span>INCHIRIERE MP</span>
                            {price.rentalMonthPriceSqm} {item.currencySymbol ?? '€'} / mp
                          </div>
                        );
                      else return null;
                    })}
                </div>
              </div>
              {item?.description && (
                <div className='description-text'>{item?.description}</div>
              )}{' '}
              {(item?.filterSurfaceUValue ||
                item?.filterSurfaceCValue ||
                item?.filterStaircaseValue ||
                item?.filterYearValue ||
                item?.categoryTitle) && (
                <div className='pills-details'>
                  {item?.filterSurfaceUValue && (
                    <span className='label-item-tag'>
                      SU: {item?.filterSurfaceUValue}mp
                    </span>
                  )}
                  {item?.filterSurfaceCValue && (
                    <span className='label-item-tag'>
                      SC: {item?.filterSurfaceCValue}mp
                    </span>
                  )}
                  {item?.categoryTitle && (
                    <span className='label-item-tag'>
                      Tip: {item?.categoryTitle}
                    </span>
                  )}
                  {item?.filterStaircaseValue && (
                    <span className='label-item-tag'>
                      Etaj: {item?.filterStaircaseValue}
                    </span>
                  )}
                  {item?.filterYearValue && (
                    <span className='label-item-tag'>
                      AN: {item?.filterYearValue}
                    </span>
                  )}
                </div>
              )}
            </div>
            {extendCard && (
              <div className='hidden-content'>
                <div className='line-border' />
                <div className='list-item-content'>
                  <div className='column-item'>
                    <div className='mobile-text'>ID</div>
                    <div className='info-id label-item-mobile'>{item.id}</div>
                  </div>
                  <div className='column-item column-photo'>
                    <div className='mobile-text'>Photo</div>
                    <div className='info-photo'>
                      {image && image?.media?.path ? (
                        <img
                          onClick={() => setOpened(true)}
                          src={getImagePath(image.media?.path)}
                          alt=''
                        />
                      ) : (
                        <img src={House} alt='' />
                      )}
                    </div>
                  </div>
                  <div className='column-item hide-mobile'>
                    {item?.categoryTitle && (
                      <>
                        <div className='mobile-text'>Tip</div>
                        <div className='info-type label-item-mobile'>
                          {item?.categoryTitle}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='column-item'>
                    {item?.filterRoomValue && (
                      <>
                        <div className='mobile-text'>Camere</div>
                        <div className='info-room label-item-mobile'>
                          {item?.filterRoomValue}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='column-item '>
                    {item?.filterStaircaseValue && (
                      <>
                        <div className='mobile-text'>Nivel</div>
                        <div className='info-level label-item-mobile'>
                          {item?.filterStaircaseValue}
                          {(item?.filterPFloorValue ||
                            item?.filterSemiBasementFloorValue ||
                            item?.filterBlockFloorValue ||
                            item?.filterBasementFloorValue ||
                            item?.filterAtticFloorValue) &&
                            ' / '}
                          {[
                            item?.filterPFloorValue,
                            item?.filterSemiBasementFloorValue,
                            item?.filterBlockFloorValue,
                            item?.filterBasementFloorValue,
                            item?.filterAtticFloorValue,
                          ]
                            .filter(value => value)
                            .join('+')}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='column-item hide-mobile'>
                    {(item?.filterSurfaceCValue ||
                      item?.filterSurfaceUValue) && (
                      <>
                        <div className='mobile-text'>Suprafata</div>
                        <div className='column-content'>
                          {item?.filterSurfaceCValue && (
                            <div className='info-surface label-item-mobile'>
                              SC: {item?.filterSurfaceCValue} mp
                            </div>
                          )}
                          {item?.filterSurfaceUValue && (
                            <div className='info-surface label-item-mobile'>
                              SU: {item?.filterSurfaceUValue} mp
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='column-item hide-mobile'>
                    {item?.filterYearValue && (
                      <>
                        <div className='mobile-text'>An</div>
                        <div className='info-year label-item-mobile'>
                          {item?.filterYearValue}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='info-adress'>
                    {item?.townTitle && (
                      <div className='display-flex'>
                        <span>Localitate </span>
                        <span className='label-item-mobile label-item-rounded-info-sm'>
                          {item?.townTitle}
                        </span>
                      </div>
                    )}
                    {item?.streetTitle && (
                      <div className='display-flex hide-mobile'>
                        <span>Adresa </span>
                        <span className='label-item-mobile label-item-rounded-info-sm'>
                          {'str. ' +
                            item?.streetTitle +
                            (item?.streetNumber
                              ? ', nr. ' + item?.streetNumber
                              : '') +
                            (item?.staircase
                              ? ', bl. ' + item?.staircase
                              : '') +
                            (item?.floorNumber
                              ? ', ap. ' + item?.floorNumber
                              : '')}
                        </span>
                      </div>
                    )}

                    {item?.contacts && item?.contacts?.length > 0 && (
                      <div className='display-flex'>
                        <span>Contacte </span>
                        <span className='contact-list column-content'>
                          {item?.contacts?.map((contact: ContactsDetails) => (
                            <span className='label-item-mobile label-item-rounded-info-sm contact-item'>
                              {contact?.contact.name +
                                ' ' +
                                contact?.contact.surname +
                                ' ' +
                                contact?.contact?.internationalPhoneNumber}
                            </span>
                          ))}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='info-data'>
                    {item?.dateCreated && (
                      <>
                        <div className='display-flex '>
                          <span>Adaugat</span>
                          <span className='label-item-mobile label-item-rounded-info-sm'>
                            {moment(item?.dateCreated).format(
                              'DD.MM.YYYY HH:mm',
                            )}
                          </span>
                        </div>
                      </>
                    )}
                    {item?.dateUpdated && (
                      <>
                        <div className='display-flex '>
                          <span>Modificat</span>
                          <span className='label-item-mobile label-item-rounded-info-sm'>
                            {moment(item?.dateUpdated).format(
                              'DD.MM.YYYY HH:mm',
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='column-item'>
                    <div className='mobile-text'>Pret</div>
                    <div className='column-content'>
                      {item?.prices &&
                        item?.prices?.length > 0 &&
                        item?.prices.map(price => {
                          if (price.id === 1 && price.sellingPrice)
                            return (
                              <div className='display-flex gap-10'>
                                <div className=' label-item-mobile'>
                                  {price.sellingPrice} {item.currencySymbol ?? '€'}
                                </div>
                              </div>
                            );
                          if (price.id === 2 && price.rentalMonthPrice)
                            return (
                              <div className='display-flex gap-10'>
                                <div className=' label-item-mobile'>
                                  {price.rentalMonthPrice} {item.currencySymbol ?? '€'} / luna
                                </div>
                              </div>
                            );
                          if (price.id === 3 && price.rentalDayPrice)
                            return (
                              <div className='display-flex gap-10'>
                                <div className=' label-item-mobile'>
                                  {price.rentalDayPrice} {item.currencySymbol ?? '€'} / zi
                                </div>
                              </div>
                            );
                          if (price.id === 4 && price.sellingPriceSqm)
                            return (
                              <div className='display-flex gap-10'>
                                <div className=' label-item-mobile'>
                                  {price.sellingPriceSqm} {item.currencySymbol ?? '€'} / mp
                                </div>
                              </div>
                            );
                          if (price.id === 5 && price.rentalMonthPriceSqm)
                            return (
                              <div className='display-flex gap-10'>
                                <div className=' label-item-mobile'>
                                  {price.rentalMonthPriceSqm} {item.currencySymbol ?? '€'} / mp
                                </div>
                              </div>
                            );
                          else return null;
                        })}
                    </div>
                  </div>
                  <div className='column-item'>
                    {/* <div className='mobile-text'>Promovare</div> */}
                    {/* <div className='label-item-tag label-item-mobile'>?</div> */}
                  </div>
                  <div className='column-item'>
                    {item?.employeeFullName && (
                      <>
                        <div className='mobile-text'>Agent</div>
                        <div className='info-agent'>
                          {item?.employeeAvatarPath ? (
                            <div className='info-image'>
                              <img
                                src={getImagePath(item?.employeeAvatarPath)}
                                alt=''
                              />
                            </div>
                          ) : (
                            <div className='info-image'>
                              <img src={Avatar} alt='' />
                            </div>
                          )}
                          <div className='info-name'>
                            {item?.employeeFullName}
                          </div>
                        </div>
                      </>
                    )}
                  </div>{' '}
                  <div className='info-icons hide-mobile'>
                    <button
                      className='button-square-md'
                      aria-label=''
                      data-tooltip-id='edit'
                      data-tooltip-content='Modifica proprietate'
                      onClick={() => navigate('/properties/' + item.id, {
                          state: {
                            isFromAgencyList: location.pathname === '/agency-properties',
                          }
                        })
                      }
                    >
                      <IconPencil />
                    </button>
                    <Tooltip id='edit' />
                    <button
                      className='button-square-md'
                      data-tooltip-id='view'
                      data-tooltip-content='Istoric preturi'
                      aria-label=''
                      onClick={() => setPricesModal(true)}
                    >
                      <IconEye className='button-square-svg' />
                    </button>
                    <Tooltip id='view' />
                  </div>
                </div>

                <div className='line-border'></div>
                <div className='actions-container'>
                  <div className='icons-container'>
                    <p className='icons-title'>Actiuni</p>
                    <button
                      className='button-square-md'
                      data-tooltip-id='edit'
                      data-tooltip-content='Modifica proprietate'
                      aria-label=''
                      onClick={() => navigate('/properties/' + item.id, {
                          state: {
                            isFromAgencyList: location.pathname === '/agency-properties',
                          }
                        })
                      }
                    >
                      <IconPencil />
                    </button>
                    <Tooltip id='edit' />
                    <button
                      className='button-square-md'
                      aria-label=''
                      data-tooltip-id='view'
                      data-tooltip-content='Istoric preturi'
                      onClick={() => setPricesModal(true)}
                    >
                      <IconEye />
                    </button>
                    <Tooltip id='view' />
                  </div>
                </div>
              </div>
            )}
            <div className='bottom-border'>
              <div className='property-item-more'>
                <button
                  className={`more-button ${extendCard ? '' : 'open'}`}
                  onClick={handleExtendCard}
                >
                  <span className='more-button-border'>
                    <span className='arrow-icon'>
                      <IconArrowDown />
                    </span>
                  </span>
                  <span>{extendCard ? 'Mai putin' : 'Mai mult'}</span>
                </button>
              </div>
            </div>
          </div>

          <GalleryModal
            closeModal={closeModal}
            isModalOpen={isOpened}
            images={
              item?.media?.filter(
                media =>
                  media.status === 1 &&
                  media.type === 'MAIN' &&
                  media.media?.path,
              ) ?? []
            }
          />
          <PricesHistoryModal
            closeModal={() => setPricesModal(false)}
            isModalOpen={openedPrices}
            prices={item?.prices ?? []}
          />
        </div>
        {isExpanded === item.id && (
          <div
            className='property-item-options announces-list-item'
            key={item.id + 'expand'}
          >
            <div className='property-item-options__column'>
              <div className='property-item-options__title'>Detalii</div>
              <div className='property-item-options__list'>
                {item?.filterSurfaceUValue && (
                  <div className='property-item-options__row'>
                    <div className='property-item-options__label'>
                      Suprafata utila:
                    </div>
                    <div className='property-item-options__value'>
                      {item?.filterSurfaceUValue} mp
                    </div>
                  </div>
                )}

                {item?.filterYearValue && (
                  <div className='property-item-options__row'>
                    <div className='property-item-options__label'>
                      An constructie:
                    </div>
                    <div className='property-item-options__value'>
                      {item?.filterYearValue}
                    </div>
                  </div>
                )}

                {item?.specificTypeTitle && (
                  <div className='property-item-options__row'>
                    <div className='property-item-options__label'>
                      Tip specific:{' '}
                    </div>
                    <div className='property-item-options__value'>
                      {item?.specificTypeTitle}
                    </div>
                  </div>
                )}

                {item?.locationTitle && (
                  <div className='property-item-options__row'>
                    <div className='property-item-options__label'>
                      Amplasament:{' '}
                    </div>
                    <div className='property-item-options__value'>
                      {item?.locationTitle}
                    </div>
                  </div>
                )}

                {getFilter(18, item).exists && (
                  <div className='property-item-options__row'>
                    <div className='property-item-options__label'>
                      Compartimentare:{' '}
                    </div>
                    <div className='property-item-options__value'>
                      {getFilter(18, item).value}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='property-item-options__column'>
              <div className='property-item-options__title'>Optionale</div>
              <div className='property-item-options__list'>
                {item.features.map(feature => (
                  <div className='property-item-options__list--item'>
                    » {feature.title}
                  </div>
                ))}
              </div>
            </div>
            <div className='property-item-options__column'>
              <div className='property-item-options__header'>
                <div
                  className={`property-item-options__title ${descTab === 0 ? 'active' : ''}`}
                  onClick={() => setDescTab(0)}
                >
                  Descriere anunt
                </div>
                <div
                  className={`property-item-options__title ${descTab === 1 ? 'active' : ''}`}
                  onClick={() => setDescTab(1)}
                >
                  Descriere privata
                </div>
              </div>
              <div className='property-item-options__desc'>
                "{descTab === 0 ? item.description : item.privateDescription}"
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default PropertyListItem;
