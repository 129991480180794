import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestEditSubmit, RequestSubmit } from '../../models/Requests';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const createRequest = createAsyncThunk<any, RequestSubmit>(
  'auth/createRequest',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/crm/requests', data);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getRequests = createAsyncThunk<any, any>(
  'auth/getRequests',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/requests', {
        params: data,
      });
      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeRequests = createAsyncThunk<string, string>(
  'auth/removeRequests',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete('api/v1/crm/requests', {
        params: {
          ids: ids,
        },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const arhiveRequests = createAsyncThunk<string, string>(
  'auth/arhiveRequests',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/requests/archive?ids=' + ids,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const updateRequest = createAsyncThunk<any, RequestEditSubmit>(
  'auth/updateRequest',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.put('api/v1/crm/requests/' + data.id, {
        ...data.form,
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const restoreRequests = createAsyncThunk<string, string>(
  'auth/restoreRequests',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/requests/restore?ids=' + ids,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// filters

export const getRequestFilters = createAsyncThunk<any>(
  'auth/getRequestFilters',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/filters', {
        params: { limit: -1, type: 'REQUEST' },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const setProperty = createAsyncThunk<any, any>(
  'auth/setProperty',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/requests/properties',
        data,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getRequest = createAsyncThunk<any, string>(
  'auth/getRequest',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/requests/' + data);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const sendEmail = createAsyncThunk<any, any>(
  'auth/sendEmail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/requests/' + data.id + '/send-email',
        data.form,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
