import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import { createContact, getContacts, getLeadSources } from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import useHelper, {
  getValue,
  parseValues,
  showError,
} from '../../helpers/useHelper';

import { ContactsForm } from '../../models/Contacts';
import { SelectValue } from '../../models/Properties';

import CloseIcon from '../../assets/svg/closemodal.svg';

import FormSelect from '../FormSelect';
import Input from '../Input';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import './ContactsModal.scss';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}

const ContactsModal: React.FC<Props> = ({ isModalOpen, closeModal }) => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const { users } = useAppSelector(state => state.users);
  const { leadSources, error } = useAppSelector(state => state.contacts);
  const [formData, setFormData] = useState<ContactsForm>({
    emails: [''],
    phoneNumbers: [
      { phone: '', needVerification: 1 },
      { phone: '', needVerification: 1 },
    ],
  });

  useEffect(() => {
    dispatch(getLeadSources());
  }, []);

  const submitForm = () => {
    formData &&
      dispatch(createContact(formData)).then(res => {
        if (res.payload?.id) {
          toast.success('Contactul a fost creat cu success!');
          dispatch(getContacts());
          setFormData({
            emails: [''],
            phoneNumbers: [
              { phone: '', needVerification: 1 },
              { phone: '', needVerification: 1 },
            ],
          });
          closeModal();
        } else diplayErrorMessages(res.payload);
      });
  };

  const extractPhone = (input: string): string => {
    const numbers = input.match(/\d+/g);
    const joinedNumbers = numbers ? numbers.join('') : '';
    const hasPlus = input.trim().startsWith('+');

    return hasPlus ? '+' + joinedNumbers : joinedNumbers;
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'mobile-modal activities-modal'}
      >
        <Modal.Header>
          {' '}
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          <span>Adauga contact</span>
        </Modal.Header>
        <Modal.Body>
          <div className='add-activity-modal'>
            <div className='form-item'>
              <Input
                labelText={true}
                value={formData?.name}
                classInput={error?.name ? 'error' : ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                inlineLabel='Nume:'
              />{' '}
              {error?.name && (
                <p className='error-message'>{showError(error.name)}</p>
              )}
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                value={formData?.surname}
                classInput={error?.surname ? 'error' : ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, surname: e.target.value })
                }
                inlineLabel='Prenume:'
              />{' '}
              {error?.surname && (
                <p className='error-message'>{showError(error.surname)}</p>
              )}
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                value={formData?.phoneNumbers[0].phone}
                type='tel'
                classInput={error?.phoneNumbers ? 'error' : ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let newPhone = formData.phoneNumbers;
                  newPhone[0].phone = extractPhone(e.target.value);
                  setFormData({
                    ...formData,
                    phoneNumbers: newPhone,
                  });
                }}
                inlineLabel='Telefon 1:'
              />
            </div>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                label='Verifica telefon'
                checked={formData.phoneNumbers[0].needVerification === 1}
                onChange={() => {
                  let newPhone = formData.phoneNumbers;
                  newPhone[0].needVerification =
                    formData.phoneNumbers[0].needVerification === 1 ? 0 : 1;
                  setFormData({
                    ...formData,
                    phoneNumbers: newPhone,
                  });
                }}
              />
            </Form.Group>
            <div className='form-item'>
              <Input
                labelText={true}
                type='tel'
                value={formData?.phoneNumbers[1].phone}
                classInput={error?.phoneNumbers ? 'error' : ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let newPhone = formData.phoneNumbers;
                  newPhone[1].phone = extractPhone(e.target.value);
                  setFormData({
                    ...formData,
                    phoneNumbers: newPhone,
                  });
                }}
                inlineLabel='Telefon 2:'
              />
            </div>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                label='Verifica telefon'
                checked={formData.phoneNumbers[1].needVerification === 1}
                onChange={() => {
                  let newPhone = formData.phoneNumbers;
                  newPhone[1].needVerification =
                    formData.phoneNumbers[1].needVerification === 1 ? 0 : 1;
                  setFormData({
                    ...formData,
                    phoneNumbers: newPhone,
                  });
                }}
              />
            </Form.Group>
            <div className='form-item'>
              <Input
                labelText={true}
                value={formData?.emails?.[0]}
                classInput={error?.emails ? 'error' : ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    emails: [e.target.value],
                  })
                }
                inlineLabel='Email 1:'
              />{' '}
              {error?.emails && (
                <p className='error-message'>{showError(error.emails)}</p>
              )}
            </div>
            <div className='form-item'>
              <Input
                labelText={true}
                type='tel'
                value={formData?.internationalPhoneNumber}
                classInput={error?.internationalPhoneNumber ? 'error' : ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    internationalPhoneNumber: extractPhone(e.target.value),
                  })
                }
                inlineLabel='Tel. international:'
              />
              {error?.internationalPhoneNumber && (
                <p className='error-message'>
                  {showError(error.internationalPhoneNumber)}
                </p>
              )}
            </div>
            <div className='form-item'>
              <FormSelect
                labelText={true}
                placeholder='Select Options'
                inlineLabel={'Sursa Lead:'}
                isSearchable={true}
                className={error?.leadSourceId ? 'error' : ''}
                value={
                  formData.leadSourceId &&
                  getValue(leadSources, formData.leadSourceId)
                }
                options={leadSources.length > 0 ? parseValues(leadSources) : []}
                onChange={(e: SelectValue) =>
                  setFormData({ ...formData, leadSourceId: Number(e.value) })
                }
              />
              {error?.leadSourceId && (
                <p className='error-message'>{showError(error.leadSourceId)}</p>
              )}
            </div>
            <div className='form-item-group'>
              <label className='form-item-group-label' htmlFor=''>
                Nota:{' '}
              </label>
              <div className={`form-item-group-block`}>
                <textarea
                  name=''
                  id=''
                  rows={4}
                  className={`form-item-control `}
                  value={formData.notes}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setFormData({
                      ...formData,
                      notes: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            <div className='form-item'>
              <MultiselectCheckboxes
                onChange={(e: number[]) => {
                  setFormData({
                    ...formData,
                    associatedEmployee: e,
                  });
                }}
                labelText={true}
                inlineLabel={'Asociaza alti agenti:'}
                value={
                  formData?.associatedEmployee
                    ? formData.associatedEmployee.map(employee =>
                        String(employee),
                      )
                    : null
                }
                options={parseValues(users) ?? []}
              />
            </div>
            <div className='btns-container'>
              <button className='reset-btn' onClick={() => closeModal()}>
                Anuleaza
              </button>
              <button
                className='button-blue button-md'
                onClick={() => submitForm()}
              >
                Trimite
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactsModal;
