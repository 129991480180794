import moment from 'moment';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import './PricesHistoryTable.scss';

interface Props {
  prices: any;
}

const PricesHistoryTable: React.FC<Props> = ({ prices }) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let listData: any[] = [];

    prices.forEach((price: any) => {
      let name = '';
      switch (price.id) {
        case 1:
          name = 'Vanzare';
          break;
        case 2:
          name = 'Inchiriere';
          break;
        case 3:
          name = 'Regim hotelier';
          break;
        case 4:
          name = 'Vanzare MP';
          break;
        case 5:
          name = 'Inchiriere MP';
          break;
        default:
          name = '';
      }
      price.history.forEach((history: any) => {
        listData.push({
          name: name,
          date: moment(history.dateCreated).format('DD-MM-YYYY'),
          price: history.price,
        });
      });
    });

    setData(listData);
  }, [prices]);

  return (
    <div className='prices-history-container'>
      <div className='table-item'>
        <Table striped hover>
          <thead>
            <tr>
              <th>Tip</th>
              <th>Pret</th>
              <th>Data modificarii</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row: any, index: number) => (
              <tr key={index}>
                <td>
                  <span>{row.name}</span>
                </td>
                <td>
                  <span>{row.price}</span>
                </td>
                <td>
                  <span>{row.date}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default PricesHistoryTable;
