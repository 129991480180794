import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '../../assets/svg/closemodal.svg';
import ActionsMobileContact from '../ActionsMobileContact';
import ContactsFilter from '../ContactsFilter';
import SearchMobile from '../SearchMobile';
import './ContactListModal.scss';

interface Props {
  isModalOpen: boolean;
  isAction: boolean;
  isSearch: boolean;
  isFilter: boolean;
  closeModal: () => void;
  removeItems?: () => void;
  exportItems?: () => void;
  editItems?: () => void;
  arhiveItems?: () => void;
  restoreItems?: () => void;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  filterByLettersOnMobile: boolean;
  setFilterByLettersOnMobile: (p: boolean) => void;
}

const ContactListModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  isAction,
  isSearch,
  isFilter,
  filtersForm,
  setFiltersForm,
  removeItems,
  editItems,
  exportItems,
  arhiveItems,
  restoreItems,
  isCheckedAll,
  setCheckedAll,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={
          isFilter || isAction ? 'mobile-modal filter-overflow' : 'mobile-modal'
        }
      >
        <Modal.Body>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          {isSearch && <SearchMobile />}
          {isAction && (
            <ActionsMobileContact
              removeItems={removeItems}
              editItems={editItems}
              exportItems={exportItems}
              arhiveItems={arhiveItems}
              restoreItems={restoreItems}
            />
          )}
          {isFilter && (
            <ContactsFilter
              setCheckedAll={setCheckedAll}
              isCheckedAll={isCheckedAll}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              onSubmit={closeModal}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactListModal;
