import { createSlice } from '@reduxjs/toolkit';
import {
  ActivityItem,
} from '../../models/Activities';
import {
  getNotificationActivities,
} from './notificationsThunk';

type PropertiesState = {
  activities: ActivityItem[];
  loading: boolean;
  totalCount: number;
  activitiesLoading: boolean;
  error: any;
};

const initialState: PropertiesState = {
  activities: [],
  loading: false,
  totalCount: 0,
  activitiesLoading: false,
  error: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getNotificationActivities.pending, state => {
        state.activitiesLoading = true;
        state.error = null;
      })
      .addCase(getNotificationActivities.fulfilled, (state, { payload }) => {
        state.activities = payload.data;
        state.totalCount = payload.totalCount;
        state.activitiesLoading = false;
        state.error = null;
      })
      .addCase(getNotificationActivities.rejected, (state, action) => {
        state.error = action.payload;
        state.activitiesLoading = false;
      })
  },
});

export const {
  clearError,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
