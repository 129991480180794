import { createAsyncThunk } from '@reduxjs/toolkit';
import { AgentRequestDto, UserProfile } from '../../models/AgentsDto';
import { Agent, Organization, OrganizationForm } from '../../models/Users';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

type UsersResponse = {
  users: Agent[];
  totalCount: number;
};

export const getUsers = createAsyncThunk<UsersResponse, any>(
  'auth/getUsers',
  async (data, { rejectWithValue }) => {
    try {
      if (!data.status) {
        data.status = '0,1';
      }
      if (!data.limit) {
        data.limit = '-1';
      }

      const response = await appAxios.get('api/v1/crm/users', { params: data });
      const totalCount = response.headers['x-total-count'];

      return { users: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getUser = createAsyncThunk<UserProfile, any>(
  'crm/user',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/users/${id}`);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getUserProfile = createAsyncThunk<UserProfile>(
  'crm/user/profile',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/users/profile`);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const addUser = createAsyncThunk<UserProfile, AgentRequestDto>(
  'crm/user/add',
  async (user, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(`api/v1/crm/users`, user.body);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const editUser = createAsyncThunk<UserProfile, AgentRequestDto>(
  'crm/user/edit',
  async (user, { rejectWithValue }) => {
    try {
      const response = await appAxios.put(
        `api/v1/crm/users/${user.id}`,
        user.body,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const deleteUser = createAsyncThunk<Agent[], any>(
  'crm/user/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete(`api/v1/crm/users/${id}`);

      return { ...response.data, id };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getOrganization = createAsyncThunk<Organization>(
  'users/getOrganization',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/organizations`);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const editOrganization = createAsyncThunk<
  Organization,
  OrganizationForm
>('users/editOrganization', async (organization, { rejectWithValue }) => {
  try {
    const response = await appAxios.put(
      `api/v1/crm/organizations`,
      organization,
    );

    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});
