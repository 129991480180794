import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import ButtonSquare from '../../components/ButtonSquare/ButtonSquare';
import { resolveActivity } from '../../redux/activities/activitiesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getImagePath } from '../../helpers/useHelper';
import Avatar from '../../assets/img/avatar.png';
import CalculatorIcon from '../../assets/svg/asociate.svg';
import './AdsAllActivity.scss';

interface Props {
  refreshActivities: () => void;
}

const AdsAllActivity: React.FC<Props> = ({ refreshActivities }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activities } = useAppSelector(state => state.activities);

  const getStatus = (status: number) => {
    switch (status) {
      case 5:
        return 'Rezolvata';
      case 6:
        return 'Viitoare';
      case 7:
        return 'Depasita';
      default:
        return '';
    }
  };

  const resolve = (id: number) => {
    dispatch(resolveActivity(id))
      .then(res => {
        if (res.payload.id) refreshActivities();
      })
      .catch((res: any) => {
        if (res.payload.status === 409) {
          refreshActivities();
        }
      });
  };

  return (
    <div className='activity-list'>
      {activities?.length > 0 &&
        activities.map(activity => (
          <div
            className={`activity-list-item bg-blue ${activity.status === 5 ? 'opacity' : ''}`}
            key={activity.id}
          >
            <div className='activity-list-item__general-info'>
              {activity.activityTypeIconPath && (
                <div className='icon'>
                  <img
                    src={getImagePath(activity.activityTypeIconPath)}
                    alt={`${activity.id}`}
                  />
                </div>
              )}
              <div className='content'>
                <h3>{activity.title}</h3>
                {activity.details && <p>{activity.details}</p>}{' '}
                {activity.contacts.length > 0 &&
                  activity.contacts?.map(contact => (
                    <span className='name' key={contact.id}>
                      {contact.name + ' ' + contact.surname}
                    </span>
                  ))}{' '}
              </div>
            </div>
            <div className='activity-list-item__date'>
              <table>
                <tbody>
                  <tr>
                    <th>De la:</th>
                    <td>
                      <strong>
                        {moment(activity.dateStart).format('DD.MM.YYYY HH:mm')}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <th>Pana la:</th>
                    <td>
                      <strong>
                        {moment(activity.dateEnd).format('DD.MM.YYYY HH:mm')}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='activity-list-item__info-user'>
              <div className='info-image'>
                {activity.employeeAvatarPath ? (
                  <img src={getImagePath(activity.employeeAvatarPath)} alt='' />
                ) : (
                  <img src={Avatar} alt='' />
                )}
              </div>
              <span className='info-name'>
                {activity.employeeProfileFullName}
              </span>
            </div>
            <div className='activity-list-item__info-labels'>
              <div className='info-details'>
                <span className='label-item-rounded-info'>
                  {getStatus(activity.status)}
                </span>
                {activity.properties?.map(property => (
                  <button
                    className='label-item-rounded-info'
                    onClick={() => navigate('/properties/' + property.id)}
                  >
                    <img src={CalculatorIcon} alt='' />
                    {property.id}
                  </button>
                ))}
              </div>
            </div>
            <div className='activity-list-item__info-actions'>
              <ButtonSquare
                btnSize='md'
                checkedBtn={activity.status !== 5}
                checkAction={() => resolve(activity.id)}
                renames={true}
                editAction={() => {
                  navigate('/activities/' + activity.id);
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default AdsAllActivity;
