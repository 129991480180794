import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input';
import Map from '../../components/Map';
import MapChoice from '../../components/MapChoice';
import StreetAddModal from '../../components/StreetAddModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearStreets,
  clearTowns,
  clearZona,
} from '../../redux/localities/localities-slice';
import {
  getCoordinates,
  getLocalities,
  getStreet,
  getZona,
} from '../../redux/localities/localitiesThunk';
import { getValue, parseValues } from '../../helpers/useHelper';
import { Coordinate, SelectValue } from '../../models/Properties';
import { ResidentialComplexForm } from '../../models/ResidentialComplexes';
import './ResidentialComplexLocalization.scss';

interface Props {
  formData: ResidentialComplexForm;
  formDataErrors: any;
  setFormData: (form: ResidentialComplexForm) => void;
  handleChange: (key: string, value: SelectValue | null) => void;
  handleString: (key: string, value: string | number | null) => void;
}

const ResidentialComplexLocalization: React.FC<Props> = ({
  formData,
  formDataErrors,
  handleChange,
  handleString,
  setFormData,
}) => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector(state => state.users);
  const { residentialComplex } = useAppSelector(
    state => state.residentialComplexes,
  );
  const { localities, county, streets, zona } = useAppSelector(
    state => state.localities,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState<Coordinate>({
    type: 'PIN',
    lat: 44.26,
    lng: 26.6,
  });

  useEffect(() => {
    if (residentialComplex?.coordinates) {
      setCoordinates(residentialComplex.coordinates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentialComplex]);

  useEffect(() => {
    coordinates && setFormData({ ...formData, coordinates: coordinates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  useEffect(() => {
    if (formData.countyId) {
      dispatch(getLocalities({ parent: formData.countyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.countyId]);

  useEffect(() => {
    if (formData.townId) {
      dispatch(getStreet({ town: formData.townId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.townId]);

  useEffect(() => {
    if (formData.streetId) {
      dispatch(getZona({ street: formData.streetId }));
    }

    getCoords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.streetId]);

  const getCoords = () => {
    if (formData?.countyId && formData?.townId && formData?.streetId) {
      dispatch(
        getCoordinates({
          county: formData.countyId,
          town: formData.townId,
          street: formData.streetId,
        }),
      ).then(res => {
        if (
          res.type === 'localities/getCoordinates/fulfilled' &&
          res.payload.lat
        ) {
          setCoordinates({
            lat: res.payload.lat,
            lng: res.payload.lng,
            type: 'PIN',
          });
        }
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='display-flex residential-complex-form-container'>
      <Container>
        <Row>
          <Col lg='7' className='residential-complex-form-container-left'>
            <Row>
              <Col lg='12'>
                <h2 className='heading-block'>Localizare</h2>
              </Col>
              <Col lg='6'>
                {!residentialComplex && (
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.title ?? ''}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('title', e.target.value)
                      }
                      inlineLabel='Nume Ansamblu'
                      error={formDataErrors?.title}
                    />
                  </div>
                )}
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Select Options'
                    inlineLabel='Agent'
                    isSearchable={true}
                    isClearable={true}
                    value={
                      formData?.employeeId
                        ? getValue(users, formData?.employeeId)
                        : null
                    }
                    error={formDataErrors?.employeeId}
                    options={parseValues(users) ?? []}
                    onChange={(e: SelectValue) => handleChange('employeeId', e)}
                  />
                </div>
                <div className='form-item'>
                  <FormSelect
                    placeholder='Select Options'
                    labelText={true}
                    isClearable={true}
                    inlineLabel='Judet'
                    value={
                      formData?.countyId
                        ? getValue(county, formData?.countyId)
                        : null
                    }
                    error={formDataErrors?.countyId}
                    options={parseValues(county) ?? []}
                    onChange={(e: SelectValue) => {
                      handleChange('countyId', e)
                      dispatch(clearStreets());
                      dispatch(clearZona());
                    }}
                  />
                </div>

                <div className='form-item'>
                  <FormSelect
                    placeholder='Select Options'
                    labelText={true}
                    isClearable={true}
                    inlineLabel='Localitate'
                    value={
                      formData?.townId
                        ? getValue(localities, formData?.townId)
                        : null
                    }
                    error={formDataErrors?.townId}
                    options={parseValues(localities) ?? []}
                    onChange={(e: SelectValue) => {
                      handleString('townId', +e?.value)
                      dispatch(clearZona());
                    }}
                  />
                </div>

                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    inlineLabel='Strada'
                    isClearable={true}
                    options={parseValues(streets) ?? []}
                    onChange={(e: SelectValue) => {
                      handleChange('streetId', e)
                    }}
                    value={
                      formData.streetId
                        ? getValue(streets, formData.streetId)
                        : null
                    }
                    error={formDataErrors?.streetId}
                  />
                  <p className='link' onClick={() => setIsModalOpen(true)}>
                    Propune Strada/Zona
                  </p>
                </div>

                <div className='form-item'>
                  <FormSelect
                    placeholder='Select Options'
                    labelText={true}
                    isClearable={true}
                    inlineLabel='Zona'
                    value={
                      formData?.zoneId ? getValue(zona, formData?.zoneId) : null
                    }
                    error={formDataErrors?.zoneId}
                    options={parseValues(zona) ?? []}
                    onChange={(e: SelectValue) => handleChange('zoneId', e)}
                  />
                </div>
                <div className='form-item'>
                  <Input
                    labelText={true}
                    value={formData?.streetNumber ?? ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleString('streetNumber', e.target.value)
                    }
                    inlineLabel='Nr. strada'
                    error={formDataErrors?.streetNumber}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          {coordinates && (
            <Col lg='5' className='residential-complex-form-container-map'>
              <div className='map-localizare'>
                <Map
                  coordinates={coordinates}
                  setCoordinates={setCoordinates}
                />
              </div>
              <MapChoice
                className='without-bg'
                coordinates={coordinates}
                setCoordinates={setCoordinates}
              />
            </Col>
          )}
        </Row>
      </Container>
      <StreetAddModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default ResidentialComplexLocalization;
