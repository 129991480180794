import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { getCurrencies } from '../../redux/currencies/currenciesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { parseCurrency } from '../../helpers/useHelper';
import { PropertyForm, SelectValue } from '../../models/Properties';
import AuctionBlock from './AuctionBlock';
import CommisionCollaborators from './CommisionCollaborators';
import CommisionRentBlock from './CommisionRentBlock';
import CommisionSaleBlock from './CommisionSaleBlock';
import EvolutionBlock from './EvolutionBlock';
import PriceBlock from './PriceBlock';
import './PriceForm.scss';
import TVABlock from './TVABlock';

interface Props {
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
  handleChange: (key: string, value: SelectValue | any | null) => void;
  handleCheck: (key: string, value: boolean) => void;
  handleString: (key: string, value: string | null) => void;
}

const PriceForm: React.FC<Props> = ({
  formData,
  handleChange,
  handleString,
  handleCheck,
  setFormData,
}) => {
  const dispatch = useAppDispatch();
  const { property } = useAppSelector(state => state.properties);
  const { currencies } = useAppSelector(state => state.currencies);
  const [currencyOptions, setCurrencyOptions] = useState();

  const [currency, setCurrency] = useState(
    property?.currency
      ? parseCurrency(property?.currency)
      : parseCurrency(currencies?.find((item: any) => item?.title === 'Euro')),
  );

  useEffect(() => {
    setCurrency(
      property?.currency
        ? parseCurrency(property?.currency)
        : parseCurrency(currencies?.find((item: any) => item?.title === 'Euro')),
    );
  }, [property]);

  useEffect(() => {
    dispatch(getCurrencies()).then((res: any) => {
      const options = res?.payload?.currencies?.map((currency: any) => ({
        value: currency.id,
        label: currency.title?.toUpperCase(),
      }));
      setCurrencyOptions(options);
    });
  }, []);

  useEffect(() => {
    handleString('currencyId', currency.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  const handleNumber = (key: string, value: number) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <div className='display-flex ads-form-container'>
      <Container>
        <Row>
          <Col className='ads-form-container-block'>
            <PriceBlock
              currency={currency}
              currencyOptions={currencyOptions}
              setCurrency={setCurrency}
              prices={formData.prices}
              formData={formData}
              setFormData={setFormData}
            />
            {(formData.prices?.find(price => price.id === 1)?.hasAuction ===
              true ||
              formData.prices?.find(price => price.id === 1)?.hasAuction ===
                1) && (
              <AuctionBlock formData={formData} setFormData={setFormData} />
            )}
            <TVABlock formData={formData} handleString={handleString} />
            {(formData.prices?.find(price => price.id === 1)?.status === 1 ||
              formData.prices?.find(price => price.id === 4)?.status === 1) && (
              <CommisionSaleBlock
                type='Vanzare'
                formData={formData}
                handleChange={handleChange}
                handleNumber={handleNumber}
                setFormData={setFormData}
              />
            )}
            {(formData.prices?.find(price => price.id === 2)?.status === 1 ||
              formData.prices?.find(price => price.id === 5)?.status === 1) && (
              <CommisionRentBlock
                type='Inchiriere'
                formData={formData}
                handleChange={handleChange}
                handleNumber={handleNumber}
                setFormData={setFormData}
              />
            )}
            <CommisionCollaborators
              formData={formData}
              handleString={handleString}
              handleNumber={handleNumber}
            />
            <EvolutionBlock />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PriceForm;
