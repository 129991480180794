import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlphabetFilter from '../../components/AlphabetFilter';
import ContactListModal from '../../components/ContactListModal';
import ContactsFilter from '../../components/ContactsFilter';
import ContactsListItem from '../../components/ContactsListItem';
import FilterListContacts from '../../components/FilterListContacts/FilterListContacts';
import FilterListHeader from '../../components/FilterListHeader';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getCompanies } from '../../redux/companies/companiesThunk';
import {
  arhiveContacts,
  getContacts,
  getContactsTypes,
  getLeadSources,
  getUserContacts,
  removeContacts,
  restoreContacts,
} from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { Contact } from '../../models/Contacts';
import { Permissions } from '../../models/Permissions';
import IconPlus from '../../icons/IconPlus';
import './Contacts.scss';
import { toggleChecked, checkAll } from '../../redux/contacts/contacts-slice';
import { Property } from '../../models/Properties';
import PropertyListItem from '../../components/PropertyListItem';

const tabs = [
  {
    label: 'Contacte Active',
    value: '1',
  },

  {
    label: 'Contacte Arhivate',
    value: '4',
  },
  {
    label: 'Contacte Sterse',
    value: '3',
  },

  {
    label: 'Toate Contactele',
    value: '1,4,0',
  },
];

interface Props {}

const Contacts: React.FC<Props> = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { contacts, totalCount, loading } = useAppSelector(
    state => state.contacts,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [activeTab, setActiveTab] = useState(tabs[3].value);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [filtersForm, setFiltersForm] = useState({ q: '' });
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [havePermission, setHavePermission] = useState(true);
  const [filterByLettersOnMobile, setFilterByLettersOnMobile] = useState(false);
  const [prevState, setPrevState] = useState({
    currentPage: 0,
    itemsPerPage: 24,
    filtersForm: {},
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');

  const optionsMock = useCallback(() => {
    switch (activeTab) {
      case '1':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'arhive',
            label: 'Arhiveaza',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];
      case '4':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];

      case '3':
        return [
          {
            value: 'restore',
            label: 'Restaurezi',
          },
        ];
      case '1,4,0':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'arhive',
            label: 'Arhiveaza',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];
    }
  }, [activeTab]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  useEffect(() => {
    if (location.pathname === '/my-contacts') {
      setHavePermission(haveUserPermission(Permissions.ContactMyList));
    } else if (location.pathname === '/agency-contacts') {
      setHavePermission(haveUserPermission(Permissions.ContactAgencyList));
    }
    dispatch(getLeadSources());
    dispatch(getUsers({}));
    dispatch(getCompanies({ limit: -1 }));
    dispatch(getContactsTypes());
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / itemsPerPage);

      setPageCount(pageCount);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, itemsPerPage]);

  useEffect(() => {
    let newState = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      filtersForm: filtersForm,
    };

    if (
      JSON.stringify(prevState.currentPage) !==
        JSON.stringify(newState.currentPage) ||
      JSON.stringify(prevState.itemsPerPage) !==
        JSON.stringify(newState.itemsPerPage)
    ) {
      getContactsList();
      setPrevState(newState);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    let newState = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      filtersForm: filtersForm,
    };
    if (
      JSON.stringify(prevState.filtersForm) !==
      JSON.stringify(newState.filtersForm)
    ) {
      setPrevState(newState);
      loadList();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filtersForm)]);

  useEffect(() => {
    loadList();
  }, [activeTab]);

  const loadList = () => {
    if (currentPage > 0) {
      setCurrentPage(0);
    } else {
      dispatch(checkAll(false));
      setCheckedAll(false);
      getContactsList();
    }
  };

  const getContactsList = async () => {
    let filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      status: activeTab,
      ...filtersForm,
    };

    setLoadingFilters(true);

    if (location.pathname === '/my-contacts') {
      try {
        const result = await dispatch(getUserContacts(filters));

        unwrapResult(result);

        setLoadingFilters(false);
        setLoadingBtn(false);
      } catch (err: any) {}
    } else if (location.pathname === '/agency-contacts') {
      try {
        const result = await dispatch(getContacts(filters));

        unwrapResult(result);

        setLoadingFilters(false);
        setLoadingBtn(false);
      } catch (err: any) {}
    }
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const item = contacts.find(item => item.id === id);

      if (item && item.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (contacts.some(item => item.checked)) {
      setTitleConfirmModal(`${titleAction} contacte`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} contactele selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin un contact!');
    }
  };

  const removeConfirmedItems = async () => {
    try {
      const result = await dispatch(
        removeContacts(contacts
          .filter(item => item.checked).map(checked => checked.id).join(',')),
      );

      unwrapResult(result);

      toast.success('Contactele selectate au fost sterse!');
      getContactsList();
      dispatch(checkAll(false));
      setCheckedAll(false);
      closeModal();
    } catch (err: any) {}
  };

  const arhiveConfirmedItems = async () => {
    try {
      const result = await dispatch(
        arhiveContacts(contacts
          .filter(item => item.checked).map(checked => checked.id).join(',')),
      );

      unwrapResult(result);

      toast.success('Contactele selectate au fost arhivate!');
      getContactsList();
      dispatch(checkAll(false));
      setCheckedAll(false);
      closeModal();
    } catch (err: any) {}
  };

  const restoreConfirmedItems = async () => {
    try {
      const result = await dispatch(
        restoreContacts(contacts
          .filter(item => item.checked).map(checked => checked.id).join(',')),
      );

      unwrapResult(result);

      toast.success('Contactele selectate au fost restaurate!');
      getContactsList();
      dispatch(checkAll(false));
      setCheckedAll(false);
      closeModal();
    } catch (err: any) {}
  };

  const exportItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere contacte') {
      removeConfirmedItems();
    } else if (titleConfirmModal === 'Arhivare contacte') {
      arhiveConfirmedItems();
    } else if (titleConfirmModal === 'Restaurare contacte') {
      restoreConfirmedItems();
    }
  };

  const removeItem = async (id: number) => {
    try {
      const result = await dispatch(removeContacts(`${id}`));

      unwrapResult(result);

      toast.success('Contactul a fost sters!');
      getContactsList();
      dispatch(checkAll(false));
      setCheckedAll(false);
    } catch (err: any) {}
  };

  const editItems = () => {
    if (contacts.some(item => item.checked)) {
      navigate(`/contacts/${contacts.filter(item => item.checked)[0].id}`, {
        state: {
          isFromAgencyList: location.pathname === '/agency-contacts',
        },
      });
    } else {
      toast.error('Alegeti un contact!');
    }
  };

  const checkAllItems = (e: boolean) => {
    setCheckedAll(e);

    if (e) {
      handleCheckboxChange(undefined, true);
    } else {
      dispatch(checkAll(false));
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        {loading &&
        contacts.length === 0 &&
        Object.keys(filtersForm).length === 0 ? (
          <Spinner />
        ) : (
          <>
            {' '}
            <Header
              title='Contacte'
              subtitle='Gestioneza din aceasta sectiune toate contactele '
              isbutton={havePermission}
              btnEvent={() => navigate('/add-contact')}
              btnText={'Adauga Contact'}
            />
            {havePermission ? (
              <>
                {isMobile ? (
                  <div>
                    <select
                      className='select-tabs '
                      value={activeTab}
                      onChange={e => setActiveTab(e.target.value)}
                    >
                      {tabs.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <Tabs
                    id='ads-tabs'
                    className='ads-form-tabs'
                    activeKey={activeTab}
                    onSelect={e => e && setActiveTab(e)}
                  >
                    {tabs.map(tab => (
                      <Tab
                        key={tab.value}
                        eventKey={tab.value}
                        title={tab.label}
                      ></Tab>
                    ))}
                  </Tabs>
                )}
                <ContactsFilter
                  filtersForm={filtersForm}
                  setFiltersForm={setFiltersForm}
                  loadingBtn={loadingBtn}
                  setLoadingBtn={setLoadingBtn}
                  setCheckedAll={checkAll}
                  isCheckedAll={isCheckedAll}
                  filterByLettersOnMobile={filterByLettersOnMobile}
                  setFilterByLettersOnMobile={setFilterByLettersOnMobile}
                />
                <div className='display-flex contact-filter-list-container'>
                  <div className='alphabet-wrapper'>
                    <AlphabetFilter
                      filtersForm={filtersForm}
                      setFiltersForm={setFiltersForm}
                    />
                  </div>
                  <div className='list-wrapper'>
                    <FilterListHeader
                      options={optionsMock()}
                      pageCount={pageCount}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      totalCount={totalCount}
                      count={contacts?.length}
                      showCheckedItems={showCheckedItems}
                      checkedItemsLength={contacts.filter(item => item.checked).length}
                      setShowCheckedItems={setShowCheckedItems}
                      setItemsPerPage={setItemsPerPage}
                      setCurrentPage={setCurrentPage}
                      handlePageClick={handlePageClick}
                      editItems={editItems}
                      removeItems={() => openConfirmModal('Stergere', 'stergi')}
                      restoreItems={() =>
                        openConfirmModal('Restaurare', 'restaurezi')
                      }
                      arhiveItems={() =>
                        openConfirmModal('Arhivare', 'arhivezi')
                      }
                      exportItems={exportItems}
                    />
                    <FilterListContacts
                      setCheckedAll={(e: boolean) => {
                        setCheckedAll(e);

                        if (e) {
                          handleCheckboxChange(undefined, true);
                        } else {
                          dispatch(checkAll(false));
                        }
                      }}
                      filtersForm={filtersForm}
                      setFiltersForm={setFiltersForm}
                      isCheckedAll={isCheckedAll}
                    />
                    {loadingFilters ? (
                      <div className='container'>
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <div className='list-items-container'>
                          {contacts
                            .filter(item => !showCheckedItems || item.checked)
                            .map((item: Contact) => (
                              <ContactsListItem
                                key={`${item.id} ${item.name}`}
                                item={item}
                                checked={item.checked}
                                handleCheckboxChange={handleCheckboxChange}
                                removeItem={id => removeItem(id)}
                              />
                            ))}
                          {!contacts.some(item => !showCheckedItems || item.checked) && (
                            <p className='empty-text'>
                              {showCheckedItems
                                ? '0 Contacte selectate'
                                : 'Cautarea ta a returnat 0 Rezultate'}
                            </p>
                          )}
                        </div>
                        {!showCheckedItems && pageCount > 1 && (
                          <Pagination
                            isSelectPage={false}
                            pageCount={pageCount}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            setItemsPerPage={setItemsPerPage}
                            handlePageClick={handlePageClick}
                          />
                        )}{' '}
                      </>
                    )}{' '}
                  </div>
                </div>
                <FixedActions
                  disabledActions={
                    contacts.every(item => !item.checked) || activeTab === '3'
                  }
                  ModalAction={ModalAction}
                  ModalFilter={ModalFilter}
                  ModalSearch={ModalSearch}
                />
                <div
                  className='add-btn'
                  onClick={() => navigate('/add-contact')}
                >
                  <IconPlus />
                </div>
                <ContactListModal
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  isAction={activeTab === '3' ? false : isAction}
                  isSearch={isSearch}
                  isFilter={isFilter}
                  filtersForm={filtersForm}
                  setFiltersForm={setFiltersForm}
                  removeItems={
                    activeTab === '3'
                      ? undefined
                      : () => openConfirmModal('Stergere', 'stergi')
                  }
                  arhiveItems={
                    activeTab === '4' || activeTab === '3'
                      ? undefined
                      : () => openConfirmModal('Arhivare', 'arhivezi')
                  }
                  exportItems={
                    activeTab === '3' ? undefined : () => exportItems()
                  }
                  restoreItems={
                    activeTab === '3'
                      ? () => openConfirmModal('Restaurare', 'restaurezi')
                      : undefined
                  }
                  editItems={activeTab === '3' ? undefined : () => editItems()}
                  setCheckedAll={checkAll}
                  isCheckedAll={isCheckedAll}
                  filterByLettersOnMobile={filterByLettersOnMobile}
                  setFilterByLettersOnMobile={setFilterByLettersOnMobile}
                />
              </>
            ) : (
              <MissingAccess />
            )}
          </>
        )}
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default Contacts;
