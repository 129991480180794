import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Form, Tab } from 'react-bootstrap';
import { Tabs } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setProperty } from '../../redux/requests/requestsThunk';
import { Filter } from '../../models/Properties';
import RequestPropertyList from '../RequestPropertyList';
import './RequestMatchingProperties.scss';

const tabs = [
  {
    label: 'Proprietati in baza de date',
    value: 'propertiesDB',
  },
  {
    label: 'Proprietati ImoAP',
    value: 'propertiesImoAP',
  },
];

interface Props {
  formData: any;
  requestFilters: any;
}

const RequestMatchingProperties: React.FC<Props> = ({
  formData,
  requestFilters,
}) => {
  const dispatch = useAppDispatch();
  const isMobile = window.innerWidth < 1081;
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [propertyFilters, setPropertyFilters] = useState<any>({
    ...requestFilters,
    zones: formData?.zones,
  });
  const { zona } = useAppSelector(state => state.localities);
  const [priceAverage, setPriceAverage] = useState<any>();

  const matchProperty = async (id: number) => {
    try {
      const result = await dispatch(
        setProperty({
          requestId: getRequestState.request?.id,
          propertyId: id,
        }),
      );

      unwrapResult(result);

      toast.success('Proprietatea a fost propusa cu succes!');
    } catch (err: any) {}
  };

  const { getRequestState } = useAppSelector(state => state.requests);

  useEffect(() => {
    setPropertyFilters({
      ...propertyFilters,
      priceMin:
        Number(formData.prices?.min) > Number(priceAverage)
          ? Number(formData.prices?.min) - Number(priceAverage)
          : 0,
      priceMax: Number(formData.prices?.max) + Number(priceAverage),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceAverage]);

  const renderTabContent = (type: string) => {
    switch (type) {
      case 'propertiesDB': {
        return (
          <RequestPropertyList
            requestFilters={propertyFilters}
            fromDB={true}
            matchItems={matchProperty}
          />
        );
      }
      case 'propertiesImoAP': {
        return (
          <RequestPropertyList
            requestFilters={propertyFilters}
            fromDB={false}
          />
        );
      }
    }
  };

  return (
    <div className='display-flex ads-form-container request-associated request-matching'>
      <Col xl={12}>
        <Row>
          <p className='heading-block'>Proprietati ce se potrivesc</p>
        </Row>
        <Row>
          <Col xl={12}>
            {isMobile ? (
              <div>
                <select
                  className='select-tabs '
                  value={activeTab}
                  onChange={e => setActiveTab(e.target.value)}
                >
                  {tabs.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <Tabs
                id='ads-tabs'
                className='ads-form-tabs'
                activeKey={activeTab}
                onSelect={e => e && setActiveTab(e)}
              >
                {tabs.map(tab => (
                  <Tab
                    key={tab.value}
                    eventKey={tab.value}
                    title={tab.label}
                  ></Tab>
                ))}
              </Tabs>
            )}
          </Col>
          <Col xl={12} className='filters'>
            <div className='form-item'>
              <Input
                labelText={true}
                inlineLabel='Pret +/-'
                type='number'
                value={priceAverage}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPriceAverage(e.target.value)
                }
              />
            </div>
            {getRequestState.request?.category?.filters &&
              getRequestState.request?.category?.filters.length > 0 &&
              getRequestState.request?.category?.filters
                .filter(
                  (filter: Filter) =>
                    filter.requestShowOnAdd &&
                    formData.filters &&
                    requestFilters?.[filter.id],
                )
                .map((filter: Filter) => {
                  return (
                    <Form.Group id='formGridCheckbox'>
                      <Form.Check
                        type='checkbox'
                        label={
                          filter.title +
                          ': ' +
                          (filter.requestTypeAdd === 'RANGE_SELECT'
                            ? (requestFilters?.[filter.id]?.from?.label ?? '') +
                              (requestFilters?.[filter.id]?.to?.label &&
                              requestFilters?.[filter.id]?.from?.label
                                ? '-' + requestFilters?.[filter.id]?.to?.label
                                : requestFilters?.[filter.id]?.to?.label ?? '')
                            : filter.requestTypeAdd === 'RANGE'
                              ? (requestFilters?.[filter.id]?.from ?? '') +
                                (requestFilters?.[filter.id]?.to &&
                                requestFilters?.[filter.id]?.from
                                  ? '-' + requestFilters?.[filter.id]?.to
                                  : requestFilters?.[filter.id]?.to ?? '')
                              : filter.values?.length > 0
                                ? filter.values
                                    .filter(value =>
                                      requestFilters?.[filter.id]?.length > 0
                                        ? requestFilters?.[filter.id]?.filter(
                                            (filterValue: any) =>
                                              (filterValue ??
                                                filterValue.value) === value.id,
                                          ).length > 0
                                        : value.id ===
                                          requestFilters?.[filter.id]?.value,
                                    )
                                    .map(value => value.title)
                                    .join(', ')
                                : null)
                        }
                        checked={propertyFilters?.[filter.id]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setPropertyFilters({
                            ...propertyFilters,
                            [filter.id]: e.target.checked
                              ? requestFilters?.[filter.id]
                              : null,
                          })
                        }
                      />
                    </Form.Group>
                  );
                })}
            {formData.zones?.length > 0 && (
              <Form.Group id='formGridCheckbox'>
                <Form.Check
                  type='checkbox'
                  label={
                    'Zona: ' +
                    zona
                      .filter(zone => formData.zones.includes(String(zone.id)))
                      .map(zone => zone.title)
                      .join(', ')
                  }
                  checked={propertyFilters.zones}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPropertyFilters({
                      ...propertyFilters,
                      zones: e ? formData.zones : null,
                    })
                  }
                />
              </Form.Group>
            )}
          </Col>
          <Col xl={12}>{renderTabContent(activeTab)}</Col>
        </Row>
      </Col>
    </div>
  );
};

export default RequestMatchingProperties;
