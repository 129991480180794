import { createSlice } from '@reduxjs/toolkit';
import { Property } from '../../models/Properties';
import {
  ResidentialComplex,
  ResidentialComplexItem,
} from '../../models/ResidentialComplexes';
import {
  addResidentialComplex,
  editResidentialComplex,
  getAssociatedProperties,
  getResidentialComplex,
  getResidentialComplexes,
  removeResidentialComplex,
  removeResidentialComplexes,
} from './residentialComplexesThunk';

type ResidentialComplexesState = {
  residentialComplexes: ResidentialComplexItem[];
  residentialComplex: ResidentialComplex | null;
  associatedProperties: Property[];
  totalCount: number;
  loading: boolean;
  error: any;
};

const initialState: ResidentialComplexesState = {
  residentialComplexes: [],
  associatedProperties: [],
  residentialComplex: null,
  totalCount: 0,
  loading: false,
  error: null,
};

const residentialComplexesSlice = createSlice({
  name: 'residentialComplexes',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearResidentialComplex(state) {
      state.residentialComplex = null;
    },
    toggleChecked(state, action) {
      const id = action.payload;
      const rc = state.residentialComplexes.find(rc => rc.id === id);

      if (rc) {
        rc.checked = !rc.checked;
      }
    },
    checkAll(state, action) {
      state.residentialComplexes.forEach(rc => (rc.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getResidentialComplexes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getResidentialComplexes.fulfilled, (state, { payload }) => {
        state.residentialComplexes = payload.data;
        state.totalCount = payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getResidentialComplexes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getResidentialComplex.pending, state => {
        state.residentialComplex = null;
        state.loading = true;
        state.error = null;
      })
      .addCase(getResidentialComplex.fulfilled, (state, { payload }) => {
        state.residentialComplex = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getResidentialComplex.rejected, (state, action) => {
        state.residentialComplex = null;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(addResidentialComplex.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addResidentialComplex.fulfilled, (state, { payload }) => {
        state.residentialComplex = payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(addResidentialComplex.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(editResidentialComplex.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editResidentialComplex.fulfilled, (state, { payload }) => {
        state.residentialComplex = payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(editResidentialComplex.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(removeResidentialComplex.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeResidentialComplex.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(removeResidentialComplex.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(removeResidentialComplexes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeResidentialComplexes.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(removeResidentialComplexes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getAssociatedProperties.pending, state => {
        state.associatedProperties = [];
      })
      .addCase(getAssociatedProperties.fulfilled, (state, { payload }) => {
        state.associatedProperties = payload;
      })
      .addCase(getAssociatedProperties.rejected, (state, action) => {
        state.associatedProperties = [];
      });
  },
});

export const { clearError, toggleChecked, checkAll, clearResidentialComplex } =
  residentialComplexesSlice.actions;

export default residentialComplexesSlice.reducer;
