import { createAsyncThunk } from '@reduxjs/toolkit';
import { Company } from '../../models/Companies';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

type CompaniesResponse = {
  companies: Company[];
  totalCount: number;
};

export const getCompanies = createAsyncThunk<CompaniesResponse, any>(
  'crm/getCompanies',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/companies', {
        params: data,
      });
      const totalCount = response.headers['x-total-count'];

      return { companies: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getCompany = createAsyncThunk<Company, any>(
  'crm/company',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/companies/${id}`);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const addCompany = createAsyncThunk<Company, Company>(
  'crm/company/add',
  async (company, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(`api/v1/crm/companies`, company);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const editCompany = createAsyncThunk<Company, Company>(
  'crm/company/edit',
  async (company, { rejectWithValue }) => {
    try {
      const response = await appAxios.put(
        `api/v1/crm/companies/${company.id}`,
        company,
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const deleteCompany = createAsyncThunk<Company[], any>(
  'crm/company/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete(`api/v1/crm/companies/${id}`);

      return { ...response.data, id };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const arhiveCompanies = createAsyncThunk<string, string>(
  'crm/arhiveCompanies',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/companies/archive?ids=' + ids,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const restoreCompanies = createAsyncThunk<string, string>(
  'crm/restoreCompanies',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/companies/restore?ids=' + ids,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
