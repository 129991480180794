import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormData } from '../../components/StreetAddModal/StreetAddModal';
import {
  LocalitiesParams,
  StreetParams,
  TownParams,
} from '../../models/Localities';
import { Filters } from '../../models/Properties';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getLocations = createAsyncThunk<any>(
  'localities/getLocations',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/locations', {
        params: { sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getSpecificTypes = createAsyncThunk<any>(
  'localities/getSpecificTypes',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/specific-types', {
        params: { sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getLocalities = createAsyncThunk<any, LocalitiesParams>(
  'localities/getLocalities',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/localities', {
        params: { ...params, sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getCounty = createAsyncThunk<any, LocalitiesParams>(
  'localities/getCounty',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/localities', {
        params: { ...params, sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getCities = createAsyncThunk<any, LocalitiesParams>(
  'localities/getCities',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/localities', {
        params: { ...params, sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getStreet = createAsyncThunk<any, StreetParams>(
  'localities/getStreet',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/streets', {
        params: { ...params, sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getZona = createAsyncThunk<any, TownParams>(
  'localities/getZona',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/localities', {
        params: { ...params, sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getZones = createAsyncThunk<any, LocalitiesParams>(
  'localities/getZones',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/localities', {
        params: { ...params, sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getResidentialComplexes = createAsyncThunk<any, Filters | null>(
  'localities/getResidentialComplexes',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/residential-complexes', {
        params: params,
      });
      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeResidentialComplexes = createAsyncThunk<string, string>(
  'auth/removeResidentialComplexes',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete(
        'api/v1/crm/residential-complexes',
        {
          params: {
            ids: ids,
          },
        },
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const suggestStreet = createAsyncThunk<any, FormData>(
  'localities/suggestStreet',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/crm/suggest-locality', data);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getCounties = createAsyncThunk<any>(
  'localities/getCounties',
  async (_, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/localities', {
        params: { type: 'COUNTY', sort: 'priority', limit: -1 },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getCoordinates = createAsyncThunk<any, any>(
  'localities/getCoordinates',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/localities/coordinates', {
        params: params,
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
