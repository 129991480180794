import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActivityForm, CreateActivity } from '../../models/Activities';
import { Filters } from '../../models/Properties';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

interface ActivitySearch {
  id: number;
  q?: string;
  typeId?: number;
}
export const getPropertyActivities = createAsyncThunk<any, ActivitySearch>(
  'auth/getPropertyActivities',
  async (data, { rejectWithValue }) => {
    let params: { q?: string; type?: number } = {};
    if (data.q) params.q = data.q;
    if (data.typeId) params.type = data.typeId;

    try {
      const response = await appAxios.get(
        'api/v1/crm/properties/' + data.id + '/activities',
        {
          params: params,
        },
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getActivities = createAsyncThunk<any, Filters | any>(
  'auth/getActivities',
  async (data, { rejectWithValue }) => {
    try {
      let params: any = {};
      Object.keys(data).forEach(key => {
        if (key === 'typeId') params.type = data[key];
        else params[key] = data[key];
      });

      const response = await appAxios.get('api/v1/crm/activities', { params });
      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeActivities = createAsyncThunk<any, string>(
  'auth/removeActivities',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete('api/v1/crm/activities', {
        params: { ids: ids },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getActivity = createAsyncThunk<any, string>(
  'auth/getActivity',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/activities/' + id);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// activities types

export const getActivitiesTypes = createAsyncThunk<any>(
  'auth/getActivitiesTypes',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/activities-types');
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// activities create

export const createActivities = createAsyncThunk<any, CreateActivity>(
  'auth/createActivities',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/properties/' + data.propertyId + '/activities',
        data.form,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// activities create

export const updateActivity = createAsyncThunk<
  any,
  { form: ActivityForm; id: string }
>('auth/updateActivity', async (data, { rejectWithValue }) => {
  try {
    const response = await appAxios.put(
      'api/v1/crm/activities/' + data.id,
      data.form,
    );
    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});

// activity create

export const createActivity = createAsyncThunk<any, ActivityForm>(
  'auth/createActivity',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/crm/activities', data);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// activities resolve

export const resolveActivity = createAsyncThunk<any, number>(
  'auth/resolveActivity',
  async (activityId, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/activities/' + activityId + '/resolve',
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
