import { Link } from 'react-router-dom';
import Anounces from '../../assets/svg/anunturi.svg';
import File from '../../assets/svg/filenotify.svg';
import PieReportIcon from '../../assets/svg/pieraport.svg';
import StarIcon from '../../assets/svg/starreport.svg';
import './ReportsCards.scss';

interface Props {}

const ReportsCards: React.FC<Props> = () => {
  return (
    <div className='reports-cards-container display-flex'>
      <Link to='/' className='notification-item'>
        <img src={PieReportIcon} alt='' />
        <p>Statistica Agenti</p>
        <button className='button-blue button-sm'>Vezi detalii</button>
      </Link>
      <Link to='/' className='notification-item'>
        <img src={File} alt='' />
        <p>Proprietari si Solicitări Adaugate</p>
        <button className='button-blue button-sm'>Vezi detalii</button>
      </Link>
      <Link to='/' className='notification-item'>
        <img src={StarIcon} alt='' />
        <p>TOP-10 Proprietari Asicoate</p>
        <button className='button-blue button-sm'>Vezi detalii</button>
      </Link>
      <Link to='/' className='notification-item'>
        <img src={Anounces} alt='' />
        <p>Sursa Solicitări</p>
        <button className='button-blue button-sm'>Vezi detalii</button>
      </Link>
    </div>
  );
};

export default ReportsCards;
