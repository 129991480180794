import React from 'react';
import Form from 'react-bootstrap/Form';
import './FilterListPropertiesImoAP.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  sortProperty: (field: string) => void;
  sort: string;
}

const FilterListPropertiesImoAP: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  sort,
  sortProperty,
}) => {
  return (
    <div className='filter-list-container property-list request-property-list-imoap'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
        </Form.Group>
        <div
          className={`filter-item arrows-label-icon ${sort === 'dateUpdated' ? 'active-bottom' : ''} ${sort === '-dateUpdated' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'dateUpdated'
              ? sortProperty('-dateUpdated')
              : sort === '-dateUpdated'
                ? sortProperty('')
                : sortProperty('dateUpdated')
          }
        >
          <label>Actualizat</label>
        </div>

        <div
          className={`filter-item arrows-label-icon ${sort === 'categoryTitle' ? 'active-bottom' : ''} ${sort === '-categoryTitle' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'categoryTitle'
              ? sortProperty('-categoryTitle')
              : sort === '-categoryTitle'
                ? sortProperty('')
                : sortProperty('categoryTitle')
          }
        >
          <label>Tip Imobil</label>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'filterRoomValue' ? 'active-bottom' : ''} ${sort === '-filterRoomValue' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'filterRoomValue'
              ? sortProperty('-filterRoomValue')
              : sort === '-filterRoomValue'
                ? sortProperty('')
                : sortProperty('filterRoomValue')
          }
        >
          <label>Nr Camere</label>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'price' ? 'active-bottom' : ''} ${sort === '-price' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'price'
              ? sortProperty('-price')
              : sort === '-price'
                ? sortProperty('')
                : sortProperty('price')
          }
        >
          <label>Pret</label>
        </div>

        <div
          className={`filter-item arrows-label-icon ${sort === 'filterSurfaceCValue' ? 'active-bottom' : ''} ${sort === '-filterSurfaceCValue' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'filterSurfaceCValue'
              ? sortProperty('-filterSurfaceCValue')
              : sort === '-filterSurfaceCValue'
                ? sortProperty('')
                : sortProperty('filterSurfaceCValue')
          }
        >
          <label>Suprafata</label>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'filterYearValue' ? 'active-bottom' : ''} ${sort === '-filterYearValue' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'filterYearValue'
              ? sortProperty('-filterYearValue')
              : sort === '-filterYearValue'
                ? sortProperty('')
                : sortProperty('filterYearValue')
          }
        >
          <label>An constructie</label>
        </div>
      </form>
    </div>
  );
};

export default FilterListPropertiesImoAP;
