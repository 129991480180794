import { toast } from 'react-toastify';
import { Contact, ContactDetailsType } from '../models/Contacts';
import { Permissions } from '../models/Permissions';
import {
  Filter,
  Price,
  Property,
  PropertyDetails,
  PropertyForm,
  TypeText,
} from '../models/Properties';
import { RequestEditForm, SingleRequest } from '../models/Requests';

const useHelper = () => {
  const diplayErrorMessages = (error: any) => {
    if (typeof error === 'string') {
      toast.error(showError(error));
    } else {
      Object.keys(error).forEach(key => toast.error(showError(error[key])));
    }
  };

  return { diplayErrorMessages };
};

export const parseCurrency = (currency: any) => {
  return {
    value: String(currency?.id),
    label: currency?.title?.toUpperCase(),
  };
};

export const parseValues = (array: any[]) => {
  if (array.length > 0) {
    return array.map(item => {
      return {
        value: String(item.id),
        label:
          item?.name || item?.surname
            ? (item?.name ?? '') + ' ' + (item?.surname ?? '')
            : item?.fullName
              ? item?.fullName
              : item?.title ?? item?.id,
      };
    });
  }

  return [];
};

export const getNumber = (value?: string) => {
  if (value) return Number(value);
  return null;
};

export const extractNumber = (str: string | null | undefined): number | null => {
  if (!str)
    return null;

  const match = str.match(/\d+/);

  return match ? parseInt(match[0], 10) : null;
}

export const parseContact = (contact: ContactDetailsType): Contact => {
  return {
    id: contact.id,
    address: contact.address,
    birthday: contact.birthday,
    cnp: contact.cnp,
    socialMedias: contact.socialMedias ?? [''],
    dateUpdated: contact.dateUpdated,
    dateCreated: contact.dateCreated,
    avatarPath: contact.avatar?.path ?? '',
    types: contact.types?.map(type => String(type.id)) ?? [],
    jobTitle: contact.jobTitle ?? undefined,
    name: contact.name,
    surname: contact.surname,
    phoneNumbers: contact.phoneNumbers ?? [{ phone: '' }],
    emails: contact.emails ?? [''],
    internationalPhoneNumber: contact.internationalPhoneNumber,
    leadSourceId: contact.leadSource.id,
    notes: contact.notes,
    associatedEmployee: contact.associatedEmployees.map(
      employee => employee.id,
    ),
    status: contact?.status ?? 1,
    companyId: contact?.company?.id,
    checked: false,
  };
};

export const parseProperty = (property: PropertyDetails): PropertyForm => {
  return {
    employeeId: property?.employee?.id,
    parcel: property?.parcel,
    lot: property?.lot,
    field: property?.field,
    associatedEmployees: property?.associatedEmployees?.map(
      employee => employee.id,
    ),
    propertyHasFilters: property.propertyHasFilters,
    residentialComplexId: property?.residentialComplex?.id,
    countyId: property?.county?.id,
    townId: property?.town?.id,
    streetId: property?.street?.id,
    zoneId: property?.zone?.id,
    streetNumber: property?.streetNumber,
    block: property?.block,
    staircase: property?.staircase,
    floorNumber: property?.floorNumber,
    specificTypeId: property?.specificType?.id,
    locationId: property?.location?.id,
    link: property?.link,
    privateDescription: property?.privateDescription,
    vatType: property?.vatType,
    vatValue: property?.vatValue,
    hasCollaborationOnSales: property?.hasCollaborationOnSales,
    collaboratorPercentageCommission:
      property?.collaboratorPercentageCommission,
    collaboratorFixCommission: property?.collaboratorFixCommission,
    isExclusiveCollaboration: property?.isExclusiveCollaboration,
    hasZeroCommissionToBuyer: property?.hasZeroCommissionToBuyer,
    rentCollectedMonthly: property?.rentCollectedMonthly,
    rentUntil: property?.rentUntil,
    energyClass: property?.energyClass,
    totalPrimaryEnergyConsumption: property?.totalPrimaryEnergyConsumption,
    emissionIndex: property?.emissionIndex,
    totalConsumptionFromRenewableSources:
      property?.totalConsumptionFromRenewableSources,
    videoLink: property?.videoLink,
    virtualTourLink: property?.virtualTourLink,
    contactsComments: property?.contactsComments,
    translations: {
      ro: {
        title: property?.translations?.ro?.title,
        description: property?.translations?.ro?.description,
      },
      en: {
        title: property?.translations?.en?.title,
        description: property?.translations?.en?.description,
      },
    },
    coordinates: property?.coordinates,
    prices: property?.prices,
    features: property?.features?.map(feature => feature.id),
    media: property?.media,
    contacts: property.propertyContacts?.map(item => {
      return { id: item.contact.id, tags: item.tags };
    }),
    status: property.status,
  };
};

export const getValue = (array: any[], id: number | string) => {
  const item = array?.find((item: any) => item?.id === Number(id));
  if (item)
    return {
      label: item?.title ?? item.fullName ?? (item?.name || item?.surname ? item?.name + ' ' + item?.surname : item?.id ?? ""),
      value: id,
    };
  return null;
};

export const getValues = (array: any[], id: number[]) => {
  const items = array?.filter((item: any) => id.includes(item?.id));
  if (items?.length > 0)
    return items.map(item => {
      if (item.fullName)
        return {
          label: item?.fullName,
          value: id,
        };
      else
        return {
          label: item?.name + ' ' + item?.surname,
          value: item.id,
        };
    });
  return null;
};

export const getFiltersParsed = (array: any[], filters: any) => {
  let options: any[] = [];

  Object.keys(filters).length > 0 &&
    array.forEach(filter => {
      if (
        filters[filter.id] &&
        filters[filter.id] !== null &&
        filters[filter.id] !== ''
      ) {
        if (filter.propertyTypeAdd === 'RANGE') {
          if (
            filters[filter.id]?.from !== '' &&
            filters[filter.id]?.from !== null
          )
            options[filter.id] = { from: filters[filter.id]?.from };
          if (filters[filter.id]?.to !== '' && filters[filter.id]?.to !== null)
            options[filter.id] = {
              ...options[filter.id],
              to: filters[filter.id]?.to,
            };
        } else if (filter.propertyTypeAdd === 'SELECT') {
          if (filters[filter.id]?.value)
            options.push({
              id: filter.id,
              valueId: filters[filter.id]?.value,
            });
        } else if (
          filter.propertyTypeAdd === 'SELECT_OPTIONS' ||
          filter.propertyTypeAdd === 'OFFER_TYPE'
        ) {
          if (filters[filter.id]?.length > 0)
            filters[filter.id].forEach((value: number) => {
              options.push({ id: filter.id, valueId: value });
            });
        } else {
          options.push({ id: filter.id, value: filters[filter.id] });
        }
      } else {
        if (filter.propertyTypeAdd === 'BLOCK_LVL') {
          filter.children.forEach((child: Filter) => {
            if (filters[child.id])
              options.push({ id: child.id, value: filters[child.id] });
          });
        }
      }
    });

  return options;
};

export const getFiltersState = (property: PropertyDetails) => {
  let initialForm: any = null;

  property?.category?.filters?.forEach(filter => {
    let value: any = null;
    property?.propertyHasFilters?.forEach(item => {
      if (item?.filter?.id === filter.id) {
        if (item.value) value = item.value;

        if (item.filterValue)
          if (item.filter.propertyTypeAdd === 'SELECT_OPTIONS') {
            if (value?.length > 0) value.push(item.filterValue.id);
            else value = [item.filterValue.id];
          } else
            value = {
              label: item.filterValue?.title,
              value: item.filterValue?.id,
            };
      } else if (filter.children.length > 0) {
        filter.children?.forEach((child: Filter) => {
          let childValue;
          if (item?.filter?.id === child.id) {
            if (item.value)
              if (item.filter.propertyTypeAdd === 'INPUT') {
                childValue = item.value;
              } else childValue = item.value === '1' ? 1 : 0;
          }
          if (child.propertyShowOnAdd && childValue) {
            value = {
              ...value,
              [child.id]: childValue,
            };
          }
        });
      }
    });

    if (filter.children.length > 0) {
      initialForm = {
        ...initialForm,
        ...value,
      };
    } else if (filter.propertyShowOnAdd) {
      initialForm = {
        ...initialForm,
        [filter.id]: value,
      };
    }
  });

  return getFiltersParsed(property?.category?.filters, initialForm);
};

export const getImagePath = (path: string) => {
  return process.env.REACT_APP_API_URL + path;
};
export default useHelper;

export const getFilter = (
  key: number,
  property: PropertyDetails | Property | null,
) => {
  return {
    exists: property?.propertyHasFilters
      ? property?.propertyHasFilters.filter(filter => filter.filter?.id === key)
          ?.length > 0
      : false,
    value: property?.propertyHasFilters
      ? property?.propertyHasFilters.filter(
          filter => filter.filter?.id === key,
        )[0]?.filterValue?.title ??
        property?.propertyHasFilters.filter(
          filter => filter.filter?.id === key,
        )[0]?.value
      : null,
  };
};

export const getSaleBlock = (property?: Price) => {
  if (property)
    return {
      hasSellingContract: property?.hasSellingContract,
      salePercentageCommission: property?.salePercentageCommission,
      saleCommission: property?.saleCommission,
      saleContractNumber: property?.saleContractNumber,
      saleContractSigningDate: property?.saleContractSigningDate,
    };
};

export const getRentBlock = (property?: Price) => {
  if (property)
    return {
      hasRentContract: property?.hasRentContract,
      rentalPercentageCommission: property?.rentalPercentageCommission,
      rentalCommission: property?.rentalCommission,
      rentalContractNumber: property?.rentalContractNumber,
      rentalContractSigningDate: property?.rentalContractSigningDate,
    };
};

export const showError = (error: string) => {
  if (error === 'FIELD_REQUIRED') {
    return 'Câmp obligatoriu.';
  } else if (error === 'USER_NOT_FOUND') {
    return 'Utilizatorul nu a fost gasit.';
  } else return error;
};

export const haveUserPermission = (permission: Permissions) => {
  return (
    localStorage
      .getItem('permissions')
      ?.split(',')
      ?.map(id => getNumber(id)) || []
  ).includes(permission);
};

export const getLoggedUserId = () => {
  return localStorage.getItem('userId');
};

export const getBackgroundColor = (type: string | undefined) => {
  switch (type) {
    case 'Telefon':
      return '#6283FF';
    case 'Convorbire':
      return '#62C2FF';
    case 'Email':
      return '#4665DA';
    case 'Vizionare':
      return '#BF62FF';
    case 'Negociere':
      return '#2379FA';
    case 'Diverse':
      return '#8062FF';
  }
};

export const parseRequest = (request: SingleRequest): RequestEditForm => {
  return {
    employeeId: request.employee?.id ?? null,
    associatedEmployees:
      request.associatedEmployees?.map(employee => employee.id) ?? [],
    specificTypes: request.specificTypes?.map(type => type.id) ?? [],
    sourceLeads: request.leadSources?.length > 0 ? request.leadSources.map(source => source.id) : [0,0],
    currencyId: request.currency?.id ?? null,
    countyId: request.county?.id ?? null,
    townId: request.town?.id ?? null,
    zones: request.zones?.map(zone => zone.id) ?? [],
    contacts: request.requestContacts?.map(contact => contact.contact) ?? [],
    prices: {
      min: request.prices?.min ?? 0,
      max: request.prices?.max ?? 0,
    },
    payMethod: request.payMethod ?? null,
    budgetComment: request.budgetComment ?? '',
    description: request.description ?? '',
    signedContract: request.signedContract ?? null,
    contractNumber: request.contractNumber ?? null,
    dateSigned: request.dateSigned ?? null,
    commission: request.commission ?? null,
    status: request.status,
    contactsComments: request.contactsComments ?? '',
  };
};

export const getRequestFiltersParsed = (array: any[], filters: any) => {
  let options: any[] = [];

  Object.keys(filters).length > 0 &&
    array.forEach(filter => {
      if (
        filters[filter.id] &&
        filters[filter.id] !== null &&
        filters[filter.id] !== ''
      ) {
        if (filter.requestTypeAdd === 'RANGE') {
          if (
            filters[filter.id]?.from !== '' &&
            filters[filter.id]?.from !== null
          )
            options[filter.id] = { from: filters[filter.id]?.from };
          if (filters[filter.id]?.to !== '' && filters[filter.id]?.to !== null)
            options[filter.id] = {
              ...options[filter.id],
              to: filters[filter.id]?.to,
            };
        } else if (filter.requestTypeAdd === 'SELECT') {
          if (filters[filter.id]?.value)
            options.push({
              id: filter.id,
              valueId: filters[filter.id]?.value,
            });
        } else if (
          filter.requestTypeAdd === 'SELECT_OPTIONS' ||
          filter.requestTypeAdd === 'OFFER_TYPE'
        ) {
          if (filters[filter.id]?.length > 0)
            filters[filter.id].forEach((value: number) => {
              options.push({ id: filter.id, valueId: value });
            });
        } else {
          options.push({ id: filter.id, value: filters[filter.id] });
        }
      } else {
        if (filter.requestTypeAdd === 'BLOCK_LVL') {
          filter.children.forEach((child: Filter) => {
            if (filters[child.id])
              options.push({ id: child.id, value: filters[child.id] });
          });
        }
      }
    });

  return options;
};

export const getRequestFiltersState = (array: any[], filters: any) => {
  let options: any[] = [];

  Object.keys(filters).length > 0 &&
    array.forEach(filter => {
      if (
        filters[filter.id] &&
        filters[filter.id] !== null &&
        filters[filter.id] !== ''
      ) {
        if (filter.requestTypeAdd === 'RANGE') {
          let obj: any = { id: filter.id };
          if (
            filters[filter.id]?.from &&
            filters[filter.id]?.from !== '' &&
            filters[filter.id]?.from !== null
          ) {
            obj = {
              ...obj,
              value: filters[filter.id]?.from,
            };
          }
          if (
            filters[filter.id]?.to &&
            filters[filter.id]?.to !== '' &&
            filters[filter.id]?.to !== null
          ) {
            obj = {
              ...obj,
              toValue: filters[filter.id]?.to,
            };
          }
          if (obj?.value || obj?.toValue) options.push(obj);
        } else if (filter.requestTypeAdd === 'RANGE_SELECT') {
          let obj: any = { id: filter.id };
          if (
            filters[filter.id]?.from &&
            filters[filter.id]?.from !== '' &&
            filters[filter.id]?.from !== null
          ) {
            obj = {
              ...obj,
              valueId: filters[filter.id]?.from?.value,
            };
          }
          if (
            filters[filter.id]?.to &&
            filters[filter.id]?.to !== '' &&
            filters[filter.id]?.to !== null
          ) {
            obj = {
              ...obj,
              toValueId: filters[filter.id]?.to?.value,
            };
          }
          if (obj?.valueId || obj?.toValueId) options.push(obj);
        } else if (filter.requestTypeAdd === 'SELECT') {
          if (filters[filter.id]?.value)
            options.push({
              id: filter.id,
              valueId: filters[filter.id]?.value,
            });
        } else if (
          filter.requestTypeAdd === 'SELECT_OPTIONS' ||
          filter.requestTypeAdd === 'OFFER_TYPE'
        ) {
          if (filters[filter.id]?.length > 0)
            filters[filter.id].forEach((value: number) => {
              options.push({ id: filter.id, valueId: value });
            });
        } else {
          options.push({ id: filter.id, value: filters[filter.id] });
        }
      } else {
        if (filter.requestTypeAdd === 'BLOCK_LVL') {
          filter.children.forEach((child: Filter) => {
            if (filters[child.id])
              options.push({ id: child.id, value: filters[child.id] });
          });
        }
      }
    });

  return options;
};

export enum DAYS_OF_WEEK {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY
}

export const getDateFromCurrentDayOfWeek = (date: Date, dayOfWeek: DAYS_OF_WEEK) => {
  const currentDay = date.getDay();
  const diff = dayOfWeek - (currentDay === DAYS_OF_WEEK.SUNDAY ? 7 : currentDay) + (dayOfWeek === DAYS_OF_WEEK.SUNDAY ? 7 : 0);
  const targetDate = new Date(date);

  targetDate.setDate(date.getDate() + diff);

  return targetDate.getDate()
}
