import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AddContactForm from '../../components/AddContactForm';
import Header from '../../components/Header';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getCompanies } from '../../redux/companies/companiesThunk';
import {
  clearContact,
  clearContacts,
} from '../../redux/contacts/contacts-slice';
import {
  getContact,
  getContactTopActivities,
  getContactTopProperties,
  getContacts,
  getContactsTypes,
  getLeadSources,
} from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import AdsActivityForm from '../AdsActivityForm';
import AdsPropertiesForm from '../AdsPropertiesForm';
import ContactEditInfo from '../ContactEditInfo';
import NoAccessToContact from '../NoAccessToContact';
import RequestsForm from '../RequestsForm';
import './EditContact.scss';


const tabs = [
  {
    label: 'Toate',
    value: 'toate',
  },

  {
    label: 'Activitati',
    value: 'activitati',
  },
  {
    label: 'Proprietati',
    value: 'proprietati',
  },

  {
    label: 'Solicitări',
    value: 'solicitări',
  },
  {
    label: 'Modifica profil',
    value: 'modifica-profil',
  },
];

interface Props {}

const EditContact: React.FC<Props> = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { contact, contacts, loadingContact } = useAppSelector(
    state => state.contacts,
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  useEffect(() => {
    contacts.length === 0 && dispatch(getContacts({ limit: -1 }));

    dispatch(getContactsTypes());
    dispatch(clearContacts());
    dispatch(getLeadSources());
    dispatch(getUsers({ limit: -1 }));
    dispatch(getCompanies({ limit: -1 }));
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getContact(id)).then(() => {
        dispatch(getContactTopActivities(id));
        dispatch(getContactTopProperties(id));
      });
    } else {
      dispatch(clearContact());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const renderTabContent = (type: string) => {
    if (contact?.error === 'NO_ACCESS_TO_CONTACT') {
      return <NoAccessToContact data={contact} />;
    }
    switch (type) {
      case 'toate':
        return <ContactEditInfo />;
      case 'activitati':
        return <AdsActivityForm fieldName='contact' fieldId={contact?.id} />;
      case 'proprietati':
        return <AdsPropertiesForm />;
      case 'solicitări':
        return <RequestsForm fieldName={'contact'} fieldId={contact?.id}/>;
      case 'modifica-profil':
        return <AddContactForm editView={id ? true : false} />;
    }

    return null;
  };

  return (
    <div className='flex-container display-flex flex-edit-contact'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        {!loadingContact && (
          <Header
            title={'Editeaza contact'}
            subtitle={
              contact?.id
                ? contact?.fullName
                  ? contact?.fullName
                  : `${contact?.surname + ' ' + contact?.name}`
                : 'Nu sunt date despre contact!'
            }
            backBtn={true}
            isbutton={false}
            btnText=''
          />
        )}
        {loadingContact ? (
          <Spinner />
        ) : (
          contact?.id && (
            <div className=' bg-blue'>
              <div className='ads-container bg-white'>
                {isMobile ? (
                  <div>
                    <select
                      className='select-tabs '
                      value={activeTab}
                      onChange={e => setActiveTab(e.target.value)}
                    >
                      {tabs.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    {activeTab && renderTabContent(activeTab)}
                  </div>
                ) : (
                  <Tabs
                    id='ads-tabs'
                    className='ads-form-tabs'
                    activeKey={activeTab}
                    onSelect={e => e && setActiveTab(e)}
                  >
                    {tabs.map(tab => (
                      <Tab
                        key={tab.value}
                        eventKey={tab.value}
                        title={tab.label}
                      >
                        {renderTabContent(tab.value)}
                      </Tab>
                    ))}
                  </Tabs>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EditContact;
