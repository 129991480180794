import React from 'react';

import './TagList.scss';

interface Props {
  tags: string[];
  removeTag: (e: string) => void;
}

const TagList: React.FC<Props> = ({ tags, removeTag }) => {
  return (
    tags.length > 0 && (
      <ul className='tag-list'>
        {tags?.map((item, index) => (
          <li key={index} className='tag-list-item'>
            <span
              className='remove-item'
              role='button'
              aria-label='Sterge'
              onClick={() => removeTag(item)}
            >
              <svg
                width='12'
                height='13'
                viewBox='0 0 12 13'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M7.41425 6.50025L11.7072 2.20725C12.0982 1.81625 12.0982 1.18425 11.7072 0.79325C11.3162 0.40225 10.6842 0.40225 10.2933 0.79325L6.00025 5.08625L1.70725 0.79325C1.31625 0.40225 0.68425 0.40225 0.29325 0.79325C-0.09775 1.18425 -0.09775 1.81625 0.29325 2.20725L4.58625 6.50025L0.29325 10.7933C-0.09775 11.1842 -0.09775 11.8162 0.29325 12.2072C0.48825 12.4022 0.74425 12.5002 1.00025 12.5002C1.25625 12.5002 1.51225 12.4022 1.70725 12.2072L6.00025 7.91425L10.2933 12.2072C10.4882 12.4022 10.7443 12.5002 11.0002 12.5002C11.2562 12.5002 11.5122 12.4022 11.7072 12.2072C12.0982 11.8162 12.0982 11.1842 11.7072 10.7933L7.41425 6.50025Z'
                  fill='#6283FF'
                />
              </svg>
            </span>
            <span className='tag-list-item__title'>{item}</span>
          </li>
        ))}
      </ul>
    )
  );
};

export default TagList;
