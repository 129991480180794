import { createSlice } from '@reduxjs/toolkit';
import { Language } from '../../models/Language';
import { getLanguages } from './languageThunk';

type LanguageState = {
  languages: Language[];
  loading: boolean;
  error: any;
};

const initialState: LanguageState = {
  languages: [],
  loading: false,
  error: null,
};

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLanguages.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLanguages.fulfilled, (state, { payload }) => {
        state.languages = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getLanguages.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = languagesSlice.actions;

export default languagesSlice.reducer;
