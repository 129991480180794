interface Props {
  className?: string;
  onClick?: () => void;
}

const IconClear: React.FC<Props> = props => {
  return (
    <svg
      {...props}
      style={{ cursor: 'pointer' }}
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.71868 4.82322L6.5419 5L6.71868 5.17678L9.5615 8.0196C9.56153 8.01963 9.56155 8.01965 9.56158 8.01968C9.81287 8.27125 9.81275 8.67859 9.56158 8.92976L8.92976 9.56158C8.67852 9.81281 8.27091 9.81281 8.01968 9.56158L5.17678 6.71868L5 6.5419L4.82322 6.71868L1.98033 9.56158C1.72909 9.81281 1.32148 9.81281 1.07024 9.56158L0.438496 8.92983C0.438472 8.92981 0.438449 8.92978 0.438424 8.92976C0.187128 8.67818 0.187256 8.27084 0.438424 8.01968L3.28132 5.17678L3.4581 5L3.28132 4.82322L0.438496 1.9804C0.187128 1.72882 0.187232 1.32144 0.438424 1.07024L1.07017 0.438496C1.07019 0.438472 1.07022 0.438448 1.07024 0.438424C1.32182 0.187128 1.72916 0.187256 1.98033 0.438424L4.82322 3.28132L5 3.4581L5.17678 3.28132L8.0196 0.438496C8.01963 0.438472 8.01965 0.438448 8.01968 0.438424C8.27125 0.187128 8.67859 0.187256 8.92976 0.438424L9.5615 1.07017C9.56153 1.07019 9.56155 1.07022 9.56158 1.07024C9.81287 1.32182 9.81275 1.72916 9.56158 1.98033L6.71868 4.82322Z'
        fill='#6283FF'
        stroke='#F5F5FD'
        strokeWidth='0.5'
      />
    </svg>
  );
};

export default IconClear;
