import React from 'react';
import Form from 'react-bootstrap/Form';
import Input from '../Input/Input';
import Select from '../Select';
import './FilterListRequest.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
  sortProperty: (field: string) => void;
  sort: string;
}
const FilterListRequest: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  sort,
  sortProperty,
}) => {
  return (
    <div className='filter-list-container request-list-filter'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
        </Form.Group>
        <div
          className={`filter-item arrows-label-icon ${sort === 'id' ? 'active-bottom' : ''} ${sort === '-id' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'id'
              ? sortProperty('-id')
              : sort === '-id'
                ? sortProperty('')
                : sortProperty('id')
          }
        >
          <label>ID</label>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'dateUpdated' ? 'active-bottom' : ''} ${sort === '-dateUpdated' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'dateUpdated'
              ? sortProperty('-dateUpdated')
              : sort === '-dateUpdated'
                ? sortProperty('')
                : sortProperty('dateUpdated')
          }
        >
          <label>Modificat</label>
        </div>
        <div className='filter-item'>
          <label>Contact</label>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'categoryTitle' ? 'active-bottom' : ''} ${sort === '-categoryTitle' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'categoryTitle'
              ? sortProperty('-categoryTitle')
              : sort === '-categoryTitle'
                ? sortProperty('')
                : sortProperty('categoryTitle')
          }
        >
          <label>Tip proprietate</label>
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'transactionType' ? 'active-bottom' : ''} ${sort === '-transactionType' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'transactionType'
              ? sortProperty('-transactionType')
              : sort === '-transactionType'
                ? sortProperty('')
                : sortProperty('transactionType')
          }
        >
          <label>Tip tranzactie</label>
        </div>
        <div className='filter-item'>
          <label>Ce cauta?</label>
        </div>

        <div
          className={`filter-item arrows-label-icon ${sort === 'price' ? 'active-bottom' : ''} ${sort === '-price' ? 'active-top' : ''}`}
          onClick={() =>
            sort === 'price'
              ? sortProperty('-price')
              : sort === '-price'
                ? sortProperty('')
                : sortProperty('price')
          }
        >
          <label>Buget/Plata</label>
        </div>
        <div className='filter-item'>
          <label>Agent</label>
        </div>
      </form>
    </div>
  );
};

export default FilterListRequest;
