import IconHome from '../../icons/IconHome';
import './AlphabetFilter.scss';

interface Props {
  filtersForm: any;
  setFiltersForm: (e: any) => void;
}

const AlphabetFilter: React.FC<Props> = ({ filtersForm, setFiltersForm }) => {
  const Alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  return (
    <div className='alphabet-container display-flex'>
      <div className='alphabet-title'>Filtrare dupa litere</div>
      <div className='alphabet-list'>
        <div
          className={`${Object.keys(filtersForm).length === 0 || filtersForm?.letter === null ? 'active' : ''} img-block`}
          onClick={() => {
            setFiltersForm({ ...filtersForm, letter: null });
          }}
        >
          <IconHome />
        </div>
        {Alphabet.map((item: string) => (
          <span
            className={filtersForm.letter === item ? 'active' : ''}
            onClick={() => {
              setFiltersForm({ ...filtersForm, letter: item });
            }}
          >
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};

export default AlphabetFilter;
