import React from 'react';
import Modal from 'react-bootstrap/Modal';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getImagePath } from '../../helpers/useHelper';
import { Media } from '../../models/Properties';
import Listitem from '../../assets/img/house.png';
import './GalleryModal.scss';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconGalleryLeft from '../../icons/IconGalleryLeft';
import IconGalleryRight from '../../icons/IconGalleryRight';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  images: Media[];
}

const GalleryModal: React.FC<Props> = ({ isModalOpen, closeModal, images }) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'mobile-modal gallery-modal'}
      >
        <div className="close-btn-modal" onClick={closeModal}>
          <img src={CloseIcon} alt="CloseIcon" />
        </div>
        <Modal.Body>
          <div className='gallery-block'>
            <button className='gallery__nav gallery__prev'>
              <IconGalleryLeft />
            </button>
            <button className='gallery__nav gallery__next'>
              <IconGalleryRight />
            </button>

            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={0}
              slidesPerView={1}
              navigation={{
                nextEl: '.gallery__next',
                prevEl: '.gallery__prev',
              }}
              pagination
            >
              {images && images.length > 0 ? (
                images?.map(
                  (image, index) =>
                    image.media?.path && (
                      <SwiperSlide key={index}>
                        <img src={getImagePath(image.media?.path)} alt='' />
                      </SwiperSlide>
                    ),
                )
              ) : (
                <SwiperSlide>
                  <img src={Listitem} alt='' />
                </SwiperSlide>
              )}

              <span className='gallery__count'>{images?.length} imagini</span>
            </Swiper>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GalleryModal;
