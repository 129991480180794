interface Props {}

const IconDetails: React.FC<Props> = props => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9998 6.40195C12.0035 6.40195 12.818 5.58883 12.818 4.58696C12.818 3.58509 12.0035 2.77197 10.9998 2.77197C9.99619 2.77197 9.18164 3.58509 9.18164 4.58696C9.18164 5.58883 9.99619 6.40195 10.9998 6.40195ZM10.9998 9.12442C9.99619 9.12442 9.18164 9.93754 9.18164 10.9394C9.18164 11.9413 9.99619 12.7544 10.9998 12.7544C12.0035 12.7544 12.818 11.9413 12.818 10.9394C12.818 9.93754 12.0035 9.12442 10.9998 9.12442ZM9.18164 17.2919C9.18164 16.29 9.99619 15.4769 10.9998 15.4769C12.0035 15.4769 12.818 16.29 12.818 17.2919C12.818 18.2937 12.0035 19.1068 10.9998 19.1068C9.99619 19.1068 9.18164 18.2937 9.18164 17.2919Z'
        fill='white'
      />
    </svg>
  );
};

export default IconDetails;
