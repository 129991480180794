interface Props {}

const IconArrowUpStrong: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7917 19.6875C24.7917 20.0608 24.6488 20.4342 24.3645 20.7186C23.7942 21.2888 22.8726 21.2888 22.3024 20.7186L17.4826 15.8988L12.6803 20.5363C12.0999 21.0948 11.1767 21.0788 10.6182 20.4998C10.0597 19.9194 10.0757 18.9963 10.6532 18.4377L16.4865 12.8056C17.0597 12.2529 17.9682 12.2602 18.5311 12.8231L24.3645 18.6565C24.6488 18.9408 24.7917 19.3142 24.7917 19.6875Z"
      />
    </svg>
  );
};

export default IconArrowUpStrong;
