import { createAsyncThunk } from '@reduxjs/toolkit';
import { Filters } from '../../models/Properties';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';


export const getNotificationActivities = createAsyncThunk<any, Filters | any>(
  'auth/getNotificationActivities',
  async (data, { rejectWithValue }) => {
    try {
      let params: any = {};
      Object.keys(data).forEach(key => {
        if (key === 'typeId') params.type = data[key];
        else params[key] = data[key];
      });

      const response = await appAxios.get('api/v1/crm/activities', { params });
      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);