import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IconArrowRight from '../../icons/IconArrowRight';
import './NotificationDropdown.scss';
import moment from 'moment';
import { DAYS_OF_WEEK, getDateFromCurrentDayOfWeek } from '../../helpers/useHelper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getNotificationActivities } from '../../redux/notifications/notificationsThunk';
import { ActivityItem } from '../../models/Activities';

interface Props {
  isNotification: boolean;
  isCalendar: boolean;
  isProfile: boolean;
  logout?: () => void;
}

const NotificationDropdown: React.FC<Props> = ({
  isNotification,
  isCalendar,
  isProfile,
  logout,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { auth } = useAppSelector(state => state.auth);
  const { activities, totalCount } = useAppSelector(state => state.notifications);

  useEffect(() => {
    const from = new Date(moment().year(), moment().month(), moment().date());
    const to = new Date(from);
    to.setDate(from.getDate() + 1);

    const params = {
        limit: 3,
        offset: 1,
        employee: auth?.profile?.id,
        dateFrom: moment(from).format('YYYY-MM-DD') + ' 00:00:00',
        dateTo: moment(to).format('YYYY-MM-DD') + ' 00:00:00',
      }

      dispatch(getNotificationActivities(params));
  }, [])

  const getCurrentWeekRange = () => {
    return `${getDateFromCurrentDayOfWeek(new Date(), DAYS_OF_WEEK.MONDAY)} 
            - ${getDateFromCurrentDayOfWeek(new Date(), DAYS_OF_WEEK.SUNDAY)}`;
  }

  return (
    <>
      <div
        className={
          isNotification || isCalendar || isProfile
            ? 'dropdown-modal-container active'
            : 'dropdown-modal-container'
        }
      >
        <div
          className={
            isNotification || isCalendar || isProfile
              ? 'dropdown-modal active'
              : 'dropdown-modal'
          }
        >
          {isNotification && (
            <div className='notifications-container'>
              <div className='header-dropdown'>
                Notificari Sistem <span>3</span>
              </div>
              <div className='notification-item-list'>
                <div className='content-container'>
                  <p>Abonament imobmanager.ro</p>
                  <span>
                    Abonamentul expira in 2 zile in data de 29.12.2023
                  </span>
                </div>
              </div>
              <div className='notification-item-list'>
                <div className='content-container'>
                  <p>Abonament imobmanager.ro</p>
                  <span>
                    Abonamentul expira in 2 zile in data de 29.12.2023
                  </span>
                </div>
              </div>
              <div className='notification-item-list'>
                <div className='content-container'>
                  <p>Abonament imobmanager.ro</p>
                  <span>
                    Abonamentul expira in 2 zile in data de 29.12.2023
                  </span>
                </div>
              </div>
              <div className='view-all-container'>
                <button>View All</button>
              </div>
            </div>
          )}
          {isProfile && (
            <div className='profile-container'>
              <div
                className='profile-item-list'
                onClick={() => navigate('/my-profile')}
              >
                <div className='content-container'>
                  <p>Profilul meu</p>
                </div>
              </div>
              <div className='profile-item-list'>
                <div className='content-container'>
                  <p>Suport</p>
                </div>
              </div>
              <div
                className='profile-item-list'
                onClick={() => logout && logout()}
              >
                <div className='content-container'>
                  <p>Logout</p>
                </div>
              </div>
            </div>
          )}
          {isCalendar && (
            <div className='activity-calendar-container'>
              <div className='header-dropdown'>Calendar Activitati</div>
              <div className='calendar-dates'>
                <div className='day-date'>
                  <div>
                    Azi <span>{totalCount}</span>
                  </div>
                </div>
                <div className='week-date'>
                  <div>
                    Sapt.
                    <span>{getCurrentWeekRange()}</span>
                  </div>
                </div>
                <div className='month-date'>
                  <div>
                    Luna
                    <span>{moment().format('MMMM')}</span>
                  </div>
                </div>
              </div>
              {activities.map((activity: ActivityItem) => {
                return (
                  <Link to={'/activities/' + activity.id} className='calendar-list-item'>
                    <div className='content-container'>
                      <div className='list-line'>
                        <span>{moment(activity.dateStart).format("DD.MM.yyyy")} {!activity.allDay &&
                             `${moment(activity.dateStart).format("HH:mm")} - ${moment(activity.dateEnd).format("HH:mm")}`}</span>
                        {activity.title}
                      </div>
                      <div className='arrow-block'>
                        <IconArrowRight />
                      </div>
                    </div>
                  </Link>
                )
              })}
              {totalCount > 3 &&
              <div className='view-all-container'>
                <button onClick={() => navigate('/calendar')}>Vezi Calendarul</button>
              </div>
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationDropdown;
