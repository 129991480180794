import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';
import moment from 'moment';


export const getActiveAgentsCount = createAsyncThunk<any>(
  'crm/getActiveAgentsCount',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        limit: 0,
        status: '1'
      }
      const response = await appAxios.get('api/v1/crm/users', { params });

      return response.headers['x-total-count'];
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  }
);


export const getLastAnnouncesCount = createAsyncThunk<any>(
  'crm/getLastAnnouncesCount',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        limit: 0,
        'filters[31][from]': moment().subtract(1, 'days').format('YYYY-MM-DD'),
        'filters[31][to]': moment().format('YYYY-MM-DD')
      }
      const response = await appAxios.get('api/v1/crm/announcements', { params });

      return response.headers['x-total-count'];
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  }
);


export const getLastRequestsCount = createAsyncThunk<any>(
  'crm/getLastRequestsCount',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        limit: 0,
        status: '1,4,0,8',
        'filters[106][from]': moment().subtract(1, 'days').format('YYYY-MM-DD'),
        'filters[106][to]': moment().format('YYYY-MM-DD')
      }
      const response = await appAxios.get('api/v1/crm/requests', { params });

      return response.headers['x-total-count'];
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  }
);