interface Props {}

const IconLogOut: React.FC<Props> = (props) => {
 return (
  <svg
   width="18"
   height="17"
   viewBox="0 0 18 17"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.33341 1.20817C4.33341 1.78109 3.86466 2.24984 3.29175 2.24984H2.25008V14.7498H3.29175C3.86466 14.7498 4.33341 15.2186 4.33341 15.7915C4.33341 16.3644 3.86466 16.8332 3.29175 16.8332H1.20841C0.635498 16.8332 0.166748 16.3644 0.166748 15.7915V1.20817C0.166748 0.635254 0.635498 0.166504 1.20841 0.166504H3.29175C3.86466 0.166504 4.33341 0.635254 4.33341 1.20817ZM14.7541 3.734L17.6854 7.90067C17.9458 8.26942 17.9375 8.76421 17.6666 9.12463L14.5416 13.2913C14.3375 13.5642 14.0239 13.708 13.7073 13.708C13.4906 13.708 13.2708 13.6403 13.0833 13.4996C12.6229 13.1548 12.5302 12.5017 12.875 12.0423L14.751 9.5413H14.75H6.41664C5.84164 9.5413 5.37498 9.07567 5.37498 8.49963C5.37498 7.92359 5.84164 7.45796 6.41664 7.45796H14.75C14.7672 7.45796 14.7832 7.46271 14.7992 7.46749C14.8123 7.47139 14.8255 7.47531 14.8396 7.47671L13.05 4.93192C12.7187 4.46213 12.8323 3.81213 13.3031 3.48088C13.7729 3.14859 14.4229 3.26317 14.7541 3.734Z"
    fill="#1D1956"
   />
  </svg>
 );
};

export default IconLogOut;
