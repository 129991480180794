import React from 'react';
import { getImagePath } from '../../helpers/useHelper';
import { Contact, PhoneNumber } from '../../models/Contacts';
import Avatar from '../../assets/img/avatar.png';
import IconEnvelope from '../../icons/IconEnvelope';
import IconPhone from '../../icons/IconPhone';
import IconRemove from '../../icons/IconRemove';
import './ContactUserInfo.scss';

interface Props {
  item: Contact;
  removeContact: (id: any) => void;
}

const ContactUserInfo: React.FC<Props> = ({ item, removeContact }) => {
  return (
    <div className='contact-info-block' key={item.id}>
      <div className='img-block'>
        {item?.avatarPath ? (
          <img src={getImagePath(item?.avatarPath)} alt='' />
        ) : (
          <img src={Avatar} alt='' />
        )}
      </div>
      <div className='info-contact'>
        <span className='name'>{item?.name + ' ' + item?.surname}</span>
        <ul className='info-item'>
          {item?.phoneNumbers?.map(
            (phoneItem: PhoneNumber, index: number) =>
              phoneItem?.phone !== '' && (
                <li key={index} className='info-item__list-item'>
                  <a
                    href={`tel:${phoneItem?.phone}`}
                    target='_blank'
                    className='number'
                    rel='noreferrer'
                  >
                    <IconPhone />
                    <span>{phoneItem?.phone}</span>
                  </a>
                </li>
              ),
          )}
        </ul>
        <ul className='info-item'>
          {item?.emails?.map(
            (emailItem: string, index: number) =>
              emailItem !== '' && (
                <li key={index} className='info-item__list-item'>
                  <a
                    href={`mailto:${emailItem}`}
                    target='_blank'
                    className='number'
                    rel='noreferrer'
                  >
                    <IconEnvelope />
                    <span>{emailItem}</span>
                  </a>
                </li>
              ),
          )}
        </ul>
      </div>
      <button
        className='remove-btn'
        aria-label=''
        onClick={e => {
          e.preventDefault();
          removeContact(item.id);
        }}
      >
        <IconRemove />
      </button>
    </div>
  );
};

export default ContactUserInfo;
