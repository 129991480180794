import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { PhoneNumber } from '../../models/Contacts';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconAlert from '../../icons/IconAlert';
import './ContactDuplicateModal.scss';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  modalType: string;
  assignedContact: string;
  contact: any;
}

const ContactDuplicateModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  modalType,
  assignedContact,
  contact,
}) => {
  const submitForm = () => {};

  const renderAlert = () => {
    switch (modalType) {
      case 'exists':
        return (
          <span>
            Contactul exista.
            <br />
            Cauta in lista ta de contacte.
          </span>
        );

      case 'alocated':
        return (
          <span>
            Contactul apartine agentului <b>{assignedContact}</b>.
            <br /> Ti-a fost alocat deja sau cererea este in asteptare.
          </span>
        );

      case 'assignedContact':
        return (
          <span>
            Contactul apartine agentului <b>{assignedContact}</b>.
            <br />
            Trimite o cerere de accesare contact.
          </span>
        );
    }
  };
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'mobile-modal activities-modal'}
      >
        <Modal.Header>
          {' '}
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          <span>
            {modalType === 'exists'
              ? 'Contact Existent'
              : 'Contactul apartine altui agent'}
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className='add-activity-modal'>
            <div className='modal-warning'>
              <IconAlert />
              {renderAlert()}
            </div>
            <div className='contact-info'>
              <div className='contact-info__row'>
                <span className='contact-info__label'>Nume: </span>
                <span className='contact-info__value'>
                  {contact.name + ' ' + contact.surname}
                </span>
              </div>
              <div className='contact-info__row'>
                <span className='contact-info__label'>Telefon: </span>
                <span className='contact-info__value'>
                  {[
                    ...contact.phoneNumbers.map(
                      (phoneNumber: PhoneNumber) => phoneNumber.phone,
                    ),
                    contact.internationalPhoneNumber,
                  ]
                    .filter(phone => phone !== '')
                    .join(', ')}
                </span>
              </div>
              <div className='contact-info__row'>
                <span className='contact-info__label'>Email: </span>
                <span className='contact-info__value'>
                  {contact.emails.join(' ')}
                </span>
              </div>
            </div>
          </div>
          <div className='btns-container'>
            {modalType === 'assignedContact' ? (
              <>
                <button className='reset-btn' onClick={() => closeModal()}>
                  Renunta
                </button>
                <button
                  className='button-blue button-md'
                  onClick={() => submitForm()}
                >
                  Trimite
                </button>
              </>
            ) : (
              <button className='reset-btn' onClick={() => closeModal()}>
                Inchide
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactDuplicateModal;
