interface Props {}

const IconUser: React.FC<Props> = props => {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Profil"
      {...props}
    >
      <path
        id="Color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 20.1482C16 20.8024 15.4891 21.3334 14.8571 21.3334C14.2251 21.3334 13.7143 20.8024 13.7143 20.1482C13.7143 16.8806 11.1509 14.2222 8 14.2222C4.84914 14.2222 2.28571 16.8806 2.28571 20.1482C2.28571 20.8024 1.77486 21.3334 1.14286 21.3334C0.510857 21.3334 0 20.8024 0 20.1482C0 15.5734 3.58971 11.8519 8 11.8519C12.4103 11.8519 16 15.5734 16 20.1482ZM8 2.37036C9.26057 2.37036 10.2857 3.43347 10.2857 4.74073C10.2857 6.04799 9.26057 7.1111 8 7.1111C6.73943 7.1111 5.71429 6.04799 5.71429 4.74073C5.71429 3.43347 6.73943 2.37036 8 2.37036ZM8 9.48148C10.5211 9.48148 12.5714 7.35526 12.5714 4.74074C12.5714 2.12622 10.5211 0 8 0C5.47886 0 3.42857 2.12622 3.42857 4.74074C3.42857 7.35526 5.47886 9.48148 8 9.48148Z"
      />
    </svg>
  );
};

export default IconUser;
