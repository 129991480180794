import { createSlice } from '@reduxjs/toolkit';
import { UserProfile } from '../../models/AgentsDto';
import { Agent, Organization } from '../../models/Users';
import {
  addUser,
  deleteUser,
  editOrganization,
  editUser,
  getOrganization,
  getUser,
  getUserProfile,
  getUsers,
} from './usersThunk';

type UsersState = {
  users: Agent[];
  user: null | UserProfile;
  count: number;
  loading: boolean;
  saving: boolean;
  error: any;
  organizationState: {
    organization: Organization | null;
    loading: boolean;
    error: any;
  };
};

const initialState: UsersState = {
  users: [],
  count: 0,
  user: null,
  loading: false,
  saving: false,
  error: null,
  organizationState: { organization: null, loading: false, error: null },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearUser(state) {
      state.user = null;
    },
    clearUsers(state) {
      state.users = [];
    },
    toggleChecked(state, action) {
      const userId = action.payload;
      const user = state.users.find(user => user.id === userId);

      if (user) {
        user.checked = !user.checked;
      }
    },
    checkAll(state, action) {
      state.users.forEach(user => (user.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUsers.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.users = payload.users;
        state.count = +payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getUser.pending, state => {
        state.user = null;
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getUserProfile.pending, state => {
        state.user = null;
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(addUser.pending, state => {
        state.saving = true;
        state.error = null;
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.saving = false;
        state.error = null;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.error = action.payload;
        state.saving = false;
      })
      .addCase(editUser.pending, state => {
        state.saving = true;
        state.error = null;
      })
      .addCase(editUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.saving = false;
        state.error = null;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.error = action.payload;
        state.saving = false;
      })

      .addCase(deleteUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getOrganization.pending, state => {
        state.organizationState.loading = true;
        state.error = null;
      })
      .addCase(getOrganization.fulfilled, (state, { payload }) => {
        state.organizationState.organization = payload;
        state.organizationState.loading = false;
        state.error = null;
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.organizationState.error = action.payload;
        state.organizationState.loading = false;
      })
      .addCase(editOrganization.pending, state => {
        state.organizationState.loading = true;
        state.organizationState.error = null;
      })
      .addCase(editOrganization.fulfilled, (state, { payload }) => {
        state.organizationState.organization = payload;
        state.organizationState.loading = false;
        state.organizationState.error = null;
      })
      .addCase(editOrganization.rejected, (state, action) => {
        state.organizationState.error = action.payload;
        state.organizationState.loading = false;
      });
  },
});

export const { clearError, clearUser, clearUsers, toggleChecked, checkAll } =
  usersSlice.actions;

export default usersSlice.reducer;
