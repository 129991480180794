interface Props {}

const IconDiskette: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M17.8,7.1l-4.8-4.8c-0.5-0.5-1.1-0.7-1.8-0.7H4C2.6,1.5,1.5,2.6,1.5,4v12c0,1.4,1.1,2.5,2.5,2.5h12
        c1.4,0,2.5-1.1,2.5-2.5V8.8C18.5,8.2,18.2,7.5,17.8,7.1z M12.5,17.5h-5v-3h5V17.5z M17.5,16c0,0.8-0.7,1.5-1.5,1.5h-2.5V14
        c0-0.3-0.2-0.5-0.5-0.5H7c-0.3,0-0.5,0.2-0.5,0.5v3.5H4c-0.8,0-1.5-0.7-1.5-1.5V4c0-0.8,0.7-1.5,1.5-1.5h2.5V8
        c0,0.3,0.2,0.5,0.5,0.5h4c0.3,0,0.5-0.2,0.5-0.5S11.3,7.5,11,7.5H7.5v-5h3.7c0.4,0,0.8,0.2,1.1,0.4l4.8,4.8c0.3,0.3,0.4,0.7,0.4,1.1
        V16z"
      />
    </svg>
  );
};

export default IconDiskette;
