import { createAsyncThunk } from '@reduxjs/toolkit';
import { Language } from '../../models/Language';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getLanguages = createAsyncThunk<Language[], any>(
  'crm/getLanguages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/languages', {
        params: data,
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  }
);
