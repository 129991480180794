import roLocale from '@fullcalendar/core/locales/ro';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { getBackgroundColor } from '../../helpers/useHelper';
import './FullCalendar.scss';

interface Event {
  title: string;
  start?: string;
  end?: string;
  date?: string;
  id: string;
  typeTitle?: string;
  color?: string;
}

interface Props {
  activeDate?: any;
  setIsModalOpen: (e: boolean) => void;
  setActiveDate: (e: any) => void;
  setActivityId: (e: string) => void;
  setInfoModalOpen: (e: boolean) => void;
}

const FullCalendarItem: React.FC<Props> = ({
  activeDate,
  setIsModalOpen,
  setActiveDate,
  setActivityId,
  setInfoModalOpen,
}: Props) => {
  const calendarRef = useRef<any>(null);
  const { activities } = useAppSelector(state => state.activities);
  const [events, setEvents] = useState<Event[] | []>([]);

  useEffect(() => {
    const activitiesEvent: Event[] = activities.map(activity => {
      return {
        id: String(activity.id),
        title: activity.title,
        start: activity.dateStart,
        end: activity.allDay === 0 ? activity.dateEnd : adjustDateTime(activity.dateEnd),
        allDay: activity.allDay,
        color: getBackgroundColor(activity?.typeTitle),
      };
    });
    setEvents(activitiesEvent);
  }, [activities]);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (activeDate.date) {
      activeDate.changeView
        ? calendarApi.changeView('timeGridDay', new Date(activeDate.date))
        : calendarApi.gotoDate(new Date(activeDate.date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate]);

  function adjustDateTime(input: string): string {
    let date = new Date(input);

    date.setDate(date.getDate() + 1);

    return date.toISOString();
  }

  return (
    <div className='full-calendar-container'>
      <FullCalendar
        dayMaxEvents={2}
        dateClick={e => {
          setActiveDate({ date: e.dateStr, changeView: false });
          setIsModalOpen(true);
        }}
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        locale={roLocale}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        events={events}
        eventClick={info => {
          setInfoModalOpen(true);
          setActivityId(info.event.id);
        }}
      />
    </div>
  );
};

export default FullCalendarItem;
