import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../models/Auth';
import {
  authentication,
  fetchToken,
  forgotPassword,
  getUserProfile,
  logout,
  resetPassword,
} from './authThunk';

type AuthState = {
  auth: null | User;
  loading: boolean;
  error: any;
};

const initialState: AuthState = {
  auth: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authentication.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authentication.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(authentication.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(forgotPassword.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(resetPassword.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchToken.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchToken.fulfilled, (state, { payload, meta }) => {
        state.loading = false;
        state.error = null;

        localStorage.setItem('userId', meta.arg.id.toString());
        localStorage.setItem(
          'userProfileId',
          meta.arg.userProfileId.toString(),
        );
        localStorage.setItem('bearerToken', payload.bearerToken);
        localStorage.setItem('refreshToken', payload.refreshToken);
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getUserProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.auth = payload;
        localStorage.setItem(
          'permissions',
          payload.permissions
            .flatMap(permission =>
              permission.items
                ? [...permission.items.map(subItem => subItem.id)]
                : permission.id,
            )
            .map(id => id.toString())
            .join(','),
        );
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(logout.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, state => {
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('permissions');
        localStorage.removeItem('userId');
        state.loading = false;
        state.auth = null;
      })
      .addCase(logout.rejected, state => {
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('permissions');
        localStorage.removeItem('userId');
        state.loading = false;
        state.auth = null;
      });
  },
});

export const { clearError } = authSlice.actions;

export default authSlice.reducer;
