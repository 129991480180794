import "./SlotAllocationTable.scss";
import ProfileIcon from "../../assets/svg/slotimg.svg";
import IconPhone from "../../icons/IconPhone";
import IconEnvelope from "../../icons/IconEnvelope";
interface Props {}

const SlotAllocationTable: React.FC<Props> = () => {
    return (
        <div className="slot-allocation-wrapper">
            <div className="slot-allocation-table">
                <div className="slot-allocation-item desktop-titles">
                    <div className="slot-column">Agent</div>
                    <div className="slot-column">Basic</div>
                    <div className="slot-column">Premium</div>
                    <div className="slot-column">Up!</div>
                    <div className="slot-column">Spotlight</div>
                </div>
                <div className="slot-allocation-item">
                    <div className="column-item">
                        {/* <div className="mobile-text">Agent</div> */}
                        <div className="slot-column">
                            <div className="img-block">
                                <img src={ProfileIcon} alt="" />
                            </div>
                            <div className="profile-details">
                                <span className="name">Ever Imob</span>
                                <a href="tel:" aria-label="" className="number">
                                    <IconPhone/>
                                    0756 83 27 57
                                </a>
                                <a href="mailto:" aria-label="" className="mail">
                                    <IconEnvelope/>
                                    lucianaraileanu@yahoo.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Basic</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Premium</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Up!</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Spotlight</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slot-allocation-item">
                    <div className="column-item">
                        <div className="mobile-text">Agent</div>
                        <div className="slot-column">
                            <div className="img-block">
                                <img src={ProfileIcon} alt="" />
                            </div>
                            <div className="profile-details">
                                <span className="name">Ever Imob</span>
                                <a href="tel:" aria-label=""  className="number">
                                    <IconPhone/>
                                    0756 83 27 57
                                </a>
                                <a href="mailto:" aria-label=""  className="mail">
                                    <IconEnvelope/>
                                    lucianaraileanu@yahoo.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Basic</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Premium</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Up!</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Spotlight</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slot-allocation-item">
                    <div className="column-item">
                        <div className="mobile-text">Agent</div>
                        <div className="slot-column">
                            <div className="img-block">
                                <img src={ProfileIcon} alt="" />
                            </div>
                            <div className="profile-details">
                                <span className="name">Ever Imob</span>
                                <a href="tel:" aria-label=""  className="number">
                                    <IconPhone/>
                                    0756 83 27 57
                                </a>
                                <a href="mailto:" aria-label="" className="mail">
                                    <IconEnvelope/>
                                    lucianaraileanu@yahoo.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Basic</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Premium</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Up!</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Spotlight</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slot-allocation-item">
                    <div className="column-item">
                        <div className="mobile-text">Agent</div>
                        <div className="slot-column">
                            <div className="img-block">
                                <img src={ProfileIcon} alt="" />
                            </div>
                            
                            <div className="profile-details">
                                <span className="name">Ever Imob</span>
                                <a href="tel:" aria-label=""  className="number">
                                    <IconPhone/>
                                    0756 83 27 57
                                </a>
                                <a href="mailto:" aria-label=""  className="mail">
                                    <IconEnvelope/>
                                    lucianaraileanu@yahoo.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Basic</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Premium</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Up!</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                    <div className="column-item">
                        <div className="mobile-text">Spotlight</div>
                        <div className="slot-column">
                            <div className="content-numbers">
                                <span className="pill">0</span>0 folosite
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slot-allocation-item-total">
                    <div className="slot-column-line">
                        <div className="slot-column">Total Alocate</div>
                        <div className="column-item">
                            <div className="mobile-text">Agent</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">28</span>971 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Basic</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">17</span>3 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Premium</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">17</span>23 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Up!</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">7</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slot-column-line">
                        <div className="slot-column">Total Alocate</div>
                        <div className="column-item">
                            <div className="mobile-text">Agent</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">28</span>971 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Basic</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">17</span>3 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Premium</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">17</span>23 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Up!</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">7</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slot-column-line">
                        <div className="slot-column">Total Alocate</div>
                        <div className="column-item">
                            <div className="mobile-text">Agent</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">28</span>971 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Basic</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">17</span>3 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Premium</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">17</span>23 ramase
                                </div>
                            </div>
                        </div>
                        <div className="column-item">
                            <div className="mobile-text">Up!</div>
                            <div className="slot-column">
                                <div className="content-numbers">
                                    <span className="pill">7</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btns-container">
                <button className="button-blue button-md">Salveaza</button>
                <button className="reset-btn">Reseteaza</button>
            </div>
        </div>
    );
};

export default SlotAllocationTable;
