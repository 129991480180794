import React from 'react';
import './NoAccessToContact.scss';

interface Props {
  data: any;
}

const NoAccessToContact: React.FC<Props> = ({ data }) => {
  return (
    <div className='no-access-container'>
      <p>
        Contactul
        <span className='highlight'> {data.fullName} </span>
        apartine agentului
        <span className='highlight'> {data.employeeFullName} </span>. Nu ai
        drepturi de vizualizare.
      </p>
      <p>
        Trimite o solicitare de accesare contact
        <button className='button'>Trimite</button>
      </p>
    </div>
  );
};

export default NoAccessToContact;
