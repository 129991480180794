interface Props {}

const IconEarth: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        d="M10.9,20.5C10.9,20.5,10.9,20.5,10.9,20.5C10.9,20.5,10.9,20.5,10.9,20.5C10.9,20.5,10.9,20.5,10.9,20.5
    L10.9,20.5z M11.2,20.5L11.2,20.5C11.2,20.5,11.2,20.5,11.2,20.5L11.2,20.5z M11,1.5L11,1.5C11,1.5,11,1.5,11,1.5
    c-5.3,0-9.5,4.3-9.5,9.5c0,5.2,4.2,9.5,9.5,9.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0.1c5.2,0,9.4-4.3,9.4-9.5
    C20.5,5.8,16.2,1.5,11,1.5z M13.8,3c3,1,5.2,3.7,5.6,7l0.1,0.6h-4l0-0.5c-0.2-2.7-1.2-4.8-2.2-6.3l-0.9-1.3L13.8,3z M11,2.7l0.4,0.4
    c1,1.1,2.8,3.5,3.1,6.9l0,0.5h-7l0-0.5c0.3-3.4,2.1-5.8,3.1-6.9L11,2.7z M2.6,9.9C3,6.8,5.1,4.1,8,3v0c0,0,0.1,0,0.1,0
    c0,0,0.1,0,0.1,0c0.7-0.2,2.4-0.8,1.9-0.5c-0.5,0.2-1,0.9-1.3,1.3c0,0,0,0.1,0,0.1C8.7,3.9,8.7,3.9,8.7,4c0,0,0,0,0,0
    c-1,1.4-1.9,3.5-2.1,6.1l0,0.5h-4L2.6,9.9z M8.2,19c-3-1-5.3-3.7-5.7-7l-0.1-0.6h4.1l0,0.5c0.2,2.8,1.2,4.9,2.3,6.3l0.9,1.3L8.2,19z
    M11,19.3L10.7,19c-1.1-1.2-2.8-3.5-3.1-6.9l0-0.5h7l0,0.5c-0.3,3.4-2.1,5.8-3.1,6.9L11,19.3z M19.4,12.1c-0.4,3.2-2.6,5.9-5.6,7
    l-1.5,0.5l0.9-1.3c1-1.5,2.1-3.6,2.3-6.3l0-0.5h4L19.4,12.1z"
      />
      <path
        d="M11,1.5C11,1.5,11,1.5,11,1.5 M11,1.5L11,1.5 M11,1.5L11,1.5 M10.9,20.5C10.9,20.5,10.9,20.5,10.9,20.5
    C10.9,20.5,10.9,20.5,10.9,20.5C10.9,20.5,10.9,20.5,10.9,20.5L10.9,20.5z M11.2,20.5L11.2,20.5C11.2,20.5,11.2,20.5,11.2,20.5
    L11.2,20.5z M10.9,20.5C10.9,20.5,10.9,20.5,10.9,20.5C10.9,20.5,10.9,20.5,10.9,20.5C10.9,20.5,10.9,20.5,10.9,20.5L10.9,20.5z
    M11.2,20.5L11.2,20.5C11.2,20.5,11.2,20.5,11.2,20.5L11.2,20.5z"
      />
    </svg>
  );
};

export default IconEarth;
