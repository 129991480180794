import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input/Input';
import MultiselectCheckboxes from '../../components/MultiselectCheckboxes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { generateDescription } from '../../redux/properties/propertiesThunk';
import {
  getFiltersParsed,
  getValue,
  parseValues,
} from '../../helpers/useHelper';
import {
  Filter,
  PropertyForm,
  SelectValue,
  ValueFilter,
} from '../../models/Properties';
import IconLoad from '../../icons/IconLoad';
import './CharacteristicsForm.scss';

interface Props {
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
  handleChange: (key: string, value: SelectValue | null) => void;
  handleString: (key: string, value: string | null) => void;
}

const CharacteristicsForm: React.FC<Props> = ({
  formData,
  handleChange,
  handleString,
  setFormData,
}) => {
  const dispatch = useAppDispatch();
  const { property } = useAppSelector(state => state.properties);
  const [activeLang, setActiveLang] = useState<'ro' | 'en'>('ro');
  const [propertyFilters, setPropertyFilters] = useState<any>({});

  const energyOptions = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: 'G', label: 'G' },
  ];

  useEffect(() => {
    let initialForm: any = null;

    property?.category?.filters?.forEach(filter => {
      let value: any = null;
      property?.propertyHasFilters?.forEach(item => {
        if (item?.filter?.id === filter.id) {
          if (item.value) value = item.value;

          if (item.filterValue)
            if (item.filter.propertyTypeAdd === 'SELECT_OPTIONS') {
              if (value?.length > 0) value.push(item.filterValue.id);
              else value = [item.filterValue.id];
            } else
              value = {
                label: item.filterValue?.title,
                value: item.filterValue?.id,
              };
        } else if (filter.children.length > 0) {
          filter.children?.forEach((child: Filter) => {
            let childValue;
            if (item?.filter?.id === child.id) {
              if (item.value)
                if (item.filter.propertyTypeAdd === 'INPUT') {
                  childValue = item.value;
                } else childValue = item.value === '1' ? 1 : 0;
            }
            if (child.propertyShowOnAdd && childValue) {
              value = {
                ...value,
                [child.id]: childValue,
              };
            }
          });
        }
      });

      if (filter.children.length > 0) {
        initialForm = {
          ...initialForm,
          ...value,
        };
      } else if (filter.propertyShowOnAdd) {
        initialForm = {
          ...initialForm,
          [filter.id]: value,
        };
      }
    });
    initialForm && setPropertyFilters(initialForm);
  }, [property?.category?.filters, property?.propertyHasFilters]);

  useEffect(() => {
    property?.category?.filters &&
      setFormData({
        ...formData,
        filters: getFiltersParsed(
          property?.category?.filters,
          propertyFilters,
        ).filter(item => item.id),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyFilters, property?.category.filters]);

  const parseValuesFilter = (values: ValueFilter[]) => {
    if (values.length > 0) {
      return values.map(filter => {
        return {
          value: filter.id,
          label: filter.title,
        };
      });
    } else return [];
  };

  const handleChangeFilter = (
    key: string | number,
    e: string | number | SelectValue | boolean,
  ) => {
    setPropertyFilters({ ...propertyFilters, [key]: e });
  };

  const handleChangeRange = (
    key: string | number,
    object: string,
    e: string | number | SelectValue,
  ) => {
    setPropertyFilters({
      ...propertyFilters,
      [key]: { ...propertyFilters[key], [object]: e },
    });
  };

  const generateText = (e: React.MouseEvent) => {
    const surfacePropertyId =
      property?.category?.filters?.find(
        f => f.title.toLowerCase() === 'suprafata',
      )?.id || 0;
    const params = {
      categoryId: property?.category?.id,
      title: formData?.translations?.[activeLang]?.title,
      surface: Number(propertyFilters[surfacePropertyId]),
    };

    e.preventDefault();
    dispatch(generateDescription(params)).then(res => {
      if (res.payload?.description)
        setFormData({
          ...formData,
          translations: {
            ...formData.translations,
            ro: {
              ...formData.translations?.ro,
              description: res.payload?.description,
            },
          },
        });
    });
  };

  return (
    <div className='display-flex ads-form-container'>
      <Container>
        <Row>
          <Col className='ads-form-container-block '>
            <fieldset className='row fieldset-block row-93'>
              <Col lg='12'>
                <legend className='heading-block'>Caracteristici</legend>
              </Col>
              <Col lg='12'>
                <fieldset className='row filters-aligning'>
                  {property?.category?.specificTypes &&
                    property?.category?.specificTypes.length > 0 && (
                      <Col lg='4'>
                        <FormSelect
                          value={
                            formData.specificTypeId &&
                            property?.category?.specificTypes
                              ? getValue(
                                  property?.category?.specificTypes,
                                  formData.specificTypeId,
                                )
                              : null
                          }
                          onChange={(e: SelectValue) =>
                            handleChange('specificTypeId', e)
                          }
                          labelText={true}
                          inlineLabel={'Tip specific'}
                          options={
                            property?.category?.specificTypes &&
                            parseValues(property?.category?.specificTypes)
                          }
                        />
                      </Col>
                    )}
                  {property?.category?.locations &&
                    property?.category?.locations.length > 0 && (
                      <Col lg='4'>
                        <FormSelect
                          value={
                            formData.locationId && property?.category?.locations
                              ? getValue(
                                  property?.category?.locations,
                                  formData.locationId,
                                )
                              : null
                          }
                          onChange={(e: SelectValue) =>
                            handleChange('locationId', e)
                          }
                          labelText={true}
                          inlineLabel={'Amplasament'}
                          options={
                            property?.category?.locations &&
                            parseValues(property?.category?.locations)
                          }
                        />
                      </Col>
                    )}
                  {property?.category?.filters &&
                    property?.category?.filters.length > 0 &&
                    property?.category?.filters.map((filter: Filter) => {
                      if (
                        filter.propertyShowOnAdd &&
                        (!filter.specificTypeId ||
                          filter.specificTypeId === 0 ||
                          filter.specificTypeId === formData.specificTypeId)
                      ) {
                        if (filter.propertyTypeAdd === 'SELECT_OPTIONS') {
                          return (
                            <Col lg='4' key={filter.id}>
                              <MultiselectCheckboxes
                                onChange={(e: SelectValue) =>
                                  handleChangeFilter(filter.id, e)
                                }
                                labelText={true}
                                inlineLabel={filter.title}
                                value={propertyFilters?.[filter.id]}
                                options={
                                  filter.values &&
                                  parseValuesFilter(filter.values)
                                }
                              />
                            </Col>
                          );
                        }
                        if (filter.propertyTypeAdd === 'BLOCK_LVL') {
                          return (
                            <Col lg='4' key={filter.id}>
                              <label className='form-item-group-label'>
                                {filter.title}
                              </label>
                              <div className='block-level'>
                                {filter.children?.map((child: Filter) => {
                                  if (child?.propertyTypeAdd === 'CHECKBOX')
                                    return (
                                      <Form.Group
                                        id='formGridCheckbox'
                                        key={child.id}
                                        style={{ display: 'flex', gap: '10px' }}
                                      >
                                        <Form.Check
                                          type='checkbox'
                                          label={child.title}
                                          checked={
                                            propertyFilters?.[child.id] === 1
                                          }
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>,
                                          ) =>
                                            handleChangeFilter(
                                              child.id,
                                              e.target.checked ? 1 : 0,
                                            )
                                          }
                                        />
                                        {child.id === 63 ? (
                                          <Input
                                            labelText={false}
                                            type='number'
                                            value={propertyFilters?.[64] ?? ''}
                                            onInput={(
                                              e: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                              handleChangeFilter(
                                                64,
                                                e.target.value,
                                              );
                                            }}
                                          />
                                        ) : null}
                                      </Form.Group>
                                    );
                                  else return null;
                                })}
                              </div>
                            </Col>
                          );
                        }
                        if (filter.propertyTypeAdd === 'RANGE')
                          return (
                            <Col lg='4'>
                              <div className='form-group' key={filter.id}>
                                <label className='form-item-group-label'>
                                  {filter.title}
                                </label>
                                <div className='flex-range'>
                                  <Input
                                    labelText={false}
                                    placeholder='De la'
                                    type='number'
                                    value={
                                      propertyFilters?.[filter.id]?.from ?? ''
                                    }
                                    onInput={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleChangeRange(
                                        filter.id,
                                        'from',
                                        e.target.value,
                                      )
                                    }
                                  />
                                  <Input
                                    labelText={false}
                                    placeholder='Pana la'
                                    type='number'
                                    value={
                                      propertyFilters?.[filter.id]?.to ?? ''
                                    }
                                    onInput={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleChangeRange(
                                        filter.id,
                                        'to',
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          );
                        if (filter.propertyTypeAdd === 'SELECT')
                          return (
                            <Col lg='4' key={filter.id}>
                              <FormSelect
                                value={propertyFilters?.[filter.id] ?? ''}
                                onChange={(e: SelectValue) =>
                                  handleChangeFilter(filter.id, e)
                                }
                                labelText={true}
                                inlineLabel={filter.title}
                                options={
                                  filter.values &&
                                  parseValuesFilter(filter.values)
                                }
                              />
                            </Col>
                          );
                        if (filter.propertyTypeAdd === 'INPUT')
                          return (
                            <Col lg='4'>
                              <div className='form-group' key={filter.id}>
                                <label className='form-item-group-label'>
                                  {filter.title}
                                </label>
                                <div className='flex-input'>
                                  <Input
                                    labelText={false}
                                    value={propertyFilters?.[filter.id] ?? ''}
                                    onInput={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleChangeFilter(
                                        filter.id,
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          );
                        if (filter.propertyTypeAdd === 'CHECKBOX')
                          return (
                            <Col lg='4'>
                              <Form.Group id='formGridCheckbox' key={filter.id}>
                                <Form.Check
                                  type='checkbox'
                                  label={filter.title}
                                  value={propertyFilters?.[filter.id]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleChangeFilter(
                                      filter.id,
                                      e.target.checked ? 1 : 0,
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                          );
                      }
                      return null;
                    })}
                </fieldset>
              </Col>

              <Col lg='12'>
                <hr className='fieldset-block__hr' />
              </Col>
            </fieldset>
            {property?.category?.additionalFields?.includes(
              'energeticClassSection',
            ) && (
              <fieldset className='row fieldset-block row-93'>
                <Col lg='12'>
                  <legend className='heading-block'>Clasa Energetica</legend>
                </Col>
                <Col lg='4'>
                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      inlineLabel='Clasa energetica'
                      value={energyOptions.find(
                        option => option.value === formData?.energyClass,
                      )}
                      options={energyOptions}
                      onChange={(e: SelectValue) =>
                        handleString('energyClass', e.value)
                      }
                    />
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.emissionIndex}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('emissionIndex', e.target.value)
                      }
                      inlineLabel='Indicele de emisii echivalent CO2'
                    />
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.totalPrimaryEnergyConsumption}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString(
                          'totalPrimaryEnergyConsumption',
                          e.target.value,
                        )
                      }
                      inlineLabel='Consum total de energie primara'
                    />
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.totalConsumptionFromRenewableSources}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString(
                          'totalConsumptionFromRenewableSources',
                          e.target.value,
                        )
                      }
                      inlineLabel='Consum total din surse regenerabile'
                    />
                  </div>
                </Col>
                <Col lg='12'>
                  <hr className='fieldset-block__hr' />
                </Col>
              </fieldset>
            )}
            <fieldset className='row fieldset-block row-52'>
              <Col lg='12'>
                <legend className='heading-block'>DESCRIERE</legend>
              </Col>
              <Col lg='12'>
                <fieldset>
                  <legend className='sr-only'>Alege limba</legend>
                  <div className='form-item-switch-lang'>
                    <Form.Check
                      type='radio'
                      id='language-ro'
                      label='RO'
                      name='language'
                      value='ro'
                      checked={activeLang === 'ro'}
                      onChange={() => setActiveLang('ro')}
                    />
                    <Form.Check
                      type='radio'
                      label='EN'
                      id='language-en'
                      name='language'
                      value='en'
                      checked={activeLang === 'en'}
                      onChange={() => setActiveLang('en')}
                    />
                  </div>
                </fieldset>
              </Col>
              {activeLang === 'ro' && (
                <>
                  <Col lg='6'>
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        inlineLabel='Titlu anunt'
                        placeholder='Maxim 100 de caractere'
                        value={formData?.translations?.ro?.title}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFormData({
                            ...formData,
                            translations: {
                              ...formData.translations,
                              ro: {
                                ...formData.translations?.ro,
                                title: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col lg='6'>
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        inlineLabel='Link oferta'
                        placeholder='https://siteagentie.ro ___'
                        type='url'
                        value={formData?.link}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleString('link', e.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col lg='6'>
                    <div className='form-item'>
                      <div className='form-item-group form-item-group-textarea'>
                        <label
                          htmlFor='description'
                          className='form-item-group-label'
                        >
                          Descriere
                        </label>
                        <textarea
                          value={formData?.translations?.ro?.description}
                          onInput={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) =>
                            setFormData({
                              ...formData,
                              translations: {
                                ...formData.translations,
                                ro: {
                                  ...formData.translations?.ro,
                                  description: e.target.value,
                                },
                              },
                            })
                          }
                          className='form-item-control'
                          placeholder='Completati descrierea anuntului'
                        ></textarea>
                      </div>
                    </div>
                  </Col>
                  <Col lg='6'>
                    <div className='form-item'>
                      <div className='form-item-group form-item-group-textarea'>
                        <label
                          htmlFor='private-description'
                          className='form-item-group-label'
                        >
                          Descriere privata
                        </label>
                        <textarea
                          value={formData?.privateDescription}
                          onInput={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) =>
                            handleString('privateDescription', e.target.value)
                          }
                          className='form-item-control'
                          placeholder='Completati descrierea anuntului'
                        ></textarea>
                      </div>
                    </div>
                  </Col>
                  <Col lg='12'>
                    <button
                      className='generate-button'
                      onClick={e => generateText(e)}
                    >
                      <span>Genereaza descriere</span>
                      <span className='icon'>
                        <IconLoad />
                      </span>
                    </button>
                  </Col>
                </>
              )}
              {activeLang === 'en' && (
                <>
                  <Col lg='6'>
                    <div className='form-item'>
                      <Input
                        labelText={true}
                        inlineLabel='Title'
                        placeholder='(Max 60 characters)'
                        value={formData?.translations?.en?.title}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFormData({
                            ...formData,
                            translations: {
                              ...formData.translations,
                              en: {
                                ...formData.translations?.en,
                                title: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </div>
                    <div className='form-item'>
                      <div className='form-item-group form-item-group-textarea'>
                        <label
                          htmlFor='description'
                          className='form-item-group-label'
                        >
                          Description
                        </label>
                        <textarea
                          value={formData?.translations?.en?.description}
                          onInput={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) =>
                            setFormData({
                              ...formData,
                              translations: {
                                ...formData.translations,
                                en: {
                                  ...formData.translations?.en,
                                  description: e.target.value,
                                },
                              },
                            })
                          }
                          className='form-item-control'
                          placeholder='(max 3000 characters)'
                        ></textarea>
                      </div>
                    </div>
                  </Col>

                  <Col lg='12'>
                    <button className='generate-button'>
                      <span>Tradu din romana</span>
                      <span className='icon'>
                        <IconLoad />
                      </span>
                    </button>
                  </Col>
                </>
              )}
            </fieldset>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CharacteristicsForm;
