import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useAppSelector } from '../../redux/hooks';
import { Category } from '../../models/Categories';
import IconArrowUp from '../../icons/IconArrowUp';
import './FilterCategory.scss';
import FiltersForm from './FilterForm';

interface Props {
  activeCategory?: number;
  setActiveCategory?: (e: number) => void;
  filtersForm?: any;
  setFiltersForm?: (e: any) => void;
}

const Filters: React.FC<Props> = ({
  activeCategory,
  setActiveCategory,
  filtersForm,
  setFiltersForm,
}) => {
  const { categories } = useAppSelector(state => state.categories);
  const [openedFilters, setOpenedFilters] = useState<boolean>(true);
  const [showSubmenu, setShowSubmenu] = useState(false);

  return (
    <>
      {categories?.length > 0 && (
        <div className='filter-container-category'>
          <Tab.Container
            id='left-tabs-example'
            defaultActiveKey={0}
            onSelect={e => setActiveCategory && setActiveCategory(Number(e))}
          >
            <Row>
              <Col sm={2} className='tab-section'>
                <Nav variant='pills' className='flex-column'>
                  <Nav.Item key={0}>
                    <Nav.Link eventKey={0}>Toate</Nav.Link>
                  </Nav.Item>
                  {categories
                    .filter(category => !category.title.includes('Teren'))
                    .map((category: Category) => (
                      <Nav.Item key={category.id}>
                        <Nav.Link eventKey={category.id}>
                          {category.title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  <div
                    className='nav-item'
                    onClick={() => setShowSubmenu(!showSubmenu)}
                  >
                    <div
                      role='tab'
                      id='left-tabs-example-tab-teren'
                      className='nav-link nav-link-has-sub'
                    >
                      <span>Terenuri</span>
                      {showSubmenu ? (
                        <IconArrowUp />
                      ) : (
                        <IconArrowUp className='rotate' />
                      )}{' '}
                    </div>
                  </div>

                  {showSubmenu && (
                    <>
                      {categories
                        .filter(category => category.title.includes('Teren'))
                        .map((category: Category) => (
                          <Nav.Item key={category.id} className='nav-item-dropdown'>
                            <Nav.Link eventKey={category.id}>
                              {category.title}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                    </>
                  )}
                </Nav>
              </Col>
              <Col
                sm={10}
                className={`filter-form-section ${openedFilters ? '' : 'closed-filters'}`}
              >
                <Tab.Content>
                  <FiltersForm
                    activeCategory={activeCategory}
                    filtersForm={filtersForm}
                    setFiltersForm={setFiltersForm}
                    openedFilters={openedFilters}
                    setOpenedFilters={setOpenedFilters}
                  />
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      )}
    </>
  );
};

export default Filters;
