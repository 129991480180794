interface Props {}

const IconArrowLeft: React.FC<Props> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4997 7.00024C13.7557 7.00024 14.0117 7.09824 14.2067 7.29324C14.5977 7.68424 14.5977 8.31624 14.2067 8.70724L10.9017 12.0122L14.0817 15.3052C14.4647 15.7032 14.4537 16.3362 14.0567 16.7192C13.6588 17.1022 13.0258 17.0912 12.6427 16.6952L8.78075 12.6952C8.40175 12.3022 8.40675 11.6792 8.79275 11.2932L12.7927 7.29324C12.9877 7.09824 13.2437 7.00024 13.4997 7.00024Z"
      />
    </svg>
  );
};

export default IconArrowLeft;
