import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import { clearTemplate } from '../../redux/emailTemplates/emailTemplates-slice';
import {
  addEmailTemplate,
  editEmailTemplate,
} from '../../redux/emailTemplates/emailTemplatesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showError } from '../../helpers/useHelper';
import { EmailTemplateRequestDto } from '../../models/EmailTemplate';
import { Language } from '../../models/Language';
import IconTimes from '../../icons/IconTimes';
import FormSelect from '../FormSelect';
import Input from '../Input/Input';
import Spinner from '../Spinner';
import './ModalEmailTemplateItem.scss';


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showModal: boolean;
  handleClose: () => void;
  onSaved?: () => void;
  isReadOnly?: boolean;
}

const ModalEmailTemplateItem: React.FC<Props> = ({
  showModal,
  handleClose,
  onSaved,
  isReadOnly = false,
}) => {
  const dispatch = useAppDispatch();

  const { languages } = useAppSelector(state => state.languages);
  const { emailTemplate, loadingTemplate } = useAppSelector(
    state => state.emailTemplates,
  );

  const [formData, setFormData] = useState<EmailTemplateRequestDto>({});
  const [formDataErrors, setFormDataErrors] = useState<any>({});

  useEffect(() => {
    resetForm();
  }, [emailTemplate]);

  const getSelectValue = () => {
    const option = formData.body?.languageId
      ? languages.find(
          (language: Language) => language.id === formData.body?.languageId,
        )
      : languages.find((language: Language) => language.title === 'Romana');

    return {
      value: option ? option?.id : null,
      label: option ? option?.title : null,
    };
  };

  const getSelectOptions = (objectsToMap: any) => {
    return objectsToMap.map((obj: any) => {
      return { value: obj.id, label: obj.title };
    });
  };

  const onInputChange = (event: any) => {
    const { value, name } = event.target;

    setFormData((formData: any) => {
      formData.body[name] = value;

      return { ...formData };
    });
  };

  const onSelectChange = (event: any) => {
    setFormData((formData: any) => {
      formData.body.languageId = event.value;

      return { ...formData };
    });
  };

  const resetForm = () => {
    setFormData({
      id: emailTemplate?.id || 0,
      body: {
        name: emailTemplate?.name || '',
        title: emailTemplate?.title || '',
        description: emailTemplate?.description || '',
        languageId:
          emailTemplate?.language?.id ||
          languages.find((language: Language) => language.title === 'Romana')
            ?.id ||
          0,
      },
    });
  };

  const saveTemplate = async () => {
    if (emailTemplate) {
      await dispatch(editEmailTemplate(formData)).then(processResponse);
    } else {
      await dispatch(addEmailTemplate(formData)).then(processResponse);
    }
  };

  const processResponse = (response: any) => {
    if (response.error) {
      toast.error(
        `Eroare la salvarea template-ului ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
      );
      setFormDataErrors(response.payload);
    } else {
      toast.success(`Succes!! Template-ul a fost adaugat cu succes.`);
      closeModal();
      onSaved && onSaved();
    }
  };

  const closeModal = () => {
    dispatch(clearTemplate());
    handleClose();
    setFormDataErrors({});
    resetForm();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        animation={false}
        className='email-templates-modal-center'
      >
        {loadingTemplate ? (
          <Spinner />
        ) : (
          <>
            <button
              className='close-modal'
              onClick={closeModal}
              aria-label='Închide'
            >
              <IconTimes />
            </button>

            <div className='modal-container'>
              <Modal.Header>
                <Modal.Title>
                  {!isReadOnly && (emailTemplate ? 'Salveaza ' : 'Adauga ')}
                  Template Email Solicitare
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <div className='form-item'>
                        <Input
                          name='name'
                          labelText={true}
                          inlineLabel='Nume template'
                          type='search'
                          id='template-name'
                          value={formData.body?.name || ''}
                          onChange={onInputChange}
                          readOnly={isReadOnly}
                          error={formDataErrors.name}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='form-item'>
                        <FormSelect
                          labelText={true}
                          inlineLabel='Limba'
                          placeholder='Selecteaza'
                          id='email-language'
                          onChange={onSelectChange}
                          options={getSelectOptions(languages)}
                          value={getSelectValue()}
                          isDisabled={isReadOnly}
                          error={formDataErrors.languageId}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='form-item'>
                        <Input
                          name='title'
                          labelText={true}
                          inlineLabel='Subiect email'
                          type='search'
                          id='email-subjec'
                          value={formData.body?.title || ''}
                          onChange={onInputChange}
                          readOnly={isReadOnly}
                          error={formDataErrors.title}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='form-item'>
                        <div className='form-item-group'>
                          <label
                            htmlFor='content-subject'
                            className='form-item-group-label'
                          >
                            Continut email
                          </label>
                          <div
                            className={`form-item-group-block ${formDataErrors.description && 'error'}`}
                          >
                            <textarea
                              id='email-subject'
                              className='form-item-control'
                              value={formData.body?.description || ''}
                              name='description'
                              onChange={onInputChange}
                              readOnly={isReadOnly}
                            ></textarea>
                          </div>
                          {formDataErrors.description && (
                            <p className='error-message'>
                              {showError(formDataErrors.description)}
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                    {!isReadOnly && (
                      <Col xs={12}>
                        <div className='form-item-button'>
                          <button
                            className='button-blue button-md'
                            onClick={saveTemplate}
                          >
                            {emailTemplate ? 'Salveaza' : 'Adauga'}
                          </button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Container>
              </Modal.Body>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ModalEmailTemplateItem;
