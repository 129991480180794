import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CompanyListItem from '../../components/CompanyListItem';
import CompanyListModal from '../../components/CompanyListModal';
import FilterListCompany from '../../components/FilterListCompany/FilterListCompany';
import FilterListHeaderCompanies from '../../components/FilterListHeaderCompanies';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalCompanyItem from '../../components/ModalCompanyItem';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import {
  checkAll,
  clearCompany,
  toggleChecked,
} from '../../redux/companies/companies-slice';
import {
  arhiveCompanies,
  deleteCompany,
  getCompanies,
  restoreCompanies,
} from '../../redux/companies/companiesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useDebounce from '../../helpers/useDebounce';
import { haveUserPermission } from '../../helpers/useHelper';
import { Company } from '../../models/Companies';
import { Permissions } from '../../models/Permissions';
import IconPlus from '../../icons/IconPlus';
import './CompanyList.scss';

const tabs = [
  {
    label: 'Companii Active',
    value: '1',
  },

  {
    label: 'Companii Arhivate',
    value: '4',
  },
  {
    label: 'Companii Sterse',
    value: '3',
  },

  {
    label: 'Toate Companiile',
    value: '1,4,0',
  },
];

interface Props {}

const CompanyList: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companies, count, loadingList } = useAppSelector(
    state => state.companies,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [activeTab, setActiveTab] = useState(tabs[3].value);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.CompanyList),
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(24);
  const [isOpenCompanyModal, setOpenCompanyModal] = useState(false);
  const [sort, setSort] = useState('');
  const [filtersForm, setFiltersForm] = useState<any>({});
  const [filterByLettersOnMobile, setFilterByLettersOnMobile] = useState(false);
  const [prevState, setPrevState] = useState({
    page: 0,
    pageSize: 24,
    sort: '',
  });

  const debouncedTitle = useDebounce(filtersForm?.name ?? null, 300);
  const debouncedPhone = useDebounce(filtersForm?.phone ?? null, 300);
  const debouncedEmail = useDebounce(filtersForm?.email ?? null, 300);

  const optionsMock = useCallback(() => {
    switch (activeTab) {
      case '1':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'arhive',
            label: 'Arhiveaza',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];
      case '4':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];

      case '3':
        return [
          {
            value: 'restore',
            label: 'Restaurezi',
          },
        ];
      case '1,4,0':
        return [
          {
            value: 'edit',
            label: 'Modifica',
          },
          {
            value: 'export',
            label: 'Exporta',
          },
          {
            value: 'arhive',
            label: 'Arhiveaza',
          },
          {
            value: 'remove',
            label: 'Sterge',
          },
        ];
    }
  }, [activeTab]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let newState = {
      page: page,
      pageSize: pageSize,
      sort: sort,
    };

    if (
      JSON.stringify(prevState.page) !== JSON.stringify(newState.page) ||
      JSON.stringify(prevState.pageSize) !==
        JSON.stringify(newState.pageSize) ||
      JSON.stringify(prevState.sort) !== JSON.stringify(newState.sort)
    ) {
      getList();
      setPrevState(newState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sort]);

  useEffect(() => {
    if (page === 0) {
      getList();
    } else {
      setPage(0);
      setCheckedAll(false);
      dispatch(checkAll(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitle, debouncedPhone, debouncedEmail, filtersForm, activeTab]);

  const getList = () => {
    dispatch(
      getCompanies({
        limit: pageSize,
        offset: page + 1,
        sort: sort,
        status: activeTab,

        ...filtersForm,
      }),
    );
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  const openCompanyModal = (companyId: number) => {
    if (companyId === 0) {
      dispatch(clearCompany());
      setOpenCompanyModal(true);
    } else {
      navigate(`/edit-company/${companyId}`);
    }
  };

  const editSelectedItems = () => {
    if (companies.some(company => company.checked)) {
      navigate(
        `/edit-company/${companies.filter(company => company.checked)[0].id}`,
      );
      dispatch(checkAll(false));
    } else {
      toast.error('Alegeti o companie!');
    }
  };

  const removeConfirmedItems = async () => {
    const deletePromises = companies
      .filter(company => company.checked)
      .map(company => deleteCompanyItem(company));
    await Promise.all(deletePromises);

    dispatch(checkAll(false));
    closeModal();
    getList();
  };

  const deleteCompanyItem = async (company: Company) => {
    await dispatch(deleteCompany(company.id)).then((res : any) => {
      if(res?.payload?.error) {
        toast.error(`Eroare la stergerea companiei ${company.title}: ${res.payload.error}`);
      }
      else {
        toast.success(`Compania ${company.title} a fost stearsa cu succes` );
      }
    });
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const company = companies.find(company => company.id === id);

      if (company && company.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (companies.some(company => company.checked)) {
      setTitleConfirmModal(`${titleAction} companii`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} companiile selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin o companie!');
    }
  };

  const exportItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const restoreConfirmedItems = async () => {
    try {
      const result = await dispatch(
        restoreCompanies(
          companies
            .filter(company => company.checked)
            .map(checked => checked.id)
            .join(','),
        ),
      );

      unwrapResult(result);

      toast.success('Companiile selectate au fost restaurate!');
      getList();
      dispatch(checkAll(false));
      setCheckedAll(false);
    } catch (err: any) {}
  };

  const arhiveConfirmedItems = async () => {
    try {
      const result = await dispatch(
        arhiveCompanies(
          companies
            .filter(company => company.checked)
            .map(checked => checked.id)
            .join(','),
        ),
      );

      unwrapResult(result);

      toast.success('Companiile selectate au fost arhivate!');
      getList();
      dispatch(checkAll(false));
      setCheckedAll(false);
    } catch (err: any) {}
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere companii') {
      removeConfirmedItems();
    } else if (titleConfirmModal === 'Arhivare companii') {
      arhiveConfirmedItems();
    } else if (titleConfirmModal === 'Restaurare companii') {
      restoreConfirmedItems();
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Listare Companii'
          subtitle='Gestioneaza din aceasta sectiune toate companiile'
          isbutton={havePermission}
          btnText='Adauga Companie'
          btnEvent={() => openCompanyModal(0)}
        />
        {havePermission ? (
          <>
            {isMobile ? (
              <div>
                <select
                  className='select-tabs '
                  value={activeTab}
                  onChange={e => setActiveTab(e.target.value)}
                >
                  {tabs.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <Tabs
                id='ads-tabs'
                className='ads-form-tabs'
                activeKey={activeTab}
                onSelect={e => e && setActiveTab(e)}
              >
                {tabs.map(tab => (
                  <Tab
                    key={tab.value}
                    eventKey={tab.value}
                    title={tab.label}
                  ></Tab>
                ))}
              </Tabs>
            )}
            <FilterListHeaderCompanies
              pageCount={Math.ceil(count / pageSize)}
              currentPage={page}
              itemsPerPage={pageSize}
              totalCount={count}
              count={companies?.length}
              setItemsPerPage={e => setPageSize(e)}
              handlePageClick={handlePageClick}
              showCheckedItems={showCheckedItems}
              checkedItemsLength={
                companies.filter(company => company.checked).length
              }
              options={optionsMock()}
              setShowCheckedItems={setShowCheckedItems}
              editItems={editSelectedItems}
              arhiveItems={() => openConfirmModal('Arhivare', 'arhivezi')}
              removeItems={() => openConfirmModal('Stergere', 'stergi')}
              restoreItems={() => openConfirmModal('Restaurare', 'restaurezi')}
              exportItems={exportItems}
            />
            <FilterListCompany
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) {
                  handleCheckboxChange(undefined, true);
                } else {
                  dispatch(checkAll(false));
                }
              }}
              isCheckedAll={isCheckedAll}
              sortProperty={setSort}
              sort={sort}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
            />
            {loadingList ? (
              <div className='container'>
                <Spinner />
              </div>
            ) : (
              <>
                <div className='list-items-container'>
                  {companies
                    .filter(company => !showCheckedItems || company.checked)
                    .map(company => (
                      <CompanyListItem
                        key={company.id + 'checked'}
                        company={company}
                        handleCheckboxChange={handleCheckboxChange}
                        onEditCompany={() => openCompanyModal(company.id || 0)}
                      />
                    ))}
                  {!companies.some(
                    company => !showCheckedItems || company.checked,
                  ) && (
                    <p className='empty-text'>
                      {showCheckedItems
                        ? '0 Companii selectate'
                        : 'Cautarea ta a returnat 0 Rezultate'}
                    </p>
                  )}
                </div>
                <Pagination
                  isSelectPage={false}
                  currentPage={page}
                  itemsPerPage={pageSize}
                  pageCount={Math.ceil(count / pageSize)}
                  handlePageClick={handlePageClick}
                  setItemsPerPage={e => setPageSize(e)}
                />
              </>
            )}
            <FixedActions
              disabledActions={
                companies.every(company => !company.checked) ||
                activeTab === '3'
              }
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <div className='add-btn' onClick={() => setOpenCompanyModal(true)}>
              <IconPlus />
            </div>
            <CompanyListModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);
                if (e) {
                  handleCheckboxChange(undefined, true);
                } else {
                  dispatch(checkAll(false));
                }
              }}
              isCheckedAll={isCheckedAll}
              removeItems={
                activeTab === '3'
                  ? undefined
                  : () => openConfirmModal('Stergere', 'stergi')
              }
              arhiveItems={
                activeTab === '4' || activeTab === '3'
                  ? undefined
                  : () => openConfirmModal('Arhivare', 'arhivezi')
              }
              exportItems={activeTab === '3' ? undefined : () => exportItems()}
              editItems={
                activeTab === '3' ? undefined : () => editSelectedItems()
              }
              restoreItems={
                activeTab === '3'
                  ? () => openConfirmModal('Restaurare', 'restaurezi')
                  : undefined
              }
              sortProperty={setSort}
              sort={sort}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <ModalCompanyItem
        handleClose={() => setOpenCompanyModal(false)}
        showModal={isOpenCompanyModal}
        onSaved={getList}
      />
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default CompanyList;
