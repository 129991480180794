import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getImagePath } from '../../helpers/useHelper';
import { ResidentialComplex } from '../../models/Properties';
import House from '../../assets/img/house.png';
import ArrowBlack from '../../assets/svg/arrowblack.svg';
import AssociateIcon from '../../assets/svg/asociate.svg';
import LogoProperty from '../../assets/svg/logoproperty.svg';
import IconPencil from '../../icons/IconPencil';
import './ResidentialComplexListItem.scss';


interface Props {
  item: ResidentialComplex;
  checked: boolean;
  handleCheckboxChange: (id: number) => void;
}

const ResidentialComplexListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
}) => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  return (
    <div
      className={`announces-list-item resident-complex-item ${checked ? 'active' : ''}`}
    >
      <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={checked}
              readOnly
              onClick={() => handleCheckboxChange(item.id)}
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div>
      <div className='list-item-content'>
        {isMobile && (
          <div className="column-item">
            {item?.media?.[0]?.media?.path ? (
              <div className="info-photo-mobile">
                <img src={getImagePath(item.media[0].media?.path)} alt="" />
              </div>
            ) : (
              <div className="info-photo-mobile">
                <img src={House} alt="ProfileIcon" />
              </div>
            )}
          </div>
        )}
        <div className="column-item">
          <div className="mobile-text">ID</div>
          <div className="info-id label-item-mobile">{item.id}</div>
        </div>
        <div className="column-item hide-mobile">
          <div className="mobile-text">Photo</div>
          {item?.media?.[0]?.media?.path ? (
            <div className="info-photo">
            <img src={getImagePath(item.media[0].media?.path)} alt='' />
            </div>
          ) : (
            <div className='info-photo'>
              <img src={House} alt='ProfileIcon' />
            </div>
          )}
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Denumire</div>
          <div className='info-type label-item-mobile'>{item.title} </div>
        </div>
        <div className='info-details'>
          {item.zoneTitle && (
            <span className='flex-mobile gap-10'>
              <span>Zona:</span>
              <span className='label-item-mobile'>{item.zoneTitle}</span>
            </span>
          )}
          {item.streetTitle && (
            <span className='flex-mobile gap-10'>
              <span>Adresa:</span>
              <span className='label-item-mobile'>
                {item.streetTitle +
                  (item.streetNumber ? ' ' + item.streetNumber : '')}
              </span>
            </span>
          )}
          {item.townTitle && (
            <span className='flex-mobile gap-10'>
              <span>Oras:</span>
              <span className='label-item-mobile'>{item.townTitle}</span>
            </span>
          )}
          {item.employeePhoneNumbers?.[0] && (
            <span className='flex-mobile gap-10'>
              <span>Proprietar:</span>
              <span className='label-item-mobile'>
                {item.employeePhoneNumbers?.[0]}
              </span>
            </span>
          )}
        </div>
        <div className='info-details'>
          {item.rangeSurface && (
            <span className='flex-mobile gap-10'>
              <span>Suprafata:</span>
              <span className='label-item-mobile'>{item.rangeSurface}</span>
            </span>
          )}
          {item.rangePrice && (
            <span className='flex-mobile gap-10'>
              <span>Preturi:</span>
              <span className='label-item-mobile'>{item.rangePrice}</span>
            </span>
          )}
          {item.rooms && (
            <span className='flex-mobile gap-10'>
              <span>Tip locuinta:</span>
              <span className='label-item-mobile'>{item.rooms}</span>
            </span>
          )}
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Anunturi Asociate</div>
          <div className='info-asociates'>
            <span className='label-item-rounded-info-lg'>
              <img src={AssociateIcon} alt='' />
              {item.propertiesCount ?? 0}
            </span>
            <a href='/' className='link hide-mobile'>
              <span className='text'>Anunturi Asociate</span>
              <img src={ArrowBlack} alt='' />
            </a>
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Agent</div>
          <div className='info-agent'>
            <div className='info-image'>
              {item.employeeAvatarPath ? (
                <img src={getImagePath(item.employeeAvatarPath)} alt='' />
              ) : (
                <img src={LogoProperty} alt='' />
              )}{' '}
            </div>
            <div className='info-name'>{item.employeeFullName}</div>
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Status</div>
          <div className='label-item-rounded-tag label-item-mobile'>
            {item.status === 1 ? 'Publicat' : 'Nepublicat'}
          </div>
        </div>
        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            aria-label=''
            data-tooltip-id='edit'
            data-tooltip-content='Modifica'
            onClick={e => {
              e.preventDefault();
              navigate('/residential-complexes/' + item.id);
            }}
          >
            <IconPencil />
          </button>
          <Tooltip id='edit' />
        </div>
      </div>
    </div>
  );
};

export default ResidentialComplexListItem;
