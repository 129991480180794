import React from "react";
import "./AnnouncesAlertsModal.scss";
import FilterListAlerts from "../../components/FilterListAlerts/FilterListAlerts";
import CloseIcon from "../../assets/svg/closemodal.svg";
import SearchMobile from "../SearchMobile";
import ActionsMobile from "../ActionsMobile";
import Modal from "react-bootstrap/Modal";

interface Props {
    isModalOpen: boolean;
    isAction: boolean;
    isSearch: boolean;
    isFilter: boolean;

    closeModal: () => void;
}

const AnnouncesAlertsModal: React.FC<Props> = ({
    isModalOpen,
    closeModal,
    isAction,
    isSearch,
    isFilter,
}) => {
    return (
        <>
            <Modal
                show={isModalOpen}
                onHide={closeModal}
                animation={true}
                className={
                    isFilter || isAction ? "mobile-modal filter-overflow" : "mobile-modal"
                }
            >
                <Modal.Body>
                    <div className="close-btn-modal" onClick={closeModal}>
                        <img src={CloseIcon} alt="CloseIcon" />
                    </div>
                    {isSearch && <SearchMobile />}
                    {isAction && <ActionsMobile />}
                    {isFilter && <FilterListAlerts />}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AnnouncesAlertsModal;
