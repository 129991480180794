import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { store } from './redux/store';

import Navigation from './Navigation';
import './styles/App.scss';

function App() {
  return (
    <div className='App container main-container'>
      <Provider store={store}>
        <Navigation />
      </Provider>

      <ToastContainer />
    </div>
  );
}

export default App;
