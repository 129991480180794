import { useState } from 'react';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ReportsCards from '../../components/ReportsCards';
import SideNav from '../../components/SideNav';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './Reports.scss';

interface Props {}

const Reports: React.FC<Props> = () => {
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.Reports),
  );

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Rapoarte'
          subtitle='Ai nevoi de ajutor? Click aici pentru Tutorial CRM - RApoarte'
          isbutton={false}
          btnText=''
        />
        {havePermission ? <ReportsCards /> : <MissingAccess />}
      </div>
    </div>
  );
};

export default Reports;
