interface Props {}

const IconPhone: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 11.55C13.5 11.4668 13.4948 11.3843 13.4843 11.304L10.0185 10.5083C9.95025 10.6403 9.87525 10.7843 9.80475 10.917C9.4635 11.5695 9.219 12.0435 8.5845 11.7893C6.105 10.9193 4.08075 8.895 3.1995 6.38475C2.93175 5.79675 3.44175 5.52975 4.08825 5.1915C4.2195 5.12325 4.36125 5.049 4.491 4.98075L3.696 1.51575C3.61275 1.5045 3.53175 1.5 3.45 1.5C2.37525 1.5 1.5 2.3745 1.5 3.44925C1.5 8.991 6.00825 13.5 11.55 13.5C12.6255 13.5 13.5 12.6255 13.5 11.55ZM14.952 10.9785C14.9835 11.1645 15 11.3558 15 11.55C15 13.452 13.4527 15 11.55 15C5.18175 15 0 9.81825 0 3.44925C0 1.54725 1.54725 0 3.45 0C3.645 0 3.83775 0.0165 4.0245 0.04875C4.20675 0.078 4.3905 0.1245 4.56825 0.186C4.81275 0.27075 4.99575 0.47475 5.0535 0.72675L6.08025 5.19975C6.13725 5.44575 6.066 5.7045 5.8905 5.88675C5.7885 5.994 5.78625 5.99625 4.85625 6.48225C5.60925 8.1135 6.918 9.417 8.5155 10.1453C9.00375 9.2145 9.006 9.2115 9.1125 9.10875C9.2955 8.934 9.55275 8.86425 9.7995 8.919L14.2725 9.94575C14.5245 10.0035 14.7292 10.1873 14.8132 10.431C14.8747 10.6073 14.922 10.791 14.952 10.9785ZM8.25 1.5C11.1442 1.5 13.5 3.855 13.5 6.75C13.5 7.164 13.8352 7.5 14.25 7.5C14.6648 7.5 15 7.164 15 6.75C15 3.02775 11.9722 0 8.25 0C7.836 0 7.5 0.33525 7.5 0.75C7.5 1.164 7.836 1.5 8.25 1.5ZM7.5 3.75C7.5 3.33525 7.836 3 8.25 3C10.3177 3 12 4.68225 12 6.75C12 7.164 11.6648 7.5 11.25 7.5C10.8352 7.5 10.5 7.164 10.5 6.75C10.5 5.50875 9.4905 4.5 8.25 4.5C7.836 4.5 7.5 4.164 7.5 3.75Z"
        fill="#6283FF"
      ></path>
    </svg>
  );
};

export default IconPhone;
