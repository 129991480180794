import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input';
import ProprietySelect from '../../components/ProprietySelect';
import RequestEmailModal from '../../components/RequestEmailModal';
import Spinner from '../../components/Spinner';
import { getContacts } from '../../redux/contacts/contactsThunk';
import { getEmailTemplates } from '../../redux/emailTemplates/emailTemplatesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getProperties } from '../../redux/properties/propertiesThunk';
import { sendEmail, setProperty } from '../../redux/requests/requestsThunk';
import useDebounce from '../../helpers/useDebounce';
import { getImagePath, getValue, parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import Avatar from '../../assets/img/avatar.png';
import IconPencil from '../../icons/IconPencil';
import IconPhone from '../../icons/IconPhone';
import IconRemove from '../../icons/IconRemove';
import RequestPropertyList from '../RequestPropertyList';
import './RequestAssociatedProperties.scss';

const optionsMock = [
  {
    value: 'print',
    label: 'Printeaza',
  },
];

interface Props {}

const RequestAssociatedProperties: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [associatedProperties, setAssociatedProperties] = useState<any[]>([]);
  const { contacts } = useAppSelector(state => state.contacts);
  const { emailTemplates } = useAppSelector(state => state.emailTemplates);
  const { getRequestState } = useAppSelector(state => state.requests);
  const [formData, setFormData] = useState<any>({});
  const [checkedProperties, setCheckedProperties] = useState<any>([]);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [contactsQuery, setContactsQuery] = useState<string | undefined>(
    undefined,
  );
  const debouncedContactSearch = useDebounce(contactsQuery ?? null, 300);
  const [loadingChange, setLoadingChange] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const fetchProperties = async () => {
    try {
      const result = await dispatch(
        getProperties({ request: getRequestState.request?.id }),
      );

      unwrapResult(result);

      setLoadingChange(false);

      setAssociatedProperties(
        result.payload?.data?.map((item: any) => {
          return { ...item, checked: false };
        }),
      );
    } catch (err: any) {}
  };

  useEffect(() => {
    setLoadingChange(true);
    fetchProperties();
    dispatch(getEmailTemplates({ limit: -1 }));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    contactsQuery && dispatch(getContacts({ q: contactsQuery }));
    // eslint-disable-next-line
  }, [debouncedContactSearch]);

  const showEmails = () => {
    if (checkedProperties.length === 0) {
      toast.error('Alegeti minimum o proprietate!');
    } else {
      setShowEmail(true);
    }
  };

  const setPropertyRequest = async (id: string | number | null) => {
    setLoadingChange(true);

    if (id && getRequestState.request?.id) {
      try {
        const result = await dispatch(
          setProperty({
            requestId: getRequestState.request?.id,
            propertyId: id,
          }),
        );

        unwrapResult(result);

        if (associatedProperties.length > result.payload?.data)
          toast.success('Proprietatea propusa a fost stearsa cu succes!');
      } catch (err: any) {}

      try {
        const result = await dispatch(
          getProperties({ request: getRequestState.request?.id }),
        );

        unwrapResult(result);

        setLoadingChange(false);
        setAssociatedProperties(
          result.payload?.data?.map((item: any) => {
            return { ...item, checked: false };
          }),
        );
      } catch (err: any) {}
    }
  };

  const submitForm = async () => {
    try {
      const result = await dispatch(
        sendEmail({ id: getRequestState.request?.id, form: formData }),
      );

      unwrapResult(result);

      toast.success('Proprietatile au fost trimise cu succes!');

      setShowEmailModal(false);
      setCheckedProperties([]);
      setShowEmail(false);
      setFormData({});
    } catch (err: any) {
      toast.error('Proprietatile au fost trimise cu eroare!');

      setShowEmailModal(false);
      setCheckedProperties([]);
      setShowEmail(false);
      setFormData({});
    }
  };
  return (
    <div className='display-flex ads-form-container request-associated'>
      {showEmail && (
        <Col xl={12} className='request-associated__emails'>
          <Row>
            <p className='heading-block'>
              Contacte catre care vrei sa trimiti propunerile
            </p>
          </Row>
          <Row style={{ justifyContent: 'space-between', paddingTop: '33px' }}>
            <Col xl={4} className='request-associated__form'>
              <div className='form-item'>
                <FormSelect
                  type='text'
                  placeholder='Cauta'
                  id='ads-more'
                  isSearchable={true}
                  className={'rotate-arrow'}
                  inlineLabel={'Contact:'}
                  labelText={true}
                  options={parseValues(contacts)}
                  onInputChange={(e: string) => {
                    setContactsQuery(e);
                  }}
                  clearOnSelect={true}
                  onChange={(e: SelectValue) => {
                    if (
                      !formData.contacts?.some(
                        (ac: any) => ac.id.toString() === e.value,
                      )
                    ) {
                      setFormData({
                        ...formData,
                        contacts: [
                          ...(formData?.contacts || []),
                          {
                            ...contacts?.find(
                              (ac: any) => ac.id.toString() === e.value,
                            ),
                            emails: contacts
                              ?.find((ac: any) => ac.id.toString() === e.value)
                              ?.emails.map(email => {
                                return { email: email, checked: true };
                              }),
                          },
                        ],
                      });
                    }
                  }}
                />
              </div>
              <div className='form-item'>
                <FormSelect
                  labelText={true}
                  inlineLabel={'Template:'}
                  value={
                    formData.emailTemplate
                      ? getValue(emailTemplates, formData.emailTemplate)
                      : null
                  }
                  placeholder={'Alege template'}
                  options={parseValues(emailTemplates)}
                  onChange={(e: SelectValue) =>
                    setFormData({
                      ...formData,
                      emailTemplate: e.value,
                      title: emailTemplates.find(email => email.id === +e.value)
                        ?.title,
                      comment: emailTemplates.find(
                        email => email.id === +e.value,
                      )?.description,
                    })
                  }
                />
              </div>
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.title ?? ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  removeItem={() => setFormData({ ...formData, title: '' })}
                  isClearable={
                    formData?.title && formData?.title !== '' ? true : false
                  }
                  inlineLabel='Subiect:'
                />
              </div>
              <div className='form-item-group'>
                <label className='form-item-group-label' htmlFor=''>
                  Mesaj:
                </label>
                <div className={`form-item-group-block`}>
                  <textarea
                    name=''
                    id=''
                    rows={4}
                    className={`form-item-control `}
                    value={formData?.comment ?? ''}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setFormData({ ...formData, comment: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
              <button
                className='button-blue button-md'
                disabled={
                  !formData.contacts ||
                  formData.contacts?.filter((contact: any) =>
                    contact.emails.find((em: any) => em.checked),
                  )?.length === 0
                }
                onClick={() => setShowEmailModal(true)}
              >
                Previzualizare
              </button>
            </Col>
            <Col xl={6} className='request-associated__contact-items'>
              {contacts
                ?.filter(contact =>
                  formData.contacts?.find(
                    (item: any) => item.id === contact.id,
                  ),
                )
                ?.map(contact => {
                  return (
                    <div className='request-associated__contact-item'>
                      <div className='request-associated__contact-item--top'>
                        <div className='img-block'>
                          {contact.avatarPath ? (
                            <img
                              src={getImagePath(contact.avatarPath)}
                              alt=''
                            />
                          ) : (
                            <img src={Avatar} alt='' />
                          )}
                        </div>
                        <div className='info-contact'>
                          <span className='name'>
                            {contact.name + ' ' + contact.surname}
                          </span>
                          <div className='info-item'>
                            {contact.internationalPhoneNumber && (
                              <a
                                href={'tel:' + contact.internationalPhoneNumber}
                                aria-label=''
                                className='number'
                              >
                                <IconPhone />
                                {contact.internationalPhoneNumber}{' '}
                              </a>
                            )}
                          </div>
                        </div>
                        <button
                          className='edit-btn'
                          aria-label=''
                          onClick={e => {
                            e.preventDefault();
                            navigate('/contacts/' + contact.id);
                          }}
                        >
                          <IconPencil />
                        </button>
                        <button
                          className='remove-btn'
                          aria-label=''
                          onClick={e => {
                            e.preventDefault();
                            setFormData({
                              ...formData,
                              contacts: formData.contacts?.filter(
                                (item: any) => item.id !== contact.id,
                              ),
                            });
                          }}
                        >
                          <IconRemove />
                        </button>
                      </div>
                      <div className='request-associated__contact-item--bottom'>
                        {contact.emails?.map(email => {
                          return (
                            <Form.Group id='formGridCheckbox'>
                              <Form.Check
                                type='checkbox'
                                label={email}
                                checked={
                                  formData.contacts
                                    ?.find(
                                      (item: any) => item.id === contact.id,
                                    )
                                    .emails.find(
                                      (em: any) => em.email === email,
                                    )?.checked
                                }
                                readOnly
                                onChange={() => {
                                  let newArray = formData.contacts?.map(
                                    (item: any) => {
                                      if (item.id === contact.id)
                                        return {
                                          ...contact,
                                          emails: item.emails.map((em: any) => {
                                            if (em.email === email)
                                              return {
                                                email: em.email,
                                                checked: !em.checked,
                                              };
                                            else return em;
                                          }),
                                        };
                                      else return item;
                                    },
                                  );
                                  setFormData({
                                    ...formData,
                                    contacts: newArray,
                                  });
                                }}
                              />
                            </Form.Group>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </Col>
      )}
      <Col xl={12}>
        <Row>
          <Col md={6} className='ads-form-container--left'>
            <p className='heading-block'>Proprietati Propuse</p>
          </Col>
          <Col md={6} className='ads-form-container--right'>
            <button
              className='button-blue button-md'
              onClick={() => showEmails()}
            >
              Trimite pe e-mail
            </button>
          </Col>
        </Row>
        <div className='request-associated-search'>
          <div className='form-item'>
            <div className='form-item-group-search'>
              <label htmlFor='description' className='form-item-group-label'>
                Propune o proprietate din baza de date:
              </label>
              <ProprietySelect
                type='text'
                placeholder='Search'
                id='ads-more'
                labelText={false}
                isClearable={true}
                searchIndicator={true}
                searchByCharacter={true}
                onChange={(e: SelectValue) => {
                  let newProperties = associatedProperties ?? [];
                  setPropertyRequest(e?.value ?? null);
                  if (
                    !newProperties.find(
                      (property: number) => String(property) === e.value,
                    )
                  )
                    newProperties = [
                      ...newProperties,
                      { id: Number(e.value), checked: false },
                    ];
                  setAssociatedProperties(newProperties);
                }}
                value={null}
              />
            </div>
          </div>
        </div>
        {loadingChange ? (
          <Row style={{ minHeight: '15rem', position: 'relative' }}>
            <Spinner />
          </Row>
        ) : associatedProperties && associatedProperties?.length > 0 ? (
          <RequestPropertyList
            fromDB={true}
            actionsOptions={optionsMock}
            removeItems={setPropertyRequest}
            setCheckedProperties={setCheckedProperties}
            items={associatedProperties}
          />
        ) : null}
      </Col>
      <RequestEmailModal
        formData={{ ...formData, properties: checkedProperties }}
        closeModal={() => setShowEmailModal(false)}
        isModalOpen={showEmailModal}
        submitForm={submitForm}
      />
    </div>
  );
};

export default RequestAssociatedProperties;
