interface Props {}

const IconCheck: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 14"
      {...props}
    >
      <path
        d="M6.5,12.3c0.1,0.1,0.2,0.2,0.4,0.2h0c0.1,0,0.3-0.1,0.4-0.2l9.1-10c0.2-0.2,0.2-0.5,0-0.7 c-0.2-0.2-0.5-0.2-0.7,0l-8.8,9.6L2.4,6.5C2.3,6.4,2.1,6.3,2,6.3c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0.1C1.6,6.5,1.5,6.7,1.5,6.8
        c0,0.1,0,0.3,0.1,0.4L6.5,12.3z"
      />
    </svg>
  );
};

export default IconCheck;
