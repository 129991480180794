import React from "react";
import "./PromotionForm.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CheckboxPromoGroup from "../CheckboxPromoGroup/CheckboxPromoGroup";

interface Props {}

const PromotionForm: React.FC<Props> = () => {
  return (
    <div className="display-flex ads-form-container">
      <Row>
        <Col xl={12} className="content-block">
          <h2 className="heading-block">Promovare</h2>
          <p>Pentru o mai buna evidentiere, completeaza cat mai corect toate informatiile suplimentare.</p>
        </Col>
        <Col xl={12}>
          <CheckboxPromoGroup/>

        </Col>
      </Row>
    </div>
  );
};

export default PromotionForm;
