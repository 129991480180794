import { createSlice } from '@reduxjs/toolkit';
import { Locality } from '../../models/Localities';
import { ResidentialComplex } from '../../models/Properties';
import {
  getCities,
  getCoordinates,
  getCounties,
  getCounty,
  getLocalities,
  getLocations,
  getResidentialComplexes,
  getSpecificTypes,
  getStreet,
  getZona,
  getZones,
  removeResidentialComplexes,
  suggestStreet,
} from './localitiesThunk';

type LocalitiesState = {
  localities: Locality[];
  county: Locality[];
  counties: Locality[];
  cities: Locality[];
  zona: Locality[];
  streets: Locality[];
  locations: Locality[];
  residentialComplexes: ResidentialComplex[];
  specificTypes: Locality[];
  coordinates: any;
  totalCount: number;
  loading: boolean;
  error: any;
};

const initialState: LocalitiesState = {
  localities: [],
  county: [],
  cities: [],
  counties: [],
  zona: [],
  streets: [],
  locations: [],
  specificTypes: [],
  residentialComplexes: [],
  coordinates: null,
  totalCount: 0,
  loading: false,
  error: null,
};

const localitiesSlice = createSlice({
  name: 'localities',
  initialState,
  reducers: {
    clearTowns(state) {
      state.localities = [];
    },
    clearStreets(state) {
      state.streets = [];
    },
    clearCities(state) {
      state.cities = [];
    },
    clearCounties(state) {
      state.counties = [];
    },
    clearZona(state) {
      state.zona = [];
    },
    clearCoordinates(state) {
      state.coordinates = null;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLocations.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLocations.fulfilled, (state, { payload }) => {
        state.locations = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getSpecificTypes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSpecificTypes.fulfilled, (state, { payload }) => {
        state.specificTypes = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSpecificTypes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getLocalities.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLocalities.fulfilled, (state, { payload }) => {
        state.localities = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getLocalities.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getCounty.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCounty.fulfilled, (state, { payload }) => {
        state.county = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCounty.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getCounties.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCounties.fulfilled, (state, { payload }) => {
        state.counties = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCounties.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getCities.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        state.cities = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCities.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getStreet.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStreet.fulfilled, (state, { payload }) => {
        state.streets = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getStreet.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getZona.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getZona.fulfilled, (state, { payload }) => {
        state.zona = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getZona.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getZones.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getZones.fulfilled, (state, { payload }) => {
        state.zona = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getZones.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getResidentialComplexes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getResidentialComplexes.fulfilled, (state, { payload }) => {
        state.residentialComplexes = payload.data;
        state.totalCount = payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getResidentialComplexes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(removeResidentialComplexes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeResidentialComplexes.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(removeResidentialComplexes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(suggestStreet.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(suggestStreet.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(suggestStreet.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getCoordinates.pending, state => {
        state.coordinates = null;
      })
      .addCase(getCoordinates.fulfilled, (state, { payload }) => {
        state.coordinates = payload;
      })
      .addCase(getCoordinates.rejected, state => {
        state.coordinates = null;
      });
  },
});

export const {
  clearError,
  clearStreets,
  clearTowns,
  clearZona,
  clearCities,
  clearCoordinates,
} = localitiesSlice.actions;

export default localitiesSlice.reducer;
