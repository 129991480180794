import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import FormSelect from '../../components/FormSelect';
import Input from '../../components/Input/Input';
import Map from '../../components/Map';
import MapChoice from '../../components/MapChoice';
import MultiselectCheckboxes from '../../components/MultiselectCheckboxes';
import StreetAddModal from '../../components/StreetAddModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearStreets,
  clearTowns,
  clearZona,
} from '../../redux/localities/localities-slice';
import {
  getCoordinates,
  getLocalities, getResidentialComplexes,
  getStreet,
  getZona,
} from '../../redux/localities/localitiesThunk';
import { getValue, parseValues } from '../../helpers/useHelper';
import { Coordinate, PropertyForm, SelectValue } from '../../models/Properties';
import './Localizate.scss';

interface Props {
  formData: PropertyForm;
  setFormData: (form: PropertyForm) => void;
  handleChange: (key: string, value: SelectValue | null) => void;
  handleString: (key: string, value: string | null) => void;
}

const Localizate: React.FC<Props> = ({
  formData,
  handleChange,
  handleString,
  setFormData,
}) => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector(state => state.users);
  const { property } = useAppSelector(state => state.properties);
  const { localities, county, streets, zona, residentialComplexes } =
    useAppSelector(state => state.localities);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState<Coordinate>({
        type: 'PIN',
        lat: 44.26,
        lng: 26.6,
      });

  useEffect(() => {
    if(property?.coordinates) {
      setCoordinates(property.coordinates)
    }

    dispatch(getResidentialComplexes({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  useEffect(() => {
    coordinates && setFormData({ ...formData, coordinates: coordinates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  useEffect(() => {
    if (formData.countyId) {
      dispatch(getLocalities({ parent: formData.countyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.countyId]);

  useEffect(() => {
    if (formData.townId) {
      dispatch(getStreet({ town: formData.townId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.townId]);

  useEffect(() => {
    if (formData.streetId) {
      dispatch(getZona({ street: formData.streetId }));
    }

    getCoords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.streetId]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getCoords = () => {
    if (formData?.countyId && formData?.townId && formData?.streetId) {
      dispatch(
          getCoordinates({
            county: formData.countyId,
            town: formData.townId,
            street: formData.streetId,
          }),
      ).then(res => {
        if (
            res.type === 'localities/getCoordinates/fulfilled' &&
            res.payload.lat
        ) {
          setCoordinates({
            lat: res.payload.lat,
            lng: res.payload.lng,
            type: 'PIN',
          });
        }
      });
    }
  };

  const handleMultiselect = (key: string, e: string[] | null) => {
    setFormData({
      ...formData,
      [key]: e,
    });
  };

  return (
    <div className='display-flex ads-form-container'>
      <Container>
        <Row>
          <Col lg='7' className='ads-form-container-left'>
            <Row>
              <Col lg='12'>
                <h2 className='heading-block'>Localizare</h2>
              </Col>
              <Col lg='6'>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Select Options'
                    inlineLabel='Agent'
                    isSearchable={true}
                    isClearable={true}
                    value={
                      formData?.employeeId
                        ? getValue(users, formData?.employeeId)
                        : null
                    }
                    options={parseValues(users) ?? []}
                    onChange={(e: SelectValue) => handleChange('employeeId', e)}
                  />
                </div>
              </Col>
              <Col lg='6'>
                <div className='form-item'>
                  <MultiselectCheckboxes
                    onChange={(e: string[]) => {
                      handleMultiselect('associatedEmployees', e);
                    }}
                    labelText={true}
                    inlineLabel={'Agenti Asociati'}
                    value={
                      formData?.associatedEmployees
                        ? formData.associatedEmployees.map(employee =>
                            String(employee),
                          )
                        : null
                    }
                    options={parseValues(users) ?? []}
                  />
                </div>
              </Col>
              <Col lg='6'>
                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    placeholder='Select Options'
                    inlineLabel='Tip Proprietate:'
                    isSearchable={true}
                    isDisabled={true}
                    value={{
                      label: property?.category.title,
                      value: property?.category.id,
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className='ads-form-container-bottom'>
              <Col lg='6'>
                <div className='form-item'>
                  <FormSelect
                    placeholder='Select Options'
                    labelText={true}
                    isClearable={true}
                    inlineLabel='Judet'
                    value={
                      formData?.countyId
                        ? getValue(county, formData?.countyId)
                        : null
                    }
                    options={parseValues(county) ?? []}
                    onChange={(e: SelectValue) => {
                      handleChange('countyId', e)
                      dispatch(clearStreets());
                      dispatch(clearZona());
                    }}
                  />
                </div>

                <div className='form-item'>
                  <FormSelect
                    placeholder='Select Options'
                    labelText={true}
                    isClearable={true}
                    inlineLabel='Localitate'
                    value={
                      formData?.townId
                        ? getValue(localities, formData?.townId)
                        : null
                    }
                    options={parseValues(localities) ?? []}
                    onChange={(e: SelectValue) => {
                      handleChange('townId', e)
                      dispatch(clearZona());
                    }}
                  />
                </div>

                <div className='form-item'>
                  <FormSelect
                    labelText={true}
                    inlineLabel='Strada'
                    isClearable={true}
                    options={parseValues(streets) ?? []}
                    onChange={(e: SelectValue) => handleChange('streetId', e)}
                    value={
                      formData.streetId
                        ? getValue(streets, formData.streetId)
                        : null
                    }
                  />
                  <p className='link' onClick={() => setIsModalOpen(true)}>
                    Propune Strada/Zona
                  </p>
                </div>

                <div className='form-item'>
                  <FormSelect
                    placeholder='Select Options'
                    labelText={true}
                    isClearable={true}
                    inlineLabel='Zona'
                    value={
                      formData?.zoneId ? getValue(zona, formData?.zoneId) : null
                    }
                    options={parseValues(zona) ?? []}
                    onChange={(e: SelectValue) => handleChange('zoneId', e)}
                  />
                </div>
              </Col>
              <Col lg='6'>
                {property?.category?.additionalFields?.includes('block') && (
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.block}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('block', e.target.value)
                      }
                      inlineLabel='Block'
                    />
                  </div>
                )}
                {property?.category?.additionalFields?.includes(
                  'staircase',
                ) && (
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.staircase}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('staircase', e.target.value)
                      }
                      inlineLabel='Scara'
                    />
                  </div>
                )}
                {property?.category?.additionalFields?.includes(
                  'floorNumber',
                ) && (
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.floorNumber}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('floorNumber', e.target.value)
                      }
                      inlineLabel='Apartament'
                    />
                  </div>
                )}
                {property?.category?.additionalFields?.includes('field') && (
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.field}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('field', e.target.value)
                      }
                      inlineLabel='Tarla'
                    />
                  </div>
                )}
                {property?.category?.additionalFields?.includes('parcel') && (
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.parcel}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('parcel', e.target.value)
                      }
                      inlineLabel='Parcela'
                    />
                  </div>
                )}
                {property?.category?.additionalFields?.includes('lot') && (
                  <div className='form-item'>
                    <Input
                      labelText={true}
                      value={formData?.lot}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleString('lot', e.target.value)
                      }
                      inlineLabel='Lot'
                    />
                  </div>
                )}
                {property?.category?.additionalFields?.includes(
                  'residentialComplex',
                ) && (
                  <div className='form-item'>
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      inlineLabel='Ansamblu rezidential'
                      isClearable={true}
                      value={
                        formData?.residentialComplexId
                          ? getValue(
                              residentialComplexes,
                              formData?.residentialComplexId,
                            )
                          : null
                      }
                      options={parseValues(residentialComplexes) ?? []}
                      onChange={(e: SelectValue) =>
                        handleChange('residentialComplexId', e)
                      }
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          {coordinates && (
            <Col lg='5' className='ads-form-container-map'>
              <div className='map-localizare'>
                <Map
                  coordinates={coordinates}
                  setCoordinates={setCoordinates}
                />
              </div>
              <MapChoice
                className='without-bg'
                coordinates={coordinates}
                setCoordinates={setCoordinates}
              />
            </Col>
          )}
        </Row>
      </Container>
      <StreetAddModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Localizate;
