import './Spinner.scss';

interface Props {
  className?: string;
}

const Spinner = ({ className }: Props) => {
  return (
    <div className={`spiner-container ${className}`}>
      <div className='spinner'>
        <span className='spinner-inner-1'></span>
        <span className='spinner-inner-2'></span>
        <span className='spinner-inner-3'></span>
      </div>
    </div>
  );
};

export default Spinner;
