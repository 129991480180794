import { useCallback, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AddAgencySettings from '../../components/AddAgencySettings';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import Agents from '../Agents/Agents';
import './AgentsPage.scss';

interface Props {}
const tabs = [
  {
    label: 'Setari Agentie',
    value: 'settings',
  },
  {
    label: 'Listare Agenti',
    value: 'list',
  },
];
const AgentsPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>('list');

  const renderContent = useCallback(
    (currentTab: string) => {
      return (
        <>
          {tab === 'settings' && currentTab === 'settings' ? (
            <AddAgencySettings />
          ) : null}
          {tab === 'list' && currentTab === 'list' ? <Agents /> : null}
        </>
      );
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [tab],
  );

  return (
    <div className='flex-container display-flex agent-list'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title={'Agenti'}
          subtitle='De aici poti modifica setarile agentie tale'
          isbutton={haveUserPermission(Permissions.EmployeeProfile)}
          btnText='Adauga Agent'
          btnEvent={() => navigate('/add-agent')}
        />
        {haveUserPermission(Permissions.EmployeeProfile) ? (
          <Tabs
            id='ads-tabs'
            className='ads-form-tabs'
            activeKey={tab}
            onSelect={e => e && setTab(e)}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} eventKey={tab.value} title={tab.label}>
                {renderContent(tab.value)}
              </Tab>
            ))}
          </Tabs>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default AgentsPage;
