import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormSelect from '../../components/FormSelect';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import RequestListItem from '../../components/RequestListItem';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import StreetAddModal from '../../components/StreetAddModal';
import { getCategories } from '../../redux/categories/categoriesThunk';
import { getContacts } from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearRequests } from '../../redux/requests/requests-slice';
import { createRequest, getRequests } from '../../redux/requests/requestsThunk';
import useDebounce from '../../helpers/useDebounce';
import {
  getNumber,
  haveUserPermission,
  parseValues,
} from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import { RequestForm, SelectValue } from '../../models/Requests';
import './Request.scss';

interface FormData {
  categoryId?: SelectValue;
  contactId?: SelectValue;
  transactionType?: any;
}

const Request: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error, loadingSave } = useAppSelector(
    state => state.requests,
  ).createRequestState;
  const { requestsListState } = useAppSelector(state => state.requests);

  const { categories } = useAppSelector(state => state.categories);
  const { contacts } = useAppSelector(state => state.contacts);

  const [formData, setFormData] = useState<FormData>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactsQuery, setContactsQuery] = useState<string | undefined>(
    undefined,
  );
  const [formDataErrors, setFormDataErrors] = useState<any>({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  const debouncedContactSearch = useDebounce(contactsQuery ?? null, 300);

  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.PropertyAdd),
  );

  const transactionTypes = [
    { label: 'Cumparare', value: 'BUY' },
    { label: 'Inchiriere', value: 'RENT' },
  ];

  useEffect(() => {
    let initialForm = {
      categoryId: undefined,
      contactId: undefined,
      transactionType: undefined,
    };

    categories.length === 0 && dispatch(getCategories());
    setFormData(initialForm);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getContacts({ q: contactsQuery }));
    // eslint-disable-next-line
  }, [debouncedContactSearch]);

  useEffect(() => {
    if (
      formData.contactId?.value ||
      formData.categoryId?.value ||
      formData.transactionType?.value
    ) {
      fetchRequests();
    } else dispatch(clearRequests()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.contactId?.value,
    formData.categoryId?.value,
    formData.transactionType?.value,
  ]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchRequests = async () => {
    try {
      const result = await dispatch(
        getRequests({
          contact: formData.contactId?.value ?? undefined,
          category: formData.categoryId?.value
            ? Number(formData.categoryId?.value)
            : undefined,
          transactionType: formData.transactionType?.value ?? undefined,
        }),
      );
      unwrapResult(result);

      if (result.payload?.length > 0) {
        toast.error(
          'Exista deja solicitari adaugate pentru acest client! Poti adauga o noua solicitare pentru clientul tau sau modifica una din solicitarile existente',
        );
      }
    } catch (err: any) {}
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const handleChange = (key: string, e: SelectValue) => {
    setFormData({ ...formData, [key]: e });
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let parseForm: RequestForm = {
      categoryId: getNumber(formData.categoryId?.value),
      contactId: getNumber(formData?.contactId?.value),
      transactionType: formData.transactionType?.value,
    };

    await dispatch(createRequest(parseForm)).then(processResponse);
  };

  const processResponse = (result: any) => {
    if (result.payload.id) {
      navigate('/requests/' + result.payload.id);
    } else {
      toast.error(`Eroare la crearea solicitarii`);
      setFormDataErrors(result.payload);
    }
  };

  const renderButtons = () => {
    return (
      <div className="form-buttons bg-white">
        <button
          className="button-blue button-lg button-spin"
          onClick={(e: React.FormEvent) => submitForm(e)}
        >
          {loadingSave ? (
            <Spinner className="btn-blue-spinner" />
          ) : null}
          Adauga
        </button>
        <button
          className="button-border button-back button-lg"
          onClick={() => navigate(-1)}
        >
          Inapoi
        </button>
      </div>
    );
  };

  return (
    <div className="flex-container display-flex">
      <SideNav />
      <div className="display-flex flex-column main-wrapper">
        <Header
          title="Adauga solicitare"
          subtitle=""
          isbutton={false}
          backBtn={true}
          btnText=""
        />
        {havePermission ? (
          <>
            <div className="section-container">
              <div className="bg-white property-left">
                <h2 className="heading-block">Localizare</h2>
                <form action="" className="form-block">
                  <div className="form-item">
                    <FormSelect
                      type='text'
                      placeholder='Search'
                      id='ads-more'
                      isSearchable={true}
                      inlineLabel={'Contacte Asociate'}
                      labelText={true}
                      options={parseValues(contacts)}
                      isClearable={true}
                      searchIndicator={true}
                      value={formData.contactId}
                      onInputChange={(e: string) => {
                        setContactsQuery(e);
                      }}
                      onChange={(e: SelectValue) =>
                        handleChange('contactId', e)
                      }
                      error={formDataErrors.contactId}
                    />
                  </div>
                  <div className='form-item'>
                    <FormSelect
                      labelText={true}
                      placeholder='Select Options'
                      inlineLabel='Tip Proprietate:'
                      isSearchable={true}
                      className={error?.categoryId ? 'error' : ''}
                      value={formData?.categoryId}
                      options={parseValues(categories) ?? []}
                      onChange={(e: SelectValue) =>
                        handleChange('categoryId', e)
                      }
                      error={formDataErrors.categoryId}
                    />
                  </div>

                  <div className='form-item'>
                    <FormSelect
                      labelText={true}
                      inlineLabel={'Tip tranzactie'}
                      value={formData?.transactionType}
                      options={transactionTypes}
                      onChange={(e: SelectValue) =>
                        handleChange('transactionType', e)
                      }
                      error={formDataErrors.transactionType}
                    />
                  </div>
                </form>
              </div>
              {isMobile && renderButtons()}
              <div
                className={`property-center ${requestsListState.loading ? 'loading' : ''}`}
              >
                {requestsListState.loading ? (
                  <Spinner />
                ) : (
                  requestsListState.requests.map(request => (
                    <RequestListItem item={request} />
                  ))
                )}
              </div>
              {!isMobile && renderButtons()}
            </div>
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <StreetAddModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Request;
