import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { Price, Property } from '../../models/Properties';
import House from '../../assets/img/house.png';
import IconPencil from '../../icons/IconPencil';
import './AddAssociatedProperties.scss';


interface Props {
  properties?: Property[];
}

const AddAssociatedProperties: React.FC<Props> = ({ properties }) => {
  const navigate = useNavigate();

  return (
    <div className='flex-container add-images-area-form display-flex'>
      <Container>
        <Row className='row-91'>
          <Col xl='12' className='content-block mb-3'>
            <h2 className='heading-block'>Proprietati Asociate</h2>
          </Col>
          <Col lg='12'>
            <div className='properties-head'>
              <div className='properties-head-item'>Foto</div>
              <div className='properties-head-item'>Adresa</div>
              <div className='properties-head-item'>Detalii Proprietati</div>
              <div className='properties-head-item'>Pret</div>
              <div className='properties-head-item'>Actiuni</div>
            </div>
            {properties &&
              properties.map(property => {
                return (
                  <div className='properties-list-item  bg-blue'>
                    <div className='properties-list-item__image'>
                      <div className='image'>
                        <span className='label-info-active-sm'>
                          {property.status ? 'Activ' : 'Inactiv'}
                        </span>
                        <img src={House} alt='' />
                      </div>
                    </div>

                    <div className='properties-list-item__address'>
                      <div className='properties-list-item__content'>
                        <table>
                          <tbody>
                            <tr>
                              <th>Zona:</th>
                              <td>
                                <strong>{property.zoneTitle}</strong>
                              </td>
                            </tr>
                            <tr>
                              <th>Adresa:</th>
                              <td>
                                <strong>{property.streetTitle} {property.streetNumber}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='properties-list-item__info'>
                      <div className='properties-list-item__content'>
                        <table>
                          <tbody>
                            <tr>
                              <th>Tip:</th>
                              <td>
                                <strong>{property.categoryTitle} {property.specificTypeTitle}</strong>
                              </td>
                            </tr>
                            <tr>
                              <th>SU:</th>
                              <td>
                                <strong>{property.filterSurfaceUValue}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='properties-list-item__price'>
                      <div className='properties-list-item__content'>
                        { property.prices?.some((price: Price) => price.sellingPrice) &&
                          <p>
                            <strong>
                              {
                                property.prices?.find(
                                  (price: Price) => price.sellingPrice,
                                )?.sellingPrice
                              } {property.currencySymbol ?? '€'}
                            </strong>
                          </p>
                        }
                        { property.prices?.some((price: Price) => price.rentalMonthPrice) &&
                          <p>
                            <strong>
                              {
                                property.prices?.find(
                                  (price: Price) => price.rentalMonthPrice,
                                )?.rentalMonthPrice
                              } {property.currencySymbol ?? '€'} / luna
                            </strong>
                          </p>
                        }
                        { property.prices?.some((price: Price) => price.rentalDayPrice) &&
                          <p>
                            <strong>
                              {
                                property.prices?.find(
                                  (price: Price) => price.rentalDayPrice,
                                )?.rentalDayPrice
                              } {property.currencySymbol ?? '€'} / zi
                            </strong>
                          </p>
                        }
                      </div>
                    </div>
                    <div className='properties-list-item__button'>
                      <div className='properties-list-item__content'>
                        <button
                          className='button-square-md'
                          aria-label=''
                          onClick={() => navigate('/properties/' + property.id)}
                        >
                          <IconPencil />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddAssociatedProperties;
