import { useEffect, useState } from 'react';

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    setDebounceTimeout(newTimeout);

    return () => clearTimeout(newTimeout);
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
