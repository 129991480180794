interface Props {}

const IconUploadPhoto: React.FC<Props> = props => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 69 60'
    >
      <path
        d='M57,17.6c0.4-6.1-4.2-11.3-10.2-11.7c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.7,0-1,0.1
        c-0.2,0-0.5,0-0.7,0.1c0,0,0,0-0.1,0c-0.3,0-0.6,0.1-0.8,0.2c-0.6,0.1-1.1,0.3-1.7,0.6c-0.2,0.1-0.5,0.2-0.7,0.3
        c-0.3,0.1-0.5,0.3-0.8,0.4c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c-5.5-9-18.3-9.8-25-1.6c-2.8,3.4-3.9,7.7-3.3,11.9
        C5.3,17.9,0,23.3,0,30c0,6.9,5.6,12.4,12.4,12.4h3.6c0.6,9.6,8.5,17.2,18.2,17.2c9.7,0,17.6-7.6,18.2-17.2h3.8
        c6.9,0,12.4-5.6,12.4-12.4C68.8,23.4,63.6,18,57,17.6z M34.3,58.1c-9,0-16.4-7.2-16.8-16.1c0-0.1,0.1-0.2,0.1-0.3
        c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0-9.3,7.5-16.8,16.8-16.8c9.3,0,16.8,7.5,16.8,16.8C51.1,50.6,43.6,58.1,34.3,58.1z M56.3,41
        h-3.8c-0.2-9.9-8.3-18-18.3-18c-10,0-18.1,8-18.3,18h-3.6c-6,0-11-4.9-11-11s4.9-11,11-11h0.3c0.5,0,0.8-0.4,0.7-0.9
        c-0.8-4.1,0.2-8.3,2.9-11.6C22.4-0.9,34,0,39,8.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-1.6,1.3-2.9,3.2-3.5,5.3
        c-0.1,0.4,0.1,0.8,0.5,0.9l0,0h0c0.4,0.1,0.7-0.2,0.8-0.5c0.6-2.1,1.9-3.8,3.6-5c0.3-0.2,0.5-0.4,0.8-0.5c0.3-0.2,0.6-0.3,0.9-0.5
        c0.2-0.1,0.4-0.2,0.6-0.2c0.5-0.2,1.1-0.3,1.7-0.4c0.5-0.1,1-0.1,1.5-0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0
        c4.9,0.3,8.8,4.5,8.8,9.5c0,0.4,0,0.9-0.1,1.4c0,0.2,0,0.4,0.2,0.6c0.1,0.2,0.3,0.2,0.5,0.2h0.1c6.1,0,11,4.9,11,11
        S62.4,41,56.3,41z'
      />
      <path
        d='M35.3,34.3l-0.1-0.1c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2l-4.3,4.3c-0.2,0.2-0.2,0.4-0.2,0.6
        c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.2l0.2-0.2l0.1-0.1l2.6-2.6v2.7v0.1v9.1c0,0.5,0.4,0.8,0.8,0.8
        s0.8-0.4,0.8-0.8v-9v-0.1v-2.7v-0.2l3,3c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0c0.2,0,0.5,0,0.7-0.2v0c0.2-0.3,0.2-0.8-0.1-1L35.3,34.3z'
      />
    </svg>
  );
};

export default IconUploadPhoto;
