import { createSlice } from '@reduxjs/toolkit';
import {
  ActivityDetails,
  ActivityItem,
  ActivityType,
  PropertyActivity,
} from '../../models/Activities';
import { Contact } from '../../models/Contacts';
import { Property } from '../../models/Properties';
import {
  createActivities,
  createActivity,
  getActivities,
  getActivitiesTypes,
  getActivity,
  getPropertyActivities,
  removeActivities,
  updateActivity,
} from './activitiesThunk';
import { RequestListType } from '../../models/Requests';

type PropertiesState = {
  activitiesTypes: ActivityType[];
  propertyActivities: PropertyActivity[];
  activities: ActivityItem[];
  activity: ActivityDetails | null;
  loading: boolean;
  totalCount: number;
  activitiesLoading: boolean;
  error: any;
  associatedContacts: Contact[];
  associatedProperties: Property[];
  associatedRequests: RequestListType[];
};

const initialState: PropertiesState = {
  activitiesTypes: [],
  propertyActivities: [],
  activities: [],
  activity: null,
  loading: false,
  totalCount: 0,
  activitiesLoading: false,
  error: null,
  associatedContacts: [],
  associatedProperties: [],
  associatedRequests: []
};

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearActivity(state) {
      state.activity = null;
      state.associatedContacts = [];
      state.associatedProperties = [];
      state.associatedRequests = [];
    },
    setAssociatedContacts(state, action) {
      state.associatedContacts = action.payload ?? [];
    },
    setAssociatedProperties(state, action) {
      state.associatedProperties = action.payload ?? [];
    },
    setAssociatedRequests(state, action) {
      state.associatedRequests = action.payload ?? [];
    },
    clearAssociatedContacts(state) {
      state.associatedContacts = [];
    },
    clearAssociatedProperties(state) {
      state.associatedProperties = [];
    },
    clearAssociatedRequests(state) {
      state.associatedRequests = [];
    },
    toggleChecked(state, action) {
      const activity = state.activities.find(activity => activity.id === action.payload);

      if (activity) {
        activity.checked = !activity.checked;
      }
    },
    checkAll(state, action) {
      state.activities.forEach(activity => (activity.checked = action.payload));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPropertyActivities.pending, state => {
        state.activitiesLoading = true;
        state.error = null;
      })
      .addCase(getPropertyActivities.fulfilled, (state, { payload }) => {
        state.propertyActivities = payload;
        state.activitiesLoading = false;
        state.error = null;
      })
      .addCase(getPropertyActivities.rejected, (state, action) => {
        state.error = action.payload;
        state.activitiesLoading = false;
      })
      .addCase(getActivities.pending, state => {
        state.activitiesLoading = true;
        state.error = null;
      })
      .addCase(getActivities.fulfilled, (state, { payload }) => {
        state.activities = payload.data;
        state.totalCount = payload.totalCount;
        state.activitiesLoading = false;
        state.error = null;
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.error = action.payload;
        state.activitiesLoading = false;
      })
      .addCase(removeActivities.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeActivities.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(removeActivities.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getActivity.pending, state => {
        state.activitiesLoading = true;
        state.error = null;
      })
      .addCase(getActivity.fulfilled, (state, { payload }) => {
        state.activity = payload;
        state.associatedContacts = payload.contacts;
        state.associatedProperties = payload.properties;
        state.activitiesLoading = false;
        state.error = null;
      })
      .addCase(getActivity.rejected, (state, action) => {
        state.error = action.payload;
        state.activitiesLoading = false;
      })
      .addCase(updateActivity.pending, state => {
        state.activitiesLoading = true;
        state.error = null;
      })
      .addCase(updateActivity.fulfilled, (state, { payload }) => {
        state.activity = payload;
        state.activitiesLoading = false;
        state.error = null;
      })
      .addCase(updateActivity.rejected, (state, action) => {
        state.error = action.payload;
        state.activitiesLoading = false;
      })
      .addCase(getActivitiesTypes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActivitiesTypes.fulfilled, (state, { payload }) => {
        state.activitiesTypes = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getActivitiesTypes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createActivities.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createActivities.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createActivities.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createActivity.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createActivity.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createActivity.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const {
  clearError,
  clearActivity,
  setAssociatedContacts,
  setAssociatedProperties,
  setAssociatedRequests,
  clearAssociatedContacts,
  clearAssociatedProperties,
  clearAssociatedRequests,
  toggleChecked,
  checkAll
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
