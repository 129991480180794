import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import { addCompany } from '../../redux/companies/companiesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Company } from '../../models/Companies';
import IconTimes from '../../icons/IconTimes';
import Input from '../Input/Input';
import Spinner from '../Spinner';
import './ModalCompanyItem.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showModal: boolean;
  handleClose: () => void;
  onSaved?: () => void;
}

const ModalCompanyItem: React.FC<Props> = ({
  showModal,
  handleClose,
  onSaved,
}) => {
  const dispatch = useAppDispatch();
  const { loadingItem } = useAppSelector(state => state.companies);

  const [formData, setFormData] = useState<Company>({});
  const [formDataErrors, setFormDataErrors] = useState<any>({});

  const onInputChange = (event: any) => {
    const { value, name } = event.target;

    setFormData((formData: any) => {
      formData[name] = value;

      return { ...formData };
    });
  };

  const saveCompany = async () => {
    await dispatch(addCompany(formData)).then(processResponse);
  };

  const processResponse = (response: any) => {
    if (response.error) {
      toast.error(
        `Eroare la salvarea companiei ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
      );
      setFormDataErrors(response.payload);
    } else {
      toast.success(`Compania a fost adaugata cu succes.`);
      closeModal();
      onSaved && onSaved();
    }
  };

  const closeModal = () => {
    setFormData({});
    setFormDataErrors({});
    handleClose();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        animation={false}
        className='companies-modal-center'
      >
        {loadingItem ? (
          <Spinner />
        ) : (
          <>
            <button
              className='close-modal'
              onClick={closeModal}
              aria-label='Închide'
            >
              <IconTimes />
            </button>

            <div className='modal-container'>
              <Modal.Header>
                <Modal.Title>Adauga Companie</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <div className='form-item'>
                        <Input
                          name='title'
                          labelText={true}
                          inlineLabel='Nume'
                          type='text'
                          value={formData?.title || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.title}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='form-item'>
                        <Input
                          name='phoneNumber'
                          labelText={true}
                          inlineLabel='Telefon'
                          type='text'
                          value={formData?.phoneNumber || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.phoneNumber}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='form-item'>
                        <Input
                          name='email'
                          labelText={true}
                          inlineLabel='E-mail'
                          type='text'
                          value={formData?.email || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.email}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='form-item'>
                        <Input
                          name='address'
                          labelText={true}
                          inlineLabel='Adresa'
                          type='text'
                          value={formData?.address || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.address}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='form-item'>
                        <Input
                          name='website'
                          labelText={true}
                          inlineLabel='Web site'
                          type='text'
                          value={formData?.website || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.website}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='form-item'>
                        <Input
                          name='details'
                          labelText={true}
                          inlineLabel='Detalii'
                          type='text'
                          value={formData?.details || ''}
                          onChange={onInputChange}
                          error={formDataErrors?.details}
                        />
                      </div>
                    </Col>
                    <div className='buttons'>
                      <div className='form-item-button'>
                        <button
                          className='reset-btn button-md'
                          onClick={closeModal}
                        >
                          Renunta
                        </button>
                      </div>
                      <div className='form-item-button'>
                        <button
                          className='button-blue button-md'
                          onClick={saveCompany}
                        >
                          Adauga
                        </button>
                      </div>
                    </div>
                  </Row>
                </Container>
              </Modal.Body>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ModalCompanyItem;
