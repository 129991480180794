interface Props {}

const IconHeadPhone: React.FC<Props> = (props) => {
 return (
  <svg
   width="21"
   height="20"
   viewBox="0 0 21 20"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.7499 17.7918C15.601 17.7918 14.6666 16.8575 14.6666 15.7085C14.6666 14.5595 15.601 13.6252 16.7499 13.6252C17.8989 13.6252 18.8333 14.5595 18.8333 15.7085C18.8333 16.8575 17.8989 17.7918 16.7499 17.7918ZM4.24992 13.6252C5.39888 13.6252 6.33325 14.5595 6.33325 15.7085C6.33325 16.8575 5.39888 17.7918 4.24992 17.7918C3.10096 17.7918 2.16659 16.8575 2.16659 15.7085C2.16659 14.5595 3.10096 13.6252 4.24992 13.6252ZM10.4999 0.0834961C4.75617 0.0834961 0.083252 4.92725 0.083252 10.8814V15.7085C0.083252 18.0064 1.952 19.8752 4.24992 19.8752C6.54784 19.8752 8.41658 18.0064 8.41658 15.7085C8.41658 13.4106 6.54784 11.5418 4.24992 11.5418C3.48742 11.5418 2.78221 11.7627 2.16659 12.122V10.8814C2.16659 6.0762 5.90513 2.16683 10.4999 2.16683C15.0947 2.16683 18.8333 6.0762 18.8333 10.8814V12.122C18.2176 11.7627 17.5124 11.5418 16.7499 11.5418C14.452 11.5418 12.5833 13.4106 12.5833 15.7085C12.5833 18.0064 14.452 19.8752 16.7499 19.8752C19.0478 19.8752 20.9166 18.0064 20.9166 15.7085V10.8814C20.9166 4.92725 16.2437 0.0834961 10.4999 0.0834961Z"
    fill="#1D1956"
   />
  </svg>
 );
};

export default IconHeadPhone;
