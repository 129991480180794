import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './RequestHistory.scss';

interface Props {}

const RequestHistory: React.FC<Props> = () => {
  return (
    <div className='display-flex ads-form-container'>
      <Row>
        <Col xl={12} className='content-block'>
          <h2 className='heading-block'>Istoric</h2>
          <p>In curand!</p>
        </Col>
      </Row>
    </div>
  );
};

export default RequestHistory;
