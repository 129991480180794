import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import IconEye from '../../icons/IconEye';
import IconTrash from '../../icons/IconTrash';
import ModalConfirm from '../ModalConfirm';
import './AnnouncesListItemTag.scss';

interface Props {}

const AnnouncesListItemTag: React.FC<Props> = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={`announces-list-item item-tag ${isChecked && 'active'}`}>
      <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div>
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='mobile-text'>Contact</div>
          <div className='info-nr label-item-tag label-item-mobile'>1</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Titlu</div>
          <div className='info-bold label-item-mobile'>De vizionat</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Anunturi</div>
          <div className='info-text label-item-mobile'>
            <p>ImoAP: 3 anunturi</p>
            <p>ImoAP Beta: 0 anuntur</p>
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Data</div>
          <div className='info-date label-item-mobile'>
            Adaugat 2023-12-14 15:20:10
          </div>
        </div>
        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            aria-label=''
            onClick={() => setShowDeleteModal(true)}
          >
            <IconTrash />
          </button>
          <button className='button-square-md' aria-label=''>
            <IconEye />
          </button>
        </div>
      </div>
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere eticheta'
        body='Esti sigur ca doresti sa stergi eticheta?'
        onConfirmed={() => {}}
        hideModal={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default AnnouncesListItemTag;
