interface Props {}

const IconArrowDown: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.1997 0.1997C0.465966 -0.0665667 0.89767 -0.0665667 1.16394 0.1997L7.5 6.53576L13.8361 0.1997C14.1023 -0.0665667 14.534 -0.0665667 14.8003 0.1997C15.0666 0.465966 15.0666 0.89767 14.8003 1.16394L7.98212 7.98212C7.71585 8.24839 7.28415 8.24839 7.01788 7.98212L0.1997 1.16394C-0.0665667 0.89767 -0.0665667 0.465966 0.1997 0.1997Z"
        fill="black"
      />
    </svg>
  );
};

export default IconArrowDown;
