interface Props {}

const IconFilter: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C11.448 6 11 5.552 11 5C11 4.448 11.448 4 12 4C12.552 4 13 4.448 13 5C13 5.552 12.552 6 12 6ZM15 5C15 3.346 13.654 2 12 2C10.346 2 9 3.346 9 5C9 6.302 9.839 7.401 11 7.815V21C11 21.553 11.447 22 12 22C12.553 22 13 21.553 13 21V7.815C14.161 7.401 15 6.302 15 5ZM6 18C5.448 18 5 17.552 5 17C5 16.448 5.448 16 6 16C6.552 16 7 16.448 7 17C7 17.552 6.552 18 6 18ZM7 14.185V3C7 2.447 6.553 2 6 2C5.447 2 5 2.447 5 3V14.185C3.839 14.599 3 15.698 3 17C3 18.302 3.839 19.401 5 19.815V21C5 21.553 5.447 22 6 22C6.553 22 7 21.553 7 21V19.815C8.161 19.401 9 18.302 9 17C9 15.698 8.161 14.599 7 14.185ZM18 14C17.448 14 17 13.552 17 13C17 12.448 17.448 12 18 12C18.552 12 19 12.448 19 13C19 13.552 18.552 14 18 14ZM21 13C21 11.698 20.161 10.599 19 10.185V3C19 2.447 18.553 2 18 2C17.447 2 17 2.447 17 3V10.185C15.839 10.599 15 11.698 15 13C15 14.302 15.839 15.401 17 15.815V21C17 21.553 17.447 22 18 22C18.553 22 19 21.553 19 21V15.815C20.161 15.401 21 14.302 21 13Z"
        fill="#1D1956"
      />
    </svg>
  );
};

export default IconFilter;
