import React, { FormEvent, useState } from 'react';
import DatePicker from 'react-datepicker';
import IconDisketteStrong from '../../icons/IconDisketteStrong';
import Input from '../Input/Input';
import Select from '../Select/Select';
import './FilterParticularyForm.scss';

interface Props {}

// interface FilterForm {}

const FilterParticularyForm: React.FC<Props> = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  // const [formData, setFormData] = useState<FilterForm>();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // console.log("FormData", formData);
  };
  return (
    <div className='filter-particular-form-container'>
      <div className='header-section display-flex'>
        <h5 className='title-section'>Filtru</h5>
        <div className='btns-section'>
          <button className='save-btn btn-squere-blue' aria-label='Salveaza'>
            <IconDisketteStrong />
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='form-line-section'>
          <Select
            placeholder='Select Options'
            labelText={true}
            inlineLabel='Tip Oferta'
          />
          <Select
            placeholder='Select Options'
            labelText={true}
            inlineLabel='Zona'
          />
          <div className='form-group'>
            <label>Pret</label>
            <div className='flex-input'>
              <Input placeholder='De la' labelText={false} />
              <Input placeholder='Pana la' labelText={false} />
            </div>
          </div>
          <Input inlineLabel='Cuvinte cheie' labelText={true} />
        </div>
        <div className='form-line-section'>
          <div className='date-picker'>
            <label>Selecteaza perioda</label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
            />
          </div>
          <Select
            placeholder='Select Options'
            labelText={true}
            inlineLabel='Oras'
          />
          <div className='form-group'>
            <label>Suprafata </label>
            <div className='flex-input'>
              <Input placeholder='De la' labelText={false} />
              <Input placeholder='Pana la' labelText={false} />
            </div>
          </div>
          <Input inlineLabel='ID' labelText={true} />
        </div>
        <div className='form-line-section'>
          <Select
            placeholder='Select Options'
            labelText={true}
            inlineLabel='Judet'
          />
        </div>
        <div className='bottom-filter-section'>
          <div className='form-btns-section'>
            <button className='reset-btn'>Reseteaza </button>
            <button className='filter-btn btn-blue'>Filtreaza</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterParticularyForm;
