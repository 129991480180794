import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AddAgentForm from '../../components/AddAgentForm';
import AgentInfo from '../../components/AgentInfo';
import AgentProfileTabs from '../../components/AgentProfileTabs';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCounty } from '../../redux/localities/localitiesThunk';
import { getSpecializations } from '../../redux/specialization/specializationThunk';
import { editUser, getUserProfile } from '../../redux/users/usersThunk';
import { getImagePath, haveUserPermission } from '../../helpers/useHelper';
import { AgentRequestDto } from '../../models/AgentsDto';
import { Permissions } from '../../models/Permissions';
import './AgentProfile.scss';

interface Props {}

const AgentProfile: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const { user, loading } = useAppSelector(state => state.users);
  const { specializations } = useAppSelector(state => state.specializations);

  const [formData, setFormData] = useState<AgentRequestDto>({});
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [formDataErrors, setFormDataErrors] = useState<any>({});
  const [showFirstTab, setShowFirstTab] = useState<boolean>(false);
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.EmployeeProfile),
  );

  useEffect(() => {
    dispatch(getUserProfile()).then(() => {
      specializations.length === 0 &&
        dispatch(getSpecializations({ limit: 20, offset: 1 }));
      dispatch(getCounty({ parent: 0 }));
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line
  }, [user]);

  const saveAgent = async () => {
    await dispatch(editUser(formData)).then(processResponse);
  };

  const processResponse = (response: any) => {
    if (response.error) {
      toast.error(
        `Eroare la salvarea agentului ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
      );
      setFormDataErrors(response.payload);
    } else {
      toast.success(
        `Succes!! Agentul a fost ${user ? 'modificat' : 'adaugat'} cu succes.`,
      );
      dispatch(getUserProfile());
    }
  };

  const resetForm = () => {
    setFormData({
      id: user?.id || 0,
      body: {
        status: user?.status || 0,
        fullName: user?.profile?.fullName || '',
        phoneNumbers: user?.profile?.phoneNumbers || [''],
        email: user?.email || '',
        birthday: user?.profile?.birthday?.substring(0, 10) || '2000-10-10',
        identificationNumber: user?.profile?.identificationNumber || '',
        description: user?.profile?.description || '',
        position: user?.profile?.position || '',
        languages: user?.profile?.languages?.map(lang => lang.id) || [],
        specializations:
          user?.profile?.specializations?.map(spec => spec.id) || [],
        mediaId: user?.profile?.avatar?.id || 0,
        countyId: user?.profile?.county?.id || 0,
        townId: user?.profile?.town?.id || 0,
        zones: user?.profile?.zones?.map(zone => zone.id) || [],
        permissions: user?.permissions?.map(permission => permission.id) || [],
        password: '',
        confirmPassword: '',
      },
    });

    if (user?.profile?.avatar?.path) {
      const imagePath = getImagePath(user?.profile?.avatar?.path);
      setProfileImage(imagePath);
    } else {
      setProfileImage(null);
    }
  };

  return (
    <div className='flex-container display-flex agent-profile'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Profil Agent'
          subtitle='De aici poti modifica setarile agentie tale, defini agenti, proprietati etc.'
          isbutton={false}
          backBtn={true}
          btnText=''
        />
        {havePermission ? (
          <>
            <AgentProfileTabs openedFirstTab={setShowFirstTab} />
            {loading ? (
              <Spinner />
            ) : showFirstTab ? (
              <AgentInfo user={user} />
            ) : (
              <AddAgentForm
                user={user}
                formData={formData}
                setFormData={setFormData}
                profileImage={profileImage}
                setProfileImage={setProfileImage}
                saveAgent={saveAgent}
                resetForm={resetForm}
                formDataErrors={formDataErrors}
              />
            )}
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default AgentProfile;
