import "./PropertiesUpdatesGrafic.scss";
import Form from "react-bootstrap/Form";
import GraficImg from "../../assets/svg/grafic.svg";

interface Props {}

const PropertiesUpdatesGrafic: React.FC<Props> = () => {
    return (
        <div className="properties-updates-wrapper">
            <div className="properties-updates-container">
                <h4>Titrez.ro</h4>
                <div className="input-checkbox-line">
                    <Form.Group id="formGridCheckbox">
                        <Form.Check type="checkbox" label="Anunturi Basic" />
                    </Form.Group>
                    <Form.Group id="formGridCheckbox">
                        <Form.Check type="checkbox" label="Anunturi Premium" />
                    </Form.Group>
                    <Form.Group id="formGridCheckbox">
                        <Form.Check type="checkbox" label="Anunturi Up!" />
                    </Form.Group>
                    <Form.Group id="formGridCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Anunturi Spotlight"
                        />
                    </Form.Group>
                </div>
                <div className="img-block">
                    <img src={GraficImg} alt="" />
                </div>
                <div className="btn-container">
                    <button className="button-blue button-md">Actulizeaza</button>
                </div>
            </div>
        </div>
    );
};

export default PropertiesUpdatesGrafic;
