interface Props {}

const IconCheckBox: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5,10.8v5.6c0,1.2-0.9,2.1-2.1,2.1H3.6c-1.2,0-2.1-0.9-2.1-2.1V3.6c0-1.2,0.9-2.1,2.1-2.1h9.6
c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5H3.6C3,2.5,2.5,3,2.5,3.6v12.8c0,0.6,0.5,1.1,1.1,1.1h12.8c0.6,0,1.1-0.5,1.1-1.1v-5.6
c0-0.3,0.2-0.5,0.5-0.5S18.5,10.6,18.5,10.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4,5.3l-6.8,7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2L7.6,10c-0.2-0.2-0.2-0.5,0-0.7
c0.2-0.2,0.5-0.2,0.7,0l1.5,1.6l0.4,0.4l0.4-0.4l6-6.3c0.2-0.2,0.5-0.2,0.7,0C17.5,4.8,17.5,5.2,17.4,5.3z"
      />
    </svg>
  );
};

export default IconCheckBox;
