import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Tooltip } from 'react-tooltip';
import { Company } from '../../models/Companies';
import IconPencil from '../../icons/IconPencil';
import './CompanyListItem.scss';

interface Props {
  company: Company;
  onEditCompany: any;
  handleCheckboxChange: (id: number) => void;
}

const CompanyListItem: React.FC<Props> = ({
  company,
  onEditCompany,
  handleCheckboxChange,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  return (
    <div
      className={`announces-list-item company-item ${company.checked ? ' active' : ''}`}
    >
      <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={company.checked || false}
              readOnly
              onClick={() => handleCheckboxChange(company.id || 0)}
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div>
      <div className='list-item-content'>
        {(!isMobile || company.title) && (
          <div className='column-item'>
            <div className='mobile-text'>Nume</div>
            <div className='info label-item-mobile'>{company.title}</div>
          </div>
        )}
        {(!isMobile || company.phoneNumber) && (
          <div className='column-item'>
            <div className='mobile-text'>Telefon</div>
            <a href='tel:' className='info label-item-mobile'>
              {company.phoneNumber || ''}
            </a>
          </div>
        )}
        {(!isMobile || company.email) && (
          <div className='column-item'>
            <div className='mobile-text'>E-mail</div>
            <a href='mailto:' className='info label-item-mobile'>
              {company.email || ''}
            </a>
          </div>
        )}
        {(!isMobile || company.address) && (
          <div className='column-item'>
            <div className='mobile-text'>Adresa</div>
            <div className='info label-item-mobile'>
              {company.address || ''}
            </div>
          </div>
        )}
        {(!isMobile || company.dateCreated) && (
          <div className='column-item'>
            <div className='mobile-text'>Data adaugare</div>
            <div className='info label-item-mobile'>
              {company.dateCreated || ''}
            </div>
          </div>
        )}
        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            data-tooltip-id='edit'
            data-tooltip-content='Editeaza'
            aria-label=''
            onClick={onEditCompany}
          >
            <IconPencil />
          </button>
          <Tooltip id='edit' />
        </div>
      </div>
    </div>
  );
};

export default CompanyListItem;
