interface Props {}

const IconPencil: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 22"
      {...props}
    >
      <path
        d="M2,16.5L2,16.5L2,16.5l4.2-0.4c0.3,0,0.7-0.2,0.9-0.4l9-9c0.5-0.5,0.5-1.4-0.1-2l-2.7-2.7c-0.6-0.6-1.5-0.6-2-0.1l-9,9
           c-0.2,0.2-0.4,0.6-0.4,0.9l-0.4,4.2c0,0.2,0,0.3,0.2,0.4C1.7,16.4,1.9,16.5,2,16.5z M10,4.6l2-2l0.4-0.3l0.4,0.3l2.7,2.7l0.3,0.4
           L13,8.4L12.7,8L10,5.3L9.6,5L10,4.6z M2.6,14.8l0.3-3l0-0.2L3,11.6L8.6,6L9,5.6L9.3,6l3,3L12,9.3L6.4,15l-0.1,0.1l-0.2,0l-3,0.3
           l-0.6,0.1L2.6,14.8z"
      />
      <path d="M16,19.5H2c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h14c0.3,0,0.5-0.2,0.5-0.5C16.5,19.7,16.3,19.5,16,19.5z" />
    </svg>
  );
};

export default IconPencil;
