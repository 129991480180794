import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import Buttons from '../../components/Buttons';
import Header from '../../components/Header';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getCategories } from '../../redux/categories/categoriesThunk';
import { getContacts } from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCounty } from '../../redux/localities/localitiesThunk';
import { getPricesTypes } from '../../redux/properties/propertiesThunk';
import { clearResidentialComplex } from '../../redux/residentialComplexes/residentialComplexes-slice';
import {
  editResidentialComplex,
  getAssociatedProperties,
  getResidentialComplex,
} from '../../redux/residentialComplexes/residentialComplexesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import useHelper from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import { ResidentialComplexForm } from '../../models/ResidentialComplexes';
import AddAssociatedProperties from '../AddAssociatedProperties';
import AddContactsForm from '../AddContactsForm';
import AddImagesAreaPriceForm from '../AddImagesAreaPriceForm';
import AddImagesCompanyLogoForm from '../AddImagesCompanyLogoForm';
import AddSpecificationsForm from '../AddSpecificationsForm';
import ResidentialComplexDetails from '../ResidentialComplexDetails';
import ResidentialComplexGeneral from '../ResidentialComplexGeneral';
import ResidentialComplexLocalization from '../ResidentialComplexLocalization';
import './EditResidentialComplex.scss';


interface Props {}

const tabs = [
  {
    label: 'Localizare',
    value: 'localizare',
  },
  {
    label: 'Detalii Generale',
    value: 'general-details',
  },
  {
    label: 'Suprafata/Pret',
    value: 'suprafata',
  },
  {
    label: 'Specificatii',
    value: 'specifications',
  },
  {
    label: 'Proprietati Asociate',
    value: 'propriety',
  },
  {
    label: 'Imagini',
    value: 'images',
  },
  {
    label: 'Contacte',
    value: 'contact',
  },
];

const EditResidentialComplex: React.FC<Props> = (...props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();

  const { residentialComplex, loading, associatedProperties } = useAppSelector(
    state => state.residentialComplexes,
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [formData, setFormData] = useState<ResidentialComplexForm>({});
  const [formDataErrors, setFormDataErrors] = useState<any>({});

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    dispatch(getCategories());
    dispatch(getPricesTypes());
    dispatch(getContacts());
    dispatch(getUsers({ roles: 'AGENCY', limit: -1 }));
    dispatch(getCounty({ parent: 0 }));

    return () => {
      window.removeEventListener('resize', handleResize);
      dispatch(clearResidentialComplex());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getResidentialComplex(id));
      dispatch(getAssociatedProperties(id));
    } else {
      resetForm();
    }
  }, [id]);

  useEffect(() => {
    if (residentialComplex) {
      resetForm();
    }
  }, [residentialComplex]);

  const resetForm = () => {
    setFormData({
      employeeId: residentialComplex?.employee?.id || 0,
      countyId: residentialComplex?.county?.id || 0,
      townId: residentialComplex?.town?.id || 0,
      streetId: residentialComplex?.street?.id || 0,
      zoneId: residentialComplex?.zone?.id || 0,
      streetNumber: residentialComplex?.streetNumber,
      title: residentialComplex?.title,
      description: residentialComplex?.description,
      offerTypes: residentialComplex?.offerTypes?.map(o => o.id),
      categories: residentialComplex?.categories?.map(c => c.id),
      website: residentialComplex?.website,
      videoUrl: residentialComplex?.videoUrl,
      coordinates: residentialComplex?.coordinates,
      status: residentialComplex?.status || 0,
      rangeSurface: residentialComplex?.rangeSurface,
      rangePrice: residentialComplex?.rangePrice,
      rangePriceFrom: residentialComplex?.rangePriceFrom,
      rangePriceTo: residentialComplex?.rangePriceTo,
      rooms: residentialComplex?.rooms,
      contactsComments: residentialComplex?.contactsComments,
      additionalInformation: residentialComplex?.additionalInformation,
      characteristics: getCharacteristics(),
      contacts: residentialComplex?.residentialComplexContacts?.map(item => {
        return { id: item.contact.id, tags: item.tags };
      }),
      media: residentialComplex?.media,
    });
  };

  const getCharacteristics = () => {
    const filledCharacteristics = [
      ...(residentialComplex?.characteristics || []),
    ];

    while (filledCharacteristics.length < 6) {
      filledCharacteristics.push('');
    }

    return filledCharacteristics;
  };

  const handleChange = (key: string, e: SelectValue | null) => {
    setFormData({
      ...formData,
      [key]: Number(e?.value),
    });
  };

  const handleString = (key: string, e: string | number | null) => {
    setFormData({
      ...formData,
      [key]: e,
    });
  };

  const renderTabContent = (type: string) => {
    switch (type) {
      case 'localizare':
        return (
          <ResidentialComplexLocalization
            formData={formData}
            formDataErrors={formDataErrors}
            setFormData={setFormData}
            handleChange={(key, value) => handleChange(key, value)}
            handleString={(key, value) => handleString(key, value)}
          />
        );
      case 'general-details':
        return (
          <ResidentialComplexDetails
            formData={formData}
            formDataErrors={formDataErrors}
            setFormData={setFormData}
            handleChange={(key, value) => handleChange(key, value)}
            handleString={(key, value) => handleString(key, value)}
          />
        );
      case 'suprafata':
        return (
          <AddImagesAreaPriceForm
            formData={formData}
            formDataErrors={formDataErrors}
            setFormData={setFormData}
          />
        );
      case 'specifications':
        return (
          <AddSpecificationsForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'propriety':
        return (
          <AddAssociatedProperties
            properties={id ? associatedProperties : []}
          />
        );
      case 'images':
        return (
          <AddImagesCompanyLogoForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'contact':
        return (
          <AddContactsForm formData={formData} setFormData={setFormData} />
        );
    }

    return null;
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const saveResidentialComplex = async () => {
    if (id) {
      await dispatch(editResidentialComplex({ id: id, body: formData })).then(
        res => {
          if (res.payload.id) {
            navigate('/residential-complexes/');
            setFormDataErrors({});
            setFormData({});
          } else {
            setFormDataErrors(res.payload);
            diplayErrorMessages(res.payload);
          }
        },
      );
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        {!loading && (
          <Header
            title='Ansamblu rezidential'
            subtitle='Completeaza toate campurile obligatorii'
            backBtn={true}
            isbutton={false}
            btnText=''
          />
        )}
        {loading ? (
          <Spinner />
        ) : (
          <div className='section-container'>
            <ResidentialComplexGeneral formData={formData} />
            <div className='residential-complex-general-lg'>
              <div className=' bg-blue'>
                <div className='residential-complex-container bg-white'>
                  {isMobile ? (
                    <div>
                      <select
                        className='select-tabs '
                        value={activeTab}
                        onChange={e => setActiveTab(e.target.value)}
                      >
                        {tabs.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>

                      {activeTab && renderTabContent(activeTab)}
                    </div>
                  ) : (
                    <Tabs
                      id='add-residential-complex'
                      className='residential-complex-form-tabs'
                      activeKey={activeTab}
                      onSelect={e => e && setActiveTab(e)}
                    >
                      {tabs.map(tab => (
                        <Tab
                          key={tab.value}
                          eventKey={tab.value}
                          title={tab.label}
                        >
                          {renderTabContent(tab.value)}
                        </Tab>
                      ))}
                    </Tabs>
                  )}
                </div>
              </div>
              <Buttons
                nextStep={() =>
                  tabs.findIndex(tab => tab.value === activeTab) !==
                    tabs.length - 1 &&
                  setActiveTab(
                    tabs[tabs.findIndex(tab => tab.value === activeTab) + 1]
                      .value,
                  )
                }
                submitLoading={loading}
                submit={saveResidentialComplex}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditResidentialComplex;
