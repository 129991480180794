import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ModalItem from '../../components/ModalEmailTemplateItem/ModalEmailTemplateItem';
import './RequestsForm.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getRequests } from '../../redux/requests/requestsThunk';
import { RequestListType } from '../../models/Requests';
import Pagination from '../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ProfileIcon from '../../assets/svg/profileIcon.svg';
import IconPhone from '../../icons/IconPhone';
import IconEnvelope from '../../icons/IconEnvelope';
import { getImagePath } from '../../helpers/useHelper';
import Avatar from '../../assets/img/avatar.png';
import IconPencil from '../../icons/IconPencil';

interface Props {
  fieldName: string;
  fieldId?: string | number;
}

const RequestsForm: React.FC<Props> = ({ fieldName, fieldId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { requests, totalCount } = useAppSelector(
    state => state.requests,
  ).requestsListState;

  const [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(Math.ceil(totalCount / pageSize));
  const [requestParams, setRequestParams] = useState<any>({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);


  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fieldId) {
      prepareRequestParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId, page]);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / pageSize);

      setPageCount(pageCount);
    }
  }, [totalCount]);

  useEffect(() => {
    if (Object.keys(requestParams).length > 0) {
      dispatch(getRequests(requestParams));
    }
  }, [JSON.stringify(requestParams)]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const prepareRequestParams = () => {
    setRequestParams({
      [fieldName]: fieldId,
      offset: page + 1,
      limit: pageSize,
    });
  };


  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <div className='display-flex ads-form-container'>
      <Container>
        <Row>
          <Col md={6} className='ads-form-container--left'>
            <p className='heading-block'>Solicitări ce se potrivesc</p>
          </Col>
          <Col md={6} className='ads-form-container--right'>
            <button className='button-blue button-md' onClick={handleShow}>
              Trimite pe e-mail
            </button>
          </Col>
          <div className='filter-list-container request-list-filter'>
            <form>
              <div className="filter-item">
                <label>ID</label>
              </div>
              <div className="filter-item">
                <label>Modificat</label>
              </div>
              <div className="filter-item">
                <label>Contact</label>
              </div>
              <div className="filter-item">
                <label>Tip proprietate</label>
              </div>
              <div className="filter-item">
                <label>Tip tranzactie</label>
              </div>
              <div className="filter-item">
                <label>Ce cauta?</label>
              </div>
              <div className="filter-item">
                <label>Buget/Plata</label>
              </div>
              <div className="filter-item">
                <label>Agent</label>
              </div>
              <div className="filter-item">
                <label>Actiuni</label>
              </div>
            </form>
          </div>
          <div className="list-items-container">
            {requests
              .map((item: RequestListType) => (
                <div
                  key={`${item.id} ${item.employeeFullName}`}
                  className='request-item announces-list-item'>
                  <div className='list-item-content'>
                    <div className='column-item'>
                      <div className='mobile-text'>ID</div>
                      <div className='info-id label-item-mobile'>{item.id}</div>
                    </div>
                    <div className='column-item'>
                      <div className='mobile-text'>Modificat</div>
                      <div className='info-date-modify label-item-mobile'>
                        {moment(item.dateUpdated).format('DD.MM.YYYY')}
                      </div>
                    </div>
                    <div className='column-item profile-column'>
                      {item.contacts?.length > 0 && (
                        <>
                          <div className='mobile-text'>Contact</div>
                          <div className='info-pofile-container'>
                            <div className='info-img'>
                              <img src={ProfileIcon} alt='ProfileIcon' />
                            </div>
                            <div className='info-contact-details'>
                              <div className='name'>
                                {item.contacts[0].name + ' ' + item.contacts[0].surname}
                              </div>
                              <div className='phone-numbers'>
                                {item.contacts[0].phoneNumbers.map(
                                  phone =>
                                    phone.phone && (
                                      <a href={'tel:' + phone.phone} aria-label=''>
                                        <IconPhone />
                                        {phone.phone}
                                      </a>
                                    ),
                                )}
                                {item.contacts[0].internationalPhoneNumber && (
                                  <a
                                    href={
                                      'tel:' + item.contacts[0].internationalPhoneNumber
                                    }
                                    aria-label=''
                                  >
                                    <IconPhone />
                                    {item.contacts[0].internationalPhoneNumber}
                                  </a>
                                )}
                              </div>
                              <div className='mail'>
                                {item.contacts[0].emails.map(
                                  email =>
                                    email && (
                                      <a href={'mailto:' + email} aria-label=''>
                                        <IconEnvelope />
                                        {email}
                                      </a>
                                    ),
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className='column-item'>
                      <div className='mobile-text'>Tip Proprietari</div>
                      <div className='info-type label-item-mobile'>
                        {item.categoryTitle}
                      </div>
                    </div>
                    <div className='column-item'>
                      <div className='mobile-text'>Tip tranzactie</div>
                      <div className='info-transaction label-item-mobile'>
                        {item.transactionType === 'BUY' ? 'Cumparare' : 'Inchiriere'}
                      </div>
                    </div>
                    {(!isMobile || item.description) && (
                      <div className='column-item'>
                        <div className='mobile-text'>Ce cauta</div>
                        <div className='info-search label-item-mobile'>
                          {item.description}
                        </div>
                      </div>
                    )}
                    {(!isMobile || item.budgetComment) && (
                      <div className='column-item'>
                        <div className='mobile-text'>Buget/Plata</div>
                        <div className='info-budget label-item-mobile'>
                          {item.budgetComment}
                        </div>
                      </div>
                    )}
                    <div className='column-item'>
                      <div className='mobile-text'>Agent</div>
                      <div className='info-agent'>
                        <div className='info-image'>
                          {item.employeeAvatarPath ? (
                            <img src={getImagePath(item.employeeAvatarPath)} alt='' />
                          ) : (
                            <img src={Avatar} alt='' />
                          )}
                        </div>
                        <div className='info-name'>{item.employeeFullName}</div>
                      </div>
                    </div>
                    <div className='line-border'></div>
                    <div className='info-icons'>
                      <button
                        className='button-square-md'
                        aria-label=''
                        onClick={() => navigate('/requests/' + item.id)}
                      >
                        <IconPencil />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          {pageCount > 1 && (
            <Pagination
              isSelectPage={false}
              pageCount={pageCount}
              currentPage={page}
              itemsPerPage={pageSize}
              setItemsPerPage={setPageSize}
              handlePageClick={handlePageClick}
            />
          )}
          <ModalItem showModal={show} handleClose={handleClose} />
        </Row>
      </Container>
    </div>
  );
};

export default RequestsForm;
