import parse from 'html-react-parser';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { extractNumber, getImagePath } from '../../helpers/useHelper';
import { Announce, hasValue } from '../../models/Announces';
import ListItemImg from '../../assets/img/house_square.png';
import IconDiskette from '../../icons/IconDiskette';
import IconLabe from '../../icons/IconLabel';
import IconPriceHistory from '../../icons/IconPriceHistory';
import GalleryModal from '../GalleryModal';
import PricesHistoryModal from '../PricesHistoryModal';
import './AnnouncesListItem.scss';

interface Props {
  item: Announce;
  checked?: boolean;
  handleCheckboxChange?: (id: number) => void;
  save: () => void;
}

const AnnouncesListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
  save,
}) => {
  const [isOpened, setOpened] = useState(false);
  const [pricePerMeter, setPricePerMeter] = useState('-');
  const [isCollapsedDescription, setIsCollapsedDescription] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(true);
  const [openedPrices, setPricesModal] = useState(false);

  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // id === 1 check if for selling
    if (
      item.price &&
      item.prices &&
      item.prices.length > 0 &&
      item.prices.at(-1).id === 1
    ) {
      const surface = extractNumber(item.usableSurface);
      const price = extractNumber(item.price);

      if (surface && price && surface !== 0) {
        const ppm = Math.round(price / surface);

        setPricePerMeter(`${ppm} ${item.currencySymbol}/m\u00B2`);
      }
    }

    setIsOverflowing((descriptionRef.current?.clientHeight ?? 0) > 120);
    setIsCollapsedDescription(
      (descriptionRef.current?.clientHeight ?? 0) > 120,
    );
  }, [item]);

  const closeModal = () => {
    setOpened(false);
  };

  return (
    <div
      className={checked ? 'announces-list-item active' : 'announces-list-item'}
    >
      <div className='checkbox-container'>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={checked}
            readOnly
            onClick={() =>
              handleCheckboxChange && handleCheckboxChange(item.id)
            }
          />
        </Form.Group>
      </div>
      <div className='list-item-content'>
        <div className='list-item-header'>
          <div className='label-item-info-lg'>
            {item.townTitle ?? item.countyTitle}
          </div>
          <div className='label-item-info-lg'>{item.categoryTitle}</div>
          <div className='label-item-info-lg'>{item.landSurface}</div>
          <div className='label-item-info-lg'>{item.streetFrontDistance}</div>
          <div className='label-item-info-lg'>
            {item.price} {item.currencySymbol}
          </div>
          <div className='label-item-info-lg'>{pricePerMeter}</div>
          <div className='label-item-info-lg'>
            {moment(item.dateUpdated ?? item.dateCreated).format(
              'DD.MM.YYYY - HH:mm',
            )}
          </div>
        </div>
        <div className='list-item-body'>
          <div
            className='img-container'
            onClick={() => setOpened(item.media && item.media.length > 0)}
          >
            <img
              src={
                item.media && item.media.length > 0
                  ? getImagePath(item.media[0].media.path)
                  : ListItemImg
              }
              alt='img-item'
            />
          </div>
          <div className='description-container'>
            <div className='item-details'>
              <div className='item-info'>
                <div className='item-tile'>
                  {item.zoneLabel && `Zona ${item.zoneLabel}`}
                </div>
                <div className='item-price'>
                  <p>
                    {item.prices && item.prices.length > 0 && (
                      <>
                        {item.prices.at(-1).id === 1 ? 'VANZARE' : 'INCHIRIERE'}{' '}
                        -{' '}
                        <span>
                          {item.price} {item.currencySymbol}
                        </span>
                      </>
                    )}
                  </p>
                </div>
                <div className='item-info-tags'>
                  {hasValue(item.usableSurface) && (
                    <span className='label-item-tag'>
                      SU: {item.usableSurface}
                    </span>
                  )}
                  {hasValue(item.constructionYear) && (
                    <span className='label-item-tag'>
                      AN: {item.constructionYear}
                    </span>
                  )}
                  {hasValue(item.floorNumber) && (
                    <span className='label-item-tag'>
                      Etaj: {item.floorNumber}
                    </span>
                  )}
                  {hasValue(item.comfort) && (
                    <span className='label-item-tag'>
                      Confort: {item.comfort}
                    </span>
                  )}
                  {hasValue(item.floors) && (
                    <span className='label-item-tag'>
                      Nr etaje: {item.floors}
                    </span>
                  )}
                  {hasValue(item.numberOfRooms) && (
                    <span className='label-item-tag'>
                      Nr camere: {item.numberOfRooms}
                    </span>
                  )}
                  {hasValue(item.phones) &&
                    item.phones.map((phone: any) => (
                      <span className='label-item-tag'>{phone}</span>
                    ))}
                </div>
              </div>
              <div className='item-description'>
                <div
                  ref={descriptionRef}
                  className={`text-container ${isCollapsedDescription ? 'collapsed' : ''}`}
                >
                  {parse(item.description)}
                </div>
                {isOverflowing && (
                  <button
                    className='toggle-button'
                    onClick={() =>
                      setIsCollapsedDescription(!isCollapsedDescription)
                    }
                  >
                    {isCollapsedDescription
                      ? 'Afiseaza toata descrierea'
                      : 'Afiseaza mai putin'}
                  </button>
                )}
              </div>
              <div className='item-icon-links'>
                <button
                  className='button-square-md'
                  title='Istoric pret'
                  onClick={() => setPricesModal(true)}
                >
                  <IconPriceHistory />
                </button>
                <button className='button-square-md' title='Etichete'>
                  <IconLabe />
                </button>
                <button
                  className='button-square-md'
                  title='Clonare'
                  onClick={save}
                >
                  <IconDiskette />
                </button>
              </div>
            </div>
            <div className='item-links'>
              {item.sources &&
                item.sources.map((source: any) => (
                  <a
                    href={source.link}
                    target='_blank'
                    className='label-item-link'
                    rel='noreferrer'
                  >
                    {source.name}
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>

      <GalleryModal
        closeModal={closeModal}
        isModalOpen={isOpened}
        images={
          item?.media?.filter(
            media => media.status === 1 && media.media?.path,
          ) ?? []
        }
      />

      <PricesHistoryModal
        closeModal={() => setPricesModal(false)}
        isModalOpen={openedPrices}
        prices={item?.prices ?? []}
      />
    </div>
  );
};

export default AnnouncesListItem;
