interface Props {}

const IconAddition: React.FC<Props> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M16.5,9c0-0.3-0.2-0.5-0.5-0.5H9.5V2c0-0.3-0.2-0.5-0.5-0.5S8.5,1.7,8.5,2v6.5H2C1.7,8.5,1.5,8.7,1.5,9
     S1.7,9.5,2,9.5h6.5V16c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V9.5H16C16.3,9.5,16.5,9.3,16.5,9z"
      />
    </svg>
  );
};

export default IconAddition;
