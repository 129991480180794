import React from "react";
import "./ActionsMobile.scss";
import Stackicon from "../../assets/svg/stackitem.svg";
import Phoneicon from "../../assets/svg/phoneitem.svg";
import WarningIcon from "../../assets/svg/warningitem.svg";
import ShareIcon from "../../assets/svg/shareitem.svg";
import SaveIcon from "../../assets/svg/saveitem.svg";
import GlobeIcon from "../../assets/svg/globeitem.svg";

interface Props {}

const ActionsMobile: React.FC<Props> = () => {
    return (
        <>
            <div className="actions-container">
                <div className="action-item">
                    <p>Istoric anunt </p>
                    <img src={Stackicon} alt="Stackicon" />
                </div>
                <div className="action-item">
                    <p>Istoric telefon</p>
                    <img src={Phoneicon} alt="Phoneicon" />
                </div>
                <div className="action-item">
                    <p>Raporteaza telefon</p>
                    <img src={WarningIcon} alt="WarningIcon" />
                </div>
                <div className="action-item">
                    <p>Etichete anunt</p>
                    <img src={ShareIcon} alt="ShareIcon" />
                </div>
                <div className="action-item">
                    <p>Salveaza anunt</p>
                    <img src={SaveIcon} alt="SaveIcon" />
                </div>
                <div className="action-item">
                    <p>Publica anunt</p>
                    <img src={GlobeIcon} alt="GlobeIcon" />
                </div>
            </div>
        </>
    );
};

export default ActionsMobile;
