import React from 'react';
import Form from 'react-bootstrap/Form';
import './FilterList.scss';

interface Props {
  isCheckedAll: boolean;
  setCheckedAll: (all: boolean) => void;
}

const FilterList: React.FC<Props> = ({ isCheckedAll, setCheckedAll }) => {
  return (
    <div className='filter-list-container'>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll(!isCheckedAll)}
          />
        </Form.Group>
        <div className='filter-item'>
          <label>Localitate</label>
        </div>
        <div className='filter-item'>
          <label>Tip Imobil</label>
        </div>
        <div className='filter-item'>
          <label>Suprafata teren</label>
        </div>
        <div className='filter-item'>
          <label>Front Stradal</label>
        </div>
        <div className='filter-item'>
          <label>Pret</label>
        </div>
        <div className='filter-item'>
          <label>
            Pret/m<sup>2</sup>
          </label>
        </div>
        <div className='filter-item'>
          <label>Actualizat</label>
        </div>
      </form>
    </div>
  );
};

export default FilterList;
