import { createAsyncThunk } from '@reduxjs/toolkit';

import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getCategories = createAsyncThunk<any>(
  'auth/getCategories',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/categories', {
        params: { sort: 'priority' },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
