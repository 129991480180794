import "./FilterListTags.scss";
import Form from "react-bootstrap/Form";
import React from "react";
import Input from "../Input/Input";
interface Props {}

const FilterListTags: React.FC<Props> = () => {
    return (
        <div className="filter-list-container filter-list-tags">
            <form>
                <Form.Group id="formGridCheckbox">
                    <Form.Check type="checkbox" />
                </Form.Group>
                <div className="filter-item">
                    <Input
                        inlineLabel="Id"
                        placeholder="Search"
                        labelText={true}
                    />
                </div>
                <div className="filter-item">
                    <Input
                        inlineLabel="Titlu"
                        placeholder="Search"
                        labelText={true}
                    />
                </div>
                <div className="filter-item">
                    <Input
                        inlineLabel="Anunturi"
                        placeholder="Search"
                        labelText={true}
                    />
                </div>
                <div className="filter-item">
                    <label>Data</label>
                    <div className="flex-input">
                        <Input placeholder="De la" labelText={false} />
                        <Input placeholder="Pana la" labelText={false} />
                    </div>
                </div>
                <div className="filter-item">
                    <label>Actiuni</label>
                </div>
            </form>
        </div>
    );
};

export default FilterListTags;
