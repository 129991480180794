import { createSlice } from '@reduxjs/toolkit';
import { Permission } from '../../models/Permissions';
import { getPermissions } from './permissionsThunk';

type PermissionsState = {
  permissions: Permission[];
  loading: boolean;
  error: any;
};

const initialState: PermissionsState = {
  permissions: [],
  loading: false,
  error: null,
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearPermissions(state) {
      state.permissions = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPermissions.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPermissions.fulfilled, (state, { payload }) => {
        state.permissions = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPermissions.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = permissionsSlice.actions;

export default permissionsSlice.reducer;
