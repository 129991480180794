import { createSlice } from '@reduxjs/toolkit';

import { getCategories } from './categoriesThunk';
import { Category } from '../../models/Categories';

type CategoriesState = {
  categories: Category[];
  loading: boolean;
  error: any;
};

const initialState: CategoriesState = {
  categories: [],
  loading: false,
  error: null
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCategories.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.categories = payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const { clearError } = categoriesSlice.actions;

export default categoriesSlice.reducer;
