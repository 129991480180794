import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { SelectValue } from '../../models/Properties';
import './MultiselectCheckboxes.scss';

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type='checkbox'
          checked={
            props.selectProps?.values.length > 0 &&
            props.selectProps?.values?.includes(props.data.value)
          }
          readOnly
        />
        <label style={{ paddingLeft: '10px' }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiselectCheckboxes: React.FC<any> = ({
  options = [],
  labelText,
  inlineLabel,
  id,
  onChange,
  value,
  showValue = true,
  ...props
}) => {
  const [values, setValues] = useState<string[]>([]);
  const { placeholder, ...restProps } = props;

  const handleClickCheckbox = (e: string) => {
    let newValues = [];

    if (values && values.length > 0) {
      if (values?.includes(e)) newValues = values.filter(value => value !== e);
      else newValues = [...values, e];
    } else newValues = [e];
    setValues(newValues);
    onChange(newValues);
  };

  const selectProps = {
    values: values,
  };

  useEffect(() => {
    value && setValues(value);
  }, [value]);

  return (
    <>
      <div className='form-item-group multiselect'>
        {labelText && (
          <label className='form-item-group-label' htmlFor={id}>
            {inlineLabel}
          </label>
        )}
        <Select
          {...selectProps}
          components={{
            Option,
          }}
          autoFocus={false}
          onChange={(e: SelectValue) => {
            handleClickCheckbox(e.value);
          }}
          options={options}
          placeholder={
            !showValue ? null : values.length > 0 ? (
              <div className='select-placeholder-text'>
                {options
                  .filter((option: SelectValue) =>
                    values.includes(option.value),
                  )
                  .map((option: SelectValue) => option.label)
                  .join(', ')}
              </div>
            ) : (
              placeholder ?? 'Select option'
            )
          }
          value={null}
          closeMenuOnSelect={false}
          isClearable={false}
          isSearchable={false}
          {...restProps}
          classNames={{
            control: state =>
              state.isFocused
                ? 'form-item-control-select'
                : 'form-item-control-select',
          }}
          classNamePrefix='react-select'
        />
      </div>
    </>
  );
};

export default MultiselectCheckboxes;
