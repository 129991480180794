import React from 'react';

import IconBars from '../../icons/IconBars';
import IconFilter from '../../icons/IconFilter';
import IconSearch from '../../icons/IconSearch';
import './FixedActions.scss';

interface Props {
  ModalAction: () => void;
  ModalFilter: () => void;
  ModalSearch: () => void;
  disabledActions?: boolean;
}

const FixedActions: React.FC<Props> = ({
  ModalAction,
  ModalFilter,
  ModalSearch,
  disabledActions = false,
}) => {
  return (
    <>
      <div className='fixed-actions-container'>
        <div className='action-list'>
          <div
            className={`action-item-btn ${disabledActions ? 'disabled' : ''}`}
            onClick={ModalAction}
          >
            <IconBars />
            Actiuni
          </div>
          <div className='action-item-btn' onClick={ModalFilter}>
            <IconFilter />
            Filter
          </div>
          <div className='action-item-btn' onClick={ModalSearch}>
            <IconSearch />
            Search
          </div>
        </div>
      </div>
    </>
  );
};

export default FixedActions;
