import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Input from '../../components/Input/Input';
import Pagination from '../../components/Pagination/Pagination';
import PropertyListItem from '../../components/PropertyListItem';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getProperties } from '../../redux/properties/propertiesThunk';
import useDebounce from '../../helpers/useDebounce';
import { Property } from '../../models/Properties';
import IconSearch from '../../icons/IconSearch';
import './AdsPropertiesForm.scss';


const AdsPropertiesForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [propertySearch, setPropertySearch] = useState<string | null>(null);
  const { properties, loading, totalCount } = useAppSelector(
    state => state.properties,
  );
  const { contact } = useAppSelector(state => state.contacts);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(Math.ceil(totalCount / pageSize));
  const [requestParams, setRequestParams] = useState<any>({});

  const debouncedPropertySearch = useDebounce(propertySearch, 300);

  useEffect(() => {
    if (contact) {
      prepareRequestParams();
    }
  }, [page, contact]);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / pageSize);

      setPageCount(pageCount);
    }
  }, [totalCount]);

  useEffect(() => {
    if (page > 0) {
      setPage(0);
    } else {
      prepareRequestParams();
    }
  }, [debouncedPropertySearch]);

  useEffect(() => {
    if (Object.keys(requestParams).length > 0) {
      dispatch(getProperties(requestParams));
    }
  }, [JSON.stringify(requestParams)]);

  const prepareRequestParams = () => {
    setRequestParams({
      contact: contact?.id,
      'filters[54]': debouncedPropertySearch,
      offset: page + 1,
      limit: pageSize,
    });
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  return (
    <div className='display-flex ads-form-container'>
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col xl={12}>
              <fieldset className='row row-16'>
                <Col xl={12}>
                  <legend className='heading-block'>Toate Proprietatile</legend>
                </Col>
                <Col xl={4} className='col-ads-sm'>
                  <div className='form-item form-item-search mb-lg'>
                    <Input
                      type='search'
                      placeholder='Cauta Proprietate'
                      value={propertySearch ?? ''}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPropertySearch(e.target.value)
                      }
                    />
                    <button className='form-item-group-icon'>
                      <IconSearch />
                    </button>
                  </div>
                </Col>
              </fieldset>
            </Col>
            <Col xl={12} style={{ position: 'relative' }}>
              {loading ? (
                <Spinner />
              ) : properties?.length > 0 ? (
                properties.map((property: Property) => (
                  <PropertyListItem
                    key={`${property.id} ${property?.title ?? ''}`}
                    item={property}
                    showChecked={false}
                  />
                ))
              ) : (
                propertySearch?.length &&
                propertySearch?.length > 0 && (
                  <p className='empty-text'>
                    Cautarea ta a returnat 0 Rezultate
                  </p>
                )
              )}
              {pageCount > 1 && (
                <Pagination
                  isSelectPage={false}
                  currentPage={page}
                  itemsPerPage={pageSize}
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                  setItemsPerPage={setPageSize}
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default AdsPropertiesForm;
